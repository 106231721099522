import { Component } from 'react';

import ModuleIoRow from './moduleiorow'
import DownloadFile from './downloadfile'
import ModuleError from './moduleerror'

// icons
import {
  TagFill,
  UiRadios,
  ChatRightTextFill,
  Diagram3Fill,
  Box,
  LockFill,
  ArrowBarUp,
} from 'react-bootstrap-icons';

import './modulepage.css'
import DxfPage from '../dxfpage';

interface ModulePageProps {
  page: DxfPage;
}

export default class ModulePage extends Component<ModulePageProps> {

  render() {
    const p = this.props.page;
    const module = p.module;
    const io = p.module?.io;

    // add rows to table
    let rows: JSX.Element[] = [];
    for (let i in io) {
    	// console.log("io list", p.io, i);
      rows.push( <ModuleIoRow io={io[i]} key={io[i].aliasTo + "|" + i}/> )
    }

    // download button
    let download: JSX.Element | null = null;
    if (p.extension === "dwg") {
      const newfilename = p.filename.replaceAll(".dwg",".dxf")

      download = (<DownloadFile
            filename={newfilename}
            text={"Converted DXF"}
            title={"Download the DXF file after conversion from DWG with LibreDXF"}
            type="image/x-dxf"
            data ={p.parsed.txtOriginal} />)
    }


    // thumbnail if available
    let thumb: JSX.Element | null = null;
    if (p.thumb) {
      // concvert p.thumb to base64
      // Do not use Buffer. It isn't available in browsers
      // const encoded = "data:image/bmp;base64," + Buffer.from(p.thumb).toString('base64');

      const encoded = "data:image/bmp;base64," + p.thumb;
      
      // Previously:
      // const encoded = "data:image/bmp;base64," + p.thumb.toString('base64');


      thumb = (
        <div className="ModulePage-Thumbnail">
          <img src={encoded} alt={p.filename + " thumbnail"} />
        </div>
      )
    }

    // heading for the table
    let title = (<h2 className="error">{p.filename} | Could not parse PLC Tags {download}</h2>)
    let header: JSX.Element | null = null
    let renderTable = false;
    if ( module && module.rackLocation && module.module) {
      title = (
        <h2>
          {p.filename} {download} <a href="#CompanyLogo" className="ScrollTop"><ArrowBarUp /> Scroll to top</a>
        </h2>
      )
      header = (
        <div className="ModulePage-Header-Details">
          <h3>{module.rackLocation} - Rack {module.rackNumber}</h3>
          <h3>Slot {module.slotNumber}</h3>
          <h3>{module.module.description}</h3>
        </div>
      )
      renderTable = true;
    }
    else if (module?.module?.ignore) {
      title = (<h2>{p.filename} Ignored</h2>);
      header = (
        <div className="ModulePage-Header-Details">
          <h3>{module.module.description}</h3>
        </div>
      )
    }

    // return HTML
    return (
      <div className="ModulePage" id={p.id} >
        <div className="ModulePage-Header">
          {p.thumb ? (<div className="ModulePage-Header-Side">
            {thumb}
          </div>): null}
          {title}
          {header}
          <ModuleError error={p.error} />
          <ModuleError error={p.warn} addClass="file-warn" />
        </div>
        {(renderTable ? (
        <table cellPadding={0} cellSpacing={0} className="ModulePage-Table">
          <thead>
            <tr>
            <th><TagFill /> Name</th>
            <th><Diagram3Fill /> Alias To</th>
            <th><Box /> Data Type</th>
            <th><LockFill /> Is Safety</th>
            <th><ChatRightTextFill /> Description</th>
            <th><UiRadios /> Schem. Point</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table> ) : null)}
      </div>
    )
  }
}
