import type {KnownIO} from "../types/io";

let knownIoRollStand: Array<KnownIO> = [

	///////////////////////////////////////////////////
	///////////////////////////////////////////////////
	//
	// ROLL STAND
	//
	///////////////////////////////////////////////////
	///////////////////////////////////////////////////

	{
		examples: [
			"ROLL STAND EMERGENCY STOP CIRCUIT",
			"EMERGENCY STOP SAFETY INPUT",
		],
		description: [
			/EMERGENCY STOP CIRCUIT/i,
			/EMERGENCY STOP SAFETY INPUT/i,
		],
		name: "{LL}_EStop_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},


	{
		examples: [
			"GUILLOTINE EMERGENCY STOP PUSH-BUTTON",
		],
		description: [
			/GUILLOTINE (EMERGENCY STOP|ESTOP)/i,
		],
		name: "RS_EStop_Guillotine_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},

	// IN PROXIMITY OF DIE LIMIT SWITCH
	{
		// ROLL STAND IN PROXIMITY OF DIE LS, NC
		examples: [
			"CLOSE TO DIE N.C. SAFETY INPUT",
			"ROLL STAND CLOSE TO DIE N.C.",
			"ROLL STAND TRACTION CLOSE APPROACH CH B"
		],
		description: [
			/IN PROXIMITY OF DIE.*NC/i,
			/CLOSE TO DIE N.C./i,
			/ROLL STAND TRACTION CLOSE APPROACH CH B/i,
		],
		name: "RS_Traction_CloseToDie_SwNC",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},
	{
		// ROLL STAND IN PROXIMITY OF DIE LS, NO
		examples: [
			"IN PROXIMITY OF DIE.*NO",
			"CLOSE TO DIE N.O. SAFETY INPUT",
			"ROLL STAND CLOSE TO DIE N.O.",
			"ROLL STAND TRACTION CLOSE APPROACH CH A"
		],
		description: [
			/IN PROXIMITY OF DIE.*NO/i,
			/CLOSE TO DIE N.O./i,
			/ROLL STAND TRACTION CLOSE APPROACH CH A/i,
		],
		name: "RS_Traction_CloseToDie_SwNO",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},
	{
		// ROLL STAND IN PROXIMITY OF DIE LIMIT SWITCH
		// Fallback to alternating NC/NO if unspecified
		description: /IN PROXIMITY OF DIE/i,
		name: "RS_Traction_CloseToDie_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		// isEven: true,
		rollStand: true
	},

	

	// CLEAN MODE PLATFORM USED BY PI
	
	{
        examples: [
            "CLEAN MODE OPERATOR CONTROL STATION E-STOP (PBL)", // 18140
			"LIGHT TOWER TABLE CLEAN MODE OPERATOR CONTROL STATION E-STOP PBL INDICATOR", // 18184
			
        ],
        description: [
            /CLEAN MODE.* E[ |-]STOP/i,
        ],
        name: "RS_Clean_Estop_PBL",
        type: "BOOL",
        direction: "out",
        rollStand: true,
        safetyType: "Standard"
    },
	{
        examples: [
            "ROLL STAND CLEAN MODE PBL", // 18140
        ],
        description: [
            /CLEAN MODE.* PB/i,
        ],
        name: "RS_Clean_ModePB",
        type: "BOOL",
        direction: "in",
        rollStand: true,
        safetyType: "Safety"
    },
	{
        examples: [
            "ROLL STAND CLEAN MODE PBL INDICATOR (WHITE)", // 18059
        ],
		exclude_examples: [
			"LIGHT TOWER TABLE CLEAN MODE OPERATOR CONTROL STATION E-STOP PBL INDICATOR", // 18184
		],
        description: [
            /CLEAN MODE.* PB. IND/i,
        ],
        name: "RS_Clean_ModePBL",
        type: "BOOL",
        direction: "out",
        rollStand: true,
        safetyType: "Standard"
    },
	{
        examples: [
            "ROLL STAND CLEAN MODE OPERATOR CONTROL STATION E-STOP PBL", // 18059
        ],
        description: [
            /CLEAN MODE.* E[ |-]STOP/i,
        ],
        name: "RS_Clean_Estop_PB",
        type: "BOOL",
        direction: "in",
        rollStand: true,
        safetyType: "Standard"
    },


	{
        examples: [
            "ROLL STAND RIGHT SIDE PLATFORM GAP ADJUSTMENT OPERATOR CONTROL STATION E-STOP PBL", // 18059
			"ROLL STAND LEFT SIDE PLATFORM GAP ADJUSTMENT OPERATOR CONTROL STATION E-STOP PBL", // 18059
        ],
        description: [
            /(LEFT|RIGHT).* PLATFORM.* GAP.* OPERATOR.* E[-| ]STOP PB/i,
        ],
        name: "RS_GapPlatform_{1:Cap}_Estop_PB",
        type: "BOOL",
        direction: "in",
        rollStand: true,
        safetyType: "Standard"
    },

	{
        examples: [
            "ROLL STAND CLEAN MODE FLATFORM UNLOCK SAFETY RELAY FEEDBACK", // 18140, yes it said FLATFORM
        ],
        description: [
            /CLEAN MODE.* UNLOCK.*FEEDBACK/i,
        ],
        name: "RS_CleanPlatform_Unlocked",
        type: "BOOL",
        direction: "in",
        rollStand: true,
        safetyType: "Safety"
    },
	{
		examples: [
			"ROLL STAND CLEAN MODE PLATFORM LOCKING SAFETY SWITCH OPEN",
		],
		description: /CLEAN MODE PLATFORM/i,
		name: "RS_CleanPlatform_SwOpened",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND CLEAN MODE PLATFORM UNLOCK SAFETY RELAY",
		],
		description: /CLEAN MODE PLATFORM UNLOCK/i,
		name: "RS_CleanPlatform_Unlock",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND CLEAN MODE PLATFORM LOCKING SAFETY SWITCH",
		],
		description: /CLEAN MODE PLATFORM.*LOCKING/i,
		name: "RS_CleanPlatform_Switch_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},


	{
		examples: [
			"AUX ROLLS AREA SAFETY SCANNER MAIN ENCL",
		],
		description: /AUX ROLLS AREA.* SCANNER/i,
		name: "RS_AuxRoll_AreaScanner_Aux",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		rollStand: true
	},
	{
		examples: [
			"HMI AUX CARRIAGE FORWARD SSL",
			"HMI AUX CARRIAGE REVERSE SSL"
		],
		description: /AUX.* CARRIAGE (FORWARD|REVERSE) SSL/i,
		name: "RS_AuxRoll_Carriage_{1:Cap}_PB",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		rollStand: true
	},
	{
		examples: [
			"HMI AUX CARRIAGE STOP PB MAIN", // IS THIS SUPPOSED TO BE AN E-STOP???
		],
		description: /AUX.* CARRIAGE STOP PB/i,
		name: "RS_AuxRoll_Carriage_Stop_PB",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		rollStand: true
	},
	{
		examples: [
			"HMI AUX CARRIAGE FORWARD SSL",
		],
		description: /AUX ROLLS CARRIAGE (FORWARD|REVERSE) LIMIT/i,
		name: "RS_AuxRoll_Carriage_{1:Cap}_Limit",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		rollStand: true
	},
	{
		examples: [
			"HMI AUX CARRIAGE MOTION ENABLED",
		],
		description: /HMI AUX CARRIAGE MOTION ENABLED/i,
		name: "RS_AuxRoll_Carriage_Warn",
		type: "BOOL",
		direction: "out",
		safetyType: "Standard",
		rollStand: true
	},
	{
		examples: [
			"AUX CARRIAGE MOTOR BRAKE RELEASE CR TO ON",
		],
		description: /AUX CARRIAGE MOTOR BRAKE RELEASE/i,
		name: "RS_AuxRoll_Carriage_Brake_Coil",
		type: "BOOL",
		direction: "out",
		safetyType: "Standard",
		rollStand: true
	},

	{
		examples: [
			"ROLL STAND SAFETY LASER SCANNER AUXILIARY OUTPUT 1",
		],
		description: /SAFETY LASER SCANNER AUX.* ([0-9])/i,
		name: "RS_AreaScanner_Aux{1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND SAFETY LASER SCANNER",
			"ROLL STAND AREA SCANNER CH 1"
		],
		description: /ROLL STAND (SAFETY LASER|AREA) SCANNER/i,
		name: "RS_AreaScanner_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},
	
	{
		examples: [
			"ROLL STAND ENABLING DEVICE OFF",
		],
		description: /ROLL STAND ENABLING DEVICE OFF/i,
		name: "RS_SafetyEnable_Pressed_Off",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND SAFETY ENABLING DEVICE",
			"ROLL STAND ENABLING DEVICE CH 1"
		],
		description: /ROLL STAND (SAFETY )?ENABLING DEVICE/i,
		name: "RS_SafetyEnable_Pressed_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},



	// TRACTION MOTOR FORWARD LIMIT SWITCH
	{
		examples: [
			"ROLL STAND TRACTION MOTOR FORWARD LS, NC",
		],
		description: [
			/TRACTION MOTOR FORWARD LS, NC/i,
		],
		name: "RS_Traction_FwdAtDie_SwNC",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND TRACTION MOTOR FORWARD LS, NO",
		],
		description: [
			/TRACTION MOTOR FORWARD LS, NO/i,
		],
		name: "RS_Traction_FwdAtDie_SwNO",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND TRACTION MOTOR FORWARD LS",
		],
		// Fallback to alternating NC/NO if unspecified
		description: /TRACTION MOTOR FORWARD LS/i,
		name: "RS_Traction_FwdAtDie_SwNC",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		isEven: true,
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND TRACTION MOTOR FORWARD LS",
		],
		// Fallback to alternating NC/NO if unspecified
		description: /TRACTION MOTOR FORWARD LS/i,
		name: "RS_Traction_FwdAtDie_SwNO",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		isOdd: true,
		rollStand: true
	},

	// TRACTION MOTOR REVERSE LIMIT SWITCH
	{
		examples: [
			"ROLL STAND TRACTION MOTOR REVERSE LS, NC",
		],
		description: /TRACTION MOTOR REVERSE LS, NC/i,
		name: "RS_Traction_RevAwayDie_SwNC",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND TRACTION MOTOR REVERSE LS, NO",
		],
		description: /TRACTION MOTOR REVERSE LS, NO/i,
		name: "RS_Traction_RevAwayDie_SwNO",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},


	{
		examples: [
			"ROLL STAND CHILLER SUPPLY PRESSURE TRANSDUCER 0-100 PSI : 4-20mA",
		],
		description: /CHILLER SUPPLY PRESSURE/i,
		name: "{LL}_ChillerSupply_PressRaw",
		analog: true,
		direction: "in",
		rollStand: true
	},
	
	{
		examples: [
			"EXPANSION TANK WATER PRESSURE TRANSDUCER 0-360 PSI : 4-20mA EXPANSION",
		],
		description: /EXPANSION TANK WATER PRESSURE/i,
		name: "{LL}_ExpansionTank_PressureRaw",
		analog: true,
		direction: "in",
		rollStand: true
	},

	{
		examples: [
			"TRACTION DRIVE ENCODER FEEDBACK 0-10V",
			"ROLL STAND TRACTION DRIVE POSITION FEEDBACK",
			"ROLL STAND TRACTION DRIVE ABSOLUTE ENCODER",
			"TRACTION ENCODER 0-10V", // 18122-r122
		],
		description: /TRACTION.* (ENCODER|POSITION FEEDBACK)/i,
		name: "RS_Traction_PosRaw",
		analog: true,
		direction: "in",
		rollStand: true
	},

	{
		examples: [
			"ROLL STAND TRACTION MOTOR REVERSE LS",
		],
		// Fallback to alternating NC/NO if unspecified
		description: /TRACTION MOTOR REVERSE LS/i,
		name: "RS_Traction_RevAwayDie_SwNC",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		isEven: true,
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND TRACTION MOTOR REVERSE LS",
		],
		// Fallback to alternating NC/NO if unspecified
		description: /TRACTION MOTOR REVERSE LS/i,
		name: "RS_Traction_RevAwayDie_SwNO",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		isOdd: true,
		rollStand: true
	},


	{
		examples: [
			"ROLL STAND TRACTION MOTOR REVERSE LS",
			"ROLL STAND TRACTION DRIVE REVERSE PROX", // 18059
			"TRACTION REVERSE LIMIT OK N.C. PROX"
		],
		description: /TRACTION (MOTOR|DRIVE)?[ ]?REVERSE (LS|PROX|LIMIT)/i,
		name: "RS_Traction_RevAwayDie_Sw",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND TRACTION MOTOR FORWARD LS",
			"ROLL STAND TRACTION DRIVE FORWARD PROX", // 18059
			"TRACTION FORWARD LIMIT OK N.C. PROX",
		],
		description: /TRACTION (MOTOR|DRIVE)?[ ]?FORWARD (LS|PROX|LIMIT)/i,
		name: "RS_Traction_FwdAtDie_Sw",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		rollStand: true
	},



	{
		examples: [
			"ROLL STAND SYSTEM RESET PBL",
			"SYSTEM RESET SAFETY INPUT",
			"SYSTEM RESET PUSH BUTTON PRESSED"
		],
		description: /SYSTEM RESET/i,
		name: "EStop_Reset_PB",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},

	{
		examples: [
			"ROLL STAND ROLL LOCKOUT SAFETY CH A",
		],
		description: [
			/ROLL STAND ROLL LOCKOUT SAFETY CH[ ]?(A|B|1|2)/i,
		],
		name: "RS_Lockout_Ch{1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND ROLL LOCKOUT SAFETY SWITCHES",
			"ROLL STAND ROLL LOCKOUT SAFETY",
		],
		description: [
			/ROLL STAND ROLL LOCKOUT SAFETY/i,
		],
		name: "RS_Lockout_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},





	{
		examples: [
			"COATER INFEED HINGED GUARD SAFETY SWITCH",
			"COATER INFEED HINGED GUARD BARREL SAFETY SW",
			"COATER INFEED HINGED GUARD",
			"TRANSFER ROLL COATER HINGED GUARD SAFETY SWITCHES CH2"
		],
		description: /COATER (INFEED )?HINGED GUARD/i,
		name: "RS_Coater_InfeedHinge_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},
	{
		examples: [
			"TRANSFER ROLL COATER GUARD SAFETY SWITCHES ",
			"TRANSFER ROLL COATER/ PULL ROLL GUARD",
			"TRANSFER ROLL COATER GUARD DOOR SAFETY SWITCHES CH2",
		],
		description: /TRANSFER ROLL COATER.* GUARD/i,
		name: "RS_Coater_Infeed_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},
	
	{
		examples: [
			"COATER GUARD SAFETY SWITCH NOTE: PI SUPPLIED COATER",
		],
		description: /COATER.* GUARD SAFETY SWITCH/i,
		name: "RS_Coater_Guard_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},

	{
		examples: [
			"ROLL STAND CLEAN MODE PLATFORM SAFETY MAT CONNECTED", // 18184 - R162
		],
		description: /CLEAN MODE PLATFORM SAFETY MAT CONNECTED/i,
		name: "RS_CleanPlatform_Mat_Connected",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND CLEAN MODE PLATFORM SAFETY MAT", // 18184 - R162
		],
		description: /CLEAN MODE PLATFORM SAFETY MAT/i,
		name: "RS_CleanPlatform_Mat_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},
	
	{
		examples: [
			"AUXILIARY ROLL 1 PLATFORM SAFETY MAT",
			"AUXILIARY ROLL 2 PLATFORM SAFETY MAT"
		],
		description: /AUX.*ROLL ([0-9]?).*SAFETY MAT/i,
		name: "RS_AuxRollMat{1}_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND AUXILIARY ROLLS GUARD DOORS SAFETY SWITCHES",
		],
		description: /AUX.*ROLL.*GUARD DOORS.*SAFETY/i,
		name: "RS_AuxRollDoors_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND GUARD SAFETY SWITCHES CIRCUIT ",
		],
		description: /ROLL STAND GUARD SAFETY SWITCHES/i,
		name: "RS_RollGuards_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},

	{
		examples: [
			"SAFETY GATE INTERLOCK BYPASS SELECTOR SWITCH",
		],
		description: /SAFETY GATE.* BYPASS SELECTOR/i,
		name: "RS_SafetyGate_Bypass_SSW",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},




	{
		examples: [
			"ROLL STAND LEFT SIDE GAP ADJUSTMENT AC DRIVES (3.2 KW) SAFE TORQUE-OFF SIGNAL",
			"ROLL STAND RIGHT SIDE GAP ADJUSTMENT AC DRIVES (3.2 KW) SAFE TORQUE-OFF SIGNAL",
		],
		description: /(LEFT|RIGHT).* GAP ADJUSTMENT.*DRIVE.*SAFE TORQUE/i,
		name: "RS_Gap_{1:Cap}Drive_STO_Ch{EVEN+1}",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND GAP ADJUSTMENT AC DRIVES SAFE TORQUE-OFF SIGNAL",
			"GAP ADJUSTMENT SERVO RACK SAFE TORQUE OFF"
		],
		description: /GAP ADJUSTMENT.*(SERVO|DRIVE).*SAFE TORQUE/i,
		name: "RS_Gap_Drive_STO_Ch{EVEN+1}",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		rollStand: true
	},

	{
		examples: [
			"AUX CARRIAGE SAFE TORQUE OFF MAIN ENCL",
		],
		description: [
			/AUX CARRIAGE SAFE TORQUE OFF/i,
		],
		name: "RS_AuxRoll_Drives_STO_Ch{EVEN+1}",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND TOP ROLL/ MIDDLE ROLL/ BOTTOM ROLL/ PULL ROLL/ AC DRIVES SAFE TORQUE-OFF SIGNAL",
			"ROLL STAND ROLL AC DRIVES SAFE TORQUE-OFF SIGNAL",
			"STO SIGNAL TO ROLL STAND TOP ROLL, MIDDLE ROLL, BOTTOM ROLL, PULL ROLL, AC DRIVES",
			"ROLL DRIVES SAFE TORQUE OFF TOP ROLL MIDDLE ROLL BOTTOM ROLL MAIN ENCL",
			"ROLL STAND ROLL AC DRIVES SAFE TORQUE-OFF SIGNAL STO SIGNAL TO ROLLS 1-7, PULL ROLL",
			"ROLL STAND ROLL #1, ROLL #2, ROLL #3, ROLL #4, ROLL #5, ROLL #6 & ROLL #7 AC DRIVES SAFE TORQUE-OFF SIGNAL STO SIGNAL TO ROLL AC DRIVES",
		],
		exclude_examples: [
			"ROLL STAND GAP ADJUSTMENT DRIVES SAFE TORQUE-OFF SIGNAL STO SIGNAL TO GAP ADJUSTMENT DRIVES",
			"ROLL STAND TRACTION, HEIGHT ADJUST, HYDRAULIC PUMP AC DRIVES SAFE TORQUE-OFF SIGNAL STO SIGNAL TO TRACTION, HEIGHT ADJ. HYD PUMP AC DRIVES"
		],
		description: [
			/ROLL DRIVES.* SAFE TORQUE/i,
			/(STO|SAFE TORQUE[ |-]OFF).*((TOP|MIDDLE|BOTTOM|ROLL [#]?[0-9])( ROLL)?[/|,]?[ ]?){2,}.* DRIVES/i,
			/((TOP|MIDDLE|BOTTOM|ROLL [#]?[0-9])[/|,]?[ ]?){2,}.* DRIVES (STO|SAFE TORQUE[ |-]OFF)/i,
			/(STO|SAFE TORQUE[ |-]OFF).*ROLLS/i,
		],
		name: "RS_Roll_Drives_STO_Ch{EVEN+1}",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND TOP ROLL AC DRIVE SAFE TORQUE-OFF SIGNAL",
		],
		description: /ROLL.*(TOP|MIDDLE|BOTTOM|PULL).*DRIVE.* SAFE TORQUE/i,
		name: "RS_Roll_{1:Cap}Drive_STO_Ch{EVEN+1}",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		rollStand: true
	},

	{
		examples: [
			"ROLL STAND TRACTION AC DRIVE SAFE TORQUE-OFF SIGNAL",
			"STO SIGNAL TO TRACTION DRIVE"
		],
		description: [
			/TRACTION AC DRIVE SAFE TORQUE/i,
			/STO .* TRACTION DRIVE/i,
		],
		name: "RS_Traction_Drive_STO_Ch{EVEN+1}",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND HYDRAULIC PUMP AC DRIVE SAFE TORQUE-OFF SIGNAL",
			"HPU AC DRIVE SAFE TORQUE OFF"
		],
		description: /(HYDRAULIC PUMP|HPU) AC DRIVE SAFE TORQUE/i,
		name: "RS_HPU_Drive_STO_Ch{EVEN+1}",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND TRACTION, HEIGHT ADJUST, HYDRAULIC PUMP AC DRIVES SAFE TORQUE-OFF SIGNAL",
		],
		description: /TRACTION, HEIGHT.* HYD.*PUMP.* SAFE TORQUE/i,
		name: "RS_OtherDrives_STO_Ch{EVEN+1}",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		rollStand: true
	},

	{
		examples: [
			"EDGE SLITTER AC DRIVE SAFE TORQUE-OFF SIGNAL",
			"STO SIGNAL TO EDGE SLITTER AC DRIVE",
		],
		description: [
			/EDGE SLITTER AC DRIVE SAFE TORQUE-OFF SIGNAL/i,
			/STO .*SLITTER AC DRIVE/i,
		],
		name: "RS_EdgeSlitter_STO_Ch{EVEN+1}",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		rollStand: true
	},


	{
		examples: [
			"WINDER E-STOP RELAY 1 ESR-W1", // 18140 PI
			"WINDER E-STOP RELAY 2 ESR-W2", // 18140 PI
			"WINDER E-STOP RELAY 3 ESR-W3", // 18140 PI
		],
		description: /WINDER E(-|MERGENCY )STOP RELAY[ ]?([0-9]?)/i,
		name: "RS_Winder_EStop{2}_Coil",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		rollStand: true,
	},
	{
		examples: [
			"WINDER E-STOP RELAY 1 ESR-W1", // 18140 PI
			"WINDER E-STOP RELAY 2 ESR-W2", // 18140 PI
			"WINDER E-STOP RELAY 3 ESR-W3", // 18140 PI
			"WINDER E-STOP RELAYS WINDER ESR 1", // 18184 PI
		],
		description: /WINDER E(-|MERGENCY )STOP RELAY[ ]?([0-9]?)/i,
		name: "RS_Winder_EStop{2}_Feedback",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true,
	},

	{
		examples: [
			"WINDER IN WARNING CONDITION", // 18140 PI
		],
		description: /WINDER .*WARNING/i,
		name: "RS_Winder_Warning",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		rollStand: true,
	},
	{
		examples: [
			"WINDER IN FAULT CONDITION", // 18140 PI
		],
		description: /WINDER .*FAULT/i,
		name: "RS_Winder_Fault",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		rollStand: true,
	},


	{
		examples: [
			"ROLL STAND E-STOP RELAY, L-R11212",
			"ROLL STAND EMERGENCY STOP RELAY 1",
			"ROLL STAND EMERGENCY STOP RELAY 2,",
			"MAIN ENCL E-STOP SWITCHED",
		],
		description: [
			/E(-|MERGENCY )STOP RELAY[ ]?([0-9]?)/i,
			/MAIN ENCL E-STOP/i,
		],
		name: "RS_EStop{2}_Coil",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		rollStand: true,
		pullroll: false,
	},
	{
		examples: [
			"ROLL STAND E-STOP RELAY SAFETY RELAY FEEDBACK",
			"ROLL STAND EMERGENCY STOP SAFETY RELAY FEEDBACK",
			"ESR-R1 EDM SAFETY INPUT MAIN ENCL E-STOP SWITCHED",
		],
		description: [
			/E(-|MERGENCY )STOP.* RELAY[ ]?([0-9]?)/i,
			/MAIN( )ENCL E-STOP[ ]?([0-9]?)/i,
		],
		name: "RS_EStop{2}_Feedback",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true,
		pullroll: false,
	},

	{
		examples: [
			"WINDER EMERGENCY STOP CH. 1 & CH. 2 (TO MONITOR STATUS OF WINDER E-STOP CIRCUIT)", // 18140-r160a.dxf
		],
		description: /WINDER (EMERGENCY |E-)STOP.* CH/i,
		name: "RS_Winder_EStop_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true,
	},
	{
		examples: [
			"WINDER E-STOP RELAYS WINDER E-STOP RELAY 1", // 18140
		],
		description: /WINDER E-STOP.*RELAY/i,
		name: "RS_Winder_EStop_Feedback",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true,
	},


	{
		examples: [
			"ROLL STAND ROLL CLOSE SAFETY RELAY, L-R11304",
			"ROLL STAND ROLLS CLOSE ENABLE SAFETY RELAY SR-RRC",
			"ROLL CLOSE ENABLE",
		],
		description: [
			/ROLL[S]? CLOSE SAFETY RELAY[ ]?([0-9]?)/i,
			/ROLL[S]? CLOSE ENABLE[ ]?([0-9]?)/i,
		],
		name: "RS_RollClose_Safety{1}_Coil",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		rollStand: true,
		pullroll: false,
	},
	{
		examples: [
			"ROLL STAND ROLL CLOSE SAFETY RELAY FEEDBACK",
			"ROLL STAND ROLL CLOSE SAFETY RELAY 1 FEEDBACK",
			"SR-RRC EDM SAFETY INPUT ROLL CLOSE ENABLE",
		],
		description: [
			/ROLL CLOSE SAFETY RELAY[ ]?([0-9]?) FEEDBACK/i,
			/ROLL[ ]?([0-9]?) CLOSE ENABLE/i,
		],
		name: "RS_RollClose_Safety{1}_Feedback",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},


	{
		examples: [
			"TRANSFER ROLL COATER SAFETY RELAY, L-R11404",
			"TRANSFER ROLL COATER CLOSE ENABLE SAFETY RELAY SR-TRC",
			"COATER SAFETY RELAY", // 18140
		],
		description: [
			/COATER.* SAFETY RELAY/i,
		],
		name: "RS_Coater_Safety_Coil",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		rollStand: true
	},
	{
		examples: [
			"TRANSFER ROLL COATER SAFETY RELAY FEEDBACK",
			"TRANSFER ROLL SAFETY RELAY FEEDBACK SR-TRC"
		],
		description: /TRANSFER ROLL.* SAFETY RELAY FEEDBACK/i,
		name: "RS_Coater_Safety_Feedback",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},



	{
		examples: [
			"EDGE SLITTER BLADE CLOSE SAFETY RELAY",
		],
		description: /EDGE SLITTER BLADE CLOSE SAFETY RELAY/i,
		name: "RS_EdgeSlitter_Safety_Coil",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		rollStand: true
	},
	{
		examples: [
			"EDGE SLITTER BLADE CLOSE SAFETY RELAY FEEDBACK",
		],
		description: /EDGE SLITTER BLADE CLOSE SAFETY RELAY FEEDBACK/i,
		name: "RS_EdgeSlitter_Safety_Feedback",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},
	


	{
		examples: [
			"HPU HP ENABLE",
		],
		description: /HPU HP ENABLE/i,
		name: "RS_HydUnit_Enable_Safety",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		rollStand: true
	},
	{
		examples: [
			"HPU HP ENABLE",
		],
		description: /HPU HP ENABLE/i,
		name: "RS_HydUnit_Enable_Feedback",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},

	{
		examples: [
			"ROLL STAND HYDRAULIC UNIT, DUMP SAFETY RELAY",
			"ROLL STAND HPU DUMP VALVE SAFETY RELAY SR-RHP", // 18085-r144
		],
		description: /ROLL STAND (HYDRAULIC UNIT|HPU)[,]? DUMP.* SAFETY RELAY/i,
		name: "RS_HydUnit_Dump_SafetyCoil",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND HYDRAULIC UNIT DUMP SAFETY RELAY FEEDBACK",
			"ROLL STAND HYDRAULIC UNIT DUMP VALVE SAFETY RELAY FEEDBACK", // 18085-r142a
		],
		description: /ROLL STAND (HYDRAULIC UNIT|HPU) DUMP.* SAFETY RELAY FEEDBACK/i,
		name: "RS_HydUnit_Dump_Feedback",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},




	

	
	{
		examples: [
			"TEMPERATURE STATION MAIN CONTROL ENCLOSURE E-STOP PBL",
			
		],
		description: [
			/TEMPERATURE STATION MAIN CONTROL ENCLOSURE E-STOP PBL/i,
		],
		name: "TS_Enclosure_Estop_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Safety",
	},

	{
		examples: [
			"ROLL STAND MAIN CONTROL ENCLOSURE E-STOP PBL",
			"MAIN ENCL E-STOP PRESSED",
		],
		description: [
			/ROLL STAND MAIN CONTROL ENCLOSURE E-STOP/i,
			/MAIN ENCL.* E-STOP PRESSED/i,
		],
		name: "RS_Enclosure_Estop_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},

	{
		examples: [
			"ROLL STAND MAIN CONTROL ENCLOSURE E-STOP (PBL)",
		],
		description: [
			/ROLL STAND MAIN CONTROL ENCLOSURE E-STOP/i,
		],
		name: "RS_Enclosure_Estop_PBL",
		type: "BOOL",
		direction: "out",
		rollStand: true,
		safetyType: "Standard",
	},

	{
		examples: [
			"TEMPERATURE STATION MAIN CONTROL ENCLOSURE E-STOP PBL",
		],
		description: /TEMPERATURE STATION MAIN CONTROL ENCLOSURE E-STOP/i,
		name: "TS_Enclosure_Estop_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},
	{
		examples: [
			"ROLL STAND OPERATOR CONTROL PENDANT E-STOP PBL",
			"HMI E-STOP PRESSED",
		],
		description: [
			/ROLL STAND OPERATOR CONTROL PENDANT E-STOP/i,
			/HMI E-STOP PRESSED/i,
		],
		name: "RS_Pendant_Estop_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},

	{
		examples: [
			"ROLL STAND OPERATOR CONTROL PENDANT E-STOP (PBL)",
		],
		description: [
			/ROLL STAND OPERATOR CONTROL PENDANT E-STOP/i,
		],
		name: "RS_Pendant_Estop_PBL",
		type: "BOOL",
		direction: "out",
		rollStand: true,
		safetyType: "Standard",
	},



	{
		examples: [
			"ROLL STAND TRACTION OPERATOR STATION E-STOP PBL",
		],
		description: /ROLL STAND TRACTION OPERATOR.* E-STOP/i,
		name: "RS_Traction_Estop_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},
	{
		examples: [
			"ROLL STAND CHROME ROLLS INFEED PULL CORD SW",
			"ROLL STAND CHROME ROLLS INFEED LEFT PULL CORD COS",
			"ROLL STAND CHROME ROLLS INFEED RIGHT PULL CORD COS",
			"CHROME ROLL INFEED PULL CORD TRIPPED",
			"CHROME ROLL INFEED PULL CORD RIGHT HAND TRIPPED",
			"CHROME ROLL INFEED PULL CORD LEFT HAND TRIPPED ROLL STAND J-BOX"
		],
		description: [
			/CHROME ROLL[S]? INFEED PULL CORD (LEFT|RIGHT)/i,
			/CHROME ROLL[S]? INFEED[ ]?([A-Z]*)( HAND)? PULL CORD/i,
		],
		name: "RS_Chrome_Infeed{1:Cap}_Estop_PullCord",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},
	{
		examples: [
			"ROLL STAND AUXILIARY ROLL 2 INFEED PULL CORD E-STOP COS",
			"AUX. CARRIAGE INFEED PULL CORD TRIPPED",
			"AUX ROLL 5-6 PULL CORD TRIPPED",
			"ROLL STAND CHROME ROLLS OUTFEED/ AUXILIARY ROLL 1 INFEED PULL CORD E-STOP COS",
			"ROLL STAND AUXILIARY ROLL 2 INFEED PULL CORD E-STOP COS",
		],
		description: [
			/AUX.* ROLL ([0-9])?[ ]?INFEED PULL CORD/i,
			/ROLL STAND AUXILIARY ROLL.*([0-9])? INFEED PULL CORD/i,
			/AUX[.]? CARRIAGE INFEED PULL CORD/i,
			/AUX ROLL ([0-9]-[0-9]) PULL CORD TRIPPED/i,
			/CHROME ROLL[S]? OUTFEED.* AUX.* ([0-9]) INFEED PULL CORD/i,
		],
		name: "RS_Aux_Infeed{1_}_Estop_PullCord",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},
	
	{
		examples: [
			"AUX ROLL INFEED LEFT E-STOP PRESSED",
		],
		description: [
			/AUX ROLL INFEED (LEFT|RIGHT)?.*E-STOP/i,
		],
		name: "RS_Aux_Infeed{1:Cap}_Estop_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},
	{
		examples: [
			"AUX. CARRIAGE E-STOP PRESSED LH",
			"AUX. CARRIAGE E-STOP PRESSED RH",
		],
		description: [
			/AUX[.]? CARRIAGE E-STOP PRESSED (LH|RH)/i,
		],
		name: "RS_Aux{1}_Estop_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},

	{
		examples: [
			"ROLL STAND AUXILIARY ROLLS OUTFEED PULL CORD",
			"AUX ROLL 6 OUTFEED PULL CORD TRIPPED",
		],
		description: [
			/ROLL STAND AUXILIARY ROLL.* OUTFEED PULL CORD/i,
			/AUX ROLL [0-9] OUTFEED/i,
		],
		name: "RS_Aux_Outfeed_Estop_PullCord",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},
	{
		examples: [
			"ROLL STAND CHROME ROLLS OUTFEED/ AUXILIARY ROLL 1 INFEED PULL CORD E-STOP COS",
			"ROLL STAND CHROME ROLLS OUTFEED PULL CORD SW",
			"CHROME ROLL OUTFEED PULL CORD TRIPPED",
			"ROLL STAND CHROME ROLLS OUTFEED PULL CORD E-STOP COS",
			"ROLL STAND OUTFEED PULL CORD COS",
			"CHROME ROLL OUTFEED TOP PULL CORD TRIPPED"
		],
		description: [
			/CHROME ROLL[S]? OUTFEED.*[ ]?(TOP|BOTTOM|MIDDLE) PULL CORD/i,
			/CHROME ROLL[S]? OUTFEED.* PULL CORD/i,
			/ROLL STAND.* OUTFEED PULL CORD/i,
		],
		name: "RS_Chrome_Outfeed{1:Cap}_Estop_PullCord",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},


	{
		examples: [
			"TRANSFER ROLL COATER INFEED/OUTFEED PULL CORD E-STOP COS",
		],
		description: [
			/TRANSFER ROLL.* INFEED\/OUTFEED PULL CORD/i,
		],
		name: "RS_Coater_Estop_PullCord",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},
	{
		examples: [
			"TRANSFER ROLL COATER OPERATOR STATION E-STOP PBL",
			"TRANSFER ROLL COATER 7-PB ENCLOSURE E-STOP",
			"TRANSFER ROLL COATER 7-PB ENCLOSURE\nE-STOP PRESSED"
		],
		description: /TRANSFER ROLL COATER.*[ |\n]E-STOP/i,
		name: "RS_Coater_Estop_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},

	
	{
		examples: [
			"THREAD UP ROLL RUN REVERSE E-STOP PRESSED",
		],
		description: /THREAD UP.* E-Stop/i,
		name: "RS_Threadup_Estop_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},
	{
		examples: [
			"TRANSFER ROLL COATER OPERATOR STATION E-STOP PBL",
			"TRANSFER ROLL COATER 7-PB ENCLOSURE E-STOP",
			"TRANSFER ROLL COATER OPERATOR CONTROL STATION E-STOP PBL"
		],
		description: /TRANSFER ROLL COATER.*[ |\n]E-STOP/i,
		name: "RS_Coater_Estop_PBL",
		type: "BOOL",
		direction: "out",
		rollStand: true,
		safetyType: "Standard",
	},


	{
		examples: [
			"SCANNER INFEED PULL CORD E-STOP COS",
		],
		description: /SCANNER INFEED PULL CORD E-STOP/i,
		name: "RS_Scanner_Infeed_Estop_PullCord",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},


	{
		examples: [
			"ROLL STAND INFEED NIP 1 LEFT SIDE PULL CORD COS",
			"ROLL STAND INFEED NIP 1 RIGHT SIDE PULL CORD COS",
		],
		description: [
			/ROLL STAND INFEED NIP ([0-9]+) (LEFT|RIGHT).* PULL CORD/i,
		],
		name: "RS_Roll{1:Cap}_Infeed{2:Cap}_Estop_PullCord",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},
	{
		examples: [
			"ROLL STAND INFEED NIP 2 PULL CORD COS",
			"ROLL STAND INFEED NIP 3 PULL CORD COS",
			"ROLL STAND INFEED NIP 4 PULL CORD COS",
			"ROLL STAND INFEED NIP 5 PULL CORD COS",
		],
		description: [
			/ROLL STAND INFEED NIP ([0-9]+) PULL CORD/i,
		],
		name: "RS_Roll{1:Cap}_Infeed_Estop_PullCord",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},

	{
		examples: [
			"ROLL STAND CHROME ROLLS INFEED RIGHT PULL CORD E-STOP COS",
			"ROLL STAND CHROME ROLLS INFEED LEFT PULL CORD E-STOP COS",
			"ROLL STAND INFEED RIGHT SIDE E-STOP PBL",
			"ROLL STAND INFEED LEFT SIDE E-STOP PBL",
			"ROLL STAND INFEED TOP PULL CORD COS",
			"ROLL STAND CHROME ROLLS INFEED LEFT E-STOP PBL",
			"ROLL STAND CHROME ROLLS INFEED RIGHT E-STOP PBL",
		],
		description: [
			/ROLL STAND CHROME ROLLS INFEED (.*) (E-STOP|PULL CORD)/i,
			/ROLL STAND INFEED.* ([A-Z]*) SIDE (E-STOP|PULL CORD)/i,
			/ROLL STAND INFEED.* ([A-Z]*) (E-STOP|PULL CORD)/i,
		],
		name: "RS_Infeed{1:Cap}_Estop_PullCord",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},

	{
		examples: [
			"END OF LINE OPERATOR CONTROL STATION E-STOP PBL", // 18140
		],
		description: [
			/END OF.* LINE.* E-STOP/i,
		],
		name: "RS_EndOfLine_Estop_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},
	{
		examples: [
			"END OF THE LINE OPERATOR CONTROL STATION E-STOP (PBL)", // 18140
		],
		description: [
			/END OF.* LINE.* E-STOP/i,
		],
		name: "RS_EndOfLine_Estop_PBL",
		type: "BOOL",
		direction: "out",
		rollStand: true,
		safetyType: "Standard",
	},
	

	{
		examples: [
			"PULL ROLL OPERATOR CONTROL STATION E-STOP PBL",
			"PULL ROLL 4-PB ENCLOSURE E-STOP PRESSED",
			"PULL ROLL 4-PB ENCLOSURE E-STOP\nPRESSED",
		],
		description: /PULL ROLL .*(OPERATOR CONTROL|ENCLOSURE).*[ |\n]E-STOP/i,
		name: "PR_PRoll_Operator_Estop",
		type: "BOOL",
		direction: "in",
		rollStand: true,
	},
	{
		examples: [
			"TRANSFER ROLL COATER OPERATOR CONTROL STATION E-STOP PBL",
		],
		description: /TRANSFER ROLL COATER OPERATOR CONTROL STATION E-STOP/i,
		name: "RS_Coater_Estop_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},

	{
		examples: [
			"MOBILE CONVEYOR PULL CORD COS",
		],
		description: /MOBILE CONVEYOR PULL CORD/i,
		name: "RS_MobileConveyor_Estop_PullCord",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},
	{
		examples: [
			"ROLL STAND MOBILE CONVEYOR LEFT SIDE E-STOP PBL",
			"ROLL STAND MOBILE CONVEYOR RIGHT SIDE E-STOP PBL",
		],
		description: /MOBILE CONVEYOR (LEFT|RIGHT)?.* E-STOP/i,
		name: "RS_MobileConveyor{1:Cap}_Estop_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},

	{
		examples: [
			"LINE SPEED OPERATOR CONTROL STATION E-STOP PBL",
		],
		description: /LINE SPEED .*E-STOP/i,
		name: "RS_LineSpeed_Estop_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},
	{
		examples: [
			"LINE SPEED CUT PBL LINE SPEED;   OPERATOR CONTROL STATION",
		],
		description: /LINE SPEED CUT PBL/i,
		name: "RS_LineSpeed_Cut_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"LINE SPEED CUT PBL",
		],
		description: /LINE SPEED CUT PBL/i,
		name: "RS_LineSpeed_Cut_PBL",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},


	{
		examples: [
			"LINE SPEED HOLD PBL",
		],
		description: /LINE SPEED HOLD PB/i,
		name: "RS_LineSpeed_Hold_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"LINE SPEED HOLD PBL",
		],
		description: /LINE SPEED HOLD PBL/i,
		name: "RS_LineSpeed_Hold_PBL",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},

	{
		examples: [
			"LINE SPEED RESUME PBL",
		],
		description: /LINE SPEED RESUME PB/i,
		name: "RS_LineSpeed_Resume_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"LINE SPEED RESUME PBL",
		],
		description: /LINE SPEED RESUME PBL/i,
		name: "RS_LineSpeed_Resume_PBL",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},







	


	{
		examples: [
			"GEAR PUMP LOCKOUT DISCONNECT AUX. CONTACT",
			"GEAR PUMP LOCKOUT DISCONNECT ON / ENABLED",
		],
		description: /GEAR PUMP LOCKOUT DISCONNECT (AUX|ON)/i,
		name: "RS_GP_Lockout_Disco_Aux",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"GEAR PUMP LOCKOUT SAFETY RELAY",
			"GEAR PUMP SAFETY CONTROL RELAY ON MAIN ENCL GEAR PUMP LOCKOUT",
		],
		description: [
			/GEAR PUMP .*SAFETY (CONTROL )?RELAY/i],
		name: "RS_GP_Lockout",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},




	{
		examples: [
			"AUX ROLL 1 BRAKE TEMP OK",
			"AUX ROLL 2 BRAKE TEMP OK",
			"AUX ROLL 2 BRAKE RESISTOR TEMP OK",
		],
		description: [
			/AUX ROLL ([0-9]+) BRAKE.* TEMP/i,
		],
		name: "RS_BrakeResistor_Aux{1:Cap}_TempOkSw",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		pullroll: false,
	},
	{
		examples: [
			"TOP ROLL DYNAMIC BRAKING RESISTORS OVER TEMPERATURE ALARM",
			"TOP ROLL DYNAMIC BRAKING RESISTOR OVER TEMPERATURE ALARM",
			"MIDDLE ROLL DYNAMIC BRAKING RESISTORS OVER TEMPERATURE ALARM",
			"BOTTOM ROLL DYNAMIC BRAKING RESISTORS OVER TEMPERATURE ALARM",
			"TOP ROLL BRAKING RESISTOR OK",
			"BOTTOM ROLL BRAKING RESISTOR OK",
			"ROLL 1 DYNAMIC BRAKING RESISTORS OVER TEMPERATURE ALARM",
			"ROLL 2 DYNAMIC BRAKING RESISTORS OVER TEMPERATURE ALARM",
			"TOP ROLL BRAKE TEMP OK",
			"BOTTOM ROLL BRAKE RESISTOR TEMP OK",
		],
		description: [
			/([A-Z]+) ROLL DYNAMIC BRAKING RESISTOR[S]? OVER TEMPERATURE ALARM/i,
			/ROLL ([0-9]+) DYNAMIC BRAKING RESISTORS OVER TEMPERATURE ALARM/i,
			/([A-Z]+) ROLL (BRAKING RESISTOR|BRAKE|BRAKE RESISTOR).* OK/i,
		],
		name: "RS_BrakeResistor_{1:Cap}_TempOkSw",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		pullroll: false,
	},
	{
		examples: [
			"DYNAMIC BRAKING RESISTORS OVER TEMPERATURE ALARM",
		],
		description: /DYNAMIC BRAKING RESISTORS OVER TEMPERATURE ALARM/i,
		name: "RS_BrakeResistor_TempOkSw",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		pullroll: false,
	},
	{
		examples: [
			"ROLL STAND MAIN ENCLOSURE TEMPERATURE OK",
			"MAIN ENCL A/C TEMP OK",
		],
		description: [
			/MAIN ENCL.* TEMP.* OK/,
		],
		name: "{L}S_Enclosure_Temperature_OK",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		pullroll: false,
	},
	{
		examples: [
			"AIR CONDITIONER HIGH TEMPERATURE ALARM",
			"MAIN ENCL A/C FAULT",
			"MAIN ENCL A/C HI-TEMP ALARM OK",
		],
		description: [
			/AIR CONDITIONER HIGH TEMPERATURE ALARM/i,
			/MAIN ENCL A\/C FAULT/i,
			/MAIN ENCL A\/C HI-TEMP ALARM/i,
		],
		name: "{L}S_Enclosure_Temperature_Alarm",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		pullroll: false,
	},

	{
		examples: [
			"TOP ROLL MOTOR TEMP OK",
			"BOTTOM ROLL MOTOR TEMP OK",
			"TOP ROLL MOTOR THERMOSTAT",
			"MIDDLE ROLL MOTOR THERMOSTAT",
			"BOTTOM ROLL MOTOR THERMOSTAT",
			"PULL ROLL MOTOR THERMOSTAT",
		],
		description: [
			/(TOP|BOTTOM|MIDDLE|BOT|MID) ROLL MOTOR (TEMP|THERMOSTAT)/i,
		],
		name: "RS_{1:Cap}_MtrThermo_TempOkSw",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	
	{
		examples: [
			"AUXILIARY ROLL 2 MOTOR THERMOSTAT",
			"AUX ROLL MOTOR THERMOSTAT",
			"AUX ROLL 1 MOTOR TEMP OK"
		],
		description: /AUX.*([0-9]).* MOTOR (THERMOSTAT|TEMP)/i,
		name: "RS_Aux{1}_MtrThermo_TempOkSw",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"ROLL #1 MOTOR THERMOSTAT",
		],
		description: /ROLL [#]?([0-9]+) MOTOR (THERMOSTAT|TEMP)/i,
		name: "RS_Roll{1}_MtrThermo_TempOkSw",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},



	{
		examples: [
			"EDGE SLITTER MOTOR 1 OL",
			"EDGE SLITTER MOTOR 1 OVERLOAD AUX",
		],
		description: /EDGE SLITTER MOTOR ([0-9]) (OL|OVERLOAD)/i,
		name: "RS_EdgeSlitter{1}_OL",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"EDGE SLITTER MOTOR 1 THERMOSTAT",
			"EDGE SLITTER MOTOR 2 THERMOSTAT",
		],
		description: /EDGE SLITTER MOTOR ([0-9]) THERMOSTAT/i,
		name: "RS_EdgeSlitter{1}_MtrThermo_TempOkSw",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"SLITTER BLADE OPEN PB",
		],
		description: /SLITTER BLADE OPEN PB/i,
		name: "RS_EdgeSlitter_OpenPB",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"SLITTER BLADE CLOSE PBL",
		],
		description: /SLITTER BLADE CLOSE PB/i,
		name: "RS_EdgeSlitter_ClosePB",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"SLITTER BLADE CLOSE PBL",
		],
		description: /SLITTER BLADE CLOSE PB/i,
		name: "RS_EdgeSlitter_ClosePBL",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"SLITTER BLADE CLOSE RELAY",
		],
		description: /SLITTER BLADE CLOSE/i,
		name: "RS_EdgeSlitter_Close",
		type: "BOOL",
		safetyType: "Standard",
		direction: "out",
		rollStand: true
	},



	{
		examples: [
			"ROLL STAND PENDANT ROLL #1 OPEN PB",
			"ROLL STAND PENDANT ROLL #1 CLOSE PBL",
			"ROLL STAND PENDANT ROLL #7 OPEN PB",
			"ROLL STAND PENDANT ROLL #7 CLOSE PBL",
		],
		description: /ROLL STAND PENDANT ROLL [#]?([0-9]+) (OPEN|CLOSE) PB/i,
		name: "RS_Roll{1}_{2:Cap}_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},


	{
		examples: [
			"COATER ROLL CLOSED PRS",
			"TRANSFER ROLL COATER TOP TRANSFER ROLL AT CLOSED",
		],
		description: [
			/COATER ROLL CLOSED/i,
			/TOP TRANSFER ROLL.*[ |\n]CLOSED/i
		],
		name: "RS_Transfer_Closed_Prox",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"COATER HIGH LEVEL FLOAT SWITCH",
			"COATER NOT HIGH LEVEL FLOAT SWITCH",
		],
		description: /COATER .*HIGH LEVEL FLOAT SWITCH/i,
		name: "RS_Coater_High_Sw",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"COATER RESERVOIR LOW LEVEL FLOAT SWITCH (L1=8.5\")",
			"COATER LOW LEVEL FLOAT SWITCH",
			"TRANSFER ROLL COATER TANK OK NOT LOW LEVEL",
		],
		description: [
			/COATER.* LOW LEVEL FLOAT SWITCH/i,
			/COATER TANK OK NOT LOW/i,
		],
		name: "RS_Coater_Low_Sw",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},

	{
		examples: [
			"TRANSFER ROLL COATER TANK OK NOT EMPTY",
		],
		description: [
			/TRANSFER ROLL COATER TANK OK NOT EMPTY/i,
		],
		name: "RS_Coater_Empty_Sw",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},

	
	{
		examples: [
			"AIR KNIFE BLOWER MOTOR MMSP AUX",
			"AIR KNIFE BLOWER MOTOR 14-MMSP AUX", // 18085-R124
			"COATER AIR KNIFE MOTOR CB OK",
			"COATER AIR KNIFE MOTOR CB ON",
		],
		description: [
			/AIR KNIFE BLOWER .*MMSP/i,
			/COATER AIR KNIFE MOTOR CB O/i
		],
		name: "RS_Coater_Blower_MMSP",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"AIR KNIFE BLOWER MOTOR CONTACTOR AUX",
			"AIR KNIFE BLOWER MOTOR CONTACTOR 14M-AUX", // 18085-R124
			"COATER AIR KNIFE MOTOR CR ON",
		],
		description: [
			/AIR KNIFE BLOWER MOTOR CON/i,
			/COATER AIR KNIFE MOTOR CR ON/i,
		],
		name: "RS_Coater_Blower_Aux",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},

	{
		examples: [
			"COATER PUMP #1 MMSP AUX", // 18140
		],
		description: [
			/COATER PUMP .*([0-9]) MMSP/i,
		],
		name: "RS_Coater_Pump{1}_MMSP",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"ANTI-STATIC COATER PUMP MOTOR MMSP AUX.",
			"ANTI-STATIC COATER PUMP 15-MMSP AUX.", // 18085-R124
			"COATER PUMP MOTOR CB OK",
		],
		description: [
			/ANTI-STATIC COATER PUMP .*MMSP/i,
			/COATER PUMP MOTOR CB O/i,
		],
		name: "RS_Coater_Pump_MMSP",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},

	{
		examples: [
			"COATER PUMP #2 CONTACTOR AUX",
		],
		description: [
			/COATER PUMP .*([0-9]) CONT/i,
		],
		name: "RS_Coater_Pump{1}_Aux",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"ANTI STATIC COATER PUMP MOTOR CONTACTOR AUX.",
			"COATER PUMP MOTOR CR ON",
		],
		description: [
			/ANTI STATIC COATER PUMP MOTOR CON/i,
			/COATER PUMP MOTOR CR ON/i,
		],
		name: "RS_Coater_Pump_Aux",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},

	{
		examples: [
			"ROLL STAND HPU PRESSURE SWITCH N.C. PRESSURE BELOW MIN.",
			"ROLL STAND HPU PRESSURE SWITCH N.O. PRESSURE ABOVE MIN.",
			"ROLL STAND HYDRBULIC UNIT PRESSURE BELOW MIN. SWITCH (NC)", // 18085-R124
			"ROLL STAND HYDRAULIC UNIT PRESSURE ABOVE MIN. SWITCH (NO)", // 18085-R124
			"HPU PRESSURE ABOVE MINIMUM N.O.",
			"HPU PRESSURE BELOW MINIMUM N.C.",
		],
		description: [
			/(HPU|HYD).* PRESSURE .*SWITCH N[.]?[C|O][.]? PRESSURE (BELOW|ABOVE) MIN\./,
			/(HPU|HYD).* PRESSURE .*SWITCH .*(NO|NC)/i,
			/(HPU|HYD).* PRESSURE (ABOVE|BELOW) MIN/i,
		],
		name: "RS_Hyd_Press_Sw_{2:Cap}Min",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND HYDRAULIC UNIT PRESSURE SWITCH",
			"ROLL STAND HYDRAULIC UNIT LOW PRESSURE SWITCH",
			"ROLL STAND HPU PRESSURE SWITCH OK",
		],
		description: /ROLL STAND (HYDRAULIC UNIT|HPU)( LOW)? PRESSURE SWITCH/i,
		name: "RS_Hyd_PressAboveMin",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"HYDRAULIC UNIT PRESSURE 4-20mA : 0-5000 PSI",
		],
		description: /(HYDRAULIC UNIT|HPU) PRESSURE/i,
		name: "RS_Hyd_Press",
		analog: true,
		direction: "in",
		rollStand: true
	},


	{
		examples: [
			"ROLL STAND HYDRAULIC UNIT LOW LEVEL SWITCH/",
			"ROLL STAND HPU LOW LEVEL OK",
			"HPU LEVEL SWITCH LOW LEVEL OK N.O.",
		],
		description: /(HYDRAULIC UNIT|HPU).* LOW LEVEL/i,
		name: "RS_Hyd_AboveLevelLow",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND HYDRAULIC UNIT HIGH TEMPERATURE SWITCH",
			"ROLL STAND HPU TEMPERATURE SWITCH OK",
			"HPU TEMPERATURE SWITCH OK N.C.",
		],
		description: [
			/(HYDRAULIC UNIT|HPU).* TEMPERATURE SWITCH/i,
		],
		name: "RS_Hyd_TemperatureBelowMax",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"EXPANSION TANK WATER PRESSURE SWITCH",
			"EXPANSION TANK WATER PRESSURE OK / BELOW MAX",
		],
		description: /EXPANSION TANK WATER PRESSURE/i,
		name: "{L}S_Expansion_WaterPressureBelowMax",
		type: "BOOL",
		direction: "in",
	},
	
	{
		examples: [
			"MAKE-UP TANK AIR PRESSURE SWITCH",
			"MAKE-UP TANK AIR PRESSURE OK / AT MIN",
		],
		description: /MAKE-UP TANK AIR PRESSURE/i,
		name: "{L}S_MakeUp_AirPressureAboveMin",
		type: "BOOL",
		direction: "in",
	},

	{
		examples: [
			"MAKE-UP TANK WATER HIGH LEVEL FLOAT SWITCH (L2=4.0\")",
			"MAKE-UP TANK WATER HIGH LEVEL FLOAT",
			"MAKE-UP TANK WATER LEVEL NOT LOW",
			"MAKE-UP TANK WATER LEVEL LOW"
		],
		description: [
			/MAKE-UP TANK WATER HIGH (LEVEL|FLOAT)/i,
			/MAKE-UP TANK WATER LEVEL (NOT )?LOW/i,
		],
		name: "{L}S_Water_Level_AboveWarn_Sw",
		type: "BOOL",
		direction: "in",
	},
	{
		examples: [
			"MAKE-UP TANK WATER LEVEL SWITCH",
			"MAKE-UP TANK WATER LOW LEVEL FLOAT",
			"MAKE-UP TANK WATER LEVEL EMPTY",
			"MAKE-UP TANK WATER LEVEL NOT EMPTY"
		],
		description: [
			/MAKE-UP TANK WATER (LOW)?.*(LEVEL|FLOAT)/i,
			/MAKE-UP TANK WATER LEVEL (EMPTY|NOT EMPTY)/i,
		],
		name: "{L}S_Water_Level_AboveEmpty_Sw",
		type: "BOOL",
		direction: "in",
	},


	{
		examples: [
			"ROLL STAND LEFT SIDE GAP 2 HIGH LIMIT PROX",
			"ROLL STAND LEFT SIDE GAP 3 LOW LIMIT PROX",
			"ROLL STAND LEFT SIDE GAP 5 LOW LIMIT PROX",
			"ROLL STAND RIGHT SIDE GAP 2 LOW LIMIT PROX",
		],
		description: /(LEFT|RIGHT) SIDE GAP ([0-9]) (HIGH|LOW) LIMIT/i,
		name: "RS_Gap{2}_{1:Cap}{3:Cap}Lim",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},

	{
		examples: [
			"TOP LEFT GAP LIMIT SWITCH",
			"BOTTOM LEFT GAP LIMIT SWITCH -/+",
		],
		description: [
			/(TOP|BOTTOM) (LEFT|RIGHT) GAP LIMIT SWITCH -\/\+/i,
		],
		name: "RS_Gap{2:Cap}_{1:Cap}{EVEN+1}Lim",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	

	{
		examples: [
			"ROLL STAND HEIGHT ADJUST UPPER LIMIT LS",
			"ROLL STAND HEIGHT ADJUST LOWER LIMIT LS",
		],
		description: /HEIGHT ADJUST (UPPER|LOWER) LIMIT/i,
		name: "RS_Gap{1:Cap}_Lim",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},



	
	{
		examples: [
			"AUXILIARY ROLL 1 TCU MOTOR RUNNING INDICATION",
			"AUX ROLL 2 TCU RUNNING MAIN"
		],
		description: /AUX.* ROLL [#]?([0-9]) TCU .*RUNNING/i,
		name: "{LL}_TCU_Aux{1}_Running",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
	},
	{
		examples: [
			"MIDDLE ROLL TCU MOTOR RUNNING INDICATION",
			"BOTTOM ROLL TCU MOTOR RUNNING INDICATION",
			"TOP ROLL TCU MOTOR RUNNING INDICATION",
			"ROLL 1 TCU MOTOR RUNNING INDICATION"
		],
		description: [
			/([A-Z]+) ROLL TCU MOTOR RUNNING/i,
			/ROLL [#]?([0-9]) TCU MOTOR RUNNING/i
		],
		name: "{LL}_TCU_{1:Cap}_Running",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
	},
	{
		examples: [
			"AUXILIARY ROLL 1 TCU HIGH LILMIT INDICATION",
			"AUX ROLL #3 TCU MOTOR RUNNING INDICATION",
		],
		description: /AUX.* ROLL [#]?([0-9]) TCU (HIGH|HI) L/i,
		name: "{LL}_TCU_Aux{1}_HighTemperatureLim",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
	},
	{
		examples: [
			"MIDDLE ROLL TCU HIGH LILMIT INDICATION",
			"BOTTOM ROLL TCU HIGH LILMIT INDICATION",
			"TOP ROLL TCU HIGH LILMIT INDICATION",
			"TOP ROLL TCU HIGH LIMIT INDICATION",
			"ROLL 1 TCU DRY CONTACTS HIGH LIMIT INDICATION",
			"TOP ROLL TCU HI LIMIT FAULT",
			"ROLL #1 TCU HIGH LIMIT INDICATION",
		],
		description: [
			/([A-Z]+) ROLL TCU (HIGH|HI) L/i,
			/ROLL [#]?([0-9]) TCU.* (HIGH|HI) L/i
		],
		name: "{LL}_TCU_{1:Cap}_HighTemperatureLim",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
	},
	{
		examples: [
			"AUXILIARY ROLL 1 TCU REMOTE STOP RELAY",
			"AUXILIARY ROLL 1 TCU REMOTE START RELAY",
			"AUXILIARY ROLL 1 MOKON TCU REMOTE STOP RELAY",
			"AUX ROLL 1 TCU REMOTE STOP",
			"AUX ROLL 1 TCU REMOTE START",
		],
		description: /AUX.* ROLL[ ]?[#]?([0-9]*).* TCU REMOTE (START|STOP)/i,
		name: "{LL}_TCU_Aux{1}_{2:Cap}",
		type: "BOOL",
		direction: "out",
	},
	{
		examples: [
			"TOP ROLL MOKON TCU REMOTE STOP RELAY",
			"TOP ROLL TCU REMOTE START RELAY",
			"ROLL 5 TCU REMOTE START RELAY",
			"TOP ROLL TCU REMOTE STOP RELAY",
			"ROLL 5 TCU REMOTE STOP RELAY",
		],
		description: [
			/(TOP|MIDDLE|BOTTOM|SPARE) ROLL.* TCU REMOTE (START|STOP)/i,
			/ROLL [#]?([0-9]+) TCU REMOTE (START|STOP)/i,
		],
		name: "{LL}_TCU_{1:Cap}_{2:Cap}",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"AUXILIARY ROLLS TCU REMOTE STOP RELAY",
			"AUXILIARY ROLLS TCU REMOTE START RELAY",
		],
		description: /AUX.* ROLLS TCU REMOTE (START|STOP)/i,
		name: "{LL}_TCU_Aux_{2:Cap}",
		type: "BOOL",
		direction: "out",
	},



	{
		examples: [
			"ZONE 1 MODULATING VALVE FEEDBACK 0-10V",
			"ZONE 3 MODULATING VALVE FEEDBACK 0-10V",
			"ZONE #1 ROLL #1 COOLING VALVE FEEDBACK 0-10VDC",
			"ZONE #2 ROLL #2 COOLING VALVE FEEDBACK\n0-10VDC",
		],
		description: /ZONE [#]?([0-9]+).* VALVE/i,
		name: "{LL}_Zone{1}_CoolingFeedback",
		analog: true,
		direction: "in",
	},
	{
		examples: [
			"ZONE #1 ROLL #1 COOLING VALVE",
			"ZONE #2 ROLL #2 COOLING VALVE",
			"ZONE 1 MODULATING VALVE  SETPOINT 0-10V",
			"ZONE 2 MODULATING VALVE  SETPOINT 0-10V",
		],
		description: [
			/ZONE [#]?([0-9]+).* COOLING VALVE/i,
			/ZONE [#]?([0-9]+).* MODULATING VALVE/i,
		],
		name: "{LL}_Zone{1}_Cooling",
		analog: true,
		direction: "out",
	},

	{
		examples: [
			"MIDDLE ROLL COOLING VALVE FEEDBACK 0-10V",
		],
		description: [
			/([A-Z]+) ROLL COOLING VALVE/i,
		],
		name: "{LL}_Zone{1:Cap}_CoolingFeedback",
		analog: true,
		direction: "in",
	},
	{
		examples: [
			"TOP ROLL COOLING VALVE REFERENCE 0-10VDC",
			"BOTTOM ROLL COOLING VALVE REFERENCE 0-10VDC",
		],
		description: [
			/([A-Z]+) ROLL COOLING VALVE/i,
		],
		name: "{LL}_Zone{1:Cap}_Cooling",
		analog: true,
		direction: "out",
	},
	
	
	{
		examples: [
			"ROLL #1 DISCHARGE TEMPERATURE T/C",
			"ROLL #2 DISCHARGE TEMPERATURE T/C",
		],
		description: /[#]?([0-9]+).* DISCHARGE/i,
		name: "{LL}_Zone{1}_Discharge_TC",
		thermocouple: true,
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"ZONE #6 TEMPERATURE SAFETY T/C",
			"ZONE #1 TEMPERATURE SAFETY T/C",
		],
		description: /ZONE [#]?([0-9]+).* SAFETY/i,
		name: "{LL}_Zone{1}_Safety_TC",
		thermocouple: true,
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"ZONE #1 TEMPERATURE T/C",
			"ZONE #2 TEMPERATURE T/C",
		],
		description: /ZONE [#]?([0-9]+).* TEMPERATURE T/i,
		name: "{LL}_Zone{1}_TC",
		thermocouple: true,
		direction: "in",
		rollStand: true
	},

	{
		examples: [
			"TOP ROLL THERMOCOUPLE TYPE J",
			"MIDDLE ROLL THERMOCOUPLE",
		],
		description: /([A-Z]+) ROLL THERMOCOUPLE/i,
		name: "{LL}_Zone{1:Cap}_TC",
		thermocouple: true,
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"TOP ROLL SAFETY THERMOCOUPLE TYPE K",
			"BOTTOM ROLL SAFETY THERMOCOUPLE TYPE K",
		],
		description: /([A-Z]+) ROLL SAFETY THERMOCOUPLE/i,
		name: "{LL}_Zone{1:Cap}_Safety_TC",
		thermocouple: true,
		direction: "in",
	},

	{
		examples: [
			"ZONE #1 ROLL #1 IMMERSION HEATER CURRENT H1 0-20mA : 0-50A",
			"ZONE #2 ROLL #2 IMMERSION HEATER CURRENT H1 0-20mA : 0-50A",
		],
		description: /ZONE [#]?([0-9]+).* HEATER CURRENT H([1-3])/i,
		name: "{L}S_Zone{1}_Current_H{2}_Raw",
		analog: true,
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"ZONE #1 PROCESS FLOW METER 4-20mA",
			"ZONE #2 PROCESS FLOW METER 4-20mA",
		],
		description: /ZONE [#]?([0-9]+).* FLOW/i,
		name: "{LL}_Zone{1}_Flow_Raw",
		analog: true,
		direction: "in",
		rollStand: true
	},

	{
		examples: [
			"PUMP MOTORS VOLTAGE MONITORING RELAY",
			"480VAC POWER VOLTAGE MONITORING RELAY OK",
			"VOLTAGE MONITORING RELAY VMR",
		],
		description: [
			/PUMP MOTORS (VOLTAGE MONITORING RELAY|VMR)/i,
			/VOLTAGE MONITORING RELAY/i
		],
		name: "{LL}_Pump_VMR",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},

	{
		examples: [
			"ZONE 1 TOP ROLL COOLING PUMP CB OK",
			"ZONE 3 BOTTOM ROLL COOLING PUMP CB OK",
			"TOP ROLL COOLING PUMP MMSP",
			"BOTTOM ROLL COOLING PUMP MMSP",
			"ZONE 3 BOTTOM ROLL COOLING PUMP CB ON", // 18122-r137
		],
		description: [
			/([A-Z]+) ROLL COOLING PUMP (CB O|MMSP)/i
		],
		name: "{LL}_Zone{1:Cap}_Pump_MMSP",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"ZONE #2 ROLL #2 WATER COOLING PUMP MOTOR MMSP",
			"ZONE #4 ROLL #4 WATER COOLING PUMP MOTOR MMSP",
			"ZONE 1 TOP ROLL COOLING PUMP CB OK",
			"ZONE 3 BOTTOM ROLL COOLING PUMP CB OK",
		],
		description: [
			/ZONE [#]?([0-9]+).* (PUMP|MOTOR).* MMSP/i,
			/ZONE [#]?([0-9]+).* ROLL COOLING PUMP CB O/i
		],
		name: "{LL}_Zone{1}_Pump_MMSP",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},

	
	{
		examples: [
			"THREAD UP ROLL RUN REVERSE PB PRESSED",
		],
		description: [
			/THREAD UP.* PB/i,
		],
		name: "RS_ThreadUp_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"THREAD UP ROLL RUN REVERSE PB LIGHT TO ON",
		],
		description: [
			/THREAD UP.* PB/i,
		],
		name: "RS_ThreadUp_PBL",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},


	{
		examples: [
			"ZONE 1 TOP ROLL COOLING PUMP CR TO ON",
			"ZONE 3 BOTTOM ROLL COOLING PUMP CR TO ON",
		],
		description: [
			/(ZONE)?[ ]?[0-9]?[ ]?([A-Z]+).* ROLL COOLING (PUMP|MOTOR)/i,
		],
		name: "{L}S_Zone{2:Cap}_Pump_Coil",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"ZONE #1 ROLL #1 WATER COOLING PUMP MOTOR CONTACTOR.",
			"ZONE #5 ROLL #5 WATER COOLING PUMP MOTOR CONTACTOR.",
		],
		description: [
			/ZONE [#]?([0-9]+).* (PUMP|MOTOR).* CONT/i,
		],
		name: "{L}S_Zone{1}_Pump_Coil",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},


	{
		examples: [
			"ZONE 1 TOP ROLL COOLING PUMP CR ON",
			"ZONE 2 MIDDLE ROLL COOLING PUMP CR ON",
			"TOP ROLL COOLING PUMP MOTOR CONTACTOR AUX.",
			"MIDDLE ROLL COOLING PUMP MOTOR CONTACTOR AUX.",
		],
		description: [
			/(ZONE)?[ ]?[0-9]?[ ]?([A-Z]+).* ROLL COOLING PUMP.* (CR ON|CONT)/i
		],
		name: "{L}S_Zone{2:Cap}_Pump_Aux",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"ZONE #1 ROLL #1 WATER COOLING PUMP MOTOR CONTACTOR AUX.",
			"ZONE #5 ROLL #5 WATER COOLING PUMP MOTOR CONTACTOR AUX.",
			"ZONE 1 TOP ROLL COOLING PUMP CR ON",
			"ZONE 2 MIDDLE ROLL COOLING PUMP CR ON",
		],
		description: [
			/ZONE [#]?([0-9]+).* (PUMP|MOTOR).* AUX/i,
			/([0-9]+).* ROLL COOLING PUMP CR ON/i
		],
		name: "{L}S_Zone{1}_Pump_Aux",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},


	{
		examples: [
			"ZONE 1 TOP ROLL HEATER CR ON",
			"ZONE 2 MIDDLE ROLL HEATER CR ON",
		],
		description: [
			/(ZONE)?[ ]?[0-9]?[ ]?([A-Z]+).* ROLL HEATER CR ON/i,
		],
		name: "{L}S_Zone{2:Cap}_Heat_Aux",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"ZONE #1 ROLL #1 IMMERSION HEATER CONTACTOR AUX.",
			"ZONE #3 ROLL #3 IMMERSION HEATER CONTACTOR AUX.",
			"ZONE 1 TOP ROLL HEATER CR ON",
			"ZONE 2 MIDDLE ROLL HEATER CR ON",
		],
		description: [
			/ZONE [#]?([0-9]+).* HEATER.* AUX/i,
			/([0-9]+).* ROLL HEATER CR ON/i,
		],
		name: "{L}S_Zone{1}_Heat_Aux",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},

	{
		examples: [
			"ZONE 1 TOP ROLL HEAT ENABLE CR TO ON",
			"ZONE 3 BOTTOM ROLL HEAT ENABLE CR TO ON",
		],
		description: /(TOP|BOTTOM|MIDDLE|MID|BOT|PULL).* HEAT.* (ENABLE|CONT)/i,
		name: "{L}S_Zone{1:Cap}_Heat_Coil",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"ZONE #2 ROLL #2 IMMERSION HEATER CONTACTOR",
			"ZONE #3 ROLL #3 IMMERSION HEATER CONTACTOR",
		],
		description: /ZONE [#]?([0-9]+).* HEATER.* CONT/i,
		name: "{L}S_Zone{1}_Heat_Coil",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},

	{
		examples: [
			"ZONE 1 TOP ROLL HEAT SSR TO ON",
			"ZONE 3 BOTTOM ROLL HEAT SSR TO ON",
			"ZONE 1 TOP ROLL SOLID STATE RELAYS (40A)"
		],
		description: /(TOP|BOTTOM|MIDDLE|MID|BOT|PULL).* ROLL .*(SSR|SOLID STATE)/i,
		name: "{L}S_Zone{1:Cap}_Heat_SSR",
		type: "BOOL",
		direction: "out",
		rollStand: true,
		pullroll: false,
	},
	{
		examples: [
			"ZONE #2 ROLL #2 IMMERSION HEATER SOLID STATE RELAYS (40A)",
			"ZONE #3 ROLL #3 IMMERSION HEATER SOLID STATE RELAYS (40A)",
		],
		description: [
			/ZONE [#]?([0-9]+).* HEATER.* (SOLID STATE RELAY|SSR)/i,
			/([0-9]+).* HEAT.* (SSR|SOLID STATE)/i,
		],
		name: "{L}S_Zone{1}_Heat_SSR",
		type: "BOOL",
		direction: "out",
		rollStand: true,
		pullroll: false,
	},


	{
		examples: [
			"ROLL STAND TOP ROLL OPEN PB",
			"ROLL STAND TOP ROLL CLOSE PBL",
			"ROLL STAND BOTTOM ROLL OPEN PB",
			"ROLL STAND BOTTOM ROLL CLOSE PBL",
		],
		description: /(TOP|BOTTOM) ROLL (OPEN|CLOSE) PB/i,
		name: "RS_Roll{1:Cap}_{2:Cap}_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},


	{
		examples: [
			"ROLL STAND TRACTION FORWARD PB",
			"ROLL STAND PENDANT TRACTION IN (FORWARD) PB"
		],
		description: /TRACTION .*FORWARD.* PB/i,
		name: "RS_Traction_FwdToDie_PB_Pendant",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND PENDANT TRACTION OUT (REVERSE) PB",
			"ROLL STAND TRACTION REVERSE PB",
			"HMI TRACTION REVERSE PB"
		],
		description: [
			/ROLL STAND( PENDANT)? TRACTION .*REVERSE.* PB/i,
			/HMI TRACTION REVERSE PB/i,
		],
		name: "RS_Traction_RevAwayDie_PB_Pendant",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},

	{
		examples: [
			"TRACTION PB", // This is actually in 17945 as an input label...
			"PULL ROLL TRACTION REVERSE PB",
			"ROLL STAND REMOTE TRACTION REVERSE PB",
			"PULL ROLL TRACTION PB" // 17625-r29
		],
		description: [
			/REMOTE TRACTION REV.* PB/i,
			/PULL ROLL TRACTION.* PB/i,
			/^TRACTION PB/i,
		],
		name: "PR_Traction_RevAwayDie_PB_PRoll",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"TRANSFER ROLL COATER TOP SQUEEGEE ROLL OPEN PB",
			"TOP SQUEEGEE ROLL OPEN PB",
		],
		description: [
			/COATER TOP SQUEEGEE ROLL OPEN PB/i,
			/TOP SQUEEGEE.* OPEN PB/i
		],
		name: "RS_TSqueegee_Open_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"TRANSFER ROLL COATER TOP SQUEEGEE ROLL CLOSE PBL",
			"TOP SQUEEGEE ROLL CLOSE PBL (TRC 7-PB ENCL)",
		],
		description: [
			/COATER TOP SQUEEGEE ROLL CLOSE PB/i,
			/TOP SQUEEGEE.*CLOSE PB/i,
		],
		name: "RS_TSqueegee_Close_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"TRANSFER ROLL COATER TOP TRANSFER ROLL OPEN PB",
			"TOP TRANSFER ROLL OPEN PB",
		],
		description: [
			/TOP TRANSFER ROLL OPEN PB/i,
		],
		name: "RS_Transfer_Open_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"TRANSFER ROLL COATER TOP TRANSFER ROLL CLOSE PBL",
			"TOP TRANSFER ROLL CLOSE PBL",
		],
		description: [
			/TOP TRANSFER ROLL CLOSE PBL/i,
		],
		name: "RS_Transfer_Close_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"TRANSFER ROLL COATER BOTTOM SQUEEGEE ROLL OPEN PB",
			"BOTTOM SQUEEGEE ROLL OPEN PB",
		],
		description: /BOTTOM SQUEEGEE ROLL OPEN PB/i,
		name: "RS_BSqueegee_Open_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"TRANSFER ROLL COATER BOTTOM SQUEEGEE ROLL CLOSE PBL",
			"BOTTOM SQUEEGEE ROLL CLOSE PBL",
		],
		description: [
			/BOTTOM SQUEEGEE ROLL CLOSE PB/i
		],
		name: "RS_BSqueegee_Close_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},



	{
		examples: [
			"TOP ROLL MOKON TCU MOTOR RUNNING INDICATION",
		],
		description: /(TOP|MIDDLE|BOTTOM|SPARE) ROLL MOKON TCU MOTOR RUNNING/i,
		name: "{L}S_{1:Cap}_TCU_Running",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"AUXILIARY ROLL 1 MOKON TCU MOTOR RUNNING INDICATION",
		],
		description: /AUXILIARY ROLL ([0-9]) MOKON TCU MOTOR RUNNING/i,
		name: "{L}S_Aux{1}_TCU_Running",
		type: "BOOL",
		direction: "in",
	},




	{
		examples: [
			"ROLL STAND TOP ROLL CLOSE PBL INDICATOR",
			"HMI TOP ROLL CLOSE PB LIGHT TO ON",
			"ROLL STAND TOP ROLL CLOSE PBL INDICATOR",
			"ROLL STAND PENDANT ROLL 1 CLOSE PBL INDICATOR (WHITE)"
		],
		description: [
			/(TOP|BOTTOM) ROLL (CLOSE|OPEN).* (INDICATOR|LIGHT)/i,
			/ROLL ([0-9]) (OPEN|CLOSE).* (INDICATOR|LIGHT)/i,
		],
		name: "RS_Roll{1:Cap}_{2:Cap}_PBL",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND SYSTEM RESET PB INDICATOR (BLUE)",
			"ROLL STAND SYSTEM RESET PBL INDICATOR",
			"HMI SYSTEM RESET PB LIGHT TO ON"
		],
		description: /SYSTEM RESET PB.* (LIGHT|INDICATOR)/i,
		name: "EStop_Reset_PBL",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"TRANSFER ROLL COATER TOP SQUEEGEE ROLL CLOSE PBL INDICATOR",
			"TOP SQUEEGEE ROLL CLOSE PB LIGHT TO ON (TRC 7-PB ENCL)"
		],
		description: [
			/COATER TOP SQUEEGEE ROLL CLOSE.*INDICATOR/i,
			/TOP SQUEEGEE.* CLOSE PB LIGHT/i,
		],
		name: "RS_TSqueegee_Close_PBL",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"TRANSFER ROLL COATER TOP TRANSFER ROLL CLOSE PBL INDICATOR",
			"TOP TRANSFER ROLL CLOSE PB LIGHT TO ON",
		],
		description: [
			/COATER TOP TRANSFER ROLL CLOSE.*INDICATOR/i,
			/TOP TRANSFER.* CLOSE PB LIGHT/i
		],
		name: "RS_Transfer_Close_PBL",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"TRANSFER ROLL COATER BOTTOM SQUEEGEE ROLL CLOSE PBL INDICATOR",
			"BOTTOM SQUEEGEE ROLL CLOSE PB LIGHT TO ON",
		],
		description: [
			/COATER BOTTOM SQUEEGEE ROLL CLOSE.*INDICATOR/i,
			/BOTTOM SQUEEGEE.* CLOSE PB LIGHT/i
		],
		name: "RS_BSqueegee_Close_PBL",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},

	{
		examples: [
			"ROLL STAND TRACTION MOTOR BRAKE CONTACTOR",
			"TRACTION DRIVE MOTOR BRAKE CONTACTOR L-R10410",
			"TRACTION DRIVE MOTOR BRAKE RELEASE CR TO ON",
			"TRACTION DRIVE MOTOR BRBKE CONTACTOR", // 18085-132a typo
		],
		description: /TRACTION.* BR[A|B]KE/i,
		name: "RS_Traction_Brake_Coil",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"AIR KNIFE BLOWER MOTOR CONTACTOR L-R10603 (3X), L-R12638 (AUX)",
			"COATER AIR KNIFE MOTOR CR TO ON",
		],
		description: [
			/BLOWER MOTOR CONTACTOR/i,
			/COATER AIR KNIFE MOTOR CR/i,
		],
		name: "RS_Coater_Blower_Coil",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},

	{
		examples: [
			"ANTI-STATIC COATER PUMP CONTACTOR L-R10608 (3X), L-R12646 (AUX)",
			"ANTI-STATIC COATER PUMP MOTOR CR TO ON",
		],
		description: [
			/COATER PUMP (CONTACTOR|MOTOR)/i,
		],
		name: "RS_Coater_Pump_Coil",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"COATER PUMP #1 CONTACTOR",
		],
		description: [
			/COATER PUMP .*([0-9]) CONTACTOR/i,
		],
		name: "RS_Coater{1}_Pump_Coil",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},

	{
		examples: [
			"ROLL STAND TOP ROLL CLOSE RELAY, L-R11304",
			"ROLL STAND TOP ROLL CLOSE CR TO ON",
		],
		description: /(TOP|BOTTOM) ROLL CLOSE (CR|RELAY)/i,
		name: "RS_Roll{1:Cap}_Close_Sol",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},

	{
		examples: [
			"TOP ROLL ABORT VALVES RELAY",
		],
		description: /TOP ROLL ABORT VALVES/i,
		name: "RS_RollTop_Abort_Sol",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"BOTTOM ROLL ABORT VALVES RELAY",
		],
		description: /BOTTOM ROLL ABORT VALVES/i,
		name: "RS_RollBottom_Abort_Sol",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},

	{
		examples: [
			"ROLL STAND ROLL #3 CLOSE IN REDUCED PRESSURE MODE RELAY",
			"ROLL STAND ROLL #5 CLOSE IN REDUCED PRESSURE MODE RELAY",
		],
		description: /ROLL [#]?([0-9]+) CLOSE.*REDUCED/i,
		name: "RS_Roll{1}_Close_Reduced_Sol",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND ROLL #1 CLOSE RELAY",
			"ROLL STAND ROLL #3 CLOSE RELAY",
			"ROLL STAND ROLL #5 CLOSE RELAY",
			"ROLL STAND ROLL #7 CLOSE RELAY",
		],
		description: /ROLL [#]?([0-9]+) CLOSE RELAY/i,
		name: "RS_Roll{1}_Close_Sol",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND PENDANT ROLL #1 CLOSE INDICATOR (PENDANT)",
			"ROLL STAND PENDANT ROLL #3 CLOSE INDICATOR (PENDANT)",
			"ROLL STAND PENDANT ROLL #7 CLOSE INDICATOR (PENDANT)",
		],
		description: /ROLL [#]?([0-9]+) CLOSE IND/i,
		name: "RS_Roll{1}_Close_PBL",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},


	{
		examples: [
			"ROLL STAND HYDRAULIC UNIT DUMP RELAY, L-R11310",
		],
		description: /HYDRAULIC UNIT DUMP/i,
		name: "RS_HydUnit_Dump_Sol",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND HYDRAULIC UNIT BYPASS VALVE RELAY, L-R11313",
			"ROLL STAND HYDRAULIC UNIT BYPASS RELAY",
			"ROLL STAND HYDRAULIC BYPASS CR TO ON",
		],
		description: /HYDRAULIC.* BYPASS/i,
		name: "RS_HydUnit_Bypass_Sol",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},

	
	{
		examples: [
			"ROLL STAND HYDRAULIC UNIT MOTOR CONTACTOR AUX.",
		],
		description: /HYDRAULIC UNIT.* CONT/i,
		name: "RS_HydUnit_Aux",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND HYDRAULIC UNIT MOTOR CONTACTOR",
		],
		description: /HYDRAULIC UNIT.* CONT/i,
		name: "RS_HydUnit_Coil",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},

	{
		examples: [
			"TRANSFER ROLL COATER TOP SIDE SQUEEGEE ROLL CLOSE RELAY, L-R11404",
			"TRC TOP SQUEEGEE ROLL CLOSE CR TO ON",
		],
		description: [
			/COATER TOP SIDE SQUEEGEE ROLL CLOSE/i,
			/TOP SQUEEGEE ROLL CLOSE/i,
		],
		name: "RS_TSqueegee_Close_Sol",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"TRANSFER ROLL COATER TRANSFER ROLL CLOSE RELAY, L-R11407",
			"TRC TOP TRANSFER ROLL CLOSE CR TO ON",
		],
		description: [
			/COATER TRANSFER ROLL CLOSE/i,
			/TOP TRANSFER ROLL CLOSE/i,
		],
		name: "RS_Transfer_Close_Sol",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"TRANSFER ROLL COATER BOTTOM SIDE SQUEEGEE ROLL CLOSE RELAY, L-R11410",
			"TRC BOTTOM SQUEEGEE ROLL CLOSE CR TO ON",
		],
		description: [
			/BOTTOM SIDE SQUEEGEE ROLL CLOSE/i,
			/BOTTOM SQUEEGEE ROLL CLOSE/i,
		],
		name: "RS_BSqueegee_Close_Sol",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"PULL ROLL CLOSE RELAY, L-R11413",
			"PULL ROLL ROLL CLOSE CR TO ON",
		],
		description: [
			/PULL ROLL CLOSE/i,
			/PULL ROLL .* CLOSE/i
		],
		name: "PR_RollPull_Close_Sol",
		type: "BOOL",
		direction: "out",
		rollStand: true,
	},


	{
		examples: [
			"ROLL STAND LIGHT TOWER HORN MODULE TONE 1",
			"ROLL STAND LIGHT TOWER HORN MODULE TONE 2",
			"LIGHT TOWER AUDIBLE ALARM IN3",
			"LIGHT TOWER AUDIBLE ALARM IN2",
			"ROLL STAND LIGHT TOWER AUDIBLE ALAM #3 WHT/BRN",
			"ROLL STAND LIGHT TOWER AUDIBLE ALAM #2 WHT/ORG",
		],
		description: [
			/LIGHT TOWER.* HORN.* TONE ()([0-9])/i,
			/LIGHT TOWER AUDIBLE .*(#|IN)([1-9])/i,
		],
		name: "RS_HornTone{2}_TowerLight",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND LIGHT TOWER HORN MODULE",
			"LIGHT TOWER AUDIBLE ALARM HORN",
		],
		description: /LIGHT TOWER.* HORN/i,
		name: "RS_Horn_TowerLight",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND LIGHT TOWER MODULE (RED STROBE)",
		],
		description: /LIGHT TOWER.*RED/i,
		name: "RS_Red_TowerLight",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND LIGHT TOWER MODULE (FLASHING AMBER)",
		],
		description: /LIGHT TOWER.*AMBER/i,
		name: "RS_Amber_TowerLight",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND LIGHT TOWER MODULE (STRAIGHT GREEN)",
		],
		description: /LIGHT TOWER.*GREEN/i,
		name: "RS_Green_TowerLight",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND TRACTION DRIVE ALARM BELL",
		],
		description: /TRACTION DRIVE.*BELL/i,
		name: "RS_Traction_Bell",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},
	{
		examples: [
			"ROLL STAND TRACTION WARNING LIGHTS SAFETY RELAY",
			"TRACTION WARNING / ALARM OUTPUT",
			"ROLL STAND TRACTION IN MOTION WARNING LIGHT INDICATOR (FLASHING AMBER)",
		],
		description: /TRACTION.* WARNING.*(LIGHT|ALARM)/i,
		name: "RS_Traction_InMotion_Light",
		type: "BOOL",
		direction: "out",
		rollStand: true
	},




	{
		examples: [
			"GRINDER ENABLE SAFETY RELAY FEEDBACK",
		],
		description: /GRINDER ENABLE SAFETY RELAY /i,
		name: "RS_Grinder_Enable_Feedback",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Safety",
	},
	{
		examples: [
			"GRINDER ENABLE SAFETY RELAY",
		],
		description: /GRINDER ENABLE SAFETY RELAY/i,
		name: "RS_Grinder_Enable_Coil",
		type: "BOOL",
		direction: "out",
		rollStand: true,
		safetyType: "Safety",
	},






	////////////////////////////////////
	// SPARE
	//

	{
		description: /SPARE T\/C INPUT/i,
		name: "TSZone_Spare_TC_Rack{N}_S{S}_{P}",
		type: "INT",
		direction: "in",
		rollStand: true,
		pullroll: false,
	},
	{
		description: /SPARE/i,
		name: "{L}S_Spare_Rack{N}_S{S}_{P}_{DIR}_{TYPE}",
		rollStand: true,
		pullroll: false,
	},
]


export  {knownIoRollStand as default}
