import type { KnownModule } from "../types/module";

/**
 * Allen Bradley 1794 - FLEX IO
 */
let knownModuleAbFlex: Array<KnownModule> = [
  {
    trigger: /1794[ |-]IT8/,
    triggerModule: /IT8/,
    triggerRack: /1794/,
    description: "1794-IT8\n8 CHANNEL THERMOCOUPLE INPUT",
    pointReg: new RegExp('CH.[0-9]+ \\(\\+\\)'),
    points: 8,
    aliasTo: [
      "hwRack_{L}{N}:{S}:I.Ch{P}Data",
      {aliasTo: "hwRack_{L}{N}:{S}:I.Ch{P}OverrangeAlarm", name: "{0:name}_Sbreak", direction: "in"},
    ],
    type: [
      "INT",
      "BOOL"
    ],
    direction: "in",
    thermocouple: true,
    safetyType: "Standard"
  },
  {
    trigger: /1794[-| ]IE8/,
    triggerLong: /1794[-| ]IE8.*8 CHANNEL/,
    triggerModule: /IE8[$| ]/, // to avoid matching IE8C
    triggerRack: /1794/,
    description: "1794-IE8\nANALOG INPUT POINT I/O",
    pointReg: new RegExp('CH.[0-9]+ [V|I]$'), // Although signals don't come in on COM, this point happens to sit between the I and V inputs
    points: 8,
    aliasTo: [
      "hwRack_{L}{N}:{S}:I.Ch{P}Data",
      {aliasTo: "hwRack_{L}{N}:{S}:I.Ch{P}Status", name: "{0:name}_Status", direction: "in"},
      {aliasTo: "hwRack_{L}{N}:{S}:I.Ch{P}Fault", name: "{0:name}_Fault", direction: "in"},
    ],
    type: [
      "INT",
      "SINT",
      "BOOL"
    ],
    direction: "in",
    analog: true,
    safetyType: "Standard"
  },
  {
    // 1794-IF4I 4 CHANNEL ISOLATED ANALOG INPUT MODULE
    trigger: /1794[ |-]IF4I/,
    triggerModule: /IF4I/,
    triggerRack: /1794/,
    description: "1794-IF4I\n4 CHANNEL ISOLATED ANALOG INPUT",
    pointReg: new RegExp('CH.[0-9]+ I$'),
    points: 4,
    aliasTo: [
      "hwRack_{L}{N}:{S}:I.Ch{P}Data",
      {aliasTo: "hwRack_{L}{N}:{S}:I.Ch{P}Fault", name: "{0:name}_Fault", direction: "in"},
    ],
    type: [
      "INT",
      "BOOL",
      "BOOL"
    ],
    direction: "in",
    analog: true,
    safetyType: "Standard"
  },

  {
    // 1794-OE4 4 CHANNEL ANALOG OUTPUT MODULE
    trigger: /1794[ |-]OE4/,
    triggerModule: /OE4/,
    triggerRack: /1794/,
    description: "1794-OE4\n4 CHANNEL ANALOG OUTPUT",
    pointReg: new RegExp('CH.[0-9]+ V$'),
    points: 4,
    aliasTo: [
      "hwRack_{L}{N}:{S}:O.Ch{P}OutputData",
      {aliasTo: "hwRack_{L}{N}:{S}:I.Ch{P}OutputOpenWire", name: "{0:name}_Fault", direction: "in"},
    ],
    type: [
      "INT",
      "BOOL",
    ],
    direction: "out",
    analog: true,
    safetyType: "Standard"
  },

  {
    trigger: /1794[ |-]IE4XOE2/,
    triggerLong: /1794[ |-]IE4XOE2.*4 INPUT.*2 OUTPUT/,
    triggerModule: /IE4XOE2/,
    triggerRack: /1794/,
    description: "1794-IE4XOE2\n4 INPUT/2 OUTPUT ANALOG COMBO",
    pointReg: new RegExp('(IN|OUT) CH.[0-9]+[ ]*I[ ]*$'),
    points: 4,
    aliasTo: [
      "hwRack_{L}{N}:{S}:I.Ch{P}InputData",
      {aliasTo: "hwRack_{L}{N}:{S}:I.Ch{P}InputUnderrange", name: "{0:name}_Fault", direction: "in"},
    ],
    aliasToRight: [
      "hwRack_{L}{N}:{S}:O.Ch{P}OutputData",
      {aliasTo: "hwRack_{L}{N}:{S}:I.Ch{P}OutputOpenWire", name: "{0:name}_Fault", direction: "in"},
    ],
    type: [
      "INT",
      "BOOL"
    ],
    direction: "in|out",
    analog: true,
    safetyType: "Standard"
  },
  {
    trigger: /1794[ |-]IB32/,
    triggerLong: /1794[ | -]IB32.*32.*INPUT/,
    triggerModule: /IB32/,
    triggerRack: /1794/,
    description: "1794-IB32\n32 PT. INPUT",
    pointReg: new RegExp('IN[ ]?[0-9]+$'),
    points: 32,
    aliasTo: [
      "hwRack_{L}{N}:{S}:I.Data.{P}"
    ],
    type: [
      "BOOL"
    ],
    direction: "in",
    safetyType: "Standard"
  },
  {
    trigger: /1794[ |-]IB16/,
    triggerLong: /1794[ | -]IB16.*16.*INPUT/,
    triggerModule: /IB16/,
    triggerRack: /1794/,
    description: "1794-IB16\n16 PT. INPUT",
    pointReg: new RegExp('IN[0-9]+$'),
    points: 16,
    aliasTo: [
      "hwRack_{L}{N}:{S}:I.{P}"
    ],
    type: [
      "BOOL"
    ],
    direction: "in",
    safetyType: "Standard"
  },
  {
    trigger: /1794[ |-]OB32P/,
    triggerLong: /1794[ |-]OB32P.*32.*OUTPUT/,
    triggerModule: /OB32P/,
    triggerRack: /1794/,
    description: "1794-OB32P\n32 PT. OUTPUT",
    pointReg: new RegExp('OUT [0-9]+'),
    points: 32,
    aliasTo: [
      "hwRack_{L}{N}:{S}:O.Data.{P}"
    ],
    type: [
      "BOOL"
    ],
    direction: "out",
    safetyType: "Standard"
  },
  {
    trigger: /1794[-| ]OB16/,
    triggerLong: /1794[-| ]OB16.*16.* OUTPUT/,
    triggerModule: /OB16/,
    triggerRack: /1794/,
    description: "1794-OB16\n16 PT. OUTPUT",
    pointReg: new RegExp('OUT [0-9]+'),
    points: 16,
    aliasTo: [
      "hwRack_{L}{N}:{S}:O.Data.{P}"
    ],
    type: [
      "BOOL"
    ],
    direction: "out",
    safetyType: "Standard"
  },
  {
    trigger: /1794[-| ]OW8/,
    triggerModule: /OW8/,
    triggerRack: /1794/,
    description: "1794-OW8\n8 RELAY OUTPUT",
    pointReg: new RegExp('OUT (CH.)?[0-9]+'),
    points: 8,
    pointEvenTrim: true,
    aliasTo: [
      "hwRack_{L}{N}:{S}:O.{P}"
    ],
    type: [
      "BOOL"
    ],
    direction: "out",
    safetyType: "Standard"
  },
];

export default knownModuleAbFlex;
