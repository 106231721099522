import { Component } from 'react';


// import { ArrowDownCircle } from 'react-bootstrap-icons';
import { Download, HddRackFill, FileEarmarkSpreadsheetFill, CardHeading } from 'react-bootstrap-icons';


import FileSaver from 'file-saver';

import './downloadfile.css'


interface DownloadFileProps {
  filename: string;
  text?: string;
  icon?: "PLC" | "CSV" | "ALM";
  title?: string;
  data: string;
  type: "text/csv" | "text/plain" | "image/x-dxf" | "application/xml"; // Valid MIME types
}


// download button that generates files from memory
export default class DownloadFile extends Component<DownloadFileProps> {
  // create the file
  save(e) {
    if (this.props?.data?.length > 0) {
      e.preventDefault();
      let blob = new Blob( [this.props.data] , {type: "text/plain;charset=utf-8"});
      FileSaver.saveAs(blob, this.props.filename);
    }
  }

  render() {
    // const type = this.props.type || "text/csv"
    // let encode = "data:" + type + ";charset=utf-8," + encodeURI(this.props.data);

    // dynamically change icon based on icon property request
    let icon = (<Download />);
    if (this.props.icon === "PLC") {icon = (<HddRackFill />)}
    if (this.props.icon === "CSV") {icon = (<FileEarmarkSpreadsheetFill />)}
    if (this.props.icon === "ALM") {icon = (<CardHeading />)}

    // pull other props
    const filename = this.props.filename || "";
    const text = this.props.text || ("Download " + filename);

    // return HTML
    return (
      <a href={"#download-" + filename}
          target="_blank"
          rel="noreferrer"
          title={this.props.title || ""}

          onClick={(e) => this.save(e)}
          download={filename}
          className="btn-download">{icon} {text}</a>)
  }
}
