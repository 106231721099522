import type {TagSafetyType} from '../types/io'
import type {TagDirection} from '../types/io'
import type {IO} from '../types/io'

type SecondaryIoTrigger = {
	name: RegExp | string;
	direction?: TagDirection;
	type?: string | RegExp; // Data type of tag
	safetyType?: TagSafetyType;
	extruder?: Boolean;
	analog?: Boolean;
}
export type SecondaryIoPair = {
	name: string; // Template String
	description?: string; // Template string for description
	direction?: TagDirection;
	type?: string | RegExp; // Data type of tag
	safetyType?: TagSafetyType;
	extruder?: Boolean;
	aliasTo?: string;
}
export type SecondaryTag = SecondaryIoPair &{
	description: string;
	io?: IO;
}
export type SecondaryIO = {
	trigger: SecondaryIoTrigger;
	pairs?: { [key: string]: SecondaryIoPair };
	tags: Array<SecondaryTag>;
	found?: IO;
	pair?: { [key: string]: IO};
}

export let secondaryIO:Array<SecondaryIO> = [
	{ 
		// Safe Torque Off Outputs
		// NORMALLY OPEN AND NORMALLY CLOSED PAIRS
		trigger: {
			name: /(.*)STO_Ch1$/i,
			direction: "out",
			safetyType: "Safety",
		},
		pairs: {
			CH2: {
				name: "/{found.name_match.1}STO_Ch2$/",
				direction: "out",
				safetyType: "Safety",
			},
			CH0: {
				name: "/{found.name_match.1}STO_Ch0$/",
				direction: "out",
				safetyType: "Safety",
			},
		},

		tags: [{
			name: "{found.name_match.1}STO",
			safetyType: "Safety",
			type: "CONFIGURABLE_ROUT",
			description: "Configurable Redundant Output\n{found.name}\n{pair.CH0.name}{pair.CH2.name}\n\n{found.description}",
		}]
	},

	
	{ 
		// Safety IO needs DCI AOIs
		// NORMALLY OPEN AND NORMALLY CLOSED PAIRS
		trigger: {
			name: /(.*)NO$/i,
			direction: "in",
			safetyType: "Safety",
		},
		pairs: {
			NC: {
				name: "/{found.name_match.1}NC$/",
				direction: "in",
				safetyType: "Safety",
			},
		},

		tags: [{
			name: "{found.name_match.1:CleanEnd}",
			safetyType: "Safety",
			type: "DCI_STOP",
			description: "Dual-Channel Input Stop Monitor\n{found.name}:\n{found.description}\n\n{pair.NC.name}:\n{pair.NC.description}",
		}]
	},



	{
		trigger: {
			name: /^TS_TCU_(.*)_Running/i,
			direction: "in",
		},

		tags: [{
			name: "Mokon_{found.name_match.1}",
			type: "TS_Mokon",
			description: "Mokon {found.name_match.1}\n\n{found.description}",
		}]
	},

	
	{
		trigger: {
			name: /^(.*)[_]?(_Estop_PB|_EstopPB|_Estop_PullCord|EstopPullCord)$/i,
			direction: "in",
		},

		tags: [{
			name: "EstopMon_{found.name_match.1}",
			type: "Estop_Nonsafe_Monitor",
			description: "Emergency Stop Button/Cord Monitor\n {found.name}\n\n{found.description}",
		}]
	},


	{ 
		// Safety IO needs DCI AOIs
		// NORMALLY OPEN AND NORMALLY CLOSED PAIRS
		trigger: {
			name: /(.*)Ch1$/i,
			direction: "in",
			safetyType: "Safety",
		},
		pairs: {
			CH2: {
				name: "/{found.name_match.1}Ch2$/",
				direction: "in",
				safetyType: "Safety",
			},
			CH0: {
				name: "/{found.name_match.1}Ch0$/",
				direction: "in",
				safetyType: "Safety",
			},
		},

		tags: [{
			name: "DCS_{found.name_match.1:CleanEnd}",
			safetyType: "Safety",
			type: "DCI_STOP",
			description: "Dual-Channel Input Stop Monitor\n{found.name}:\n{found.description}\n\n{pair.CH0.name}{pair.CH2.name}:\n{pair.CH0.description}{pair.CH2.description}",
		}]
	},

	{
		// Create an PID tags for each temperature zone
		trigger: {
			// PREVIOUSLY: BEX_ZoneDS4_TC
			// NOW: ZoneBS4_TC | ZoneB4_TC | ZoneB4V_TC | ZoneBSC1_TC

			name: /Zone([A-Z]+[0-9]+[V]?)_TC$/i,
			direction: "in",
			type: /(INT|REAL)/i,
			extruder: true
		},
		pairs: {
			H1: {

				// ZoneBS4_CurrentH1
				name: "/Zone{found.name_match.1}_Heat_CurrentH1/i",
				direction: "in",
				type: /(INT|REAL)/i,
				extruder: true,
			},
			H3: {

				// ZoneBS4_CurrentH3
				name: "/Zone{found.name_match.1}_Heat_CurrentH3/i",
				direction: "in",
				type: /(INT|REAL)/i,
				extruder: true,
			},
			SSR: {
				name: "/Zone{found.name_match.1}_Heat_SSR/i",
				direction: "out",
				type: "BOOL",
				extruder: true,
			},
			CoolSol: {
				// ZoneB8_Cool_Sol
				name: "/Zone{found.name_match.1}_Cool_Sol/i",
				direction: "out",
				type: "BOOL",
				extruder: true,
			},
			BlowerCoil: {
				// ZoneA1_Cool_Blower_Coil
				name: "/Zone{found.name_match.1}_Cool_Blower_Coil/i",
				direction: "out",
				type: "BOOL",
				extruder: true,
			}
		},

		tags: [{
			name: "Zone{found.name_match.1}",
			type: "HC_ZONE",
			description: "HC_ZONE AOI\n{'*'found.description:L0'*'}{'\n\n*'pair.H1.name'*'}{'\n*'pair.H3.name'*'}{'\n'pair.H1.description:L0}{'\n\n*'pair.SSR.name'*'}{'\n'pair.SSR.description:L0}{'\n*'pair.CoolSol.name'*'}{'\n*'pair.BlowerCoil.name'*'}",
		},
		{
			name: "Zone{found.name_match.1}_TZ",
			type: "TEMP_PID",
			description: "TEMP_PID UDT\n{'*'found.description:L0'*'}{'\n\n*'pair.H1.name'*'}{'\n*'pair.H3.name'*'}{'\n'pair.H1.description:L0}{'\n\n*'pair.SSR.name'*'}{'\n'pair.SSR.description:L0}{'\n*'pair.CoolSol.name'*'}{'\n*'pair.BlowerCoil.name'*'}",
		}]
	},

	{ 
		// Ex Drive Blower
		trigger: {
			// BEX_Blower_Coil
			name: /([A-M])EX_Blower_Coil$/i,
			direction: "out",
		},
		pairs: {
			mmsp: {
				// BEX_Blower_MMSP
				name: "/{found.name_match.1}EX_Blower_MMSP$/",
				direction: "in",
			},
			aux: {
				// BEX_Blower_Aux
				name: "/{found.name_match.1}EX_Blower_Aux$/",
				direction: "in",
			},
		},

		tags: [{
			// B_Blower
			name: "{found.name_match.1}_Blower",
			type: "Blower",
			description: "EXTRUDER BLOWER {found.name_match.1} AOI\n{found.name}{'\n'pair.mmsp.name}{'\n'pair.aux.name}{'\n\n'found.description}{'\n\n'pair.mmsp.description}",
		},
		]
	},


	{ 
		// EX LUBE PUMP
		trigger: {
			// BEX_LubePump_Coil
			name: /([A-M])EX_LubePump_Coil$/i,
			direction: "out",
		},
		pairs: {
			mmsp: {
				// BEX_LubePump_MMSP
				name: "/{found.name_match.1}EX_LubePump_MMSP$/",
				direction: "in",
			},
			aux: {
				// BEX_LubePump_Aux
				name: "/{found.name_match.1}EX_LubePump_Aux$/",
				direction: "in",
			},
			tempsw: {
				// BEX_Lube_Temp_Sw
				name: "/{found.name_match.1}EX_Lube_Temp_Sw$/",
				direction: "in",
			},
			phigh: {
				// BEX_Lube_Press_Low
				name: "/{found.name_match.1}EX_Lube_Press_Low$/",
				direction: "in",
			},
			plow: {
				// BEX_Lube_Press_High
				name: "/{found.name_match.1}EX_Lube_Press_High$/",
				direction: "in",
			},
		},

		tags: [{
			// B_Blower
			name: "{found.name_match.1}_LubePump",
			type: "LubePump",
			description: "LUBE PUMP {found.name_match.1} AOI\n{found.name}{'\n'pair.mmsp.name}{'\n'pair.aux.name}{'\n'pair.tempsw.name}{'\n'pair.plow.name}{'\n'pair.phigh.name}{'\n\n'pair.tempsw.description}",
		},
		]
	},

	{ 
		// EX Vacuum PUMP
		trigger: {
			// BEX_VacPump1_Coil
			name: /([A-M])EX_VacPump1_Coil$/i,
			direction: "out",
		},

		tags: [{
			// B_Blower
			name: "{found.name_match.1}_VacPumps",
			type: "VacuumPumps",
			description: "VACUUM PUMP {found.name_match.1} AOI",
		},
		]
	},

]