import { Component } from 'react';

export default class ModuleError extends Component {
	render() {
		// don't display error message if there is no text
		if (! this.props.error) {
			return null
		}

		// create list of line elements
    let lines = [];
    const errors = this.props.error.split('\n');
    for (let e in errors) {
      let line = errors[e];
      if (line !== "") {
        lines.push(<div key={e}>{line}</div>);
      }
    }

		// return HTML
    return (<div className={"file-error " + (this.props.addClass || '')}>
    	{lines}
    </div>);
	}
}
