import logo from './logo.svg';
import './App.css';
import FileInput from './fileinput'
import LightToggle from './components/lighttoggle'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a className="App-link"
        href="https://reactjs.org"
        target="_blank"
        rel="noopener noreferrer" >
          <img src={logo} className="App-logo" alt="React JS" />
        </a>
        <h1>
          <img src="./pti.png" id="CompanyLogo" className="company-logo" alt="PTi" />
          I/O Schematics to PLC Tags
          <LightToggle />
        </h1>
      </header>
      <div className="App-Body">
	     <FileInput />
       {/* <FileInput preloads={[ {url: "data/18062/18062-b224.dxf", module: "", notes: "BDS"} ]} /> */}
      </div>
      <div className="App-Footer">
        Made by Evan Boldt.
        <div className="explain">
          DXF processing and parsing for PLC tags is performed entirely in-browser.<br />
          DWG's are uploaded to a VPS temporarily for conversion. If DXF files are chosen, those will not be transferred.
        </div>
        <div className="libredwg">
          DWG to DXF conversion performed using <a href="https://www.gnu.org/software/libredwg/">GNU LibreDWG</a> supported by the Free Software Foundation and licensed under the <a href="https://www.gnu.org/licenses/gpl-3.0.html">GNU General Public License V3</a>
        </div>
      </div>
    </div>
  );
}

export default App;
