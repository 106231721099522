import type {KnownIO} from "../types/io";

let knownIoPullRoll: Array<KnownIO> = [

	{
		examples: [
			"PULL ROLL STAND EMERGENCY STOP CIRCUIT",
		],
		description: [
			/PULL ROLL EMERGENCY STOP CIRCUIT/i,
		],
		name: "PR_EStop_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true,
	},
	
	
	{
		examples: [
			"PULL ROLL INFEED PULL CORD E-STOP COS",
			"PULL ROLL INFEED/OUTFEED PULL CORD SW",
			"PULL ROLL 1 INFEED/OUTFEED PULL CORD COS",
		],
		description: /PULL ROLL[ ]?([0-9]*) INFEED.* PULL CORD/i,
		name: "PR_PRoll{1}_Infeed_Estop_PullCord",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},
	{
		examples: [
			"PULL ROLL OUTFEED PULL CORD E-STOP COS",
		],
		description: /PULL ROLL OUTFEED PULL CORD/i,
		name: "PR_PRoll_Outfeed_Estop_PullCord",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},

	
	{
		examples: [
			"PULL ROLL OPERATOR CONTROL STATION E-STOP PBL",
			"PULL ROLL 1 OPERATOR CONTROL STATION E-STOP PBL",
			"PULL ROLL 4-PB BOX E-STOP PRESSED PULL ROLL JB MAIN ENCL",
		],
		description: [
			/PULL ROLL[ ]?([0-9]+) OPERATOR CONTROL STATION E-STOP/i,
			/PULL ROLL.*E[ |-]STOP PRESSED/i,
		],
		name: "PR_PRoll{1}_Operator_Estop_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},
	{
		examples: [
			"TRANFER ROLL COATER INFEED/ PULL ROLL OUTFEED PULL CORD E-STOP COS",
			"COATER INFEED PULL CORD TRIPPED"
		],
		description: [
			/TRANFER ROLL COATER INFEED.*PULL ROLL OUTFEED PULL CORD E-STOP/i,
			/COATER INFEED PULL CORD/i,
		],
		name: "PR_PRoll_Infeed_Estop_PullCord",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},


	
	{
		examples: [
			"PULL ROLL INFEED LOWER HINGED GUARD SAFETY SWITCH",
		],
		description: /PULL ROLL INFEED.*HING/i,
		name: "PR_PRoll_InfeedHinge_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},
	{
		examples: [
			"PULL ROLL INFEED HINGED GUARD SAFETY SWITCH ",
			"PULL ROLL INFEED GUARD SAFETY SWITCHES",
			"PULL ROLL INLET GUARD DOOR SAFETY SWITCHES",
			"PULL ROLL INFEED SAFETY SWITCHES",
		],
		description: /PULL ROLL (INLET|INFEED).* SAFETY/i,
		name: "PR_PRoll_InfeedGuard_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},
	{
		examples: [
			"PULL ROLL OUTFEED LOWER HINGED GUARD SAFETY SWITCH",
		],
		description: /PULL ROLL OUTFEED.*HING/i,
		name: "PR_PRoll_OutfeedHinge_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},
	{
		examples: [
			"PULL ROLL OUTFEED LIGHT CURTAIN",
			"PULL ROLL OUTFEED SAFETY SWITCHES",
		],
		description: [
			/PULL ROLL OUTFEED LIGHT CURTAIN/i,
			/PULL ROLL OUTFEED SAFETY SWITCHES/i,
		],
		name: "PR_PRoll_OutfeedGuard_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true
	},

	
    {
		examples: [
			"LOWER UNWIND BUMP NIP ROLL GUARD SAFETY SWITCH",
		],
		description: [
			/(LOWER|UPPER).* ROLL GUARD SAFETY SWITCH/i,
		],
		name: "PR_Guard_{1:Cap}_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		pullroll: true
	},

	{
		examples: [
			"PULL ROLL 1 (LAMINATION NIP) INFEED SAFETY SWITCHES",
			"PULL ROLL 1 (LAMINATION NIP) OUTFEED SAFETY SWITCHES",
		],
		description: [
			/PULL ROLL ([0-9]).* (INFEED|OUTFEED) SAFETY SWITCHES/i,
		],
		name: "PR_Guard_{1:Cap}_{2:Cap}_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		pullroll: true
	},
    {
		examples: [
            "PULL ROLL 2 SAFETY SWITCHES"
		],
		description: [
			/PULL ROLL ([0-9]).* SAFETY SWITCHES/i,
		],
		name: "PR_Guard_Roll{1}_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		pullroll: true
	},
    {
		examples: [
			"GUILLOTINE E-STOP RELAY 1 SAFETY RELAY FEEDBACK",
		],
		description: [
			/GUILLOTINE E-STOP RELAY ([0-9]).* FEEDBACK/i,
		],
		name: "PR_SafetyRelay_Guillotine{1}_Feedback",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		pullroll: true
	},
    {
		examples: [
			"GUILLOTINE E-STOP RELAY 1",
            "GUILLOTINE E-STOP RELAY 2",
		],
		description: [
			/GUILLOTINE E-STOP RELAY ([0-9])/i,
		],
		name: "PR_SafetyRelay_Guillotine{1}",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		rollStand: true
	},


    {
		examples: [
			"PULL ROLL E-SROP RELAY FEEDBACK",
            "PULL ROLL E-STOP RELAY FEEDBACK",
		],
		description: [
			/PULL ROLL E-S[T|R]OP RELAY/i,
		],
		name: "PR_SafetyRelay_PullRoll_Feedback",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		pullroll: true,
	},
    {
		examples: [
			"PULL ROLL E-STOP RELAY",
		],
		description: [
			/PULL ROLL E-STOP RELAY/i,
		],
		name: "PR_SafetyRelay_PullRoll",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		pullroll: true,
	},

    {
		examples: [
			"UPPER UNWIND BUMP NIP ROLL SAFETY RELAY FEEDBACK",
            "LOWER UNWIND BUMP NIP ROLL SAFETY RELAY FEEDBACK"
		],
		description: [
			/(LOWER|UPPER) UNWIND.* SAFETY RELAY/i,
		],
		name: "PR_SafetyRelay_Unwind{1:Cap}_Feedback",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		pullroll: true,
	},
    {
		examples: [
			"UPPER UNWIND BUMP NIP ROLL SAFETY RELAY",
            "LOWER UNWIND BUMP NIP ROLL SAFETY RELAY"
		],
		description: [
			/(LOWER|UPPER) UNWIND.* SAFETY RELAY/i,
		],
		name: "PR_SafetyRelay_Unwind{1:Cap}",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		pullroll: true,
	},
    
    {
		examples: [
			"PULL ROLL 1 (LAMINATION NIP) SAFETY RELAY FEEDBACK",
            "PULL ROLL 2 SAFETY RELAY SAFETY RELAY FEEDBACK"
		],
		description: [
			/PULL ROLL ([0-9]).* SAFETY RELAY/i,
		],
		name: "PR_SafetyRelay_Roll{1:Cap}_Feedback",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		pullroll: true,
	},
    {
		examples: [
			"PULL ROLL 1 (LAMINATION NIP) SAFETY RELAY",
            "PULL ROLL 2 SAFETY RELAY SAFETY RELAY"
		],
		description: [
			/PULL ROLL ([0-9]).* SAFETY RELAY/i,
		],
		name: "PR_SafetyRelay_Roll{1:Cap}",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		pullroll: true,
	},

    {
		examples: [
			"PULL ROLL 1 AC DRIVE SAFE TORQUE-OFF SIGNAL",
            "PULL ROLL 2 AC DRIVE SAFE TORQUE-OFF SIGNAL"
		],
		description: [
			/PULL ROLL ([0-9]).* (SAFE TORQUE[-| ]OFF|STO)/i,
		],
		name: "PR_Roll{1:Cap}_STO_Ch{EVEN+1}",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		pullroll: true,
	},




    {
		examples: [
			"PULL ROLL 1 (LAMINATION NIP) CLOSE RELAY",
            "PULL ROLL 2 CLOSE RELAY",
		],
		description: [
			/PULL ROLL ([0-9]).* CLOSE RELAY/i,
		],
		name: "PR_Roll{1:Cap}_Close_Sol",
		type: "BOOL",
		direction: "out",
		pullroll: true,
	},
    {
		examples: [
			"PULL ROLL 1 (LAMINATION NIP) ROLL OPEN PB",
            "PULL ROLL 2 ROLL OPEN PB",
            "PULL ROLL 1 (LAMINATION NIP) ROLL CLOSE PBL",
            "PULL ROLL 2 ROLL CLOSE PBL",
		],
		description: [
			/PULL ROLL ([0-9]).*(OPEN|CLOSE) PB/i,
		],
		name: "PR_Roll{1}_{2:Cap}_PB",
		type: "BOOL",
		direction: "in",
		pullroll: true,
	},
    {
		examples: [
			"PULL ROLL 1 (LAMINATION NIP) CLOSE PBL INDICATOR",
            "PULL ROLL 2 CLOSE PBL INDICATOR",
		],
		description: [
			/PULL ROLL ([0-9]).*(OPEN|CLOSE) PBL/i,
		],
		name: "PR_Roll{1}_{2:Cap}_PBL",
		type: "BOOL",
		direction: "out",
		pullroll: true,
	},
    
    {
		examples: [
			"PULL ROLL 1 (LAMINATION NIP) CLOSED PRS",
            "PULL ROLL 2 CLOSED PRS",
		],
		description: [
			/PULL ROLL ([0-9]).*CLOSED PRS/i,
		],
		name: "PR_Roll{1}_Closed_Prox",
		type: "BOOL",
		direction: "in",
		pullroll: true,
	},
    {
		examples: [
			"PULL ROLL 1 (LAMINATION NIP) RESERVOIR TANK AIR PRESSURE SWITCH (LOW PRESSURE)",
		],
		description: [
			/PULL ROLL ([0-9]).*LOW.* PRESSURE/i,
		],
		name: "PR_Roll{1}_AirPressure_AboveMin",
		type: "BOOL",
		direction: "in",
		pullroll: true,
	},


    {
		examples: [
			"UPPER UNWIND STATION ULTRASONIC SENSOR 1",
            "LOWER UNWIND STATION ULTRASONIC SENSOR 2",
            "LOWER UNWIND STATION ULTRASONIC SENSOR 2",
		],
		description: /(UPPER|LOWER) UNWIND STATION ULTRASONIC[ ](SENSOR)? ([0-9])/i,
		name: "PR_Unwind_{1:Cap}_Diam{3}_Raw",
		analog: true,
		direction: "in",
		pullroll: true
	},

    {
		examples: [
            "UPPER UNWIND STATION REGULATOR 2 TENSION REFERENCE 0-10VDC",
            "UPPER UNWIND STATION REGULATOR 2 TENSION REFERENCE 0-10VDC",
			"LOWER UNWIND STATION REGULATOR 4 TENSION REFERENCE 0-10VDC",
		],
		description: /(UPPER|LOWER) UNWIND STATION .*([0-9]) TENSION/i,
		name: "PR_Unwind_{1:Cap}_Tension{2}_Raw",
		analog: true,
		direction: "out",
		pullroll: true
	},

	{
		examples: [
            "FOREMOST GRINDER ROLLER FEEDER 1 SPEED REFERENCE 0-10V",
            "FOREMOST GRINDER ROLLER FEEDER 2 SPEED REFERENCE 0-10V",
		],
		description: /GRINDER.* ([0-9]) SPEED/i,
		name: "PR_Grinder_{1}_SpeedRef_Raw",
		analog: true,
		direction: "out",
		pullroll: true
	},

    {
        examples: [
            "PULL ROLL 1 (LAMINATION NIP) DYNAMIC BRAKING RESISTOR OVER TEMPERATURE ALARM",
            "PULL ROLL 2 DYNAMIC BRAKING RESISTORS OVER TEMPERATURE ALARM",
        ],
		description: /ROLL ([0-9]).* BRAKING RESISTOR.* TEMP/i,
		name: "PR_Roll{1}_BrakeResistor_Sw",
		type: "BOOL",
		direction: "in",
		pullroll: true,
	},
	{
		examples: [
			"PULL ROLL DYNAMIC BRAKING RESISTORS OVER TEMPERATURE ALARM",
		],
		description: [
			/([A-Z]+) ROLL DYNAMIC BRAKING RESISTOR[S]? OVER TEMPERATURE ALARM/i,
			/([A-Z]+) ROLL BRAKING RESISTOR OK/i
		],
		name: "PR_BrakeResistor_{1:Cap}_Sw",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		pullroll: true,
	},
	{
		examples: [
			"AIR CONDITIONER HIGH TEMPERATURE ALARM",
			"MAIN ENCL A/C FAULT",
		],
		description: [
			/AIR CONDITIONER HIGH TEMPERATURE ALARM/i,
			/MAIN ENCL A\/C FAULT/i,
		],
		name: "PR_Enclosure_Temperature_Sw",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		pullroll: true,
	},

    {
        examples: [
            "PULL ROLL 1 (LAMINATION NIP) MOTOR THERMOSTAT",
        ],
		description: /ROLL ([0-9]).* MOTOR THERMO/i,
		name: "PR_Roll{1}_MtrThermo_SwOK",
		type: "BOOL",
		direction: "in",
		pullroll: true,
	},

    {
        examples: [
            "PULL ROLL MAIN CONTROL ENCLOSURE E-STOP PBL",
        ],
		description: /MAIN (CONTROL)? ENCLOSURE E-STOP/i,
		name: "PR_Enclosure_Estop_PB",
		type: "BOOL",
		direction: "in",
		pullroll: true,
        safetyType: "Standard",
	},

	{
        examples: [
            "PULL ROLL OPERATOR CONTROL STATION E-STOP PBL",
        ],
		description: /PULL ROLL OPERATOR.* E-STOP/i,
		name: "PR_Operator_Estop_PB",
		type: "BOOL",
		direction: "in",
		pullroll: true,
        safetyType: "Standard",
	},

	{
        examples: [
            "PULL ROLL FIXED CONVEYOR EDGE TRIM RAZOR KNIFE PULL CORD COS",
        ],
		description: /FIXED CONVEYOR EDGE TRIM.* PULL CORD/i,
		name: "PR_FixedConveyor_EdgeTrim_Estop_PullCord",
		type: "BOOL",
		direction: "in",
		pullroll: true,
        safetyType: "Standard",
	},
    {
        examples: [
            "PULL ROLL FIXED CONVEYOR LEFT SIDE E-STOP PBL",
            "PULL ROLL FIXED CONVEYOR RIGHT SIDE E-STOP PBL",
        ],
		description: /FIXED CONVEYOR (LEFT|RIGHT).* E-STOP/i,
		name: "PR_FixedConveyor_{1:Cap}_Estop_PB",
		type: "BOOL",
		direction: "in",
		pullroll: true,
        safetyType: "Standard",
	},

	
	{
		examples: [
			"SLITTER OPERATOR CONTROL STATION E-STOP PBL",
		],
		description: /SLITTER OPERATOR CONTROL STATION E-STOP/i,
		name: "{LL}_EdgeSlitter_Estop_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true,
		safetyType: "Standard",
	},
	{
		examples: [
			"ROTARY SLITTER PULL CORD E-STOP COS",
			"SLITTER 1 PULL CORD E-STOP COS"
		],
		description: [
			/ROTARY SLITTER PULL CORD/i,
			/SLITTER ([0-9]+) PULL CORD/i,
		],
		name: "{LL}_EdgeSlitter{1}_Estop_PullCord",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
	},

	{
		examples: [
			"SLITTER INFEED PULL CORD COS",
		],
		description: /SLITTER INFEED PULL CORD/i,
		name: "{LL}_EdgeSlitter_Infeed_Estop_PullCord",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
	},

    {
        examples: [
            "SLITTER STATION OUTFEED PULL CORD COS",
        ],
		description: /SLITTER.* (INFEED|OUTFEED) PULL CORD/i,
		name: "PR_Slitter_{1:Cap}_Estop_PullCord",
		type: "BOOL",
		direction: "in",
        safetyType: "Standard",
	},
	{
		examples: [
			"SLITTER OPERATOR CONTROL STATION E-STOP PBL",
		],
		description: /SLITTER OPERATOR.* E-STOP/i,
		name: "{LL}_Slitter_Operator_Estop_PB",
		type: "BOOL",
		direction: "in",
		pullroll: true,
		safetyType: "Standard",
	},
	{
		examples: [
			"SLITTER OPEN PB",
			"SLITTER CLOSE PBL",
		],
		description: /SLITTER (OPEN|CLOSE)/i,
		name: "{LL}_Slitter_{1:Cap}_PB",
		type: "BOOL",
		direction: "in",
		pullroll: true,
		safetyType: "Standard",
	},
	{
		examples: [
			"SLITTER CLOSE PBL INDICATOR",
		],
		description: /SLITTER (OPEN|CLOSE)/i,
		name: "{LL}_Slitter_{1:Cap}_PBL",
		type: "BOOL",
		direction: "out",
		pullroll: true,
		safetyType: "Standard",
	},


	{
		examples: [
			"SLITTER SAFETY RELAY",
		],
		description: /SLITTER SAFETY RELAY/i,
		name: "{LL}_Slitter_Safety_Coil",
		type: "BOOL",
		direction: "out",
		pullroll: true,
		safetyType: "Safety",
	},
	{
		examples: [
			"SLITTER SAFETY RELAY FEEDBACK",
		],
		description: /SLITTER SAFETY RELAY/i,
		name: "{LL}_Slitter_Safety_Feedback",
		type: "BOOL",
		direction: "in",
		pullroll: true,
		safetyType: "Safety",
	},



	{
		examples: [
			"PULL ROLL CLOSED",
			"PULL ROLL AT CLOSED",
		],
		description: [
			/PULL ROLL CLOSED/i,
			/PULL ROLL.* CLOSED/i,
		],
		name: "{LL}_PRoll_Closed_Prox",
		type: "BOOL",
		safetyType: "Standard",
		direction: "in",
		rollStand: true,
	},
	{
		examples: [
			"PULL ROLL LOW AIR PRESSURE SWITCH",
			"PULL ROLL RESERVOIR TANK LOW PRESSURE",
			"PULL ROLL AIR TANK OK NOT LOW",
		],
		description: [
			/PULL ROLL.*LOW.* PRESSURE/i,
			/PULL ROLL.* AIR .*LOW.*/i,
		],
		name: "PR_PRoll_AirPressure_AboveMin",
		type: "BOOL",
		direction: "in",
		rollStand: true,
	},

	
	{
		examples: [
			"PULL ROLL ROLL OPEN PB",
		],
		description: /PULL ROLL ROLL OPEN PB/i,
		name: "PR_PRoll_Open_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"PULL ROLL ROLL CLOSE PBL",
		],
		description: /PULL ROLL ROLL CLOSE PBL/i,
		name: "PR_PRoll_Close_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"PULL ROLL OPEN PB",
		],
		description: /PULL ROLL OPEN PB/i,
		name: "PR_PRoll_Open_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"PULL ROLL CLOSE PBL",
		],
		description: /PULL ROLL CLOSE PB/i,
		name: "PR_PRoll_Close_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true
	},
	{
		examples: [
			"PULL ROLL CLOSE PBL INDICATOR",
			"PULL ROLL OPERATOR STATION CLOSE PBL INDICATOR (WHITE)",
			"PULL ROLL CLOSE PB LIGHT TO ON",
			"PULL ROLL CLOSE PB LIGHT TO ON (PULL ROLL 4-PB ENCL)",
		],
		description: [
			/PULL ROLL.* CLOSE.*(PBL|INDICATOR|PB LIGHT)/i,
		],
		name: "PR_PRoll_Close_PBL",
		type: "BOOL",
		direction: "out",
		rollStand: true,
	},


	{
		examples: [
			"TRANSFER ROLL CLOSE ENABLE", // 18122
		],
		description: [
			/TRANSFER ROLL CLOSE ENABLE/i,
		],
		name: "PR_Transfer_CloseEnable_Coil",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		rollStand: true,
	},
	{
		examples: [
			"TRANSFER ROLL CLOSE ENABLE", // 18122
		],
		description: [
			/TRANSFER ROLL CLOSE ENABLE/i,
		],
		name: "PR_Transfer_CloseEnable_Feedback",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true,
	},
	
	{
		examples: [
			"PULL ROLL SAFETY RELAY, L-R11413",
			"PULL ROLL 1 SAFETY RELAY",
			"PULL ROLL CLOSE ENABLE", // 18122
		],
		description: [
			/PULL ROLL[ ]?([0-9]*).* SAFETY RELAY/i,
			/PULL ROLL[ ]?([0-9]*) CLOSE ENABLE/i,
		],
		name: "PR_PRoll{1}_CloseEnable_Coil",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		rollStand: true,
	},
	{
		examples: [
			"PULL ROLL SAFETY RELAY FEEDBACK",
			"PULL ROLL ROLL CLOSE SAFETY RELAY FEEDBACK",
			"PULL ROLL CLOSE ENABLE", // 18122
		],
		description: [
			/PULL ROLL[ ]?([0-9]*).* SAFETY RELAY FEEDBACK/i,
			/PULL ROLL[ ]?([0-9]*) CLOSE ENABLE/i,
		],
		name: "PR_PRoll{1}_CloseEnable_Feedback",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		rollStand: true,
	},

	{
		examples: [
			"PULL ROLL AC DRIVE SAFE TORQUE-OFF SIGNAL",
		],
		description: /PULL ROLL.*DRIVE.* SAFE TORQUE/i,
		name: "PR_Roll_Drive_STO_Ch{EVEN+1}",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		rollStand: true
	},


	{
        examples: [
            "CORONA TREATER MAIN CONTROL ENCLOSURE (POWER SUPPLY) E-STOP PBL",
        ],
		description: /CORONA.* ENCLOSURE.* E-STOP/i,
		name: "PR_Corona_Enclosure_Estop_PB",
		type: "BOOL",
		direction: "in",
		pullroll: true,
        safetyType: "Standard",
	},
	{
        examples: [
            "CORONA TREATER OPERATOR CONTROL STATION E-STOP PBL",
        ],
		description: /CORONA.* OPERATOR.* E-STOP/i,
		name: "PR_Corona_Operator_Estop_PB",
		type: "BOOL",
		direction: "in",
		pullroll: true,
        safetyType: "Standard",
	},
    {
        examples: [
            "CORONA TREATER E-STOP INTEGRATION",
        ],
		description: /CORONA.* E-STOP/i,
		name: "PR_Corona_Estop_PB",
		type: "BOOL",
		direction: "in",
		pullroll: true,
        safetyType: "Standard",
	},
	{
        examples: [
            "COROTEC CORONA TREATER LOW POWER ALARM",
        ],
		description: /CORONA.*LOW POWER/i,
		name: "PR_Corona_LowPower",
		type: "BOOL",
		direction: "in",
		pullroll: true,
        safetyType: "Standard",
	},
	{
        examples: [
            "COROTEC CORONA TREATER REMOTE STOP RELAY",
			"COROTEC CORONA TREATER REMOTE START RELAY",
        ],
		description: /CORONA.*REMOTE (START|STOP)/i,
		name: "PR_Corona_{1:Cap}_Relay_Coil",
		type: "BOOL",
		direction: "out",
		pullroll: true,
        safetyType: "Standard",
	},
	{
		examples: [
			"COROTEC CORONA TREATER EMERGENCY STOP RELAY",
		],
		description: /CORONA.*EMERGENCY STOP RELAY/i,
		name: "PR_Corona_Estop_Relay_Coil",
		type: "BOOL",
		direction: "out",
		pullroll: true,
		safetyType: "Safety",
	},

    ////////////////////////////////////
	// SPARE
	//
	{
		description: /SPARE T\/C INPUT/i,
		name: "PR_Zone_Spare_TC_Rack{N}_S{S}_{P}",
		type: "INT",
		direction: "in",
		pullroll: true,
	},
	{
		description: /SPARE/i,
		name: "PR_Spare_Rack{N}_S{S}_{P}_{DIR}_{TYPE}",
		pullroll: true,
	},
];

export  {knownIoPullRoll as default}