import DxfParser from '../dxf-parser';
import { KnownModule } from '../types/module';

export type BoundingBox = {
	xmin: number,
	xmax: number,
	ymin: number,
	ymax: number,
}

export type Position = {
	x: number,
	y: number,
}

export interface DxfParseEntity {
	attr?: string; // only for attrib entities
	text?: string;
	name?: string;
	parent?: DxfParseEntity;
	children?: DxfParseEntity[];
	position: Position;
	relPos: Position;
	fieldType?: string;
	startPoint: Position;
	handle?: string;
	ownerHandle?: string;
	type?: string;
	dxfSection?: string;
	layer?: string;
	softID?: string;
	ebModule?: KnownModule;
}

export interface DxfParseEntityText extends DxfParseEntity {
	text: string;
	ebUsedStartPoint?: boolean;
}

export class DxfPageParse {
	
	/**
	* The original text of the DXF file as passed by the constructor
	*/
	txtOriginal: string = "";
	
	/**
	* The text of the DXF file. May be altered slightly by the parser.
	*/
	txt: string = "";
	
	//////////////////////////////////////////////////////
	// DXF PARSER OBJECTS
	//////////////////////////////////////////////////////
	static parser: DxfParser = new DxfParser();
	dxf: any = {}; // TODO: add types to DxfParser.parseSync
	texts: Array<DxfParseEntityText> = [];
	entityHandles: {[handle: string]: DxfParseEntity} = {};
	inserts: Array<DxfParseEntity> = [];
	
	constructor(txt: string, dxf: any) {
		this.txtOriginal = txt.slice();
		this.txt = txt;
		this.dxf = dxf;
		this.findAllTexts();
	}
	
	static blank(): DxfPageParse {
		return new DxfPageParse("", {});
	}
	
	static parse(txt: string): DxfPageParse | Error {
		let error: Error | null = null;
		
		if (typeof txt !== "string" || txt === "") {
			error = new Error("NO FILE PROVIDED");
			return error;
		}
		if (txt.includes("<!DOCTYPE html>")) {
			error = new Error("404 Not Found");
			return error;
		}
		
		try {
			const dxf = this.parser.parseSync(txt);
			const dxfPageParse = new DxfPageParse(txt, dxf);
			return dxfPageParse;
		} 
		catch (err: any) {
			error = new Error("Could not parse DXF file");
			error.name = err.name;
			error.message = err.message;
			error.stack = err.stack;
			console.error(err.stack);
			console.log(this);
			console.error(err);
			return error;
		}
		
	}
	
	
	//////////////////////////////////////////////////////
	// FIND ALL TEXTS
	//////////////////////////////////////////////////////
	//
	// Scours DXF for all TEXT and MTEXT entities
	// stores them all in one place
	// applies preprocessing to make text readable
	//
	// Gets all texts from the DXF object
	// some are nested, so we put them all in one big list
	//
	private findAllTexts() {
		this.texts = [];
		this.entityHandles = {};
		this.inserts = [];
		
		
		for (let b in this.dxf?.tables?.block?.blocks) {
			const block = this.dxf.tables.block.blocks[b];
			this.entityHandles[ block.handle ] = block;
		}
		
		this.findSubTexts(this.dxf.entities, "entities");
		
		// console.log( this.dxf.blocks);
		for (let b in this.dxf.blocks) {
			const block = this.dxf.blocks[b];
			// console.log(b, block);
			
			this.entityHandles[ block.handle ] = block;
			this.findSubTexts(block.entities, "block." + b, block);
		}
		
		
		// Parse text into standard formats
		for (let t in this.texts) {
			let txt = this.texts[t];

			txt.text = txt.text.replace(/[^\x08-\x7E]/g, ""); // remove non-ascii
			txt.text = txt.text.replace(/%%d/g, "°");
			txt.text = txt.text.replace(/\\P/g, " ");
			txt.text = txt.text.replace(/\\l/g, "");
			txt.text = txt.text.replace(/\\Fromans.shx|c1;/g, "");
			txt.text = txt.text.replace(/\\Fromans.shx|c0;/g, "");
			txt.text = txt.text.replace(/\\L/g, "");
			txt.text = txt.text.replace(/{/g, "");
			txt.text = txt.text.replace(/}/g, "");
			txt.text = txt.text.replace(/\\Fsimplex.shx|c0;/g,"");
			txt.text = txt.text.replace(/\\Fsimplex.shx|c1;/g,"");
			txt.text = txt.text.replace(/\\H[0-1]\.[0-9]+x;/g,"");
			txt.text = txt.text.replace(/[\u{0000}-\u{001F}]/gu, ""); // replace non characters
			txt.text = txt.text.replace(/DESC(|0[1-9])$/g,"");
			txt.text = txt.text.replace(/L_REL$/g,"");
			txt.text = txt.text.replace(/LOC$/g,"");
			txt.text = txt.text.replace(/RATING$/g,"");
			txt.text = txt.text.replace(/\\H1.1x;/g, "");
			txt.text = txt.text.replace(/\\H1.222x;/g, "");
			txt.text = txt.text.replace(/\\H0.8182x;/g, "");
			txt.text = txt.text.replace(/\\H0.9091x;/g, "");
			txt.text = txt.text.replace(/\\H0.9412x;/g, "");
			txt.text = txt.text.replace(/\\H1x;/g, "");
			txt.text = txt.text.replace(/\\H1.5X;/g, "");
			txt.text = txt.text.replace(/�/g, "");
			txt.text = txt.text.replace(/\^J/g, "");
			txt.text = txt.text.replace(/\|\|@/g, "");
			txt.text = txt.text.replace(/\\fArial\|b0\|i0\|c0\|p34;/g, "");
			txt.text = txt.text.replace(/\|/g, "");
			txt.text = txt.text.replace(/[ ]+/g, " ");
			txt.text = txt.text.replace("^I", "");
			txt.text = txt.text.replace("%%u", "")

			// REMOVE THE TERMINAL LEGEND 
			// This was causing things like STO and drives to match with everything in the list
			//
			// HYDRAULIC UNIT JUNCTION BOX TERMINAL
			// ROLL STAND J-BOX TERMINAL
			// PULL ROLL OPERATOR STATION TERMINAL
			// PULL ROLL JUNCTION BOX TERMINAL
			// ROLL STAND CONTROL PANEL TERMINAL
			// TEMPERATURE STATION PANEL TERMINAL
			// TITAN OPERATOR CONSOLE TERMINAL"
			if (txt.text.match(/(.*(BOX|STATION|PANEL|ENCLOSURE).* TERMINAL){2,}/)) {
				txt.text = txt.text = "TERMINAL BLOCK LEGEND REMOVED"
			}

			
			if (typeof txt.position === "undefined") {
				txt.position = txt.startPoint;
				txt.ebUsedStartPoint = true;
			}
		}
	}
	
	//////////////////////////////////////////////////////
	// Find Sub Texts
	//////////////////////////////////////////////////////
	// Helper that recursively scans list of entities
	//
	private findSubTexts(entities: DxfParseEntity[], sectionName: string, parent?: DxfParseEntity) {
		
		for (let e in entities) {
			let entity = entities[e];
			
			// It doesn't seem to help anything but module identification to remove non-referenced entities
			// if (entity.ownerHandle && (this.entityHandles[entity.ownerHandle]?.softID || "1") <= "0") {
			//   continue;
			// }
			
			// Assign positions if they don't have one
			if ( typeof entity.position === "undefined" ) {
				if ( typeof entity.startPoint === "object") {
					entity.position = Object.assign({}, entity.startPoint);
				}
				else if ( typeof entity.parent === "object" &&
				typeof entity.parent.position === "object") {
					entity.position = Object.assign({}, entity.parent.position);
				}
				else {
					entity.position = {x: -100, y: -100}
				}
			}
			
			// Calculate the relative position
			if (typeof parent === "object") {
				entity.parent = parent;
				// calculate absolute positions
				if (typeof parent.position === "object") {
					entity.relPos = {
						x: entity.position.x - parent.position.x,
						y: entity.position.y - parent.position.y
					}
				}
			}
			
			// add entity handle to the lookup table
			if (typeof entity.handle === "string") {
				this.entityHandles[ entity.handle ] = entity;
			}
			
			
			// if (entity.type === "PART" && typeof entity.fieldType === "string" && entity.fieldType.includes("ARROW")) {
			// 	this.ioArrow = entity;
			// }
			
			// add text to text array
			if ( entity.type === "TEXT"  || entity.type === "MTEXT" || entity.type === "BTEXT" || entity.type === "BTEXTL" || entity.type === "PART") {
				if (typeof entity.text !== "string" && typeof entity.fieldType === "string") {
					entity.text = entity.fieldType;
				}
				
				if (typeof entity.text === "string") {
					entity.dxfSection = sectionName;
					// entity.text is ensured as string by this point, so typecast is safe
					this.texts.push( entity as DxfParseEntityText );
				}
				
				
			}
			
			// search children if it has any
			if ( entity.type === "INSERT") {
				this.inserts.push( entity );
				if ( Array.isArray(entity.children)) {
					this.findSubTexts( entity.children, sectionName + ".insert-" + (entity.name || entity.text || entity.handle || ""), entity)
				}
			}
			else if ( entity.type === "ATTRIB") {
				this.inserts.push( entity );
				if ( Array.isArray(entity.children)) {
					this.findSubTexts( entity.children, sectionName + ".attrib-" + (entity.name || entity.text || entity.handle || ""), entity)
				}
			}
		}
	}
	public findTextEntity(regex: RegExp): FoundTextEntity | undefined {
		for (let t in this.texts) {
			const entity = this.texts[t];
			const txt = entity.text;
			
			// skip if text is from other field
			if (typeof txt !== "string") {continue;}
			let match = txt.match(regex);
			if (match) {
				return {entity: entity, match: match} as FoundTextEntity;
			}
		}
	}
	
	public findTextEntities(regex: RegExp): FoundTextEntity[] {
		let results: FoundTextEntity[] = [];
		for (let t in this.texts) {
			const entity = this.texts[t];
			const txt = entity.text;
			
			// skip if text is from other field
			if (typeof txt !== "string") {continue;}
			let match = txt.match(regex);
			if (match) {
				results.push({entity: entity, match: match} as FoundTextEntity);
			}
		}
		return results;
	}
	
	
	static entitiesHaveSameOwner(entities: DxfParseEntity[]): boolean {
		if (entities.length === 0) {
			console.log("No entities to check");
			return true;
		}
		let firstHandle = entities[0]?.ownerHandle;
		
		let allSame = true;
		for (let entity of entities) {
			if (entity?.ownerHandle !== firstHandle) {
				// console.log("Not all entities have the same owner", firstHandle, entity);
				allSame = false;
			}
		}
		return allSame;
	}
	
	/**
	* Filter By Owner Name
	* @description Filter entities by given owner name
	*/
	static filterByOwnerName<T extends DxfParseEntity>(entities: T[], expected_name: RegExp | string, expected_points: number): T[] {
		let filtered: T[] = [];
		
		filtered = entities.filter((p) => {return p?.parent?.name?.match( expected_name )})
		if (filtered.length === expected_points) {
			return filtered;
		}
		/** Special case for 2-point modules with one point on left and one point on right */
		if ((filtered.length === expected_points - 1) && (expected_points !== 1)) {
			const warn = "Missing one point when filtering by name";
			console.log(warn);
			return filtered;
		}
		// console.log("Could not filter by name", filtered);
		
		for (let check of entities) {
			const filter_name = check?.parent?.name || "nothing......";
			filtered = entities.filter((p) => {return p?.parent?.name?.match( filter_name )})
			
			// console.log("Expected length", expected_points, filtered.length, filtered)
			if (filtered.length === expected_points) {
				return filtered;
			}
		}
		// console.log("Could not filter by expected length");
		
		// fall back to just anything greater than 1
		for (let check of entities) {
			const filter_name = check?.parent?.name || "nothing.....";
			filtered = entities.filter((p) => {return p?.parent?.name?.match( filter_name )})
			
			console.log("Above 1 length", expected_points, filtered.length, filtered);
			if (filtered.length > 1) {
				return filtered;
			}
		}
		console.log("Could not filter to just reduce to above 1");
		
		// return everything
		return entities;
	}
	
	/**
	* Filter Duplicates
	* @description - Remove duplicate entities from a list based on text and positions
	* based on matching text and positions within a tolerance
	* 
	* @param entities - List of entities to filter duplicates from
	* @param xtolerance - Tolerance for x position
	* @param ytolerance - Tolerance for y position
	* @returns - List of entities with duplicates removed
	*/
	static filterDuplicates<T extends DxfParseEntity>(entities: T[], xtolerance=0.0001, ytolerance=0.0001): T[] {
		let filtered: T[] = [];
		
		for (let e in entities) {
			const entity = entities[e];
			
			const match = filtered.find((el: T) => {
				if (typeof el.position === "object" && typeof entity.position === "object") {
					return (el.text === entity.text &&
						Math.abs(el.position.x - entity.position.x) < xtolerance &&
						Math.abs(el.position.y - entity.position.y) < ytolerance
						);
					}
					return el.text === entity.text && el.fieldType === entity.fieldType;
					//  && DxfPage.isNear(el, entity)
					// TODO: Maybe also check that positions match within a tolerance
				})
				
				if (! match) {
					filtered.push(entity);
				}
			}
			
			return filtered;
		}
		
		
		// remove every other element in the list
		// Since they are in different positions, they are not detected as duplicates
		// TODO: Possibly check if the text is actually the same
		static filterEven<T>(points: T[]): T[] {
			return points.filter(function(el, index) {
				return index % 2 === 0;
			});
		}
		
		/**
		* Is Near
		* @description - Check if two entities are near each other within a tolerance
		* @param entityA - Entity A
		* @param entityB - Entity B
		* @param xtolerance - Tolerance for x position in document units (typically inches)
		* @param ytolerance - Tolerance for y position in document units (typically inches)
		* @returns 
		*/
		static isNear<T extends DxfParseEntity>(entityA: T, entityB: T, xtolerance=0.05, ytolerance=0.05): boolean {
			const dx = entityA.position.x - entityB.position.x;
			const dy = entityA.position.y - entityB.position.y;
			
			return Math.abs(dx) < xtolerance && Math.abs(dy) < ytolerance;
		}
		
		/**
		* Sort In X
		* @description - Sort a list of entities horizontally
		* @param entities - List of entities to sort
		* @returns - Sorted list of entities
		*/
		static sortInX<T extends DxfParseEntity>(entities: T[]): T[] {
			return entities.sort((a,b) => {
				// sort by x position unless the position is the same, then sort by Y
				if (a.position.x > b.position.x + 0.0001) {return 1} 
				if (a.position.x < b.position.x - 0.0001) {return -1}
				return (a.position.y < b.position.y) ? 1 : -1;
			})
		}
		
		/**
		* Sort In Y
		* @param entities - List of entities to sort
		* @returns - Sorted list of entities
		*/
		static sortInY<T extends DxfParseEntity>(entities: T[]): T[] {
			return entities.sort((a,b) => (a.position.y > b.position.y) ? 1 : -1)
		}
		
		/**
		* sortSimilarInY
		* @param entities : List to Sort
		* @param x_tolerance : Tolerance of X position. Points closer than this will be sorted in Y
		* @param reverseX : Reverse the X sort direction
		* @returns sorted list of entities
		*/
		static sortSimilarInY<T extends DxfParseEntity>(entities: T[], x_tolerance: number, reverseX=false): T[] {
			return entities.sort((a,b) => {
				const ap = a.position;
				const bp = b.position;
				if ( Math.abs(ap.x - bp.x) > x_tolerance ) {
					if (reverseX) {
						return ((ap.x < bp.x) ? 1: -1);
					}
					else {
						return ((ap.x > bp.x) ? 1: -1);
					}
				}
				// two points are close, so sort them by Y
				else {
					// y coordinates are from the bottom
					return ((ap.y < bp.y) ? 1 : -1)
				}
			})
		}
		
		/**
		* Split Bounding Box in X
		* @description - Split a bounding box in half horizontally
		* @param bb - Bounding box to split
		* @returns - Two bounding boxes in left and right
		*/
		static splitBBinX(bb: BoundingBox): {l: BoundingBox, r: BoundingBox} {
			if (typeof bb !== "object") {
				console.log("Bounding box not defined for split")
			}
			let l = Object.assign({}, bb);
			let r = Object.assign({}, bb);
			
			const mid = (bb.xmin + bb.xmax) / 2;
			
			l.xmax = mid;
			r.xmin = mid;
			
			return {
				l: l,
				r: r
			}
		}
		
		/**
		* Bounding Box
		* @description - Create a box that encapsulates all entities in a given list
		* @param entities - List of entities to find bounding box for
		* @returns - Bounding box for the list of entities
		*/
		static boundingBox<T extends DxfParseEntity>(entities: T[]): BoundingBox {
			if ( ! Array.isArray(entities) || entities.length < 1) {
				console.log("no entities to search for bounding box for", entities);
			}
			
			let xmin = entities[0].position.x;
			let xmax = xmin;
			let ymin = entities[0].position.y;
			let ymax = ymin;
			
			for (let e in entities) {
				const entity = entities[e];
				
				if (typeof entity.position !== "object") {continue}
				xmin = Math.min(xmin, entity.position.x);
				xmax = Math.max(xmax, entity.position.x);
				ymin = Math.min(ymin, entity.position.y);
				ymax = Math.max(ymax, entity.position.y);
			}
			
			return {
				xmin: xmin,
				xmax: xmax,
				ymin: ymin,
				ymax: ymax
			}
		}
		
		// Select all text entities within a bounding box
		/**
		* Box Select
		* @description - Select all entities within a bounding box
		* 
		* @param entities - List of entities to select from
		* @param bounds - Bounding box to select within
		* @param inclusive - Include entities that are on the edge of the bounding box
		* @returns 
		*/
		static boxSelect<T extends DxfParseEntity>(entities: T[], bounds: BoundingBox, inclusive: boolean): T[] {
			const xmin = Math.min( bounds.xmin, bounds.xmax )
			const ymin = Math.min( bounds.ymin, bounds.ymax )
			const xmax = Math.max( bounds.xmin, bounds.xmax )
			const ymax = Math.max( bounds.ymin, bounds.ymax )
			
			let filtered: T[] = [];
			
			for (const entity of entities) {
				let position = entity.position;
				
				if (typeof position !== "object") {continue}
				
				if (inclusive) {
					if (xmin <= position.x && xmax >= position.x ) {
						if (ymin <= position.y && ymax >= position.y) {
							filtered.push(entity)
						}
					}
				}
				else {
					if (xmin < position.x && xmax > position.x ) {
						if (ymin < position.y && ymax > position.y) {
							filtered.push(entity)
						}
					}
				}
				
			}
			
			return filtered;
		}
		public boxSelect(bounds: BoundingBox, inclusive: boolean): DxfParseEntityText[] {
			return DxfPageParse.boxSelect(this.texts, bounds, inclusive);
		}

		public getProbableNewlineTexts(center_text, newline_offset_y, tolerance_x, required_padding_y, max_distance_y): DxfParseEntityText[] {
			let results: DxfParseEntityText[] = [];

			const within_search_area_bb = {
				xmin: center_text.position.x - tolerance_x,
				xmax: center_text.position.x + tolerance_x,
				ymin: center_text.position.y - max_distance_y,
				ymax: center_text.position.y + max_distance_y,
			}
			const within_search_area = this.boxSelect(within_search_area_bb, true);

			// soted bottom to top
			const sorted_vertically = DxfPageParse.filterDuplicates(DxfPageParse.sortInY(within_search_area));

			// console.log("Found possible newlines for:", center_text.text, " : ", sorted_vertically);

			// find center text in list
			const center_index = sorted_vertically.findIndex((el) => {
				return el === center_text;
			})
			if (center_index === -1) {
				console.warn("Should have at least found center text in list of texts")
				return [];
			}

			// console.log("Center index:", center_index);

			let found_below: DxfParseEntityText[] = [];
			let last_newline = center_index;
			for( let i = center_index - 1; i >= 0; i--) {
				const below_text = sorted_vertically[i];
				const focused_text = sorted_vertically[last_newline];
				const vertical_distance = focused_text.position.y - below_text.position.y;

				if (vertical_distance < newline_offset_y ) {
					found_below.push(below_text);
					// console.log("Found below:", below_text.text, " : ", vertical_distance);
					last_newline = i;
				}
				else {
					// console.log("Ignoring below:", below_text.text, " : ", vertical_distance, " : ", newline_offset_y);
					break;
				}
			}

			// verify that there is enough padding below the last found text to be considered a newline of the center text
			// Otherewise dense text could just be all considered new lines.
			if (found_below.length > 0) {
				const bottom_found = found_below[found_below.length - 1];

				let next_nearest_below = center_text.position.y - max_distance_y;
				if ( last_newline > 0 ) {
					next_nearest_below = sorted_vertically[last_newline - 1].position.y;
				}
				
				if ( bottom_found.position.y - next_nearest_below > required_padding_y ) {
					results = results.concat(found_below);
				}
			}


			let found_above: DxfParseEntityText[] = [];
			last_newline = center_index;
			for( let i = center_index + 1; i < sorted_vertically.length; i++) {
				const above_text = sorted_vertically[i];
				const focused_text = sorted_vertically[last_newline];
				const vertical_distance = above_text.position.y - focused_text.position.y;
				
				if (vertical_distance < newline_offset_y ) {
					found_above.push(above_text);
					last_newline = i;
				}
				else {
					break;
				}
			}

			// verify that there is enough padding above the last found text to be considered a newline of the center text
			// Otherewise dense text could just be all considered new lines.
			if (found_above.length > 0) {
				const top_found = found_above[found_above.length - 1];

				let next_nearest_above = center_text.position.y + max_distance_y;
				if ( last_newline < sorted_vertically.length - 1 ) {
					next_nearest_above = sorted_vertically[last_newline + 1].position.y;
				}

				if ( next_nearest_above - top_found.position.y > required_padding_y ) {
					results = results.concat(found_above);
				}
			}

			return results;
		}
	}
	
	export type FoundTextEntity = {
		entity: DxfParseEntityText, 
		match: RegExpMatchArray,
	};