import type {KnownIO} from "../types/io";

let knownIoAuxRoll: Array<KnownIO> = [
    {
        examples: [
            "AUXILIARY ROLL STATION EMERGENCY STOP CIRCUIT",
        ],
        description: /AUXILIARY ROLL STATION EMERGENCY STOP CIRCUIT/i,
        name: "AR_Estop_Chain_Ch{EVEN+1}",
        type: "BOOL",
        direction: "in",
        safetyType: "Safety",
        auxRoll: true,
    },
    
    {
        examples: [
            "AUXILIARY ROLL STATION MAIN CONTROL ENCLOSURE E-STOP PBL",
        ],
        description: /AUXILIARY ROLL STATION MAIN CONTROL ENCLOSURE E-STOP PBL/i,
        name: "AR_MainEnclosure_Estop_PB",
        type: "BOOL",
        direction: "in",
        safetyType: "Standard",
        auxRoll: true,
    },
    {
        examples: [
            "AUXILIARY ROLLS OPERATOR CONTROL STATION E-STOP PBL",
        ],
        description: /AUXILIARY ROLLS OPERATOR CONTROL STATION E-STOP/i,
        name: "AR_Operator_Estop_PB",
        type: "BOOL",
        direction: "in",
        safetyType: "Standard",
        auxRoll: true,
    },
    {
        examples: [
            "FIXED CONVEYOR 1 LEFT SIDE E-STOP PBL",
            "FIXED CONVEYOR 1 RIGHT SIDE E-STOP PBL",
        ],
        description: /FIXED CONVEYOR ([0-9]?)[ ]?(LEFT|RIGHT).* E-STOP PBL/i,
        name: "AR_FixedConveyor{1}_{2:Cap}_Estop_PB",
        type: "BOOL",
        direction: "in",
        safetyType: "Standard",
        auxRoll: true,
    },
    {
        examples: [
            "AUXILIARY ROLL CAGE INFEED PULL CORD COS",
            "AUXILIARY ROLL CAGE OUTFEED PULL CORD COS",
        ],
        description: /AUXILIARY ROLL CAGE (INFEED|OUTFEED) PULL CORD COS/i,
        name: "AR_Cage_{1:Cap}_Estop_PullCord",
        type: "BOOL",
        direction: "in",
        safetyType: "Standard",
        auxRoll: true,
    },

    {
        examples: [
            "AUXILIARY ROLL 1 PULL CORD COS",
            "AUXILIARY ROLL 3 PULL CORD COS",
        ],
        description: /AUXILIARY ROLL ([0-9]?)[ ]?PULL CORD COS/i,
        name: "AR_Roll{1:Cap}_Estop_PullCord",
        type: "BOOL",
        direction: "in",
        safetyType: "Standard",
        auxRoll: true,
    },

    {
        examples: [
            "OPERATOR SIDE LIGHT CURTAIN RESET PB INDICATOR",
            "NON-OPERATOR SIDE LIGHT CURTAIN RESET PB INDICATOR",
        ],
        description: /((NON)?)[-]?OPERATOR SIDE LIGHT CURTAIN RESET PB INDICATOR/i,
        name: "AR_Cage{1:Cap}Op_CurtainReset_PBL",
        type: "BOOL",
        direction: "out",
        safetyType: "Standard",
        auxRoll: true,
    },

    {
        examples: [
            "NON-OPERATOR LIGHT CURTAIN RESET CONTROL STATION E-STOP PBL",
        ],
        description: /((NON)?)[-]?OPERATOR LIGHT CURTAIN RESET CONTROL STATION E-STOP PBL/i,
        name: "AR_Cage{1:Cap}Op_Estop_PB",
        type: "BOOL",
        direction: "in",
        safetyType: "Standard",
        auxRoll: true,
    },
    {
        examples: [
                "OPERATOR SIDE LIGHT CURTAIN MANUAL RESET SAFETY RELAY",
            "NON-OPERATOR SIDE LIGHT CURTAIN MANUAL RESET SAFETY RELAY",
        ],
        description: [
            // Match both non-and operator side and capture the pattern
            /((NON)?)[-]?OPERATOR SIDE LIGHT CURTAIN MANUAL RESET SAFETY RELAY/i,
            
        ],
        name: "AR_Cage{1:Cap}Op_CurtainReset_Relay",
        type: "BOOL",
        direction: "out",
        auxRoll: true,
        safetyType: "Safety",
    },
    {
        examples: [
                "OPERATOR SIDE LIGHT CURTAIN MANUAL RESET SAFETY RELAY",
            "NON-OPERATOR SIDE LIGHT CURTAIN MANUAL RESET SAFETY RELAY",
        ],
        description: [
            // Match both non-and operator side and capture the pattern
            /((NON)?)[-]?OPERATOR SIDE LIGHT CURTAIN MANUAL RESET SAFETY RELAY/i,
            
        ],
        name: "AR_Cage{1:Cap}Op_CurtainReset_RelayFeedback",
        type: "BOOL",
        direction: "in",
        auxRoll: true,
        safetyType: "Safety",
    },
    {
        examples: [
            "NON-OPERATOR SIDE LIGHT CURTAIN RESET PBL",
            "OPERATOR SIDE LIGHT CURTAIN RESET PBL",
        ],
        description: [
            /((NON)?)[-]?OPERATOR SIDE LIGHT CURTAIN RESET PBL/i,
        ],
        name: "AR_Cage{1:Cap}Op_CurtainReset_PB",
        type: "BOOL",
        direction: "in",
        auxRoll: true,
        safetyType: "Safety",
    },
    {
        examples: [
            "OPERATOR SIDE LIGHT CURTAIN",
            "NON-OPERATOR SIDE LIGHT CURTAIN",
        ],
        description: /((NON)?)[-]?OPERATOR SIDE LIGHT CURTAIN/i,
        name: "AR_Cage{1:Cap}Op_Curtain_Ch{EVEN+1}",
        type: "BOOL",
        direction: "in",
        safetyType: "Safety",
        auxRoll: true,
    },

    {
        examples: [
            "AUXIARY ROLL STATION OPERATOR SIDE LIGHT TOWER HORN MODULE TONE 1",
            "AUXIARY ROLL STATION OPERATOR SIDE LIGHT TOWER HORN MODULE TONE 2",
            "AUXIARY ROLL STATION NON-OPERATOR SIDE LIGHT TOWER HORN MODULE TONE 1",
            "AUXIARY ROLL STATION NON-OPERATOR SIDE LIGHT TOWER HORN MODULE TONE 2",
        ],
        description: [
            /AUXIARY ROLL STATION ((NON)?)[-]?OPERATOR SIDE LIGHT TOWER HORN.* TONE ([0-9]?)/i,
        ],
        name: "AR_Cage{1:Cap}Op_Horn{3:Cap}",
        type: "BOOL",
        direction: "out",
        auxRoll: true,
    },
    {
        examples: [
            "AUXILIARY ROLL STATION OPERATOR SIDE LIGHT TOWER MODULE (RED STROBE)",
            "AUXILIARY ROLL STATION OPERATOR SIDE LIGHT TOWER MODULE (FLASHING AMBER)",
            "AUXILIARY ROLL STATION OPERATOR SIDE LIGHT TOWER MODULE (STRAIGHT GREEN)",
            "AUXIARY ROLL STATION NON-OPERATOR SIDE LIGHT TOWER MODULE (RED STROBE)", // The typo is in the document
        ],
        description: [
            /.* ROLL STATION ((NON)?)[-]?OPERATOR SIDE LIGHT TOWER MODULE .*(RED|AMBER|GREEN)/i,
        ],
        name: "AR_Cage{1:Cap}Op_Light{3:Cap}",
        type: "BOOL",
        direction: "out",
        auxRoll: true,
    },



    // AUX ROLL DRIVES
    {
		examples: [
			"AUXILIARY ROLL 1 DYNAMIC BRAKING RESISTORS OVER TEMPERATURE ALARM",
			"AUXILIARY ROLL 4 DYNAMIC BRAKING RESISTORS OVER TEMPERATURE ALARM",
		],
		description: [
            /AUXILIARY ROLL ([0-9]?)[ ]?DYNAMIC BRAKING RESISTORS OVER TEMPERATURE ALARM/i,
		],
		name: "AR_BrakeResistor_AuxRoll{1}_TempOkSw",
		type: "BOOL",
		direction: "in",
		auxRoll: true,
	},
    {
		examples: [
			"AUXILIARY ROLL 1 MOTOR THERMOSTAT",
            "AUXILIARY ROLL 2 MOTOR THERMOSTAT",
		],
		description: [
			/AUXILIARY ROLL ([0-9]) MOTOR (TEMP|THERMOSTAT)/i,
		],
		name: "AR_Aux{1:Cap}_MtrThermo_TempOkSw",
		type: "BOOL",
		direction: "in",
		auxRoll: true
	},
    {
		examples: [
			"AUXILIARY ROLL AC DRIVES SAFE TORQUE-OFF SIGNAL",
		],
		description: [
			/AUXILIARY ROLL AC DRIVES SAFE TORQUE/i,
		],
		name: "AR_AuxRolls_STO_Ch{EVEN+1}",
		type: "BOOL",
		direction: "out",
        safetyType: "Safety",
		auxRoll: true
	},




    // BLOWERS
    {
		examples: [
			"TOP OF SHEET AIR BLOWER 1 MOTOR THERMOSTAT",
            "BOTTOM OF SHEET AIR BLOWER 2 MOTOR THERMOSTAT",
		],
		description: [
			/(TOP|BOTTOM) OF SHEET AIR BLOWER ([0-9]) MOTOR (TEMP|THERMOSTAT)/i,
		],
		name: "AR_AirBlower{1:Cap}_{2}_MtrThermo_TempOkSw",
		type: "BOOL",
		direction: "in",
		auxRoll: true
	},
    {
        examples: [
            "TOP OF SHEET AIR BLOWER 1 MOTOR OVERLOAD AUX.",
            "BOTTOM OF SHEET AIR BLOWER 2 MOTOR OVERLOAD AUX.",
        ],
        description: [
            /(TOP|BOTTOM) OF SHEET AIR BLOWER ([0-9]) MOTOR OVERLOAD/i,
        ],
        name: "AR_AirBlower{1:Cap}_{2}_MtrOverloadSw",
        type: "BOOL",
        direction: "in",
        auxRoll: true
    },
    {
        examples: [
            "TOP/BOTTOM OF SHEET BLOWER MOTORS AC DRIVES SAFE TORQUE-OFF SIGNAL",
        ],
        description: [
            /OF SHEET .*BLOWER MOTORS.* SAFE TORQUE/i,
        ],
        name: "AR_AirBlower_STO_Ch{EVEN+1}",
        type: "BOOL",
        direction: "out",
        auxRoll: true,
        safetyType: "Safety",
    },



    // ROLL CLOSE
    {
		examples: [
			"AUXILIARY NIP ROLL LOW AIR PRESSURE SWITCH",
		],
		description: [
			/AUXILIARY NIP ROLL LOW AIR PRESSURE SWITCH/i,
		],
		name: "AR_RollClose_AirPressure_AboveMin",
		type: "BOOL",
		direction: "in",
		auxRoll: true
	},
    {
		examples: [
			"UPSTREAM NIP ROLL OPEN PB", 
            "UPSTREAM NIP ROLL CLOSE PBL",
            "DOWNSTREAM NIP ROLL OPEN PB",
		],
		description: [
			/(UPSTREAM|DOWNSTREAM) NIP ROLL (OPEN|CLOSE) PB/i,
		],
		name: "AR_Roll{1:Cap}_{2:Cap}_PB",
		type: "BOOL",
		direction: "in",
		auxRoll: true
	},
    {
		examples: [
			"UPSTREAM NIP ROLL CLOSE PB INDICATOR", 
            "DOWNSTREAM NIP ROLL CLOSE PB INDICATOR",
		],
		description: [
			/(UPSTREAM|DOWNSTREAM) NIP ROLL (OPEN|CLOSE) PB/i,
		],
		name: "AR_Roll{1:Cap}_{2:Cap}_PBL",
		type: "BOOL",
		direction: "out",
		auxRoll: true
	},
    {
        examples: [
            "DOWNSTREAM NIP ROLL CLOSED PRS",
            "UPSTREAM NIP ROLL CLOSED PRS",
        ],
        description: [
            /(UPSTREAM|DOWNSTREAM) NIP ROLL CLOSED/i,
        ],
        name: "AR_Roll{1:Cap}_Closed",
        type: "BOOL",
        direction: "in",
        auxRoll: true
    },
    {
        examples: [
            "INFEED NIP ROLL CLOSE RELAY",
        ],
        description: [
            /INFEED NIP ROLL CLOSE RELAY/i,
        ],
        name: "AR_RollUpstream_CloseRelay",
        type: "BOOL",
        direction: "out",
        auxRoll: true
    },
    {
        examples: [
            "OUTFEED NIP ROLL CLOSE RELAY",
        ],
        description: [
            /OUTFEED NIP ROLL CLOSE RELAY/i,
        ],
        name: "AR_RollDownstream_CloseRelay",
        type: "BOOL",
        direction: "out",
        auxRoll: true
    },
    {
        examples: [
            "INFEED NIP ROLL CLOSE SAFETY RELAY",
        ],
        description: [
            /INFEED NIP ROLL CLOSE SAFETY RELAY/i,
        ],
        name: "AR_RollUpstream_CloseEnable",
        type: "BOOL",
        direction: "out",
        auxRoll: true
    },
    {
        examples: [
            "OUTFEED NIP ROLL CLOSE SAFETY RELAY",
        ],
        description: [
            /OUTFEED NIP ROLL CLOSE SAFETY RELAY/i,
        ],
        name: "AR_RollDownstream_CloseEnable",
        type: "BOOL",
        direction: "out",
        safetyType: "Safety",
        auxRoll: true
    },
    {
        examples: [
            "INFEED NIP ROLL CLOSE SAFETY RELAY FEEDBACK",
        ],
        description: [
            /OUTFEED NIP ROLL CLOSE SAFETY RELAY/i,
        ],
        name: "AR_RollDownstream_CloseEnable_Feedback",
        type: "BOOL",
        direction: "in",
        safetyType: "Safety",
        auxRoll: true
    },
    {
        examples: [
            "INFEED NIP ROLL CLOSE SAFETY RELAY FEEDBACK",
        ],
        description: [
            /INFEED NIP ROLL CLOSE SAFETY RELAY/i,
        ],
        name: "AR_RollUpstream_CloseEnable_Feedback",
        type: "BOOL",
        direction: "in",
        safetyType: "Safety",
        auxRoll: true
    },







    
    {
		examples: [
			"ROLL STAND TRACTION REVERSE PB", // 18059-u121 aux only
		],
		description: [
			/ROLL STAND TRACTION REVERSE PB/i,
		],
		name: "RS_Traction_RevAwayDie_PB_Aux",
		type: "BOOL",
		direction: "in",
		auxRoll: true
	},


    {
		examples: [
			"AIR CONDITIONER HIGH TEMPERATURE ALARM",
		],
		description: [
			/AIR CONDITIONER HIGH TEMPERATURE ALARM/i,
		],
		name: "AR_Enclosure_Temperature_Sw",
		type: "BOOL",
		direction: "in",
        auxRoll: true,
	},



    /////////////////////////////
    // SPARE / RESERVED
    {
		examples: [
			"SPARE OUTPUT U1DO0507",
            "SPARE SAFETY OUTPUT U2DO0404",
            "SPARE INPUT U1DI0206"
		],
		description: [
            /SPARE.*[\n| ](U[0-9][A|D][I|O][0-9]+)/mi, // multiline
        ],
        auxRoll: true,
		name: "AR_Spare_{1}",
	},
    {
		examples: [
			"E-STOP RESERVED INPUT U1DI0003",
			"E-STOP RESERVED INPUT\nU1DI0003",
		],
		description: [
            /E[ ||-]STOP RESERVED.*[ |\n](U[0-9][A|D][I|O][0-9]+)/mi, // multiline
        ],
        auxRoll: true,
		name: "AR_Estop_Reserved_{1}",
	},

];



export {knownIoAuxRoll as default}