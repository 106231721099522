
import * as helpers from '../ParseHelpers'

export default function EntityParser() {}

EntityParser.ForEntityName = 'MTEXT';

EntityParser.prototype.parseEntity = function(scanner, curr) {
    var entity = { 
        type: curr.value,
        dxfStartPos: scanner._pointer,
        dxfEndPos: 0,
    };

    curr = scanner.next();
    while(curr !== 'EOF') {
        if(curr.code === 0) break;

        switch(curr.code) {
          case 3:
              entity.text ? entity.text += curr.value : entity.text = curr.value;
              break;
          case 1:
              entity.text ? entity.text += curr.value : entity.text = curr.value;
              break;
          case 2:
              entity.fieldType = curr.value;
              break;
          case 7:
              entity.font = curr.value;
              break;
          case 8:
              entity.layer = curr.value;
              break;
          case 10:
              entity.position = helpers.parsePoint(scanner);
              break;
          case 11:
              entity.vec = {x: curr.value};
              break;
          case 21:
              entity.vec.y = curr.value;
              break;
          case 31:
              entity.vec.z = curr.value;
              break;
          case 40:
              //Note: this is the text height
              entity.height = curr.value;
              break;
          case 41:
              entity.width = curr.value;
              break;
          case 42:
              entity.charwidth = curr.value;
              break;
          case 44:
              entity.lineHeight = curr.value;
              break;
          case 50:
              entity.rotation = curr.value;
              break;
          case 71:
              entity.attachmentPoint = curr.value;
              break;
          case 72:
              entity.drawingDirection = curr.value;
              break;
          case 73:
              entity.vspace = curr.value;
              break;
          case 210:
              entity.extrude = {x: curr.value};
              break;
          case 220:
              entity.extrude.y = curr.value;
              break;
          case 230:
              entity.extrude.z = curr.value;
              break;
          default:
              var found = helpers.checkCommonEntityProperties(entity, curr, "TEXT");
            //   if (! found) {console.log("MTEXT could not find code", curr, entity)}
              break;
        }
        curr = scanner.next();
    }
    entity.dxfEndPos = scanner._pointer;
    return entity;
};
