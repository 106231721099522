import knownIoExtruder from './knownio-extruder'
import knownIoPullroll from './knownio-pullroll'
import knownIoAuxRoll from './knownio-rollstand-aux'
import knownIoShear from './knownio-rollstand-shear'
import knownIoRollStand from './knownio-rollstand'
import knownIoTitan from './knownio-titan'
import knownIoVacuum from './knownio-vacuum'
import knownIoWinder from './knownio-winder'
import knownIoUnwind from './knownio-rollstand-unwind'
import knownIoUniversal from './knownio-universal'
import type {KnownIO} from "../types/io";

// Find: "\/\/ (.+)"
// Replace: "examples: [\n\t\t\t"$1",\n\t\t],"

let knownIO: Array<KnownIO> = [
	...knownIoExtruder,
	...knownIoUnwind,
	...knownIoShear,
	...knownIoTitan,
	...knownIoWinder,
	...knownIoAuxRoll,
	...knownIoVacuum,
	...knownIoPullroll,
	...knownIoRollStand,
	...knownIoUniversal,

	///////////////////////////////////////////////////
	///////////////////////////////////////////////////
	//
	// CATCH ALL WITH UNKNOWN
	//
	///////////////////////////////////////////////////
	///////////////////////////////////////////////////
	{
		description: /.*/,
		name: "{L}{N}_S{S}_{P}_UNKNOWN_{DIR}_{TYPE}",
	},
];


const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
if (urlParams.get('GE')) {
	knownIO = [
		// Safety
		{
			description: /.*/,
			name: "si{L}{N}{S:2}{P:2}",
			type: "BOOL",
			direction: "in",
			safetyType: "Safety",
		},
		{
			description: /.*/,
			name: "sq{L}{N}{S:2}{P:2}",
			type: "BOOL",
			direction: "out",
			safetyType: "Safety",
		},


		// Analog
		{
			description: /.*/,
			name: "AI{L}{N}{S:2}{P:2}",
			type: "INT",
			direction: "in",
		},
		{
			description: /.*/,
			name: "AQ{L}{N}{S:2}{P:2}",
			type: "INT",
			direction: "out",
		},

		// Analog Status
		{
			description: /.*/,
			name: "AI{L}{N}{S:2}{P:2}_Status",
			type: "SINT",
			direction: "in",
		},
		{
			description: /.*/,
			name: "AQ{L}{N}{S:2}{P:2}_Status",
			type: "SINT",
			direction: "out",
		},


		// Boolean
		{
			description: /.*/,
			name: "I{L}{N}{S:2}{P:2}",
			type: "BOOL",
			direction: "in",
		},
		{
			description: /.*/,
			name: "Q{L}{N}{S:2}{P:2}",
			type: "BOOL",
			direction: "out",
		},
	]
}


// knownIO.concat(knownIoExtruder, knownIoRollStand)

export  {knownIO as default}
