
export type PreloadProject = {
  name: string;
  alarm?: boolean;  // This page should only display alarms
  debug?: Boolean; // should this be displayed in the live view, or only in tests
  path: string; // path to prefix to each page file/url
  pages: PreloadPage[];
  tests: PreloadTest[];
  notes?: string;
}

export type PreloadTest = {
  search: string | RegExp;
  count?: number;
}

export type PreloadPage = {
  filename: string;   // filename of the page
  url?: string;    // full location of the file
  module: string; // module name that should be found in the preload
  notes?: string;  // graphical display in table
  data?: string; // loaded data of the file as a string
  tests: PreloadTest[]; // test to run on the page
  rackLetter?: string; // letter of the rack should be found for this page
  rackNumber?: number; // number of the rack should be found for this page
  slotNumber?: number; // number of the slot should be found for this page
  tags?: number; // total number of tags that should be found on the page (known + unknown), but excluding secondaries that should be generated
  unknownTags?: number; // number of unknown tags should be found for this page (ignore for all known tags)
}

export let projects: Array<PreloadProject> = [
  {
    name: "Alarms",
    debug: false,
    alarm: true,
    path: "data/AlarmExamples/",
    pages: [
    // {
    //   filename: "GE_18027-Tags.CSV", module: "CSVTAGS", tests: [],
    // },
    // {
    //   filename: "PTi_Line1_New_upload_240_Path-Tags.csv", module: "CSVTAGS", tests: [],
    // }
    // {
    //   filename: "PTi_Line1_New_upload_242_StandaloneFault-Tags.CSV", module: "CSVTAGS", tests: [],
    // }
    // {
    //   filename: "GE_18027-Tags3.CSV", module: "CSVTAGS", tests: [],
    // }
    {
      filename: "TDC_18133_ProgramScope.CSV", module: "CSVTAGS", tests: [],
    }
  ],
    tests: [],
  },

  {
    name: '17927',
    debug: false,
    path: "data/17927-libredwg/",
    pages: [
      {filename: "17927-320.dxf", rackLetter: "C", rackNumber: 1, slotNumber: 0, module: "1794-IT8",      tests: [{search: "C1AI000"}, {search: "ZONE 6V BARREL VENT ZONE TEMPERATURE T/C"}]},
      {filename: "17927-322.dxf", rackLetter: "C", rackNumber: 1, slotNumber: 1, module: "1794-IT8",      tests: [{search: "C1AI100"}]},
      {filename: "17927-324.dxf", rackLetter: "C", rackNumber: 1, slotNumber: 2, module: "1794-IB32",     tests: [{search: "EXTRUDER C E-STOP RELAYS"}, {search: "OPENS @ 72.5 PSI"}, {search: "BLOWER MOTOR OVERLOAD", count: 8}]},
      {filename: "17927-325.dxf", rackLetter: "C", rackNumber: 1, slotNumber: 2, module: "1794-IB32",     tests: [{search: "CONTACTOR", count: 5}, {search: "C1DI229"}, {search: "CON-31008"}]},
      {filename: "17927-326.dxf", rackLetter: "C", rackNumber: 1, slotNumber: 3, module: "1794-OB32P",    tests: [{search: " SOLID STATE RELAY", count: 16}, {search: "EX 90 GEAR PUMP"}]},
      {filename: "17927-327.dxf", rackLetter: "C", rackNumber: 1, slotNumber: 3, module: "1794-OB32P",    tests: [{search: " SOLID STATE RELAY", count: 3}, {search: "C1DO328"}]},
      {filename: "17927-328.dxf", rackLetter: "C", rackNumber: 1, slotNumber: 4, module: "1794-OB16",     tests: [{search: " HEATER BLOWER MOTOR ", count: 8}, {search: "EXTRUDER MOTOR BLOWER"}, {search: "C1DO414"}]},
      {filename: "17927-336.dxf", rackLetter: "C", rackNumber: 2, slotNumber: 0, module: "1794-IT8",      tests: [{search: " TEMPERATURE T", count: 32}]},
      {filename: "17927-338.dxf", rackLetter: "C", rackNumber: 2, slotNumber: 1, module: "1794-IT8",      tests: [{search: " TEMPERATURE T", count: 6}]},
      {filename: "17927-340.dxf", rackLetter: "C", rackNumber: 2, slotNumber: 2, module: "1794-IF4I",     tests: [{search: " BARREL HEAD PRESSURE"}, {search: "C2AI202"}]},
      {filename: "17927-342.dxf", rackLetter: "C", rackNumber: 2, slotNumber: 3, module: "1794-OW8",      tests: [{search: " AUTOZERO CONTACTS", count: 3}, {search: "C2RO300A"}]},
      {filename: "17927-344.dxf", rackLetter: "C", rackNumber: 2, slotNumber: 4, module: "1794-IE4XOE2",  tests: [{search: " DISTANCE MEASUREMENT", count: 4}, {search: "4-20mA", count: 4}, {search: "C2AO400"}]},
      {filename: "17927-346.dxf", rackLetter: "C", rackNumber: 2, slotNumber: 5, module: "1794-IB16",     tests: [{search: " SCREEN CHANGER PENDANT", count: 4}, {search: "C2DI506"}]},
      {filename: "17927-348.dxf", rackLetter: "C", rackNumber: 2, slotNumber: 6, module: "1794-OB16",     tests: [{search: /Schem:.*\.348/, count: 16}, {search: "MAAG CSC-176 SCREEN CHANGER", count: 5}]},
    ],
    tests: [
      {search: "HC_ZONE AOI", count: 18},
      {search: "P_HeatCool Controller", count: 18},
      // {search: "EXTRUDER C DRIVE AOI"},
      {search: "Blower"},
      {search: "LubePump"},
      // {search: "ExWithGP"}, // TODO
    ]
  },





  {
    name: '17928',
    debug: false,
    path: "data/17928/",
    pages: [
      {filename: "17928-b120-r1.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 0, module: "1794-IT8", tests: []},
      {filename: "17928-b122-r1.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 1, module: "1794-IT8", tests: []},
      {filename: "17928-b124-r1.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 2, module: "1794-IT8", tests: []},
      {filename: "17928-b126-r1.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 3, module: "1794-IF4I", tests: []},
      {filename: "17928-b137-r1.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 1, module: "1734-IB8", tests: []},
      {filename: "17928-b138-r1.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 2, module: "1734-OW4", tests: []},
      {filename: "17928-b139-r1.dxf", module: "PWS", tests: []},
      {filename: "17928-b140-r1.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 3, module: "1734-OB2EP", tests: []},
      {filename: "17928-b141-r1.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 4, module: "1734-OB2EP", tests: []},
      {filename: "17928-b142-r1.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 5, module: "1734-OB2EP", tests: []},
      {filename: "17928-b143-r1.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 6, module: "1734-OB2EP", tests: []},
      {filename: "17928-b144-r1.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 7, module: "1734-OB2EP", tests: []},
      {filename: "17928-b145-r1.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 8, module: "1734-OB2EP", tests: []},
      {filename: "17928-b152-r1.dxf", module: "ETHERNET ADAPTER", tests: []},
      {filename: "17928-b153-r1.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 1, module: "1734-IE8C", tests: []},
      {filename: "17928-b154-r1.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 2, module: "1734-IE8C", tests: []},
      {filename: "17928-b155-r1.dxf", module: "PWS", tests: []},
      {filename: "17928-b156-r1.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 3, module: "1734-IB8", tests: []},
      {filename: "17928-b157-r1.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 4, module: "1734-IB8", tests: []},
      {filename: "17928-b158-r1.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 5, module: "1734-OB8", tests: []},
      {filename: "17928-b159-r1.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 6, module: "1734-OB8", tests: []},
      {filename: "17928-b160-r1.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 7, module: "1734-OB8", tests: []},
      {filename: "17928-b161-r1.dxf", module: "PWS", tests: []},
      {filename: "17928-b162-r1.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 8, module: "1734-IB8S", tests: []},
      {filename: "17928-b163-r1.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 9, module: "1734-OB8S", tests: []},
      
      // BEX Downstream
      {filename: "17928-b220-r1.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 0, module: "1794-IT8", tests: []},
      {filename: "17928-b222-r1.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 1, module: "1794-IT8", tests: []},
      {filename: "17928-b224-r1.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 2, module: "1794-IT8", notes: "Die TC", tests: []},
      {filename: "17928-b226-r1.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 3, module: "1794-IT8", notes: "All Spare", tests: []},
      {filename: "17928-b228-r1.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 4, module: "1794-IF4", notes: "pressure", tests: []},
      {filename: "17928-b230-r1.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 5, module: "1794-IE4XOE2", notes: "Screen Changer", tests: []},
      {filename: "17928-b232-r1.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 6, module: "1794-IB32", notes: "Screen Changer", tests: []},
      {filename: "17928-b233-r1.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 6, module: "1794-IB32", notes: "Screen Changer", tests: []},
      {filename: "17928-b234-r1.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 7, module: "1794-OB32P", notes: "Screen changer", tests: []},
      {filename: "17928-b235-r1.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 7, module: "1794-OB32P", notes: "Screen changer", tests: []},
      {filename: "17928-b236-r1.dxf", module: "ETHERNET ADAPTER", tests: []},
      {filename: "17928-b237-r1.dxf", rackLetter: "B", rackNumber: 5, slotNumber: 1, module: "1734-IE8C", notes: "Zone Currents", tests: []},
      {filename: "17928-b238-r1.dxf", rackLetter: "B", rackNumber: 5, slotNumber: 2, module: "1734-IE8C", tests: []},
      {filename: "17928-b239-r1.dxf", rackLetter: "B", rackNumber: 5, slotNumber: 3, module: "1734-IE8C", notes: "Die Currents", tests: []},
      {filename: "17928-b240-r1.dxf", module: "PWS", tests: []},
      {filename: "17928-b241-r1.dxf", rackLetter: "B", rackNumber: 5, slotNumber: 4, module: "1734-IB8", notes: "Estop", tests: []},
      {filename: "17928-b242-r1.dxf", rackLetter: "B", rackNumber: 5, slotNumber: 5, module: "1734-IB8", notes: "Motors", tests: []},
      {filename: "17928-b243-r1.dxf", rackLetter: "B", rackNumber: 5, slotNumber: 6, module: "1734-OB8", notes: "Zone SSR", tests: []},
      {filename: "17928-b244-r1.dxf", rackLetter: "B", rackNumber: 5, slotNumber: 7, module: "1734-OB8", notes: "DS SSR", tests: []},
      {filename: "17928-b245-r1.dxf", rackLetter: "B", rackNumber: 5, slotNumber: 8, module: "1734-OB8", notes: "Die SSR", tests: []},
      {filename: "17928-b246-r1.dxf", rackLetter: "B", rackNumber: 5, slotNumber: 9, module: "1734-OB8", tests: []},
      {filename: "17928-b247-r1.dxf", module: "PWS", tests: []},
      {filename: "17928-b248-r1.dxf", rackLetter: "B", rackNumber: 5, slotNumber: 10, module: "1734-IB8S", notes: "DS Estop", tests: []},
      {filename: "17928-b249-r1.dxf", rackLetter: "B", rackNumber: 5, slotNumber: 11, module: "1734-OB8S", notes: "BEX STO", tests: []},

      // // ROLL STAND
      {filename: "17928-r121-r1.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 1, module: "MODBUS 485", notes: "MODBUS CARD", tests: []},
      {filename: "17928-r122-r1.dxf", module: "PWS", tests: []},
      {filename: "17928-r123-r1.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 2, module: "1734-IB8", tests: []},
      {filename: "17928-r124-r1.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 3, module: "1734-IB8", tests: []},
      {filename: "17928-r125-r1.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 4, module: "1734-IB8", tests: []},
      {filename: "17928-r126-r1.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 5, module: "1734-IB8", notes: "coater", tests: []}, //
      {filename: "17928-r127-r1.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 6, module: "1734-IB8", tests: []},
      {filename: "17928-r128-r1.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 7, module: "1734-IB8", tests: []},
      {filename: "17928-r129-r1.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 8, module: "1734-IB8", tests: []}, // Was mistaken for ENET
      {filename: "17928-r130-r1.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 9, module: "1734-IB8", tests: []},
      {filename: "17928-r131-r1.dxf", module: "PWS", tests: []},
      {filename: "17928-r132-r1.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 10, module: "1734-OB8", tests: []},
      {filename: "17928-r133-r1.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 11, module: "1734-OB8", tests: []},
      {filename: "17928-r134-r1.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 12, module: "1734-OB8", tests: []},
      {filename: "17928-r135-r1.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 13, module: "1734-OB8", tests: []},
      {filename: "17928-r136-r1.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 14, module: "1734-OB8", notes: "Button indicators", tests: []},
      {filename: "17928-r137-r1.dxf", module: "PWS", tests: []},
      {filename: "17928-r138-r1.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 15, module: "1734-IB8S", tests: []},
      {filename: "17928-r139-r1.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 16, module: "1734-IB8S", tests: []},
      {filename: "17928-r140-r1.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 17, module: "1734-OB8S", notes: "STO", tests: []},
      {filename: "17928-r141-r1.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 18, module: "1734-OB8S", notes: "Coils", tests: []},


      // // TITAN (RENAMING ALL TITANS TO K-RACK TO FOLLOW NEW STANDARDS)
      {filename: "17928-t918-r1.dxf", rackLetter: "K", rackNumber: 1, slotNumber: 1, module: "1734-IB8", tests: []},
      {filename: "17928-t919-r1.dxf", rackLetter: "K", rackNumber: 1, slotNumber: 2, module: "1734-OB2EP", notes: "Tower light", tests: []},


      // // WINDER
      {filename: "17928-w120-r1.dxf", module: "ETHERNET ADAPTER", tests: []},
      {filename: "17928-w121-r1.dxf", rackLetter: "W", rackNumber: 1, slotNumber: 1, module: "1734-IB8", tests: []},
      {filename: "17928-w122-r1.dxf", rackLetter: "W", rackNumber: 1, slotNumber: 2, module: "1734-IB8", tests: []},
      {filename: "17928-w123-r1.dxf", rackLetter: "W", rackNumber: 1, slotNumber: 3, module: "1734-IB8", tests: []},
      {filename: "17928-w124-r1.dxf", rackLetter: "W", rackNumber: 1, slotNumber: 4, module: "1734-IB8", tests: []},
      {filename: "17928-w125-r1.dxf", rackLetter: "W", rackNumber: 1, slotNumber: 5, module: "1734-IB8", notes: "Cart inputs", tests: []},
      {filename: "17928-w126-r1.dxf", module: "FPD", tests: []},
      {filename: "17928-w127-r1.dxf", rackLetter: "W", rackNumber: 1, slotNumber: 6, module: "1734-OB8", tests: []},
      {filename: "17928-w128-r1.dxf", rackLetter: "W", rackNumber: 1, slotNumber: 7, module: "1734-OB8", tests: []},
      {filename: "17928-w129-r1.dxf", rackLetter: "W", rackNumber: 1, slotNumber: 8, module: "1734-OB8", tests: []},
      {filename: "17928-w130-r1.dxf", rackLetter: "W", rackNumber: 1, slotNumber: 9, module: "1734-OB8", tests: []},
      {filename: "17928-w131-r1.dxf", module: "PWS", tests: []},
      {filename: "17928-w132-r1.dxf", rackLetter: "W", rackNumber: 1, slotNumber: 10, module: "1734-IB8S", tests: []},
      {filename: "17928-w133-r1.dxf", rackLetter: "W", rackNumber: 1, slotNumber: 11, module: "1734-IB8S", tests: []},
      {filename: "17928-w134-r1.dxf", rackLetter: "W", rackNumber: 1, slotNumber: 12, module: "1734-IB8S", tests: []},
      {filename: "17928-w135-r1.dxf", rackLetter: "W", rackNumber: 1, slotNumber: 13, module: "1734-OB8S", tests: []},
      {filename: "17928-w136-r1.dxf", rackLetter: "W", rackNumber: 1, slotNumber: 14, module: "1734-OB8S", tests: []},
      {filename: "17928-w137-r1.dxf", rackLetter: "W", rackNumber: 1, slotNumber: 15, module: "1734-OB8S", tests: []},
      {filename: "17928-w140-r1.dxf", module: "ETHERNET ADAPTER", tests: []},
      {filename: "17928-w141-r1.dxf", rackLetter: "W", rackNumber: 2, slotNumber: 1, module: "1734-IB8", tests: []},
      {filename: "17928-w142-r1.dxf", rackLetter: "W", rackNumber: 2, slotNumber: 2, module: "1734-IB8", tests: []},
      {filename: "17928-w143-r1.dxf", rackLetter: "W", rackNumber: 2, slotNumber: 3, module: "1734-OB8", tests: []},
      {filename: "17928-w144-r1.dxf", rackLetter: "W", rackNumber: 2, slotNumber: 4, module: "1734-OB8", tests: []},
      {filename: "17928-w145-r1.dxf", rackLetter: "W", rackNumber: 2, slotNumber: 5, module: "1734-IB8S", tests: []},
      {filename: "17928-w150-r1.dxf", module: "ETHERNET ADAPTER", tests: []},
      {filename: "17928-w151-r1.dxf", rackLetter: "W", rackNumber: 3, slotNumber: 1, module: "1734-IE2V", notes: "Analog voltage in 2ch", tests: []},
      {filename: "17928-w152-r1.dxf", rackLetter: "W", rackNumber: 3, slotNumber: 2, module: "1734-IB8", tests: []},
      {filename: "17928-w153-r1.dxf", rackLetter: "W", rackNumber: 3, slotNumber: 3, module: "1734-IB8", tests: []},
      {filename: "17928-w154-r1.dxf", rackLetter: "W", rackNumber: 3, slotNumber: 4, module: "1734-IB8", tests: []},
      {filename: "17928-w155-r1.dxf", rackLetter: "W", rackNumber: 3, slotNumber: 5, module: "1734-IB8S", tests: []},
      {filename: "17928-w156-r1.dxf", module: "FPD", tests: []},
      {filename: "17928-w157-r1.dxf", rackLetter: "W", rackNumber: 3, slotNumber: 6, module: "1734-OB8", tests: []},
      {filename: "17928-w160-r1.dxf", module: "ETHERNET ADAPTER", tests: []},
      {filename: "17928-w161-r1.dxf", rackLetter: "W", rackNumber: 4, slotNumber: 1, module: "1734-IE2V", tests: []},
      {filename: "17928-w162-r1.dxf", rackLetter: "W", rackNumber: 4, slotNumber: 2, module: "1734-IB8", tests: []},
      {filename: "17928-w163-r1.dxf", rackLetter: "W", rackNumber: 4, slotNumber: 3, module: "1734-IB8", tests: []},
      {filename: "17928-w164-r1.dxf", rackLetter: "W", rackNumber: 4, slotNumber: 4, module: "1734-IB8", tests: []},
      {filename: "17928-w165-r1.dxf", rackLetter: "W", rackNumber: 4, slotNumber: 5, module: "1734-IB8S", tests: []},
      {filename: "17928-w166-r1.dxf", module: "FPD", tests: []},
      {filename: "17928-w167-r1.dxf", rackLetter: "W", rackNumber: 4, slotNumber: 6, module: "1734-OB8", tests: [], unknownTags: 1, notes: "Schematic does not include SPARE"},

      // // // VACUUM
      // {filename: "17928-v21-r1.dxf", rackLetter: "V", rackNumber: 1, slotNumber: 1, module: "1734-IE8C", tests: []},
      // {filename: "17928-v22-r1.dxf", rackLetter: "V", rackNumber: 1, slotNumber: 2, module: "1734-IB8", tests: []},
      // {filename: "17928-v23-r1.dxf", rackLetter: "V", rackNumber: 1, slotNumber: 3, module: "1734-IB8", tests: []},
      // {filename: "17928-v24-r1.dxf", rackLetter: "V", rackNumber: 1, slotNumber: 4, module: "1734-OB8", tests: []},
      // {filename: "17928-v25-r1.dxf", rackLetter: "V", rackNumber: 1, slotNumber: 5, module: "1734-IB8S", notes: "E stop", tests: []},
    ],
    tests: [
      {search: "HC_ZONE AOI", count: 35},
      {search: "DCI_STOP", count: 22},
      {search: "CONFIGURABLE_ROUT", count: 13},
      // {search: "RollDrive", count: 4}  // TODO
    ]
  },



  {
    name: "17600 TDC",
    debug: false,
    notes: "",
    path: "data/17600-tdc/",
    pages: [
      {filename: "17600-B120.dxf",    rackLetter: "B", rackNumber: 1, slotNumber: 0, module: "1794-IT8", tags: 16, tests: []},
      {filename: "17600-B122.dxf",    rackLetter: "B", rackNumber: 1, slotNumber: 1, module: "1794-IT8", tags: 16, tests: []},
      {filename: "17600-B124.dxf",    rackLetter: "B", rackNumber: 1, slotNumber: 2, module: "1794-IT8", tags: 16, tests: []},
      // {filename: "17600-B126B.dxf",   rackLetter: "B", rackNumber: 1, slotNumber: 3, module: "1794-IE4XOE2", tags: 12, tests: [], notes: "Points are found off-screen"},
      {filename: "17600-B136.dxf",        module: "ETHERNET ADAPTER", tags: 16, tests: []},
      {filename: "17600-B137.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 1,  module: "1734-OW4", tags: 8, tests: []},
      {filename: "17600-B138.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 2,  module: "1734-IB8", tags: 8, tests: []},
      {filename: "17600-B139.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 3,  module: "1734-IB8", tags: 8, tests: []},
      {filename: "17600-B140.dxf",    module: "FPD", tags: 8, tests: []},
      {filename: "17600-B141.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 4,  module: "1734-OB2EP", tags: 2, unknownTags: 1, tests: []},
      {filename: "17600-B142.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 5,  module: "1734-OB2EP", tags: 2, tests: []},
      {filename: "17600-B143.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 6,  module: "1734-OB2EP", tags: 2, tests: []},
      {filename: "17600-B144.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 7,  module: "1734-OB2EP", tags: 2, tests: []},
      {filename: "17600-B145.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 8,  module: "1734-OB2EP", tags: 2, tests: []},
      {filename: "17600-B146.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 9,  module: "1734-OB2EP", tags: 2, tests: []},
      {filename: "17600-B147.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OB2EP", tags: 2, tests: []},
      {filename: "17600-B148.dxf",    module: "FPD", tags: 8, tests: []},
      {filename: "17600-B149.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 11, module: "1734-IB8S", tags: 8, unknownTags: 1, tests: []},
      {filename: "17600-B150.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 12,  module: "1734-OB8S", tags: 8, unknownTags: 6, tests: []},

      {filename: "17600-B152.dxf",    rackLetter: "B", rackNumber: 3, slotNumber: 0,  module: "1794-IE8", tags: 8, tests: []},
      {filename: "17600-B154.dxf",    rackLetter: "B", rackNumber: 3, slotNumber: 1,  module: "1794-IE8", tags: 8, tests: []},
      {filename: "17600-B156.dxf",    rackLetter: "B", rackNumber: 3, slotNumber: 2,  module: "1794-IB16", tags: 16, tests: []},
      {filename: "17600-B158.dxf",    rackLetter: "B", rackNumber: 3, slotNumber: 3,  module: "1794-OB16", tags: 16, tests: []},
      {filename: "17600-B160.dxf",    rackLetter: "B", rackNumber: 3, slotNumber: 4,  module: "1794-OB16", tags: 16, tests: []},

      {filename: "17600-V20-R1.dxf",    rackLetter: "V", rackNumber: 1, slotNumber: 0, module: "ETHERNET ADAPTER", tests: []},
      {filename: "17600-V21-R1.dxf",    rackLetter: "V", rackNumber: 1, slotNumber: 1, module: "1734-IE8C", tags: 8, tests: []},
      {filename: "17600-V22-R1.dxf",    rackLetter: "V", rackNumber: 1, slotNumber: 2, module: "1734-IB8", tags: 8, unknownTags: 4, tests: []},
      {filename: "17600-V23-R1.dxf",    rackLetter: "V", rackNumber: 1, slotNumber: 3, module: "1734-IB8", tags: 8, unknownTags: 4, tests: []},
      {filename: "17600-V24-R1.dxf",    rackLetter: "V", rackNumber: 1, slotNumber: 4, module: "1734-OB8", tags: 8, tests: []},
      {filename: "17600-V25-R1.dxf",    rackLetter: "V", rackNumber: 1, slotNumber: 5, module: "1734-IB8S", tags: 8, unknownTags: 6, tests: []},
    ],
    tests: [
    ]
  },




  {
    name: "17888 TDC (Extruder A, but B in schematics)",
    debug: false,
    notes: "Actually TDC ext A",
    path: "data/17800-tdc/",
    pages: [
      {filename: "17888-b220.dxf",    rackLetter: "B", rackNumber: 1, slotNumber: 0, module: "1794-IT8", notes: "", tests: []},
      {filename: "17888-b222.dxf",    rackLetter: "B", rackNumber: 1, slotNumber: 1, module: "1794-IT8", notes: "", tests: []},
      {filename: "17888-b224.dxf",    rackLetter: "B", rackNumber: 1, slotNumber: 2, module: "1794-IT8", notes: "", tests: []},
      {filename: "17888-b226.dxf",    rackLetter: "B", rackNumber: 1, slotNumber: 3, module: "1794-IF4I", notes: "", tests: []},
      {filename: "17888-b228.dxf",    rackLetter: "B", rackNumber: 1, slotNumber: 4, module: "1794-IE4XOE2", notes: "", tests: []},
      {filename: "17888-b230.dxf",    rackLetter: "B", rackNumber: 1, slotNumber: 5, module: "1794-IB32", notes: "IO too dense and duplicated, will not fix", tests: [], unknownTags: 16},
      // {filename: "17888-b231.dxf",    rackLetter: "B", rackNumber: 1, slotNumber: 5, module: "1794-IB32", notes: "IO too dense and duplicated, will not fix", tests: [], unknownTags: 16},
      {filename: "17888-b232.dxf",    rackLetter: "B", rackNumber: 1, slotNumber: 6, module: "1794-OB32P", notes: "IO too dense and duplicated, will not fix", tests: [], unknownTags: 16},
      // {filename: "17888-b233.dxf",    rackLetter: "B", rackNumber: 1, slotNumber: 6, module: "1794-OB32P", notes: "IO too dense and duplicated, will not fix", tests: [], unknownTags: 16},
      {filename: "17888-b234.dxf",    rackLetter: "B", rackNumber: 1, slotNumber: 7, module: "1794-OW8", notes: "", tests: []},
      {filename: "17888-b236.dxf",    module: "ETHERNET ADAPTER", notes: "", tests: []},
      {filename: "17888-b237.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 1, module: "1734-IE8C", notes: "", tests: []},
      {filename: "17888-b238.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 2, module: "1734-IE8C", notes: "", tests: []},
      {filename: "17888-b239.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 3, module: "1734-IE8C", notes: "", tests: []},
      {filename: "17888-b240.dxf",    module: "PWS", notes: "", tests: []},
      {filename: "17888-b241.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 4, module: "1734-IB8", notes: "", tests: []},
      {filename: "17888-b242.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 5, module: "1734-IB8", notes: "", tests: []},
      {filename: "17888-b243.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 6, module: "1734-OB8", notes: "", tests: []},
      {filename: "17888-b244.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 7, module: "1734-OB8", notes: "", tests: []},
      {filename: "17888-b245.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 8, module: "1734-OB8", notes: "", tests: []},
      {filename: "17888-b246.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 9, module: "1734-OB8", notes: "", tests: []},
      {filename: "17888-b247.dxf",    module: "PWS", notes: "", tests: []},
      {filename: "17888-b248.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-IB8S", notes: "", tests: []},
      {filename: "17888-b249.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 11, module: "1734-OB8S", notes: "", tests: []},
    ],
    tests: [
    ]
  },





  {
    name: '17800 TDC',
    debug: false,
    path: "data/17800-tdc/",
    pages: [
      // TDC Ext
      {filename: "17800-b122r1a.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 1, module: "1794-IT8", notes: "", tests: []},
      {filename: "17800-b124r1a.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 2, module: "1794-IF4I", notes: "", tests: []},
      {filename: "17800-b136r1.dxf",  module: "ETHERNET ADAPTER", notes: "", tests: []},
      {filename: "17800-b137r1.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 1, module: "1734-IE8C", notes: "", tests: []},
      {filename: "17800-b138r1.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 2, module: "1734-IE8C", notes: "", tests: []},
      {filename: "17800-b139r1.dxf",  module: "PWS", notes: "", tests: []},
      {filename: "17800-b140.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 3, module: "1734-IB8", notes: "", tests: []},
      {filename: "17800-b141r1.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 4, module: "1734-IB8", notes: "", tests: []},
      {filename: "17800-b142.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 5, module: "1734-IB8", notes: "", tests: []},
      {filename: "17800-b143.dxf",    rackLetter: "A", rackNumber: 2, slotNumber: 6, module: "ADDRESS RESERVE", notes: "", tests: []},
      {filename: "17800-b144r1.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 7, module: "1734-OB8", notes: "", tests: []},
      {filename: "17800-b145r1a.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 8, module: "1734-OB8", notes: "", tests: []},
      {filename: "17800-b146.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 9, module: "1734-OB2EP", notes: "", tests: []},
      {filename: "17800-b147.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-IB8S", notes: "", tests: []},
      {filename: "17800-b148.dxf",    rackLetter: "B", rackNumber: 2, slotNumber: 11, module: "1734-OB8S", notes: "", tests: []},
      {filename: "17800-b149r1.dxf",  module: "PWS", notes: "", tests: []},
      {filename: "17800-b150r1.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 12, module: "1734-OB8", notes: "", tests: []},
      {filename: "17800-b151a.dxf",   rackLetter: "B", rackNumber: 2, slotNumber: 13, module: "1734-OB8", notes: "", tests: []},
      
      
      // TDC Roll Stand
      {filename: "17625-r20.dxf",     module: "ETHERNET ADAPTER", notes: "", tests: []},
      {filename: "17625-r21.dxf",     rackLetter: "R", rackNumber: 1, slotNumber: 1, module: "MODBUS 485", notes: "MODBUS", tests: []},
      {filename: "17625-r22.dxf",     module: "PWS", notes: "", tests: []},
      {filename: "17625-r23c.dxf",    rackLetter: "R", rackNumber: 1, slotNumber: 2, module: "1734-IB8", notes: "", tests: []},
      {filename: "17625-r24.dxf",     rackLetter: "R", rackNumber: 1, slotNumber: 3, module: "1734-IB8", notes: "", tests: []},
      {filename: "17625-r25.dxf",     rackLetter: "R", rackNumber: 1, slotNumber: 4, module: "1734-IB8", notes: "", tests: []},
      {filename: "17625-r26.dxf",     rackLetter: "R", rackNumber: 1, slotNumber: 5, module: "1734-IB8", notes: "Mokon / Hydraulics", tests: []},
      {filename: "17625-r27.dxf",     rackLetter: "R", rackNumber: 1, slotNumber: 6, module: "1734-IB8", notes: "", tests: []},
      {filename: "17625-r28.dxf",     rackLetter: "R", rackNumber: 1, slotNumber: 7, module: "1734-IB8", notes: "", tests: []},
      {filename: "17625-r29.dxf",     rackLetter: "R", rackNumber: 1, slotNumber: 8, module: "1734-IB8", notes: "", tests: []},
      {filename: "17625-r31.dxf",     module: "PWS", notes: "", tests: []},
      {filename: "17625-r32.dxf",     rackLetter: "R", rackNumber: 1, slotNumber: 9, module: "1734-OB8", notes: "", tests: []},
      {filename: "17625-r33.dxf",     rackLetter: "R", rackNumber: 1, slotNumber: 10, module: "1734-OB8", notes: "", tests: []},
      {filename: "17625-r34.dxf",     rackLetter: "R", rackNumber: 1, slotNumber: 11, module: "1734-OB8", notes: "", tests: []},
      {filename: "17625-r35.dxf",     rackLetter: "R", rackNumber: 1, slotNumber: 12, module: "1734-OB8", notes: "", tests: []},
      {filename: "17625-r36.dxf",     rackLetter: "R", rackNumber: 1, slotNumber: 13, module: "1734-OB8", notes: "", tests: []},
      {filename: "17625-r39.dxf",     module: "PWS", notes: "", tests: []},
      {filename: "17625-r40.dxf",     rackLetter: "R", rackNumber: 1, slotNumber: 14, module: "1734-IB8S", notes: "", tests: []},
      {filename: "17625-r41.dxf",     rackLetter: "R", rackNumber: 1, slotNumber: 15, module: "1734-IB8S", notes: "guard", tests: []},
      {filename: "17625-r46.dxf",     rackLetter: "R", rackNumber: 1, slotNumber: 16, module: "1734-OB8S", notes: "", tests: []},
    ],
    tests: [
    ],
  },





  // {
  //   name: "Goex 17933",
  //   debug: true,
  //   path: "data/17933-goex/",
  //   notes: "does not fully pass. Issue with IB32 on G20 and G21.  The errors found in these schematics seem to be actual problems with the schematics like wierd module numbering",
  //   pages: [
  //     {filename: "17933-G12.dxf",  rackLetter: "G", rackNumber: 2, slotNumber: 0, module: "1794-IT8", notes: "", tests: []},
  //     {filename: "17933-G13A.dxf", rackLetter: "G", rackNumber: 2, slotNumber: 1, module: "1794-IT8", notes: "", tests: []},
  //     {filename: "17933-G14A.dxf", rackLetter: "G", rackNumber: 2, slotNumber: 2, module: "1794-IT8", notes: "", tests: []},
  //     {filename: "17933-G15.dxf",  rackLetter: "G", rackNumber: 2, slotNumber: 3, module: "1794-IF4I", notes: "", tests: []},
  //     {filename: "17933-G16.dxf",  rackLetter: "G", rackNumber: 1, slotNumber: 0, module: "1794-IE8", notes: "", tests: []}, // Is the G2,S4 or G1 S0
  //     {filename: "17933-G17.dxf",  rackLetter: "G", rackNumber: 1, slotNumber: 1, module: "", notes: "", tests: []},
  //     {filename: "17933-G18.dxf",  rackLetter: "G", rackNumber: 1, slotNumber: 2, module: "", notes: "", tests: []},
  //     {filename: "17933-G20.dxf",  rackLetter: "G", rackNumber: 1, slotNumber: 3, module: "", notes: "", tests: []}, // LEFT OFF HERE
  //     {filename: "17933-G21A.dxf", rackLetter: "G", rackNumber: 1, slotNumber: 3, module: "", notes: "", tests: []},
  //     {filename: "17933-G22A.dxf", rackLetter: "G", rackNumber: 1, slotNumber: 4, module: "", notes: "", tests: []},
  //     {filename: "17933-G24.dxf",  rackLetter: "G", rackNumber: 1, slotNumber: 4, module: "", notes: "", tests: []},
  //     {filename: "17933-G25A.dxf", rackLetter: "G", rackNumber: 1, slotNumber: 4, module: "", notes: "", tests: []},
  //     {filename: "17933-G26.dxf",  rackLetter: "G", rackNumber: 1, slotNumber: 5, module: "", notes: "", tests: []},
  //   ],
  //   tests: [
  //   ],
  // }






  {
    name: "17945 Berry",
    debug: false,
    path: "data/17945-berry/",
    pages: [
      {filename: "17945-B120C.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 0, module: "1794-IT8", notes: "", tests: []},
      {filename: "17945-B122C.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 1, module: "1794-IT8", notes: "", tests: []},
      {filename: "17945-B124.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 2, module: "1794-IT8", notes: "", tests: []},
      {filename: "17945-B126.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 3, module: "1794-IT8", notes: "", tests: []},
      {filename: "17945-B128.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 4, module: "1794-IF4I", notes: "", tests: []},
      {filename: "17945-B136C.dxf", module: "ETHERNET ADAPTER", notes: "", tests: []},
      {filename: "17945-B137C.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 1, module: "1734-IE8C", notes: "", tests: []},
      {filename: "17945-B138C.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 2, module: "1734-IE8C", notes: "", tests: []},
      {filename: "17945-B139C.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 3, module: "1734-IE8C", notes: "", tests: []},
      {filename: "17945-B140C.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 4, module: "1734-IE8C", notes: "", tests: []},
      {filename: "17945-B141C.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 5, module: "1734-IE8C", notes: "", tests: []},
      {filename: "17945-B142C.dxf", module: "PWS", notes: "", tests: []},
      {filename: "17945-B143C.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 6, module: "1734-IB8", notes: "", tests: []},
      {filename: "17945-B144C.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 7, module: "1734-IB8", notes: "", tests: []},
      {filename: "17945-B145C.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 8, module: "1734-IB8", notes: "", tests: []},
      {filename: "17945-B146C.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 9, module: "1734-IB8", notes: "", tests: []},
      {filename: "17945-B147C.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-IB8", notes: "", tests: []},
      {filename: "17945-B148C.dxf", module: "PWS", notes: "", tests: []},
      {filename: "17945-B149C.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 11, module: "1734-OB8", notes: "", tests: []},
      {filename: "17945-B150C.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 12, module: "1734-OB8", notes: "", tests: []},
      {filename: "17945-B151C.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 13, module: "1734-OB8", notes: "Heat SSR", tests: []},
      {filename: "17945-B152C.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 14, module: "1734-OB8", notes: "", tests: []},
      {filename: "17945-B153C.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 15, module: "1734-OB8", notes: "Heat Coils", tests: []},
      {filename: "17945-B154C.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 16, module: "1734-OB8", notes: "Zone Cooling", tests: []},
      // {filename: "17945-B155C.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 16, module: "1734-OW4", notes: "Detects slot 16 instead of 17. Schematic improperly labelled. Zero Cal", tests: []},
      {filename: "17945-B156C.dxf", module: "PWS", notes: "", tests: []},
      {filename: "17945-B157C.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 18, module: "1734-IB8S", notes: "Schematic improperly labelled.", tests: []},
      {filename: "17945-B158C.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 19, module: "1734-OB8S", notes: "Schematic improperly labelled.", tests: []},
      // {filename: "17945-B159C.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 19, module: "1734-OB8S", notes: "Detects slot 19 instead of 20. Schematic improperly labelled.", tests: []},

      {filename: "17945-C120B.dxf", rackLetter: "C", rackNumber: 1, slotNumber: 0, module: "1794-IT8", notes: "", tests: []},
      {filename: "17945-C122B.dxf", rackLetter: "C", rackNumber: 1, slotNumber: 1, module: "1794-IT8", notes: "", tests: []},
      {filename: "17945-C124.dxf",  rackLetter: "C", rackNumber: 1, slotNumber: 2, module: "1794-IT8", notes: "", tests: []},
      {filename: "17945-C126.dxf",  rackLetter: "C", rackNumber: 1, slotNumber: 3, module: "1794-IF4I", notes: "", tests: []},
      {filename: "17945-C136.dxf",  module: "ETHERNET ADAPTER", notes: "", tests: []},
      {filename: "17945-C137.dxf",  rackLetter: "C", rackNumber: 2, slotNumber: 1, module: "IE8C", notes: "", tests: []},
      {filename: "17945-C138B.dxf", rackLetter: "C", rackNumber: 2, slotNumber: 2, module: "IE8C", notes: "", tests: []},
      {filename: "17945-C139B.dxf", rackLetter: "C", rackNumber: 2, slotNumber: 3, module: "IE8C", notes: "", tests: []},
      {filename: "17945-C140.dxf",  module: "PWS", notes: "", tests: []},
      {filename: "17945-C141B.dxf", rackLetter: "C", rackNumber: 2, slotNumber: 4, module: "IB8", notes: "", tests: []},
      {filename: "17945-C142.dxf",  rackLetter: "C", rackNumber: 2, slotNumber: 5, module: "IB8", notes: "", tests: []},
      {filename: "17945-C143.dxf",  rackLetter: "C", rackNumber: 2, slotNumber: 6, module: "IB8", notes: "", tests: []},
      {filename: "17945-C144.dxf",  rackLetter: "C", rackNumber: 2, slotNumber: 7, module: "IB8", notes: "", tests: []},
      {filename: "17945-C145.dxf",   module: "PWS", notes: "", tests: []},
      {filename: "17945-C146B.dxf", rackLetter: "C", rackNumber: 2, slotNumber: 8, module: "OB8", notes: "", tests: []},
      {filename: "17945-C147B.dxf", rackLetter: "C", rackNumber: 2, slotNumber: 9, module: "OB8", notes: "", tests: []},
      {filename: "17945-C148.dxf",  rackLetter: "C", rackNumber: 2, slotNumber: 10, module: "OB8", notes: "", tests: []},
      {filename: "17945-C149.dxf",  rackLetter: "C", rackNumber: 2, slotNumber: 11, module: "OB8", notes: "", tests: []},
      {filename: "17945-C150.dxf",  rackLetter: "C", rackNumber: 2, slotNumber: 12, module: "OB8", notes: "", tests: []},
      {filename: "17945-C151.dxf",  rackLetter: "C", rackNumber: 2, slotNumber: 13, module: "OW4", notes: "", tests: []},
      {filename: "17945-C152.dxf",  module: "PWS", notes: "", tests: []},
      {filename: "17945-C153.dxf",  rackLetter: "C", rackNumber: 2, slotNumber: 14, module: "IB8S", notes: "", tests: []},
      {filename: "17945-C154.dxf",  rackLetter: "C", rackNumber: 2, slotNumber: 15, module: "OB8S", notes: "", tests: []},
      // {filename: "17945-C155.dxf",  rackLetter: "C", rackNumber: 2, slotNumber: 15, module: "OB8S", notes: "Should be 16 but is improperly labelled.", tests: []},

      {filename: "17945-R122.dxf",  module: "PWS", notes: "", tests: []},
      {filename: "17945-R123.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 2, module: "IB8", notes: "", tests: []},
      {filename: "17945-R124.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 3, module: "IB8", notes: "", tests: []},
      {filename: "17945-R125.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 4, module: "IB8", notes: "", tests: []},
      {filename: "17945-R126.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 5, module: "IB8", notes: "", tests: []},
      {filename: "17945-R127.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 6, module: "IB8", notes: "", tests: []},
      {filename: "17945-R128.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 7, module: "IB8", notes: "", tests: []},
      {filename: "17945-R129.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 8, module: "IB8", notes: "", tests: []},
      {filename: "17945-R130.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 9, module: "IB8", notes: "", tests: []},
      {filename: "17945-R131.dxf",  module: "PWS", notes: "", tests: []},
      {filename: "17945-R132A.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 10, module: "OB8", notes: "", tests: []},
      {filename: "17945-R134.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 12, module: "OB8", notes: "", tests: []},
      {filename: "17945-R135.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 13, module: "OB8", notes: "", tests: []},
      {filename: "17945-R136.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 14, module: "OB8", notes: "", tests: []},
      {filename: "17945-R137.dxf",  module: "PWS", notes: "", tests: []},
      {filename: "17945-R138A.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 15, module: "IB8S", notes: "", tests: []},
      {filename: "17945-R139.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 16, module: "IB8S", notes: "", tests: []},
      {filename: "17945-R140.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 17, module: "IB8S", notes: "", tests: []},
      {filename: "17945-R141B.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 18, module: "IB8S", notes: "", tests: []},
      {filename: "17945-R142.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 19, module: "OB8S", notes: "", tests: []},
      {filename: "17945-R143.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 20, module: "OB8S", notes: "", tests: []},
    ],
    tests: [
    ]
  },

  {
    name: "18095",
    notes: "Sabert",
    debug: false,
    path: "data/18095/",
    pages: [
      {filename: "18095-a120.dxf",  module: "ETHERNET ADAPTER", tests: []},
      {filename: "18095-a121.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 0, module: "750-498", notes: "", tests: []},
      {filename: "18095-a122.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 1, module: "750-498", notes: "", tests: []},
      {filename: "18095-a123.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 2, module: "750-498", notes: "", tests: []},
      {filename: "18095-a124.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 3, module: "750-496", notes: "", tests: []},
      {filename: "18095-a125.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 4, module: "750-515", notes: "", tests: []},
      {filename: "18095-a127.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 5, module: "750-1405", notes: "", tests: []},
      {filename: "18095-a128.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 6, module: "750-1405", notes: "", tests: []},
      {filename: "18095-a129.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 7, module: "750-1405", notes: "", tests: []},
      {filename: "18095-a130.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 8, module: "750-530", notes: "", tests: []},
      {filename: "18095-a131.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 9, module: "750-530", notes: "", tests: []},
      {filename: "18095-a132.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 10, module: "750-530", notes: "", tests: []},
      {filename: "18095-a133.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 11, module: "750-530", notes: "", tests: []},
      {filename: "18095-a134.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 12, module: "750-530", notes: "", tests: []},
      
      {filename: "18095-a160.dxf",  rackLetter: "A", rackNumber: 3, module: "ETHERNET ADAPTER", notes: "", tests: []},
      // {filename: "18095-a161.dxf",  rackLetter: "A", rackNumber: 3, slotNumber: 1, module: "1734-IB8S", notes: "Alignment interferes with dual channel detection", tests: []},
      {filename: "18095-a162.dxf",  rackLetter: "A", rackNumber: 3, slotNumber: 2, module: "1734-OB8S", notes: "", tests: []},

      {filename: "18095-b121.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 0, module: "750-498", notes: "", tests: []},
      {filename: "18095-b122.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 1, module: "750-498", notes: "", tests: []},
      {filename: "18095-b123.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 2, module: "750-496", notes: "", tests: []},
      {filename: "18095-b124.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 3, module: "750-515", notes: "", tests: []},
      // {filename: "18095-b125.dxf",  module: "FPD", notes: "", tests: []},
      // {filename: "18095-b127.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 4, module: "750-1405", notes: "", tests: []},
      // {filename: "18095-b128.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 5, module: "750-1405", notes: "", tests: []},
      {filename: "18095-b129.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 7, module: "750-530", notes: "", tests: []},
      {filename: "18095-b130.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 8, module: "750-530", notes: "", tests: []},
      {filename: "18095-b131.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 9, module: "750-530", notes: "", tests: []},
      {filename: "18095-b132.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 10, module: "750-530", notes: "", tests: []},
      {filename: "18095-b133.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 11, module: "750-530", notes: "", tests: []},
      {filename: "18095-b134.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 12, module: "750-530", notes: "", tests: []},
      {filename: "18095-b135.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 13, module: "750-530", notes: "", tests: []},
      
      // {filename: "18095-b140.dxf",  module: "ETHERNET ADAPTER", notes: "", tests: []},
      {filename: "18095-b141.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 0, module: "750-498", notes: "", tests: []},
      {filename: "18095-b142.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 1, module: "750-498", notes: "", tests: []},
      {filename: "18095-b143.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 2, module: "750-498", notes: "", tests: []},
      {filename: "18095-b144.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 3, module: "750-496", notes: "", tests: []},
      {filename: "18095-b145.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 4, module: "750-515", notes: "", tests: []},
      // {filename: "18095-b146.dxf",  module: "FPD", notes: "", tests: []},
      {filename: "18095-b147.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 5, module: "750-1405", notes: "", tests: []},
      {filename: "18095-b148.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 6, module: "750-530", notes: "", tests: []},

      {filename: "18095-b160.dxf",  module: "ETHERNET ADAPTER", notes: "", tests: []},
      // {filename: "18095-b161.dxf",  rackLetter: "B", rackNumber: 3, slotNumber: 1, module: "1734-IB8S", notes: "", tests: []},
      // {filename: "18095-b162.dxf",  rackLetter: "B", rackNumber: 3, slotNumber: 2, module: "1734-OB8S", notes: "", tests: []},
      
      {filename: "18095-r120.dxf",  module: "ETHERNET ADAPTER", notes: "", tests: []},
      {filename: "18095-r121.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 0, module: "750-498", notes: "", tests: []},
      {filename: "18095-r122.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 1, module: "750-497", notes: "", tests: []},
      {filename: "18095-r123.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 2, module: "750-597", notes: "", tests: []},
      {filename: "18095-r124.dxf",  module: "FPD", notes: "", tests: []},
      {filename: "18095-r125.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 3, module: "750-1405", notes: "", tests: []},
      {filename: "18095-r126.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 4, module: "750-1405", notes: "", tests: []},
      {filename: "18095-r127.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 5, module: "750-1405", notes: "", tests: []},
      {filename: "18095-r128.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 6, module: "750-1405", notes: "", tests: []},
      {filename: "18095-r129.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 7, module: "750-1405", notes: "", tests: []},
      {filename: "18095-r130.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 8, module: "750-1405", notes: "", tests: []},
      {filename: "18095-r131.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 9, module: "750-530", notes: "", tests: []},
      {filename: "18095-r132.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 10, module: "750-530", notes: "", tests: []},
      {filename: "18095-r133.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 11, module: "750-530", notes: "", tests: []},
      {filename: "18095-r134.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 12, module: "750-530", notes: "", tests: []},
      {filename: "18095-r135.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 13, module: "750-530", notes: "", tests: []},
      {filename: "18095-r136.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 14, module: "750-530", notes: "", tests: []},
      {filename: "18095-r137.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 15, module: "750-530", notes: "", tests: []},

      // TODO: Detect the word "DUAL CHANNEL" and make it so
      {filename: "18095-r160.dxf",  module: "ETHERNET ADAPTER", notes: "", tests: []},
      {filename: "18095-r161.dxf",  rackLetter: "R", rackNumber: 3, slotNumber: 1, module: "1734-IB8S", notes: "", tests: [], unknownTags: 1},
      {filename: "18095-r162.dxf",  rackLetter: "R", rackNumber: 3, slotNumber: 2, module: "1734-IB8S", notes: "", tests: [], unknownTags: 4},
      // {filename: "18095-r163.dxf",  rackLetter: "R", rackNumber: 3, slotNumber: 3, module: "1734-OB8S", notes: "", tests: []},
      // {filename: "18095-r164.dxf",  rackLetter: "R", rackNumber: 3, slotNumber: 3, module: "1734-OB8S", notes: "", tests: []},


      {filename: "18095-r170.dxf",  module: "GA800", notes: "", tests: []},
      {filename: "18095-r171.dxf",  module: "GA800", notes: "", tests: []},
      {filename: "18095-r172.dxf",  module: "GA800", notes: "", tests: []},
      {filename: "18095-r175.dxf",  module: "GA800", notes: "", tests: []},

    ],
    tests: [
    ]
  },


  {
    name: "18004 Goex Wago",
    notes: "Has many rolls and partial WAGO conversion",
    debug: false,
    path: "data/18004-goex/",
    pages: [
      {filename: "18004-a120c.dxf",  rackLetter: "A", rackNumber: 1, module: "ETHERNET ADAPTER", tests: []},
      {filename: "18004-a121c.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 0, module: "750-498", tests: []},
      {filename: "18004-a122c.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 1, module: "750-498", tests: []},
      {filename: "18004-a123c.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 2, module: "750-498", tests: []},
      {filename: "18004-a124c.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 3, module: "750-496", tests: []},
      {filename: "18004-a125c.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 4, module: "750-496", tests: []},
      {filename: "18004-a126c.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 5, module: "750-496", tests: []},
      {filename: "18004-a127c.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 6, module: "750-496", tests: []},
      {filename: "18004-a128c.dxf",  module: "PWS", tests: []},
      {filename: "18004-a129c.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 7, module: "750-1405", tests: []},
      {filename: "18004-a130c.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 8, module: "750-1405", tests: []},
      {filename: "18004-a131c.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 9, module: "750-530", tests: []},
      {filename: "18004-a132c.dxf",  rackLetter: "A", rackNumber: 2, slotNumber: 9, module: "750-530", tests: [], notes: "Improperly labelled in schematic as slot 9. and rack 2"},
      {filename: "18004-a133c.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 11, module: "750-530", tests: []},
      {filename: "18004-a134c.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 12, module: "750-530", tests: []},
      {filename: "18004-a135c.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 13, module: "750-515", tests: []},
      
      {filename: "18004-a136c.dxf",  module: "ETHERNET ADAPTER", tests: []},
      {filename: "18004-a137c.dxf",  module: "PWS", tests: []},
      {filename: "18004-a138c.dxf",  rackLetter: "A", rackNumber: 2, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18004-a139c.dxf",  rackLetter: "A", rackNumber: 2, slotNumber: 2, module: "1734-OB8S", tests: []},


      {filename: "18004-b120c.dxf",  rackLetter: "B", rackNumber: 1, module: "ETHERNET ADAPTER", tests: [], notes: "750-366"},
      {filename: "18004-b121c.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 0, module: "750-498", tests: []},
      {filename: "18004-b122c.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 1, module: "750-498", tests: []},
      {filename: "18004-b123c.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 2, module: "750-498", tests: []},
      {filename: "18004-b124c.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 3, module: "750-498", tests: []},
      {filename: "18004-b125c.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 4, module: "750-496", tests: []},
      {filename: "18004-b126c.dxf",  module: "PWS", tests: []},
      {filename: "18004-b127c.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 5, module: "750-1405", unknownTags: 8, tests: []},
      {filename: "18004-b128c.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 6, module: "750-530", tests: []},
      {filename: "18004-b129c.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 7, module: "750-530", tests: []},
      {filename: "18004-b130c.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 8, module: "750-515", tests: []},

      {filename: "18004-b136c.dxf",   module: "ETHERNET ADAPTER", tests: [],},
      {filename: "18004-b138c.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18004-b139c.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 2, module: "1734-OB8S", tests: []},

      {filename: "18004-b152c.dxf",   module: "ETHERNET ADAPTER", tests: [],},
      {filename: "18004-b153c.dxf",  rackLetter: "B", rackNumber: 3, slotNumber: 0, module: "750-496", tests: []},
      {filename: "18004-b154c.dxf",  rackLetter: "B", rackNumber: 3, slotNumber: 1, module: "750-496", tests: []},
      {filename: "18004-b155c.dxf",  rackLetter: "B", rackNumber: 3, slotNumber: 2, module: "750-496", tests: []},
      {filename: "18004-b156c.dxf",  rackLetter: "B", rackNumber: 3, slotNumber: 3, module: "750-496", tests: []},
      {filename: "18004-b157c.dxf",  rackLetter: "B", rackNumber: 3, slotNumber: 4, module: "750-496", tests: []},
      {filename: "18004-b158c.dxf",  module: "PWS", tests: []},
      {filename: "18004-b159c.dxf",  rackLetter: "B", rackNumber: 3, slotNumber: 5, module: "750-1405", tests: []},
      {filename: "18004-b160c.dxf",  rackLetter: "B", rackNumber: 3, slotNumber: 6, module: "750-1405", unknownTags: 8, tests: []},
      {filename: "18004-b161c.dxf",  rackLetter: "B", rackNumber: 3, slotNumber: 7, module: "750-530", tests: []},
      {filename: "18004-b162c.dxf",  rackLetter: "B", rackNumber: 3, slotNumber: 8, module: "750-530", tests: []},
      {filename: "18004-b163c.dxf",  rackLetter: "B", rackNumber: 3, slotNumber: 9, module: "750-530", tests: []},
      {filename: "18004-b164c.dxf",  rackLetter: "B", rackNumber: 3, slotNumber: 10, module: "750-530", tests: []},
      {filename: "18004-b165c.dxf",  rackLetter: "B", rackNumber: 3, slotNumber: 11, module: "750-530", tests: []},

      {filename: "18004-b168c.dxf",  module: "ETHERNET ADAPTER", tests: []},
      {filename: "18004-b169c.dxf",  module: "FPD", tests: []},
      {filename: "18004-b170c.dxf",  rackLetter: "B", rackNumber: 4, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18004-b171c.dxf",  rackLetter: "B", rackNumber: 4, slotNumber: 2, module: "1734-OB8S", tests: []},


      {filename: "18004-r120a.dxf",  rackLetter: "R", rackNumber: 1, module: "750-366", tests: []},
      {filename: "18004-r121a.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 0, module: "750-1405", tests: []},
      {filename: "18004-r122a.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 1, module: "750-1405", tests: []},
      {filename: "18004-r123a.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 2, module: "750-1405", tests: []},
      {filename: "18004-r124a.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 3, module: "750-430", tests: []},
      // {filename: "18004-r125a.dxf",   module: "PWS", tests: []},
      {filename: "18004-r126a.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 4, module: "750-530", tests: []},
      {filename: "18004-r127a.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 5, module: "750-530", tests: []},
      {filename: "18004-r128a.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 6, module: "750-530", tests: []},
      {filename: "18004-r129a.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 7, module: "750-530", unknownTags: 1, tests: []},
      {filename: "18004-r136a.dxf",  module: "ETHERNET ADAPTER", tests: []},
      {filename: "18004-r137a.dxf",  rackLetter: "R", rackNumber: 2, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18004-r138a.dxf",  rackLetter: "R", rackNumber: 2, slotNumber: 2, module: "1734-IB8S", tests: []},
      {filename: "18004-r139a.dxf",  rackLetter: "R", rackNumber: 2, slotNumber: 3, module: "1734-OB8S", tests: []},
      {filename: "18004-r140a.dxf",  rackLetter: "R", rackNumber: 2, slotNumber: 4, module: "1734-OB8S", unknownTags: 8, tests: []},

      
      {filename: "18004-t120c.dxf",  module: "ETHERNET ADAPTER", tests: []},
      {filename: "18004-t121c.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 0, module: "750-498", tests: []},
      {filename: "18004-t122c.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 1, module: "750-498", tests: []},
      {filename: "18004-t123c.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 2, module: "750-498", tests: []},
      {filename: "18004-t124c.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 3, module: "750-496", tests: []},
      {filename: "18004-t125c.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 4, module: "750-496", tests: []},
      {filename: "18004-t126c.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 5, module: "750-496", tests: []},
      {filename: "18004-t127c.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 6, module: "750-497", tests: []},
      {filename: "18004-t128c.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 7, module: "750-557", tests: []},
      {filename: "18004-t129c.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 8, module: "750-557", tests: []},
      {filename: "18004-t130c.dxf",  module: "PWS", tests: []},
      {filename: "18004-t131c.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 9, module: "750-1405", tests: []},
      {filename: "18004-t132c.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 10, module: "750-1405", tests: []},
      {filename: "18004-t133c.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 11, module: "750-530", tests: []},
      {filename: "18004-t134c.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 12, module: "750-530", tests: []},
      {filename: "18004-t135c.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 13, module: "750-530", tests: []},

      
      {filename: "18004-w120-r0.dxf",  module: "ETHERNET ADAPTER", tests: []},
      {filename: "18004-w121-r0.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 1, module: "1734-IB8", tests: []},
      {filename: "18004-w122-r0.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 2, module: "1734-IB8", tests: []},
      {filename: "18004-w123-r0.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 3, module: "1734-IB8", tests: []},
      {filename: "18004-w124-r0.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 4, module: "1734-IB8", tests: []},
      {filename: "18004-w125-r0.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 5, module: "1734-IB8", tests: []},

      {filename: "18004-w126-r0.dxf",  module: "PWS", tests: []},
      {filename: "18004-w127-r0.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 6, module: "1734-OB8", tests: []},
      {filename: "18004-w128-r0.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 7, module: "1734-OB8", tests: []},
      {filename: "18004-w129-r0.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 8, module: "1734-OB8", tests: []},
      {filename: "18004-w130-r0.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 9, module: "1734-OB8", tests: []},

      {filename: "18004-w131-r0.dxf",  module: "PWS", tests: []},
      {filename: "18004-w132-r0.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 10, module: "1734-IB8S", tests: []},
      {filename: "18004-w133-r0.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 11, module: "1734-IB8S", tests: []},
      {filename: "18004-w134-r0.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 12, module: "1734-IB8S", tests: []},
      {filename: "18004-w135-r0.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 13, module: "1734-OB8S", tests: []},
      {filename: "18004-w136-r0.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 14, module: "1734-OB8S", tests: []},
      {filename: "18004-w137-r0.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 15, module: "1734-OB8S", tests: []},

      {filename: "18004-w140-r0.dxf",  module: "ETHERNET ADAPTER", tests: []},
      {filename: "18004-w141-r0.dxf",  rackLetter: "W", rackNumber: 2, slotNumber: 1, module: "1734-IB8", tests: []},
      {filename: "18004-w142-r0.dxf",  rackLetter: "W", rackNumber: 2, slotNumber: 2, module: "1734-IB8", tests: []},
      {filename: "18004-w143-r0.dxf",  rackLetter: "W", rackNumber: 2, slotNumber: 3, module: "1734-OB8", tests: []},
      {filename: "18004-w144-r0.dxf",  rackLetter: "W", rackNumber: 2, slotNumber: 4, module: "1734-OB8", tests: []},
      {filename: "18004-w145-r0.dxf",  rackLetter: "W", rackNumber: 2, slotNumber: 5, module: "1734-IB8S", tests: []},

      {filename: "18004-w150-r0.dxf",  module: "ETHERNET ADAPTER", tests: []},
      {filename: "18004-w151-r0.dxf",  rackLetter: "W", rackNumber: 3, slotNumber: 1, module: "1734-IE2V", tests: []},
      {filename: "18004-w152-r0.dxf",  rackLetter: "W", rackNumber: 3, slotNumber: 2, module: "1734-IB8", tests: []},
      {filename: "18004-w153-r0.dxf",  rackLetter: "W", rackNumber: 3, slotNumber: 3, module: "1734-IB8", tests: []},
      {filename: "18004-w154-r0.dxf",  rackLetter: "W", rackNumber: 3, slotNumber: 4, module: "1734-IB8", tests: []},
      {filename: "18004-w155-r0.dxf",  rackLetter: "W", rackNumber: 3, slotNumber: 5, module: "1734-IB8S", tests: []},
      {filename: "18004-w156-r0.dxf",  module: "PWS", tests: []},
      {filename: "18004-w157-r0.dxf",  rackLetter: "W", rackNumber: 3, slotNumber: 6, module: "1734-OB8", tests: []},

      {filename: "18004-w160-r0.dxf",  module: "ETHERNET ADAPTER", tests: []},
      {filename: "18004-w161-r0.dxf",  rackLetter: "W", rackNumber: 4, slotNumber: 1, module: "1734-IE2V", tests: []},
      {filename: "18004-w162-r0.dxf",  rackLetter: "W", rackNumber: 4, slotNumber: 2, module: "1734-IB8", tests: []},
      {filename: "18004-w163-r0.dxf",  rackLetter: "W", rackNumber: 4, slotNumber: 3, module: "1734-IB8", tests: []},
      {filename: "18004-w164-r0.dxf",  rackLetter: "W", rackNumber: 4, slotNumber: 4, module: "1734-IB8", tests: []},
      {filename: "18004-w165-r0.dxf",  rackLetter: "W", rackNumber: 4, slotNumber: 5, module: "1734-IB8S", tests: []},
      {filename: "18004-w166-r0.dxf",  module: "PWS", tests: []},
      {filename: "18004-w167-r0.dxf",  rackLetter: "W", rackNumber: 4, slotNumber: 6, module: "1734-OB8", unknownTags: 1, tests: []},
    ],
    tests: [
    ]
  },



  // TODO
  {
    name: "18059 TekModo",
    notes: "Wago",
    debug: false,
    path: "data/18059-TekModo-wago/",
    pages: [
      {filename: "18059-a120a.dxf",  rackLetter: "A", rackNumber: 1, module: "ETHERNET ADAPTER", tests: []},
      {filename: "18059-a121a.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 0, module: "750-498", tests: []},
      {filename: "18059-a122a.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 1, module: "750-498", tests: []},
      {filename: "18059-a123a.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 2, module: "750-498", tests: []},
      {filename: "18059-a124a.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 3, module: "750-498", tests: []},
      {filename: "18059-a125a.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 4, module: "750-466", tests: []},
      {filename: "18059-a126a.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 5, module: "750-455", tests: []},
      {filename: "18059-a127a.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 6, module: "750-455", tests: []},
      {filename: "18059-a128a.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 7, module: "750-455", tests: []},
      {filename: "18059-a129a.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 8, module: "750-455", tests: []},
      {filename: "18059-a130a.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 9, module: "750-455", tests: []},
      {filename: "18059-a131a.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 10, module: "750-455", tests: []},
      {filename: "18059-a132a.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 11, module: "750-455", tests: []},
      {filename: "18059-a133a.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 12, module: "750-455", tests: []},
      {filename: "18059-a134a.dxf",  rackLetter: "A", module: "PWS", tests: []},
      // {filename: "18059-a135.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 13, module: "750-530", tests: [], notes: "Old?"},
      {filename: "18059-a136a.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 14, module: "750-1405", tests: []},
      {filename: "18059-a137a.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 15, module: "750-530", tests: []},
      {filename: "18059-a138a.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 16, module: "750-530", tests: []},
      {filename: "18059-a139a.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 17, module: "750-530", tests: []},
      {filename: "18059-a140a.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 18, module: "750-530", tests: []},
      {filename: "18059-a141a.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 19, module: "750-530", tests: []},
      {filename: "18059-a142a.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 20, module: "750-530", tests: []},
      {filename: "18059-a143a.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 21, module: "750-527", tests: []},
      {filename: "18059-a144a.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 22, module: "750-515", tests: []},

      {filename: "18059-a153.dxf",  rackLetter: "A", rackNumber: 2, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18059-a154.dxf",  rackLetter: "A", rackNumber: 2, slotNumber: 2, module: "1734-OB8S", tests: []},
      {filename: "18059-a155.dxf",  rackLetter: "A", rackNumber: 2, slotNumber: 3, module: "1734-OB8S", tests: []},

      {filename: "18059-a170.dxf",  module: "ABB ACS 880", tests: []},



      {filename: "18059-b120a.dxf",  rackLetter: "B", rackNumber: 1, module: "ETHERNET ADAPTER", tests: []},
      {filename: "18059-b121a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 0, module: "750-498", tests: []},
      {filename: "18059-b122a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 1, module: "750-498", tests: []},
      {filename: "18059-b123a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 2, module: "750-498", tests: []},
      {filename: "18059-b124a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 3, module: "750-498", tests: []},
      {filename: "18059-b125a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 4, module: "750-466", tests: []},
      {filename: "18059-b126a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 5, module: "750-455", tests: []},
      {filename: "18059-b127a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 6, module: "750-455", tests: []},
      // {filename: "18059-b128a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 7, module: "750-455", tests: [], notes: "Slot number is incorrect in schematic"},
      // {filename: "18059-b129a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 8, module: "750-455", tests: [], notes: "Slot number is incorrect in schematic"},
      {filename: "18059-b130a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 9, module: "750-455", tests: []},
      {filename: "18059-b131a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 10, module: "750-455", tests: []},
      {filename: "18059-b132a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 11, module: "750-455", tests: []},
      {filename: "18059-b133a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 12, module: "750-455", tests: []},
      {filename: "18059-b134a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 13, module: "750-455", tests: []},
      {filename: "18059-b135a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 14, module: "750-455", tests: []},
      {filename: "18059-b136a.dxf",  rackLetter: "B", module: "PWS", tests: []},
      {filename: "18059-b137a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 15, module: "750-1405", tests: []},
      {filename: "18059-b138a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 16, module: "750-1405", tests: []},
      {filename: "18059-b139a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 17, module: "750-530", tests: []},
      {filename: "18059-b140a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 18, module: "750-530", tests: []},
      {filename: "18059-b141a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 19, module: "750-530", tests: []},
      {filename: "18059-b142a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 20, module: "750-530", tests: []},
      {filename: "18059-b143a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 21, module: "750-530", tests: []},
      {filename: "18059-b144a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 22, module: "750-530", tests: []},
      {filename: "18059-b145a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 23, module: "750-530", tests: []},
      {filename: "18059-b146a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 24, module: "750-527", tests: []},
      {filename: "18059-b147a.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 25, module: "750-515", tests: []},

      {filename: "18059-b153.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18059-b154.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 2, module: "1734-OB8S", tests: []},
      {filename: "18059-b155.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 3, module: "1734-OB8S", tests: []},

      {filename: "18059-b170.dxf",  module: "ABB ACS 880", tests: []},
      
      {filename: "18059-k120.dxf",  rackLetter: "K", rackNumber: 1, module: "ETHERNET ADAPTER", tests: []},
      {filename: "18059-k121.dxf",  rackLetter: "K", rackNumber: 1, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18059-k122.dxf",  rackLetter: "K", rackNumber: 1, slotNumber: 2, module: "1734-OB2EP", tests: []},

      
      {filename: "18059-p120c.dxf",  rackLetter: "P", rackNumber: 1, module: "ETHERNET ADAPTER", tests: []},
      {filename: "18059-p121c.dxf",  rackLetter: "P", rackNumber: 1, slotNumber: 0, module: "750-557", tests: []},
      {filename: "18059-p122c.dxf",  rackLetter: "P", module: "FPD", tests: []},
      {filename: "18059-p123c.dxf",  rackLetter: "P", rackNumber: 1, slotNumber: 1, module: "750-1405", tests: []},
      {filename: "18059-p124c.dxf",  rackLetter: "P", rackNumber: 1, slotNumber: 2, module: "750-1405", tests: []},
      {filename: "18059-p125c.dxf",  rackLetter: "P", rackNumber: 1, slotNumber: 3, module: "750-530", tests: []},
      
      {filename: "18059-p136.dxf",  rackLetter: "P", rackNumber: 2, module: "1734-AENT", tests: []},
      {filename: "18059-p137.dxf",  rackLetter: "P", rackNumber: 2, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18059-p138.dxf",  rackLetter: "P", rackNumber: 2, slotNumber: 2, module: "1734-IB8S", tests: []},
      {filename: "18059-p139.dxf",  rackLetter: "P", rackNumber: 2, slotNumber: 3, module: "1734-OB8S", tests: []},

      {filename: "18059-r120d.dxf",  rackLetter: "R", rackNumber: 1, module: "ETHERNET ADAPTER", tests: []},
      {filename: "18059-r121d.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 0, module: "750-1405", tests: []},
      {filename: "18059-r122d.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 1, module: "750-1405", tests: []},
      {filename: "18059-r123d.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 2, module: "750-1405", tests: []},
      {filename: "18059-r124d.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 3, module: "750-1405", tests: []},
      {filename: "18059-r125d.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 4, module: "750-1405", tests: []},
      {filename: "18059-r126d.dxf",  rackLetter: "R", module: "PWS", tests: []},
      {filename: "18059-r127d.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 5, module: "750-530", tests: []},
      {filename: "18059-r128d.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 6, module: "750-530", tests: []},
      {filename: "18059-r129d.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 7, module: "750-530", tests: []},
      {filename: "18059-r130d.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 8, module: "750-530", tests: []},

      {filename: "18059-r136c.dxf",  rackLetter: "R", rackNumber: 2, module: "ETHERNET ADAPTER", tests: []},
      {filename: "18059-r137c.dxf",  rackLetter: "R", rackNumber: 2, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18059-r138c.dxf",  rackLetter: "R", rackNumber: 2, slotNumber: 2, module: "1734-IB8S", tests: []},
      {filename: "18059-r139c.dxf",  rackLetter: "R", rackNumber: 2, slotNumber: 3, module: "1734-IB8S", tests: [], unknownTags: 1},
      {filename: "18059-r140c.dxf",  rackLetter: "R", rackNumber: 2, slotNumber: 4, module: "1734-IB8S", tests: []},
      {filename: "18059-r141c.dxf",  rackLetter: "R", module: "PWS", tests: []},
      {filename: "18059-r142c.dxf",  rackLetter: "R", rackNumber: 2, slotNumber: 5, module: "1734-OB8S", tests: []},
      {filename: "18059-r143c.dxf",  rackLetter: "R", rackNumber: 2, slotNumber: 6, module: "1734-OB8S", tests: []},
      {filename: "18059-r144c.dxf",  rackLetter: "R", rackNumber: 2, slotNumber: 7, module: "1734-OB8S", tests: []},

      {filename: "18059-r160a.dxf",  module: "ABB ACS", tests: []},
      {filename: "18059-r161a.dxf",  module: "ABB ACS", tests: []},
      {filename: "18059-r162a.dxf",  module: "ABB ACS", tests: []},
      {filename: "18059-r163a.dxf",  module: "ABB ACS", tests: []},
      {filename: "18059-r164a.dxf",  module: "ABB ACS", tests: []},
      {filename: "18059-r165a.dxf",  module: "ABB ACS", tests: []},
      {filename: "18059-r166a.dxf",  module: "ABB ACS", tests: []},

      // {filename: "18059-t120b.dxf",  rackLetter: "T", rackNumber: 1, module: "ETHERNET ADAPTER", tests: [], notes: "Title block prevents module detection"},
      {filename: "18059-t121b.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 0, module: "750-1405", tests: []},
      // {filename: "18059-t122b.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 1, module: "750-1405", tests: [], notes: "Has Duplicate point"},
      {filename: "18059-t123b.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 2, module: "750-530", tests: []},
      {filename: "18059-t124b.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 3, module: "750-530", tests: []},

      {filename: "18059-t136a.dxf",  rackLetter: "T", rackNumber: 2, module: "ETHERNET ADAPTER", tests: []},
      {filename: "18059-t137c.dxf",  module: "ILX34-MBS485", tests: []},
      {filename: "18059-t138a.dxf",  rackLetter: "T", module: "PWS", tests: []},
      {filename: "18059-t139a.dxf",  rackLetter: "T", rackNumber: 2, slotNumber: 2, module: "IB8S", tests: []},


      {filename: "18059-u120b.dxf",  rackLetter: "U", rackNumber: 1, module: "ETHERNET ADAPTER", tests: []},
      {filename: "18059-u121b.dxf",  rackLetter: "U", rackNumber: 1, slotNumber: 0, module: "750-1405", tests: []},
      {filename: "18059-u122b.dxf",  rackLetter: "U", rackNumber: 1, slotNumber: 1, module: "750-1405", tests: []},
      {filename: "18059-u123b.dxf",  rackLetter: "U", rackNumber: 1, slotNumber: 2, module: "750-1405", tests: []},
      {filename: "18059-u124b.dxf",  rackLetter: "U", rackNumber: 1, slotNumber: 3, module: "750-1405", tests: []},
      {filename: "18059-u125c.dxf",  rackLetter: "U", rackNumber: 1, slotNumber: 4, module: "750-1405", tests: []},
      {filename: "18059-u126b.dxf",  rackLetter: "U", module: "PWS", tests: []},
      {filename: "18059-u127b.dxf",  rackLetter: "U", rackNumber: 2, slotNumber: 5, module: "750-530", tests: [], notes: "Not actually rack 2"},
      {filename: "18059-u128b.dxf",  rackLetter: "U", rackNumber: 2, slotNumber: 6, module: "750-530", tests: [], notes: "Not actually rack 2"},
      {filename: "18059-u129b.dxf",  rackLetter: "U", rackNumber: 2, slotNumber: 7, module: "750-530", tests: [], notes: "Not actually rack 2"},
      {filename: "18059-u130b.dxf",  rackLetter: "U", rackNumber: 2, slotNumber: 8, module: "750-530", tests: [], notes: "Not actually rack 2"},
      {filename: "18059-u131b.dxf",  rackLetter: "U", rackNumber: 2, slotNumber: 9, module: "750-530", tests: [], notes: "Not actually rack 2"},

      {filename: "18059-u136b.dxf",  rackLetter: "U", rackNumber: 2, module: "1734-AENT", tests: []},
      {filename: "18059-u137c.dxf",  rackLetter: "U", rackNumber: 2, slotNumber: 1, module: "MODBUS", tests: []},
      {filename: "18059-u138b.dxf",  rackLetter: "U", module: "PWS", tests: []},
      {filename: "18059-u139b.dxf",  rackLetter: "U", rackNumber: 2, slotNumber: 2, module: "1734-IB8S", tests: []},
      {filename: "18059-u140b.dxf",  rackLetter: "U", rackNumber: 2, slotNumber: 3, module: "1734-IB8S", tests: []},
      {filename: "18059-u141b.dxf",  rackLetter: "U", rackNumber: 2, slotNumber: 4, module: "1734-OB8S", tests: []},
      {filename: "18059-u142b.dxf",  rackLetter: "U", rackNumber: 2, slotNumber: 5, module: "1734-OB8S", tests: []},

      {filename: "18059-u170a.dxf",  module: "ABB ACS", tests: []},
      {filename: "18059-u171a.dxf",  module: "ABB ACS", tests: []},
      {filename: "18059-u172a.dxf",  module: "ABB ACS", tests: []},
      {filename: "18059-u173a.dxf",  module: "ABB ACS", tests: []},
      {filename: "18059-u174.dxf",  module: "POWERFLEX", tests: []},
      {filename: "18059-u175.dxf",  module: "POWERFLEX", tests: []},

    ],
    tests: [
    ]
  },




  {
    name: "18072 Simona PMC",
    notes: "Has a pull roll with cooling",
    debug: false,
    path: "data/18072-Simona/",
    pages: [
      {filename: "18072-a120b.dxf",  module: "ETHERNET ADAPTER", tests: []},
      {filename: "18072-a121c.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 0, module: "750-498", tests: []},
      {filename: "18072-a122b.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 1, module: "750-498", tests: []},
      {filename: "18072-a123b.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 2, module: "750-496", tests: []},
      {filename: "18072-a124b.dxf",  module: "PWS", tests: []},
      {filename: "18072-a125c.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 3, module: "750-1405", tests: []},
      {filename: "18072-a126b.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 4, module: "750-1405", tests: []},
      {filename: "18072-a127c.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 5, module: "750-530", tests: []},
      {filename: "18072-a128b.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 6, module: "750-530", tests: []},
      {filename: "18072-a129b.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 7, module: "750-530", tests: []},
      {filename: "18072-a130b.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 8, module: "750-527", tests: []},
      {filename: "18072-a131b.dxf",  rackLetter: "A", rackNumber: 1, slotNumber: 9, module: "750-515", tests: []},
      {filename: "18072-a136b.dxf",  module: "ETHERNET ADAPTER", tests: []},
      {filename: "18072-a137b.dxf",  module: "PWS", tests: []},
      {filename: "18072-a138b.dxf",  rackLetter: "A", rackNumber: 2, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18072-a139c.dxf",  rackLetter: "A", rackNumber: 2, slotNumber: 2, module: "1734-OB8S", tests: []},


      {filename: "18072-b120b.dxf",  rackLetter: "B", rackNumber: 1, module: "ETHERNET ADAPTER", tests: []},
      {filename: "18072-b121c.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 0, module: "750-498", tests: []},
      {filename: "18072-b122c.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 1, module: "750-498", tests: []},
      {filename: "18072-b123c.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 2, module: "750-496", tests: []},
      {filename: "18072-b124b.dxf",  module: "PWS", tests: []},
      {filename: "18072-b125b.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 3, module: "750-1405", tests: []},
      {filename: "18072-b126c.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 4, module: "750-1405", tests: []},
      {filename: "18072-b127c.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 5, module: "750-1405", tests: []},
      {filename: "18072-b128c.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 6, module: "750-530", tests: []},
      {filename: "18072-b129c.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 7, module: "750-530", tests: []},
      {filename: "18072-b130c.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 8, module: "750-530", tests: []},
      {filename: "18072-b131c.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 9, module: "750-530", tests: []},
      {filename: "18072-b132c.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 10, module: "750-527", tests: []},
      {filename: "18072-b133c.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 11, module: "750-515", tests: []},

      {filename: "18072-b136b.dxf",  rackLetter: "B", rackNumber: 2, module: "ETHERNET ADAPTER", tests: []},
      {filename: "18072-b137b.dxf",  module: "PWS", tests: []},
      {filename: "18072-b138b.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18072-b139b.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 2, module: "1734-OB8S", tests: []},

      {filename: "18072-b170b.dxf",  module: "ABB", tests: []},
      
      {filename: "18072-s120b.dxf",  rackLetter: "S", rackNumber: 1, module: "ETHERNET ADAPTER", tests: []},
      {filename: "18072-s121b.dxf",  rackLetter: "S", rackNumber: 1, slotNumber: 0, module: "750-498", tests: []},
      {filename: "18072-s122b.dxf",  rackLetter: "S", rackNumber: 1, slotNumber: 1, module: "750-498", tests: []},
      {filename: "18072-s123b.dxf",  rackLetter: "S", rackNumber: 1, slotNumber: 2, module: "750-498", tests: []},
      {filename: "18072-s124b.dxf",  rackLetter: "S", rackNumber: 1, slotNumber: 3, module: "750-498", tests: []},
      {filename: "18072-s125b.dxf",  rackLetter: "S", rackNumber: 1, slotNumber: 4, module: "750-498", tests: []},
      {filename: "18072-s126b.dxf",  rackLetter: "S", rackNumber: 1, slotNumber: 5, module: "750-498", tests: []},
      {filename: "18072-s127b.dxf",  rackLetter: "S", rackNumber: 1, slotNumber: 6, module: "750-496", tests: []},
      {filename: "18072-s128b.dxf",  module: "PWS", tests: []},
      {filename: "18072-s129b.dxf",  rackLetter: "S", rackNumber: 1, slotNumber: 7, module: "750-1405", tests: []},
      {filename: "18072-s130b.dxf",  rackLetter: "S", rackNumber: 1, slotNumber: 8, module: "750-515", tests: []},
      {filename: "18072-s131b.dxf",  module: "PWS", tests: []},
      {filename: "18072-s132b.dxf",  rackLetter: "S", rackNumber: 1, slotNumber: 9, module: "750-530", tests: []},

      {filename: "18072-s136b.dxf",  rackLetter: "S", rackNumber: 2, module: "ETHERNET ADAPTER", tests: []},
      {filename: "18072-s137b.dxf",  rackLetter: "S", rackNumber: 2, slotNumber: 0, module: "750-1405", tests: []},
      {filename: "18072-s138b.dxf",  rackLetter: "S", rackNumber: 2, slotNumber: 1, module: "750-1405", tests: []},
      {filename: "18072-s139b.dxf",  rackLetter: "S", rackNumber: 2, slotNumber: 2, module: "750-530", tests: []},
      {filename: "18072-s140b.dxf",  rackLetter: "S", rackNumber: 2, slotNumber: 3, module: "750-530", tests: []},
      {filename: "18072-s141b.dxf",  rackLetter: "S", rackNumber: 2, slotNumber: 4, module: "750-530", tests: []},
      {filename: "18072-s142b.dxf",  rackLetter: "S", rackNumber: 2, slotNumber: 5, module: "750-530", tests: []},
      {filename: "18072-s143b.dxf",  rackLetter: "S", rackNumber: 2, slotNumber: 6, module: "750-530", tests: []},
      {filename: "18072-s144b.dxf",  rackLetter: "S", rackNumber: 2, slotNumber: 7, module: "750-530", tests: []},
      {filename: "18072-s145b.dxf",  rackLetter: "S", rackNumber: 2, slotNumber: 8, module: "750-530", tests: []},
      {filename: "18072-s146b.dxf",  rackLetter: "S", rackNumber: 2, slotNumber: 9, module: "750-527", tests: []},

      {filename: "18072-s152b.dxf",  rackLetter: "S", rackNumber: 3, module: "ETHERNET ADAPTER", tests: []},
      {filename: "18072-s153b.dxf",  module: "PWS", tests: []},
      {filename: "18072-s154b.dxf",  rackLetter: "S", rackNumber: 3, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18072-s155b.dxf",  rackLetter: "S", rackNumber: 3, slotNumber: 2, module: "1734-OB8S", tests: []},

      {filename: "18072-s180b.dxf",  module: "GA800", tests: []},
      {filename: "18072-s181b.dxf",  module: "GA800", tests: []},

      {filename: "18072-r120b.dxf",  rackLetter: "R", rackNumber: 1, module: "ETHERNET ADAPTER", tests: []},
      {filename: "18072-r121b.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 0, module: "750-496", tests: []},
      {filename: "18072-r122b.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 1, module: "750-497", tests: []},
      {filename: "18072-r123b.dxf",  module: "PWS", tests: []},
      {filename: "18072-r124b.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 2, module: "750-1405", tests: []},
      {filename: "18072-r125b.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 3, module: "750-1405", tests: []},
      {filename: "18072-r126b.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 4, module: "750-1405", tests: []},
      {filename: "18072-r127b.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 5, module: "750-1405", tests: []},
      {filename: "18072-r128b.dxf",  module: "PWS", tests: []},
      {filename: "18072-r129b.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 6, module: "750-530", tests: []},
      {filename: "18072-r130b.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 7, module: "750-530", tests: []},
      {filename: "18072-r131b.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 8, module: "750-530", tests: []},
      {filename: "18072-r132b.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 9, module: "750-530", tests: []},



      {filename: "18072-p120b.dxf",  rackLetter: "P", rackNumber: 1, module: "ETHERNET ADAPTER", tests: []},
      // {filename: "18072-p121b.dxf",  rackLetter: "P", rackNumber: 1, slotNumber: 0, module: "750-496", tests: [], notes: "Terrible IO names that are copied from current."},
      {filename: "18072-p122b.dxf",  rackLetter: "P", rackNumber: 1, slotNumber: 1, module: "750-496", tests: []},
      {filename: "18072-p123b.dxf",  rackLetter: "P", rackNumber: 1, slotNumber: 2, module: "750-496", tests: []},
      {filename: "18072-p124b.dxf",  rackLetter: "P", rackNumber: 1, slotNumber: 3, module: "750-557", tests: []},
      {filename: "18072-p125b.dxf",  module: "PWS", tests: []},

      {filename: "18072-p126b.dxf",  rackLetter: "P", rackNumber: 1, slotNumber: 4, module: "750-1405", tests: []},
      {filename: "18072-p127b.dxf",  rackLetter: "P", rackNumber: 1, slotNumber: 5, module: "750-1405", tests: [], unknownTags: 1,},
      {filename: "18072-p128b.dxf",  rackLetter: "P", rackNumber: 1, slotNumber: 6, module: "750-1405", tests: []},
      {filename: "18072-p129b.dxf",  rackLetter: "P", rackNumber: 1, slotNumber: 7, module: "750-1405", tests: []},
      {filename: "18072-p130b.dxf",  module: "PWS", tests: []},
      {filename: "18072-p131b.dxf",  rackLetter: "P", rackNumber: 1, slotNumber: 8, module: "750-530", tests: []},
      {filename: "18072-p132b.dxf",  rackLetter: "P", rackNumber: 1, slotNumber: 9, module: "750-530", tests: []},
      {filename: "18072-p133b.dxf",  rackLetter: "P", rackNumber: 1, slotNumber: 10, module: "750-530", tests: []},


      {filename: "18072-p136b.dxf",  rackLetter: "P", rackNumber: 2, module: "ETHERNET ADAPTER", tests: []},
      {filename: "18072-p137b.dxf",  rackLetter: "P", rackNumber: 2, slotNumber: 1, module: "1734-IB8S", tests: []},
      // {filename: "18072-p138b.dxf",  rackLetter: "P", rackNumber: 2, slotNumber: 2, module: "1734-IB8S", tests: []}, // known duplicates
      {filename: "18072-p139b.dxf",  rackLetter: "P", rackNumber: 2, slotNumber: 3, module: "1734-IB8S", tests: []},
      {filename: "18072-p140b.dxf",  module: "PWS", tests: []},
      {filename: "18072-p141b.dxf",  rackLetter: "P", rackNumber: 2, slotNumber: 4, module: "1734-OB8S", tests: []},
      {filename: "18072-p142b.dxf",  rackLetter: "P", rackNumber: 2, slotNumber: 5, module: "1734-OB8S", tests: []},
    ],
    tests: [
    ]
  },

  {
    name: "18072 Simona PMC Revision",
    notes: "Aux Rolls were Updated",
    debug: false,
    path: "data/18072-Simona-PR-Update/",
    pages: [
      {filename: "18072-p120f.dxf",  module: "ETHERNET ADAPTER", tests: []},
      {filename: "18072-p121f.dxf",  module: "750-455", rackLetter: "P", rackNumber: 1, slotNumber: 0, tests: []},
      {filename: "18072-p122f.dxf",  module: "750-455", rackLetter: "P", rackNumber: 1, slotNumber: 1, tests: []},
      {filename: "18072-p123f.dxf",  module: "750-455", rackLetter: "P", rackNumber: 1, slotNumber: 2, tests: []},
      {filename: "18072-p124f.dxf",  module: "750-455", rackLetter: "P", rackNumber: 1, slotNumber: 3, tests: []},
      {filename: "18072-p125f.dxf",  module: "750-455", rackLetter: "P", rackNumber: 1, slotNumber: 4, tests: []},
      {filename: "18072-p126f.dxf",  module: "750-557", rackLetter: "P", rackNumber: 1, slotNumber: 5, tests: []},
      {filename: "18072-p127f.dxf",  module: "PWS", tests: []},
      {filename: "18072-p128g.dxf",  module: "750-1405", rackLetter: "P", rackNumber: 1, slotNumber: 6, tests: []},
      {filename: "18072-p129f.dxf",  module: "750-1405", rackLetter: "P", rackNumber: 1, slotNumber: 7, tests: []},
      {filename: "18072-p130g.dxf",  module: "750-1405", rackLetter: "P", rackNumber: 1, slotNumber: 8, tests: []},
      {filename: "18072-p131f.dxf",  module: "750-1405", rackLetter: "P", rackNumber: 1, slotNumber: 9, tests: []},
      {filename: "18072-p133f.dxf",  module: "750-530", rackLetter: "P", rackNumber: 1, slotNumber: 10, tests: []},
      // {filename: "18072-p134f.dxf",  module: "750-530", rackLetter: "P", rackNumber: 1, slotNumber: 11, tests: []},
      {filename: "18072-p135g.dxf",  module: "750-530", rackLetter: "P", rackNumber: 1, slotNumber: 12, tests: []},


      {filename: "18072-p136b.dxf",  module: "1734-AENT", rackLetter: "P", rackNumber: 2, tests: []},
      {filename: "18072-p137b.dxf",  module: "1734-IB8S", rackLetter: "P", rackNumber: 2, slotNumber: 1, tests: []},
      {filename: "18072-p138b.dxf",  module: "1734-IB8S", rackLetter: "P", rackNumber: 2, slotNumber: 2, tests: []},
      {filename: "18072-p139e.dxf",  module: "1734-IB8S", rackLetter: "P", rackNumber: 2, slotNumber: 3, tests: []},
      {filename: "18072-p140b.dxf",  module: "PWS", tests: []},
      {filename: "18072-p141g.dxf",  module: "1734-OB8S", rackLetter: "P", rackNumber: 2, slotNumber: 4, tests: []},
      {filename: "18072-p142e.dxf",  module: "1734-OB8S", rackLetter: "P", rackNumber: 2, slotNumber: 5, tests: []},

    ],
    tests: [
    ]
  },






  {
    name: "18081 YesPac",
    notes: "YesPac Wago",
    debug: false,
    path: "data/18081-yespac-wago/",
    pages: [
      {filename: "18081-b120.dxf",  module: "ETHERNET ADAPTER", tests: []},
      {filename: "18081-b121.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 0, module: "750-498", tests: []},
      {filename: "18081-b122.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 1, module: "750-498", tests: []},
      {filename: "18081-b123.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 2, module: "750-498", tests: []},
      {filename: "18081-b124.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 3, module: "750-496", tests: []},
      {filename: "18081-b125.dxf",  module: "PWS", tests: []},
      {filename: "18081-b126.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 4, module: "750-1405", tests: []},
      {filename: "18081-b127.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 5, module: "750-515", tests: []},
      {filename: "18081-b128.dxf",  module: "PWS", tests: []},
      {filename: "18081-b129.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 6, module: "750-527", tests: []},
      {filename: "18081-b130.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 7, module: "750-527", tests: []},
      {filename: "18081-b131.dxf",  module: "PWS", tests: []},
      {filename: "18081-b132.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 8, module: "750-527", tests: []},

      {filename: "18081-b136.dxf",  module: "ETHERNET ADAPTER", tests: []},
      {filename: "18081-b137.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 0, module: "750-496", tests: []},
      {filename: "18081-b138.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 1, module: "750-496", tests: []},
      {filename: "18081-b139.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 2, module: "750-496", tests: []},
      {filename: "18081-b140.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 3, module: "750-496", tests: []},
      {filename: "18081-b141.dxf",  module: "PWS", tests: []},
      {filename: "18081-b142.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 4, module: "750-1405", tests: []},
      {filename: "18081-b143.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 5, module: "750-1405", tests: []},
      {filename: "18081-b144.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 6, module: "750-530", tests: []},
      {filename: "18081-b145.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 7, module: "750-530", tests: [], unknownTags: 1},
      {filename: "18081-b146.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 8, module: "750-530", tests: []},
      {filename: "18081-b147.dxf",  rackLetter: "B", rackNumber: 2, slotNumber: 9, module: "750-527", tests: []},

      {filename: "18081-b152.dxf",  module: "ETHERNET ADAPTER", tests: []},
      {filename: "18081-b154.dxf",  rackLetter: "B", rackNumber: 3, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18081-b155.dxf",  rackLetter: "B", rackNumber: 3, slotNumber: 2, module: "1734-OB8S", tests: []},

      {filename: "18081-b170.dxf",  module: "ACS 880 500HP", tests: []},

      {filename: "18081-b220.dxf",   module: "ETHERNET ADAPTER", tests: []},
      {filename: "18081-b221.dxf",   rackLetter: "B", rackNumber: 4, slotNumber: 0, module: "750-498", tests: []},
      {filename: "18081-b222.dxf",   rackLetter: "B", rackNumber: 4, slotNumber: 1, module: "750-498", tests: []},
      {filename: "18081-b223.dxf",   rackLetter: "B", rackNumber: 4, slotNumber: 2, module: "750-498", tests: []},
      {filename: "18081-b224.dxf",   rackLetter: "B", rackNumber: 4, slotNumber: 3, module: "750-496", tests: []},
      {filename: "18081-b225a.dxf",  rackLetter: "B", rackNumber: 4, slotNumber: 4, module: "750-471", tests: []},
      {filename: "18081-b226a.dxf",  rackLetter: "B", rackNumber: 4, slotNumber: 5, module: "750-564", tests: []},
      {filename: "18081-b227.dxf",   module: "PWS", tests: []},
      {filename: "18081-b228a.dxf",  rackLetter: "B", rackNumber: 4, slotNumber: 6, module: "750-1405", tests: []},
      {filename: "18081-b229.dxf",   rackLetter: "B", rackNumber: 4, slotNumber: 7, module: "750-515", tests: []},
      {filename: "18081-b230.dxf",   module: "PWS", tests: []},
      {filename: "18081-b231a.dxf",  rackLetter: "B", rackNumber: 4, slotNumber: 8, module: "750-530", tests: []},

      {filename: "18081-b271.dxf",  module: "YASKAWA GA800", tests: []},

      {filename: "18081-r120a.dxf",  module: "ETHERNET ADAPTER", tests: []},
      {filename: "18081-r121b.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 0, module: "750-496", tests: []},
      {filename: "18081-r122a.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 1, module: "750-497", tests: []},
      {filename: "18081-r123a.dxf",  module: "PWS", tests: []},
      {filename: "18081-r124a.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 2, module: "750-1405", tests: []},
      {filename: "18081-r125a.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 3, module: "750-1405", tests: []},
      {filename: "18081-r126a.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 4, module: "750-1405", tests: []},
      {filename: "18081-r127a.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 5, module: "750-1405", tests: []},
      {filename: "18081-r128a.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 6, module: "750-1405", tests: []},
      {filename: "18081-r129a.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 7, module: "750-1405", tests: []},
      {filename: "18081-r130a.dxf",  module: "PWS", tests: []},
      {filename: "18081-r131a.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 8, module: "750-530", tests: []},
      {filename: "18081-r132a.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 9, module: "750-530", tests: []},
      {filename: "18081-r133a.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 10, module: "750-530", tests: []},
      {filename: "18081-r134a.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 11, module: "750-530", tests: []},
      {filename: "18081-r135a.dxf",  rackLetter: "R", rackNumber: 1, slotNumber: 12, module: "750-530", tests: []},

      {filename: "18081-r136a.dxf",  module: "ETHERNET", tests: []},
      {filename: "18081-r137a.dxf",  rackLetter: "R", rackNumber: 2, slotNumber: 1, module: "MODBUS", tests: []},
      {filename: "18081-r138a.dxf",  module: "PWS", tests: []},
      {filename: "18081-r139a.dxf",  rackLetter: "R", rackNumber: 2, slotNumber: 2, module: "1734-IB8S", tests: []},
      {filename: "18081-r140a.dxf",  rackLetter: "R", rackNumber: 2, slotNumber: 3, module: "1734-IB8S", tests: []},
      {filename: "18081-r141a.dxf",  rackLetter: "R", rackNumber: 2, slotNumber: 4, module: "1734-IB8S", tests: []},
      {filename: "18081-r142a.dxf",  rackLetter: "R", rackNumber: 2, slotNumber: 5, module: "1734-OB8S", tests: []},
      {filename: "18081-r143a.dxf",  rackLetter: "R", rackNumber: 2, slotNumber: 6, module: "1734-OB8S", tests: []},

      {filename: "18081-r170a.dxf",  module: "ACS 880 5HP", tests: []},
      {filename: "18081-r171a.dxf",  module: "ACS 880 5HP", tests: []},
      {filename: "18081-r172a.dxf",  module: "ACS 880 5HP", tests: []},
      {filename: "18081-r173a.dxf",  module: "ACS 880 5HP", tests: []},
      // {filename: "18081-r174a.dxf",  module: "Kinetix", tests: [], notes: "Gap Adjustment Kinetix"},
      {filename: "18081-r175a.dxf",  module: "POWERFLEX", tests: []},
      {filename: "18081-r176a.dxf",  module: "POWERFLEX", tests: []},
      {filename: "18081-r177a.dxf",  module: "POWERFLEX", tests: []},

      {filename: "18081-v120.dxf",  module: "ETHERNET", tests: []},
      {filename: "18081-v121.dxf",  rackLetter: "V", rackNumber: 1, slotNumber: 0, module: "750-496", tests: []},
      {filename: "18081-v122.dxf",  rackLetter: "V", rackNumber: 1, slotNumber: 1, module: "750-1405", tests: []},
      {filename: "18081-v123.dxf",  rackLetter: "V", rackNumber: 1, slotNumber: 2, module: "750-530", tests: []},

      {filename: "18081-v136.dxf",  module: "ETHERNET", tests: []},
      {filename: "18081-v137.dxf",  rackLetter: "V", rackNumber: 2, slotNumber: 1, module: "1734-IB8S", tests: []},


    ],
    tests: [
    ]
  },


  {
    name: '18122 Sabert',
    debug: false,
    path: "data/18122-Sabert/",
    pages: [
      {filename: "18122-b120.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 0, module: "1794-IT8", tests: []},
      {filename: "18122-b121.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 1, module: "1794-IF4I", tests: []},
      {filename: "18122-b130.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 0, module: "1794-IT8", tests: []},
      {filename: "18122-b131.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 1, module: "1794-IT8", tests: []},
      {filename: "18122-b132.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 2, module: "1794-IT8", tests: []},
      // {filename: "18122-b133.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 3, module: "1794-IF4I", tests: []},
      {filename: "18122-b134.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 4, module: "1794-OW8", tests: []},
      {filename: "18122-b135.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 5, module: "1794-IB16", tests: []},
      {filename: "18122-b140.dxf", rackLetter: "B", rackNumber: 3, module: "1734-AENT", tests: []},
      {filename: "18122-b141.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 1, module: "ADDRESS RESERVE", tests: []},
      {filename: "18122-b142.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 2, module: "ADDRESS RESERVE", tests: []},
      {filename: "18122-b143.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 3, module: "ADDRESS RESERVE", tests: []},
      {filename: "18122-b144.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 4, module: "ADDRESS RESERVE", tests: []},
      {filename: "18122-b145.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 5, module: "ADDRESS RESERVE", tests: []},
      {filename: "18122-b146.dxf", rackLetter: "B", module: "PWS", tests: []},
      {filename: "18122-b147.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 6, module: "1734-IB8", tests: []},
      {filename: "18122-b148.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 7, module: "1734-IB8", tests: []},
      {filename: "18122-b149.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 8, module: "1734-IB8", tests: []},
      {filename: "18122-b150.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 9, module: "1734-IB8", tests: []},
      {filename: "18122-b151.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 10, module: "1734-OB8", tests: []},
      {filename: "18122-b152.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 11, module: "1734-OB8", tests: []},
      {filename: "18122-b153.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 12, module: "1734-OB8", tests: []},
      {filename: "18122-b154.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 13, module: "1734-OB8", tests: []},
      {filename: "18122-b155.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 14, module: "1734-OB8", tests: []},
      {filename: "18122-b156.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 15, module: "1734-OB8", tests: []},
      {filename: "18122-b157.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 16, module: "1734-OB8", tests: []},
      {filename: "18122-b158.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 17, module: "1734-OW4", tests: []},
      {filename: "18122-b160.dxf", rackLetter: "B", rackNumber: 4, module: "1734-AENT", tests: []},
      {filename: "18122-b161.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 1, module: "1734-IB8S", tests: [], unknownTags: 1},
      {filename: "18122-b162.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 2, module: "1734-OB8S", tests: [], unknownTags: 8},
      
      {filename: "18122-r120.dxf", rackLetter: "R",  rackNumber: 1, slotNumber: 0, module: "1794-IT8", tests: []},
      {filename: "18122-r121.dxf", rackLetter: "R",  rackNumber: 1, slotNumber: 1, module: "1794-IE8", tests: [], unknownTags: 3, notes: "READS POINTS OFFSET BY 1"},
      {filename: "18122-r122.dxf", rackLetter: "R",  rackNumber: 1, slotNumber: 2, module: "1794-OE4", tests: []},
    
      {filename: "18122-r130.dxf", rackLetter: "R",  rackNumber: 2, module: "ETHERNET ADAPTER", tests: []},
      {filename: "18122-r131.dxf", rackLetter: "R",  rackNumber: 2, slotNumber: 1, module: "1734-IB8", tests: []},
      {filename: "18122-r132.dxf", rackLetter: "R",  rackNumber: 2, slotNumber: 2, module: "1734-IB8", tests: []},
      {filename: "18122-r133.dxf", rackLetter: "R",  rackNumber: 2, slotNumber: 3, module: "1734-IB8", tests: []},
      {filename: "18122-r134.dxf", rackLetter: "R",  rackNumber: 2, slotNumber: 4, module: "1734-IB8", tests: []},
      {filename: "18122-r135.dxf", rackLetter: "R",  rackNumber: 2, slotNumber: 5, module: "1734-IB8", tests: []},
      {filename: "18122-r136.dxf", rackLetter: "R",  rackNumber: 2, slotNumber: 6, module: "1734-IB8", tests: [], unknownTags: 1},
      {filename: "18122-r137.dxf", rackLetter: "R",  rackNumber: 2, slotNumber: 7, module: "1734-IB8", tests: []},
      {filename: "18122-r138.dxf", rackLetter: "R",  rackNumber: 2, slotNumber: 8, module: "1734-IB8", tests: []},
      {filename: "18122-r139.dxf", rackLetter: "R",  rackNumber: 2, slotNumber: 9, module: "1734-IB8", tests: []},
      {filename: "18122-r140.dxf", rackLetter: "R",  rackNumber: 2, slotNumber: 10, module: "1734-IB8", tests: []},
      {filename: "18122-r141.dxf", rackLetter: "R",  rackNumber: 2, slotNumber: 11, module: "1734-IB8", tests: []},
      {filename: "18122-r142.dxf", module: "PWS", tests: []},
      {filename: "18122-r143.dxf", rackLetter: "R",  rackNumber: 2, slotNumber: 12, module: "1734-OB8", tests: []},
      {filename: "18122-r144.dxf", rackLetter: "R",  rackNumber: 2, slotNumber: 13, module: "1734-OB8", tests: []},
      {filename: "18122-r145.dxf", rackLetter: "R",  rackNumber: 2, slotNumber: 14, module: "1734-OB8", tests: []},
      {filename: "18122-r146.dxf", rackLetter: "R",  rackNumber: 2, slotNumber: 15, module: "1734-OB8", tests: []},
      {filename: "18122-r147.dxf", rackLetter: "R",  rackNumber: 2, slotNumber: 16, module: "1734-OB8", tests: []},
      {filename: "18122-r148.dxf", rackLetter: "R",  rackNumber: 2, slotNumber: 17, module: "1734-OB8", tests: []},

      {filename: "18122-r160.dxf", rackLetter: "R",  rackNumber: 3, module: "ETHERNET ADAPTER", tests: []},
      {filename: "18122-r161.dxf", rackLetter: "R",  rackNumber: 3, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18122-r162.dxf", rackLetter: "R",  rackNumber: 3, slotNumber: 1, module: "1734-IB8S", tests: [], notes: "Should be module 2 but they wrote 1"},
      {filename: "18122-r163.dxf", rackLetter: "R",  rackNumber: 3, slotNumber: 3, module: "1734-OB8S", tests: []},
      {filename: "18122-r164.dxf", rackLetter: "R",  rackNumber: 3, slotNumber: 4, module: "1734-OB8S", tests: []},
      {filename: "18122-r165.dxf", rackLetter: "R",  rackNumber: 3, slotNumber: 5, module: "1734-IB8S", tests: []},
    ],


    tests: [
    ]
  },
  
  {
    name: '18140',
    debug: false,
    path: "data/18140-PI/",
    pages: [
      {filename: "18140-a120.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 0, module: "1794-IT8", tests: []},
      {filename: "18140-a122.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 1, module: "1794-IF4I", tests: []},
      {filename: "18140-a136.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 0, module: "1734-AENT", tests: []},
      {filename: "18140-a137.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 1, module: "1734-IE8C", tests: []},
      {filename: "18140-a138.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 2, module: "RESERVE", tests: []},
      {filename: "18140-a139.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 3, module: "RESERVE", tests: []},
      {filename: "18140-a140.dxf", module: "PWS", tests: []},
      {filename: "18140-a141.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 4, module: "1734-IB8", tests: []},
      {filename: "18140-a142.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 5, module: "1734-IB8", tests: []},
      {filename: "18140-a143.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 6, module: "1734-IB8", tests: []},
      {filename: "18140-a144.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 7, module: "1734-OB8", tests: []},
      {filename: "18140-a145.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 8, module: "1734-OB8", tests: []},
      {filename: "18140-a146.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 9, module: "1734-OB8", tests: []},
      {filename: "18140-a147.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      {filename: "18140-a148.dxf", module: "PWS", tests: []},
      {filename: "18140-a149.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 11, module: "1734-IB8S", tests: []},
      {filename: "18140-a150.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 12, module: "1734-OB8S", tests: []},
      {filename: "18140-a170.dxf", module: "ABB", tests: []},

      {filename: "18140-b120.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 0, module: "1794-IT8", tests: []},
      {filename: "18140-b122.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 1, module: "1794-IT8", tests: []},
      {filename: "18140-b136.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 0, module: "1734-AENT", tests: []},
      {filename: "18140-b137.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 1, module: "1734-IE8C", tests: []},
      {filename: "18140-b138.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 2, module: "RESERVE", tests: []},
      {filename: "18140-b139.dxf", module: "PWS", tests: []},
      {filename: "18140-b140.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 3, module: "1734-IB8", tests: []},
      {filename: "18140-b141.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 4, module: "1734-IB8", tests: []},
      {filename: "18140-b142.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 5, module: "1734-IB8", tests: []},
      {filename: "18140-b143.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 6, module: "1734-IB8", tests: []},
      {filename: "18140-b144.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 7, module: "1734-OB8", tests: []},
      {filename: "18140-b145.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 8, module: "1734-OB8", tests: []},
      {filename: "18140-b146.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 9, module: "1734-OB8", tests: []},
      {filename: "18140-b147.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      {filename: "18140-b148.dxf", module: "PWS", tests: []},
      {filename: "18140-b149.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 11, module: "1734-IB8S", tests: []},
      {filename: "18140-b150.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 12, module: "1734-OB8S", tests: []},
      {filename: "18140-b170.dxf", module: "ABB", tests: []},

      {filename: "18140-r120a.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 0, module: "1794-IT8", tests: []},
      {filename: "18140-r122a.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 1, module: "1794-IE8", tests: []},
      {filename: "18140-r124a.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 2, module: "1794-IE8", tests: [], unknownTags: 6},
      {filename: "18140-r126a.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 3, module: "1794-IF4I", tests: []},
      {filename: "18140-r128a.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 4, module: "1794-OE4", tests: []},
      {filename: "18140-r130a.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 5, module: "1794-OE4", tests: []},
      {filename: "18140-r136a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 0, module: "1734-AENT", tests: []},
      {filename: "18140-r137a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 1, module: "MBS485", tests: []},
      {filename: "18140-r138a.dxf", module: "PWS", tests: []},
      // {filename: "18140-r139a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 2, module: "1734-IB8", tests: [], notes: "Position of everything are far right. Not sure how to fix."},
      {filename: "18140-r140a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 3, module: "1734-IB8", tests: []},
      {filename: "18140-r141a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 4, module: "1734-IB8", tests: []},
      {filename: "18140-r142a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 5, module: "1734-IB8", tests: [], unknownTags: 3, notes: "No descriptions, only line numbers."},
      // {filename: "18140-r143a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 6, module: "1734-IB8", tests: [], notes: "MMSP and AUX are labelled with same numbers"},
      {filename: "18140-r145a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 7, module: "1734-IB8", tests: []},
      {filename: "18140-r146a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 8, module: "1734-IB8", tests: []},
      {filename: "18140-r147a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 9, module: "1734-IB8", tests: []},
      {filename: "18140-r148a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 11, module: "1734-IB8", tests: []},
      // {filename: "18140-r149a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 12, module: "1734-IB8", tests: [], notes: "Detected module incorrectly"},
      {filename: "18140-r150a.dxf", module: "PWS", tests: []},
      {filename: "18140-r151a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 13, module: "1734-OB8", tests: []},
      {filename: "18140-r152a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 14, module: "1734-OB8", tests: []},
      {filename: "18140-r153a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 15, module: "1734-OB8", tests: []},
      {filename: "18140-r154a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 16, module: "1734-OB8", tests: []},
      {filename: "18140-r155a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 17, module: "1734-OB8", tests: []},
      {filename: "18140-r157a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 19, module: "1734-OB8", tests: []},
      {filename: "18140-r158a.dxf", module: "PWS", tests: []},
      {filename: "18140-r159a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 20, module: "1734-IB8S", tests: []},
      {filename: "18140-r160a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 21, module: "1734-IB8S", tests: [], unknownTags: 1, notes: "Winder Estop Notes multiline"},
      {filename: "18140-r161a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 22, module: "1734-IB8S", tests: []},
      {filename: "18140-r162a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 23, module: "1734-IB8S", tests: [], unknownTags: 1, notes: "Safety mat wiring not labelled"},
      {filename: "18140-r163a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 24, module: "1734-IB8S", tests: []},
      {filename: "18140-r164a.dxf", module: "PWS", tests: []},
      {filename: "18140-r165a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 25, module: "1734-OB8S", tests: []},
      {filename: "18140-r166a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 26, module: "1734-OB8S", tests: []},
      {filename: "18140-r167a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 27, module: "1734-OB8S", tests: []},
      {filename: "18140-r170a.dxf", module: "RMC", tests: []},
      {filename: "18140-r171a.dxf", module: "RMC151E", tests: []},
      {filename: "18140-r172a.dxf", module: "RMC151E", tests: []},
      {filename: "18140-r173a.dxf", module: "RMC151E", tests: []},
      {filename: "18140-r174a.dxf", module: "RMC151E", tests: []},
    ],
    tests: [
    ]
  },


  {
    name: '18184-PI',
    debug: false,
    path: "data/18184-PI/",
    pages: [
      {filename: "18184-b120.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 0, module: "1794-IT8", tests: []},
      {filename: "18184-b122.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 1, module: "1794-IT8", tests: []},
      {filename: "18184-b124.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 2, module: "1794-IF4I", tests: []},

      {filename: "18184-b136.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 0, module: "1734-AENT", tests: []},
      {filename: "18184-b137.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 1, module: "1734-IE8C", tests: []},
      {filename: "18184-b138.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 2, module: "RESERVE", tests: []},
      {filename: "18184-b139.dxf", module: "PWS", tests: []},
      {filename: "18184-b140.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 3, module: "1734-IB8", tests: []},
      {filename: "18184-b141.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 4, module: "1734-IB8", tests: []},
      {filename: "18184-b142.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 5, module: "1734-IB8", tests: []},
      {filename: "18184-b143.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 6, module: "1734-IB8", tests: []},
      {filename: "18184-b144.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 7, module: "1734-OB8", tests: []},
      {filename: "18184-b145.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 8, module: "1734-OB8", tests: []},
      {filename: "18184-b146.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 9, module: "1734-OB8", tests: []},
      {filename: "18184-b147.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      {filename: "18184-b148.dxf", rackLetter: "B", module: "PWS", tests: []},
      {filename: "18184-b149.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 11, module: "1734-IB8S", tests: []},
      {filename: "18184-b150.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 12, module: "1734-OB8S", tests: []},

      {filename: "18184-k120.dxf", rackLetter: "K", rackNumber: 1, module: "1734-AENT", tests: []},
      {filename: "18184-k121.dxf", rackLetter: "K", rackNumber: 1, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18184-k122.dxf", rackLetter: "K", rackNumber: 1, slotNumber: 2, module: "1734-OB2EP", tests: []},
      {filename: "18184-k123.dxf", rackLetter: "K", rackNumber: 1, slotNumber: 3, module: "1734-OB2EP", tests: []},

      {filename: "18184-r120.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 0, module: "1794-IT8", tests: []},
      {filename: "18184-r122.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 1, module: "1794-IE8", tests: []},
      {filename: "18184-r124.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 2, module: "1794-IE8", tests: []},
      {filename: "18184-r126.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 3, module: "1794-IF4I", tests: []},
      {filename: "18184-r128.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 4, module: "1794-OE4", tests: []},
      {filename: "18184-r130.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 5, module: "1794-OE4", tests: []},

      {filename: "18184-r136.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 0, module: "1734-AENT", tests: []},
      {filename: "18184-r137.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 1, module: "MODBUS", tests: []},
      {filename: "18184-r138.dxf", rackLetter: "R", module: "PWS", tests: []},
      {filename: "18184-r139.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 2, module: "1734-IB8", tests: []},
      {filename: "18184-r140.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 3, module: "1734-IB8", tests: []},
      {filename: "18184-r141.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 4, module: "1734-IB8", tests: []},
      {filename: "18184-r142.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 5, module: "1734-IB8", tests: []},
      // {filename: "18184-r143.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 6, module: "1734-IB8", tests: [], notes: "Coater Pump is misnumered and creates duplicates"},
      {filename: "18184-r144.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 7, module: "1734-IB8", tests: []},
      {filename: "18184-r145.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 8, module: "1734-IB8", tests: []},
      {filename: "18184-r146.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 9, module: "1734-IB8", tests: []},
      {filename: "18184-r147.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 10, module: "1734-IB8", tests: []},
      {filename: "18184-r148.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 11, module: "1734-IB8", tests: []},
      {filename: "18184-r149.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 12, module: "1734-IB8", tests: []},
      {filename: "18184-r150.dxf", rackLetter: "R", module: "PWS", tests: []},
      {filename: "18184-r151.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 13, module: "1734-OB8", tests: []},
      {filename: "18184-r152.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 14, module: "1734-OB8", tests: []},
      {filename: "18184-r153.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 15, module: "1734-OB8", tests: []},
      {filename: "18184-r154.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 16, module: "1734-OB8", tests: []},
      {filename: "18184-r155.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 17, module: "1734-OB8", tests: []},
      {filename: "18184-r156.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 18, module: "1734-OB8", tests: []},
      {filename: "18184-r157.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 19, module: "1734-OB8", tests: []},
      {filename: "18184-r158.dxf", rackLetter: "R", module: "PWS", tests: []},
      {filename: "18184-r159.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 20, module: "1734-IB8S", tests: []},
      {filename: "18184-r160.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 21, module: "1734-IB8S", tests: []},
      {filename: "18184-r161.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 22, module: "1734-IB8S", tests: []},
      {filename: "18184-r162.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 23, module: "1734-IB8S", tests: []},
      {filename: "18184-r163.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 24, module: "1734-IB8S", tests: []},
      {filename: "18184-r164.dxf", rackLetter: "R", module: "PWS", tests: []},
      {filename: "18184-r165.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 25, module: "1734-OB8S", tests: []},
      {filename: "18184-r166.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 26, module: "1734-OB8S", tests: []},
      {filename: "18184-r167.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 27, module: "1734-OB8S", tests: []},

      {filename: "18184-r170.dxf", module: "ABB ACS 880", tests: []},
      {filename: "18184-r171.dxf", module: "ABB ACS 880", tests: []},
      {filename: "18184-r172.dxf", module: "ABB ACS 880", tests: []},
      {filename: "18184-r175.dxf", module: "ABB ACS 880", tests: []},

      // {filename: "18184-r176.dxf", module: "Kinetix 5500", tests: [], notes: "Kinetix"},


      {filename: "18184-s120.dxf", rackLetter: "S", rackNumber: 1, slotNumber: 0, module: "1794-IT8", tests: []},
      {filename: "18184-s122.dxf", rackLetter: "S", rackNumber: 1, slotNumber: 1, module: "1794-IT8", tests: []},
      {filename: "18184-s124.dxf", rackLetter: "S", rackNumber: 1, slotNumber: 2, module: "1794-IT8", tests: []},
      {filename: "18184-s126.dxf", rackLetter: "S", rackNumber: 1, slotNumber: 3, module: "1794-IT8", tests: []},
      {filename: "18184-s128.dxf", rackLetter: "S", rackNumber: 1, slotNumber: 4, module: "1794-IT8", tests: []},
      {filename: "18184-s130.dxf", rackLetter: "S", rackNumber: 1, slotNumber: 5, module: "1794-IF4I", tests: []},
      {filename: "18184-s132.dxf", rackLetter: "S", rackNumber: 1, slotNumber: 6, module: "1794-IF4I", tests: []},
      {filename: "18184-s134.dxf", rackLetter: "S", rackNumber: 1, slotNumber: 7, module: "1794-IE4XOE2", tests: [], unknownTags: 2},

      {filename: "18184-s136.dxf", rackLetter: "S", rackNumber: 2, slotNumber: 0, module: "1794-IB32", tests: [], unknownTags: 7},
      {filename: "18184-s137.dxf", rackLetter: "S", rackNumber: 2, slotNumber: 0, module: "1794-IB32", tests: []},
      {filename: "18184-s138.dxf", rackLetter: "S", rackNumber: 2, slotNumber: 1, module: "1794-OB32P", tests: [], unknownTags: 1},
      {filename: "18184-s139.dxf", rackLetter: "S", rackNumber: 2, slotNumber: 1, module: "1794-OB32P", tests: []},
      {filename: "18184-s140.dxf", rackLetter: "S", rackNumber: 2, slotNumber: 2, module: "1794-OW8", tests: []},


      {filename: "18184-s152.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 0, module: "1734-AENT", tests: []},
      {filename: "18184-s153.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 1, module: "1734-IE8C", tests: []},
      {filename: "18184-s154.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 2, module: "1734-IE8C", tests: []},
      {filename: "18184-s155.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 3, module: "1734-IE8C", tests: []},
      {filename: "18184-s156.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 4, module: "1734-IE8C", tests: []},
      {filename: "18184-s157.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 5, module: "1734-IE8C", tests: []},
      {filename: "18184-s158.dxf", module: "PWS", tests: []},
      {filename: "18184-s159.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 6, module: "1734-IB8", tests: []},
      {filename: "18184-s160.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 7, module: "1734-IB8", tests: []},
      {filename: "18184-s161.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 8, module: "1734-IB8", tests: []},
      {filename: "18184-s162.dxf", module: "PWS", tests: []},
      {filename: "18184-s163.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 9, module: "1734-OB8", tests: []},
      {filename: "18184-s164.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 10, module: "1734-OB8", tests: []},
      {filename: "18184-s165.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 11, module: "1734-OB8", tests: []},
      {filename: "18184-s166.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 12, module: "1734-OB8", tests: []},
      {filename: "18184-s167.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 13, module: "1734-OB8", tests: []},
      {filename: "18184-s168.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 14, module: "1734-OB8", tests: []},

      {filename: "18184-s169.dxf", module: "PWS", tests: []},
      {filename: "18184-s170.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 15, module: "1734-IB8S", tests: []},
      {filename: "18184-s171.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 16, module: "1734-OB8S", tests: []},

    ],
    tests: [
    ]
  },


  {
    name: '18199 East Jordan',
    debug: false,
    path: "data/18199-EastJordan/",
    pages: [
      {filename: "18199-a120.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 0, module: "1794-IT8", tests: []},
      {filename: "18199-a122.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 1, module: "1794-IF4I", tests: []},
      {filename: "18199-a136.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 0, module: "1734-AENT", tests: []},
      {filename: "18199-a137.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 1, module: "ADDRESS RESERVE", tests: []},
      {filename: "18199-a138.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 2, module: "ADDRESS RESERVE", tests: []},
      {filename: "18199-a139.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 3, module: "ADDRESS RESERVE", tests: []},
      {filename: "18199-a140.dxf", rackLetter: "A", module: "PWS", tests: []},
      {filename: "18199-a141.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 4, module: "1734-IB8", tests: []},
      {filename: "18199-a142.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 5, module: "1734-IB8", tests: []},
      {filename: "18199-a143.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 6, module: "1734-IB8", tests: []},
      {filename: "18199-a144.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 7, module: "1734-OB8", tests: []},
      {filename: "18199-a145.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 8, module: "1734-OB8", tests: []},
      {filename: "18199-a146.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 9, module: "1734-OB8", tests: []},
      {filename: "18199-a147.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      {filename: "18199-a148.dxf", rackLetter: "A", module: "PWS", tests: []},
      {filename: "18199-a149.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 11, module: "1734-IB8S", tests: []},
      {filename: "18199-a150.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 12, module: "1734-OB8S", tests: []},


      {filename: "18199-b120.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 0, module: "1794-IT8", tests: []},
      {filename: "18199-b122.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 1, module: "1794-IT8", tests: []},
      {filename: "18199-b124.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 2, module: "1794-IF4I", tests: []},

      {filename: "18199-b136.dxf", rackLetter: "B", rackNumber: 2, module: "1734-AENT", tests: []},
      {filename: "18199-b137.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 1, module: "ADDRESS RESERVE", tests: []},
      {filename: "18199-b138.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 2, module: "ADDRESS RESERVE", tests: []},
      {filename: "18199-b139.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 3, module: "ADDRESS RESERVE", tests: []},
      {filename: "18199-b140.dxf", rackLetter: "B", module: "PWS", tests: []},
      {filename: "18199-b141.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 4, module: "1734-IB8", tests: []},
      {filename: "18199-b142.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 5, module: "1734-IB8", tests: []},
      {filename: "18199-b143.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 6, module: "1734-IB8", tests: []},
      {filename: "18199-b144.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 7, module: "1734-OB8", tests: []},
      {filename: "18199-b145.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 8, module: "1734-OB8", tests: []},
      {filename: "18199-b146.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 9, module: "1734-OB8", tests: []},
      {filename: "18199-b147.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},

      // {filename: "18199-b220.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},

    ],
    tests: [
    ]
  },


  {
    name: '18221 Berry',
    debug: false,
    path: "data/18221-Berry/",
    pages: [
      {filename: "18221-a120.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 0, module: "1794-IT8", tests: []},

      {filename: "18221-a130.dxf", rackLetter: "A", rackNumber: 2, module: "1734-AENT", tests: []},
      {filename: "18221-a131.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 1, module: "1734-IE8C", tests: []},
      // {filename: "18221-a132.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 2, module: "ARM", tests: [], notes: "Spare but marked as IE8C"},
      {filename: "18221-a133.dxf", rackLetter: "A", module: "PWS", tests: []},
      {filename: "18221-a134.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 3, module: "1734-IB8", tests: []},
      {filename: "18221-a135.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 4, module: "1734-IB8", tests: []},
      {filename: "18221-a136.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 5, module: "1734-IB8", tests: []},
      {filename: "18221-a137.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 6, module: "1734-IB8", tests: []},
      {filename: "18221-a138.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 7, module: "1734-OB8", tests: []},
      {filename: "18221-a139.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 8, module: "1734-OB8", tests: []},
      {filename: "18221-a140.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 9, module: "1734-OB8", tests: []},
      {filename: "18221-a141.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 10, module: "1734-OB8", tests: []},

      {filename: "18221-a160.dxf", rackLetter: "A", rackNumber: 3, module: "1734-AENT", tests: []},
      {filename: "18221-a161.dxf", rackLetter: "A", rackNumber: 3, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18221-a162.dxf", rackLetter: "A", rackNumber: 3, slotNumber: 2, module: "1734-OB8S", tests: []},

      {filename: "18221-a170.dxf", rackLetter: "A", module: "ACS 880", tests: []},


      {filename: "18221-b120.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 0, module: "1794-IT8", tests: []},
      
      {filename: "18221-b130.dxf", rackLetter: "B", rackNumber: 2, module: "1734-AENT", tests: []},
      {filename: "18221-b131.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 1, module: "1734-IE8C", tests: []},
      // {filename: "18221-b132.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 2, module: "1734-ARM", tests: [], notes: "Spare but marked as IE8C"},
      {filename: "18221-b133.dxf", rackLetter: "B", module: "PWS", tests: []},
      {filename: "18221-b134.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 3, module: "1734-IB8", tests: []},
      {filename: "18221-b135.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 4, module: "1734-IB8", tests: []},
      {filename: "18221-b136.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 5, module: "1734-IB8", tests: []},
      {filename: "18221-b137.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 6, module: "1734-IB8", tests: []},
      {filename: "18221-b138.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 7, module: "1734-OB8", tests: []},
      {filename: "18221-b139.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 8, module: "1734-OB8", tests: []},
      {filename: "18221-b140.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 9, module: "1734-OB8", tests: []},
      {filename: "18221-b141.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OB8", tests: []},
     
      {filename: "18221-b160.dxf", rackLetter: "B", rackNumber: 3, module: "1734-AENT", tests: []},
      {filename: "18221-b161.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18221-b162.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 2, module: "1734-OB8S", tests: []},


      {filename: "18221-r120.dxf", rackLetter: "R", rackNumber: 1, module: "1734-AENT", tests: []},
      {filename: "18221-r121.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 1, module: "ILX34-MBS485", tests: []},
      {filename: "18221-r122.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 2, module: "1734-IE2V", tests: []},
      {filename: "18221-r123.dxf", rackLetter: "R", module: "PWS", tests: []},
      {filename: "18221-r124.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 3, module: "1734-IB8", tests: []},
      {filename: "18221-r125.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 4, module: "1734-IB8", tests: []},
      {filename: "18221-r126.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 5, module: "1734-IB8", tests: []},
      {filename: "18221-r127.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 6, module: "1734-IB8", tests: []},
      {filename: "18221-r128.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 7, module: "1734-IB8", tests: []},
      {filename: "18221-r129.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 8, module: "1734-IB8", tests: []},
      {filename: "18221-r130.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 9, module: "1734-IB8", tests: []},
      {filename: "18221-r131.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 10, module: "1734-IB8", tests: []},
      {filename: "18221-r132.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 11, module: "1734-IB8", tests: []},
      {filename: "18221-r133.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 12, module: "1734-IB8", tests: []},
      {filename: "18221-r134.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 13, module: "1734-OB8", tests: []},
      {filename: "18221-r135.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 14, module: "1734-OB8", tests: []},
      {filename: "18221-r136.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 15, module: "1734-OB8", tests: []},
      {filename: "18221-r137.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 16, module: "1734-OB8", tests: []},
      {filename: "18221-r138.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 17, module: "1734-OB8", tests: []},

      {filename: "18221-r160.dxf", rackLetter: "R", rackNumber: 3, module: "1734-AENT", tests: []},
      {filename: "18221-r161.dxf", rackLetter: "R", rackNumber: 3, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18221-r162.dxf", rackLetter: "R", rackNumber: 3, slotNumber: 2, module: "1734-IB8S", tests: []},
      {filename: "18221-r163.dxf", rackLetter: "R", rackNumber: 3, slotNumber: 3, module: "1734-IB8S", tests: []},
      {filename: "18221-r164.dxf", rackLetter: "R", module: "PWS", tests: []},
      {filename: "18221-r165.dxf", rackLetter: "R", rackNumber: 3, slotNumber: 4, module: "1734-OB8S", tests: []},
      {filename: "18221-r166.dxf", rackLetter: "R", rackNumber: 3, slotNumber: 5, module: "1734-OB8S", tests: []},
      {filename: "18221-r167.dxf", rackLetter: "R", rackNumber: 3, slotNumber: 6, module: "1734-OB8S", tests: []},

      {filename: "18221-r170.dxf", module: "ACS 880", tests: []},
      {filename: "18221-r171.dxf", module: "ACS 880", tests: []},
      {filename: "18221-r172.dxf", module: "ACS 880", tests: []},
      {filename: "18221-r173.dxf", module: "ACS 880", tests: []},
      {filename: "18221-r174.dxf", module: "ACS 880", tests: []},
      {filename: "18221-r175.dxf", module: "ACS 880", tests: []},

      {filename: "18221-r177.dxf", module: "ACS 880", tests: []},
      {filename: "18221-r178.dxf", module: "ACS 880", tests: []},
      {filename: "18221-r179.dxf", module: "ACS 880", tests: []},
    

      {filename: "18221-s120.dxf", rackLetter: "S", rackNumber: 1, slotNumber: 0, module: "1794-IT8", tests: []},
      {filename: "18221-s121.dxf", rackLetter: "S", rackNumber: 1, slotNumber: 1, module: "1794-IT8", tests: []},
      {filename: "18221-s122.dxf", rackLetter: "S", rackNumber: 1, slotNumber: 2, module: "1794-IF4I", tests: []},
      {filename: "18221-s123.dxf", rackLetter: "S", rackNumber: 1, slotNumber: 3, module: "1794-OW8", tests: []},
      {filename: "18221-s124.dxf", rackLetter: "S", rackNumber: 1, slotNumber: 4, module: "1794-IB32", tests: []},
      {filename: "18221-s125.dxf", rackLetter: "S", rackNumber: 1, slotNumber: 4, module: "1794-IB32", tests: []},

      {filename: "18221-s130.dxf", rackLetter: "S", rackNumber: 2, slotNumber: 0, module: "1794-IT8", tests: []},
      {filename: "18221-s131.dxf", rackLetter: "S", rackNumber: 2, slotNumber: 0, module: "1794-IT8", tests: [], notes: "Incorrectly labelled as 0"},
      {filename: "18221-s132.dxf", rackLetter: "S", rackNumber: 2, slotNumber: 2, module: "1794-IT8", tests: []},
      {filename: "18221-s133.dxf", rackLetter: "S", rackNumber: 2, slotNumber: 3, module: "1794-IF4I", tests: []},
      {filename: "18221-s134.dxf", rackLetter: "S", rackNumber: 2, slotNumber: 4, module: "1794-OW8", tests: []},
      {filename: "18221-s135.dxf", rackLetter: "S", rackNumber: 2, slotNumber: 5, module: "1794-IB32", tests: []},
      {filename: "18221-s136.dxf", rackLetter: "S", rackNumber: 2, slotNumber: 5, module: "1794-IB32", tests: []},
      
      {filename: "18221-s140.dxf", rackLetter: "S", rackNumber: 3, module: "1734-AENT", tests: []},
      {filename: "18221-s141.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 1, module: "1734-IE8C", tests: []},
      {filename: "18221-s143.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 3, module: "1734-IE8C", tests: []},
      {filename: "18221-s144.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 4, module: "1734-IE8C", tests: []},
      {filename: "18221-s145-newlibre.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 5, module: "1734-IE8C", tests: []},
      {filename: "18221-s146.dxf", module: "PWS", tests: []},
      {filename: "18221-s147.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 6, module: "1734-IB8", tests: []},
      {filename: "18221-s148.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 7, module: "1734-IB8", tests: []},
      {filename: "18221-s149.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 8, module: "1734-IB8", tests: []},
      {filename: "18221-s150.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 9, module: "1734-OB8", tests: []},
      {filename: "18221-s151.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 10, module: "1734-OB8", tests: []},
      {filename: "18221-s152.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 11, module: "1734-OB8", tests: []},
      {filename: "18221-s153.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 12, module: "1734-OB8", tests: []},
      {filename: "18221-s154.dxf", rackLetter: "S", rackNumber: 3, slotNumber: 13, module: "1734-OB8", tests: []},
      
      {filename: "18221-s160.dxf", rackLetter: "S", rackNumber: 4, module: "1734-AENT", tests: []},
      {filename: "18221-s161.dxf", rackLetter: "S", rackNumber: 4, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18221-s162.dxf", rackLetter: "S", rackNumber: 4, slotNumber: 2, module: "1734-OB8S", tests: []},

      {filename: "18221-s180.dxf", module: "ACS 880", tests: []},
      {filename: "18221-s181.dxf", module: "ACS 880", tests: []},
    
    
      
    ],
    tests: [
    ]
  },


  {
    name: '18085 ExTech',
    debug: false,
    path: "data/18085-ExTech/",
    pages: [
      {filename: "18085-b120.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 0, module: "1794-IT8", tests: []},
      {filename: "18085-b122.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 1, module: "1794-IF4I", tests: []},
      
      {filename: "18085-b136.dxf", rackLetter: "B", rackNumber: 2, module: "1734-AENT", tests: []},
      {filename: "18085-b137.dxf", rackLetter: "B", module: "ADDRESS RESERVE", tests: []},
      {filename: "18085-b138.dxf", rackLetter: "B", module: "ADDRESS RESERVE", tests: []},
      {filename: "18085-b139.dxf", rackLetter: "B", module: "ADDRESS RESERVE", tests: []},
      {filename: "18085-b140.dxf", rackLetter: "B", module: "PWS", tests: []},
      {filename: "18085-b141.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 4, module: "1734-IB8", tests: []},
      {filename: "18085-b142.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 5, module: "1734-IB8", tests: []},
      {filename: "18085-b143.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 6, module: "1734-IB8", tests: []},
      {filename: "18085-b144.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 7, module: "1734-OB8", tests: []},
      {filename: "18085-b145.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 8, module: "1734-OB8", tests: []},
      {filename: "18085-b146.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 9, module: "1734-OB8", tests: []},
      {filename: "18085-b147.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      {filename: "18085-b148.dxf", rackLetter: "B",  module: "PWS", tests: []},
      {filename: "18085-b149.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 11, module: "1734-IB8S", tests: []},
      {filename: "18085-b150.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 12, module: "1734-OB8S", tests: []},

      {filename: "18085-b170.dxf", module: "ABB ACS 880", tests: []},

      {filename: "18085-b220.dxf", rackLetter: "B", rackNumber: 4, module: "750-366", tests: []},
      // {filename: "18085-b221.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 0, module: "750-498", tests: [], notes: "Duplicated downstream/screen"},
      {filename: "18085-b222.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 1, module: "750-498", tests: []},
      {filename: "18085-b223.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 2, module: "750-498", tests: []},
      {filename: "18085-b224.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 3, module: "750-455", tests: []},
      {filename: "18085-b225a.dxf", rackLetter: "B", module: "PWS", tests: []},
      {filename: "18085-b226a.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 4, module: "750-1405", tests: []},
      {filename: "18085-b227a.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 5, module: "750-515", tests: []},
      {filename: "18085-b228a.dxf", rackLetter: "B", module: "PWS", tests: []},
      {filename: "18085-b229a.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 8, module: "750-530", tests: []},

      {filename: "18085-b236.dxf", rackLetter: "B", rackNumber: 5, module: "750-366", tests: []},
      {filename: "18085-b237.dxf", rackLetter: "B", rackNumber: 5, slotNumber: 0, module: "750-1405", tests: []},
      {filename: "18085-b238b.dxf", rackLetter: "B", rackNumber: 5, slotNumber: 1, module: "750-530", tests: []},
      {filename: "18085-b239b.dxf", rackLetter: "B", rackNumber: 5, slotNumber: 2, module: "750-530", tests: []},
      {filename: "18085-b240.dxf", rackLetter: "B", rackNumber: 5, slotNumber: 3, module: "750-530", tests: []},
      {filename: "18085-b241.dxf", rackLetter: "B", rackNumber: 5, slotNumber: 4, module: "750-530", tests: []},

      {filename: "18085-b252.dxf", rackLetter: "B", rackNumber: 6, module: "1734-AENT", tests: []},
      {filename: "18085-b253.dxf", rackLetter: "B", rackNumber: 6, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18085-b254.dxf", rackLetter: "B", rackNumber: 6, slotNumber: 2, module: "1734-OB8S", tests: []},

      {filename: "18085-r120a.dxf", rackLetter: "R", rackNumber: 1, module: "750-366", tests: []},
      {filename: "18085-r121a.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 0, module: "750-455", tests: []},
      {filename: "18085-r122a.dxf", rackLetter: "R", module: "PWS", tests: []},
      {filename: "18085-r123a.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 1, module: "750-1405", tests: []},
      {filename: "18085-r124a.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 2, module: "750-1405", tests: []},
      {filename: "18085-r125a.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 3, module: "750-1405", tests: []},
      {filename: "18085-r126a.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 4, module: "750-1405", tests: []},
      {filename: "18085-r127a.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 5, module: "750-1405", tests: []},
      {filename: "18085-r128a.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 6, module: "750-530", tests: []},
      {filename: "18085-r129a.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 7, module: "750-530", tests: []},
      {filename: "18085-r130a.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 8, module: "750-530", tests: []},
      {filename: "18085-r131a.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 9, module: "750-530", tests: []},
      {filename: "18085-r132a.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 10, module: "750-527", tests: []},

      {filename: "18085-r136a.dxf", rackLetter: "R", rackNumber: 2, module: "1734-AENT", tests: []},
      {filename: "18085-r137a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 1, module: "ILX34-MBS485", tests: []},
      {filename: "18085-r138.dxf",  rackLetter: "R", rackNumber: 2, slotNumber: 2, module: "1734-IE2V", tests: []},
      {filename: "18085-r139a.dxf", rackLetter: "R", module: "PWS", tests: []},
      {filename: "18085-r140a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 3, module: "1734-IB8S", tests: []},
      {filename: "18085-r141a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 4, module: "1734-IB8S", tests: []},
      {filename: "18085-r142a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 5, module: "1734-IB8S", tests: []},
      {filename: "18085-r143a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 6, module: "1734-OB8S", tests: []},
      {filename: "18085-r144a.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 7, module: "1734-OB8S", tests: []},

      {filename: "18085-r170a.dxf", module: "ABB ACS 880", tests: []},
      {filename: "18085-r171a.dxf", module: "ABB ACS 880", tests: []},
      {filename: "18085-r172a.dxf", module: "ABB ACS 880", tests: []},
      {filename: "18085-r175a.dxf", module: "ABB ACS 880", tests: []},
      {filename: "18085-r177a.dxf", module: "POWERFLEX 525", tests: []},
      {filename: "18085-r178a.dxf", module: "POWERFLEX 525", tests: []},


      {filename: "18085-w120.dxf",  rackLetter: "W", rackNumber: 1, module: "1734-AENT", tests: []},
      {filename: "18085-w121a.dxf", rackLetter: "W", rackNumber: 1, slotNumber: 1, module: "1734-IB8", tests: []},
      {filename: "18085-w122.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 2, module: "1734-IB8", tests: []},
      {filename: "18085-w123.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 3, module: "1734-IB8", tests: []},
      {filename: "18085-w124c.dxf", rackLetter: "W", rackNumber: 1, slotNumber: 4, module: "1734-IB8", tests: []},
      {filename: "18085-w125.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 5, module: "1734-IB8", tests: []},
      {filename: "18085-w126.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 6, module: "1734-IB8", tests: []},
      {filename: "18085-w127.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 7, module: "1734-IB8", tests: []},
      {filename: "18085-w128.dxf",  module: "PWS", tests: []},
      {filename: "18085-w129a.dxf", rackLetter: "W", rackNumber: 1, slotNumber: 8, module: "1734-OB8", tests: []},
      {filename: "18085-w130.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 9, module: "1734-OB8", tests: []},
      {filename: "18085-w131.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 10, module: "1734-OB8", tests: []},
      {filename: "18085-w132.dxf",  module: "PWS", tests: []},
      {filename: "18085-w133c.dxf", rackLetter: "W", rackNumber: 1, slotNumber: 11, module: "1734-IB8S", tests: []},
      {filename: "18085-w134c.dxf", rackLetter: "W", rackNumber: 1, slotNumber: 12, module: "1734-IB8S", tests: []},
      {filename: "18085-w135.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 13, module: "1734-IB8S", tests: []},
      {filename: "18085-w136.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 14, module: "1734-IB8S", tests: []},
      {filename: "18085-w137.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 15, module: "1734-IB8S", tests: []},
      {filename: "18085-w138.dxf",  module: "PWS", tests: []},
      {filename: "18085-w139.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 16, module: "1734-OB8S", tests: []},
      {filename: "18085-w140.dxf",  rackLetter: "W", rackNumber: 1, slotNumber: 17, module: "1734-OB8S", tests: []},
      {filename: "18085-w141a.dxf", rackLetter: "W", rackNumber: 1, slotNumber: 18, module: "1734-OB8S", tests: []},

      {filename: "18085-w150.dxf", rackLetter: "W", rackNumber: 2, module: "1734-AENT", tests: []},
      {filename: "18085-w151.dxf", rackLetter: "W", rackNumber: 2, slotNumber: 1, module: "1734-IB8", tests: []},
      // {filename: "18085-w152.dxf", rackLetter: "W", rackNumber: 2, slotNumber: 2, module: "1734-IB8", tests: [], notes: "Uses bounding box to distinguish"},
      // {filename: "18085-w153.dxf", rackLetter: "W", rackNumber: 2, slotNumber: 3, module: "1734-OB8", tests: [], notes: "Uses bounding box to distinguish"},
      {filename: "18085-w154.dxf", rackLetter: "W", rackNumber: 2, slotNumber: 4, module: "1734-IB8S", tests: []},
      
      {filename: "18085-w160.dxf", rackLetter: "W", rackNumber: 3, module: "1734-AENT", tests: []},
      {filename: "18085-w161.dxf", rackLetter: "W", rackNumber: 3, slotNumber: 1, module: "1734-IB8", tests: []},
      // {filename: "18085-w162.dxf", rackLetter: "W", rackNumber: 3, slotNumber: 2, module: "1734-IB8", tests: [], notes: "Uses bounding box to distinguish"},
      // {filename: "18085-w163.dxf", rackLetter: "W", rackNumber: 3, slotNumber: 3, module: "1734-OB8", tests: [], notes: "Uses bounding box to distinguish"},
      {filename: "18085-w164.dxf", rackLetter: "W", rackNumber: 3, slotNumber: 4, module: "1734-IB8S", tests: []},

      {filename: "18085-w170.dxf", module: "ABB ACS 880", tests: []},
      {filename: "18085-w171.dxf", module: "ABB ACS 880", tests: []},
      {filename: "18085-w172.dxf", module: "ABB ACS 880", tests: []},
      {filename: "18085-w173.dxf", module: "ABB ACS 880", tests: []},
      {filename: "18085-w174.dxf", module: "ABB ACS 880", tests: []},
      {filename: "18085-w175.dxf", module: "ABB ACS 880", tests: []},
      {filename: "18085-w176.dxf", module: "ABB ACS 355", tests: []},
    ],
    tests: [
    ]
  },

  {
    name: '18229 Penda',
    debug: false,
    path: "data/18229-Penda/",
    pages: [
      {filename: "18229-a120d.dxf", rackLetter: "A", rackNumber: 1, module: "750-366", tests: []},
      {filename: "18229-a121c.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 0, module: "750-498", tests: []},
      {filename: "18229-a122c.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 1, module: "750-498", tests: []},

      {filename: "18229-a123c.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 2, module: "750-455", tests: []},
      {filename: "18229-a124c.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 3, module: "750-455", tests: []},
      {filename: "18229-a125c.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 4, module: "750-455", tests: []},
      {filename: "18229-a126c.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 5, module: "750-455", tests: []},
      {filename: "18229-a127c.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 6, module: "750-455", tests: []},
      {filename: "18229-a128c.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 7, module: "750-455", tests: []},
      {filename: "18229-a129c.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 8, module: "750-455", tests: []},
      {filename: "18229-a130c.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 9, module: "750-455", tests: []},
      {filename: "18229-a131c.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 10, module: "750-455", tests: []},
      {filename: "18229-a132c.dxf", rackLetter: "A", module: "PWS", tests: []},

      {filename: "18229-a133c.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 11, module: "750-1405", tests: []},
      {filename: "18229-a134c.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 12, module: "750-1405", tests: []},
      {filename: "18229-a135c.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 13, module: "750-1405", tests: []},
      
      {filename: "18229-a136c.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 14, module: "750-530", tests: []},
      {filename: "18229-a137d.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 15, module: "750-530", tests: []},
      {filename: "18229-a138c.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 16, module: "750-530", tests: [], unknownTags: 1},
      {filename: "18229-a139c.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 17, module: "750-530", tests: []},
      {filename: "18229-a140c.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 18, module: "750-530", tests: []},
      {filename: "18229-a141c.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 19, module: "750-530", tests: []},
      {filename: "18229-a142c.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 20, module: "750-527", tests: []},
      
      {filename: "18229-a152f.dxf", rackLetter: "A", rackNumber: 2, module: "750-366", tests: []},
      {filename: "18229-a153e.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 0, module: "750-498", tests: []},
      {filename: "18229-a154e.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 1, module: "750-498", tests: []},
      {filename: "18229-a155e.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 2, module: "750-453", tests: []},
      {filename: "18229-a156e.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 3, module: "750-455", tests: []},
      {filename: "18229-a157e.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 4, module: "750-555", tests: [], unknownTags: 1},
      {filename: "18229-a158d.dxf", rackLetter: "A", module: "PWS", tests: []},
      {filename: "18229-a159d.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 5, module: "750-1405", tests: []},
      {filename: "18229-a160d.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 6, module: "750-530", tests: []},
      {filename: "18229-a161d.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 7, module: "750-515", tests: []},

      {filename: "18229-a166e.dxf", rackLetter: "A", rackNumber: 3, module: "1734-AENT", tests: []},
      {filename: "18229-a167d.dxf", rackLetter: "A", rackNumber: 3, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18229-a168d.dxf", rackLetter: "A", rackNumber: 3, slotNumber: 2, module: "1734-OB8S", tests: []},

      {filename: "18229-a180b.dxf", rackLetter: "A", module: "ABB ACS", tests: []},
      {filename: "18229-a181b.dxf", rackLetter: "A", module: "ABB ACS", tests: []},
      


      {filename: "18229-b120b.dxf", rackLetter: "B", rackNumber: 1, module: "750-366", tests: []},
      {filename: "18229-b121.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 0, module: "750-498", tests: []},
      {filename: "18229-b122.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 1, module: "750-498", tests: []},

      {filename: "18229-b123.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 2, module: "750-455", tests: []},
      {filename: "18229-b124.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 3, module: "750-455", tests: []},
      {filename: "18229-b125.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 4, module: "750-455", tests: []},
      {filename: "18229-b126.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 5, module: "750-455", tests: []},
      {filename: "18229-b127.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 6, module: "750-455", tests: []},
      {filename: "18229-b128.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 7, module: "750-455", tests: []},
      {filename: "18229-b129.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 8, module: "750-455", tests: [], unknownTags: 1},
      {filename: "18229-b130.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 9, module: "750-455", tests: []},
      {filename: "18229-b131.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 10, module: "750-455", tests: []},
      {filename: "18229-b132.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 11, module: "750-455", tests: []},
      {filename: "18229-b133.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 12, module: "750-455", tests: []},
      {filename: "18229-b134.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 13, module: "750-455", tests: []},
      {filename: "18229-b135.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 14, module: "750-455", tests: []},
      {filename: "18229-b136.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 15, module: "750-455", tests: []},
      {filename: "18229-b137.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 16, module: "750-455", tests: []},
      {filename: "18229-b138.dxf", rackLetter: "B", module: "PWS", tests: []},

      {filename: "18229-b139.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 18, module: "750-1405", tests: []},
      {filename: "18229-b140.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 19, module: "750-1405", tests: []},
      {filename: "18229-b141.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 20, module: "750-1405", tests: []},
      
      {filename: "18229-b142.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 21, module: "750-530", tests: []},
      {filename: "18229-b143.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 22, module: "750-530", tests: []},
      {filename: "18229-b144.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 23, module: "750-530", tests: [], unknownTags: 1},
      {filename: "18229-b145.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 24, module: "750-530", tests: []},
      {filename: "18229-b146.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 25, module: "750-530", tests: []},
      {filename: "18229-b147.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 26, module: "750-530", tests: []},
      {filename: "18229-b148.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 27, module: "750-530", tests: []},
      {filename: "18229-b149.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 28, module: "750-530", tests: []},
      {filename: "18229-b150.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 29, module: "750-530", tests: []},
      {filename: "18229-b151.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 30, module: "750-530", tests: []},
      {filename: "18229-b152.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 31, module: "750-527", tests: []},
      
      {filename: "18229-b160b.dxf", rackLetter: "B", rackNumber: 2, module: "750-366", tests: []},
      {filename: "18229-b161.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 0, module: "750-498", tests: []},
      {filename: "18229-b162.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 1, module: "750-498", tests: []},
      {filename: "18229-b163.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 2, module: "750-498", tests: []},
      {filename: "18229-b164.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 3, module: "750-498", tests: []},
      {filename: "18229-b165.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 4, module: "750-498", tests: []},
      {filename: "18229-b166.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 5, module: "750-498", tests: []},
      {filename: "18229-b167.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 6, module: "750-453", tests: []},
      {filename: "18229-b168.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 7, module: "750-455", tests: [], notes: "Doesn't find all points"},
      {filename: "18229-b169.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 8, module: "750-555", tests: [], unknownTags: 1},
      {filename: "18229-b170.dxf", rackLetter: "B", module: "PWS", tests: []},
      {filename: "18229-b171.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "750-1405", tests: []},
      {filename: "18229-b172.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 11, module: "750-1405", tests: []},
      {filename: "18229-b173.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 12, module: "750-530", tests: []},
      {filename: "18229-b174.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 13, module: "750-515", tests: []},

      {filename: "18229-b180b.dxf", rackLetter: "B", rackNumber: 3, module: "1734-AENT", tests: []},
      {filename: "18229-b181.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 1, module: "1734-IB8S", tests: [], unknownTags: 1},
      {filename: "18229-b182.dxf", rackLetter: "B", rackNumber: 3, slotNumber: 2, module: "1734-OB8S", tests: []},

      {filename: "18229-b185b.dxf", rackLetter: "B", module: "ABB ACS", tests: []},
      {filename: "18229-b186c.dxf", rackLetter: "B", module: "ABB ACS", tests: []},



      {filename: "18229-r120a.dxf", rackLetter: "R", rackNumber: 1, module: "750-366", tests: []},
      {filename: "18229-r121.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 0, module: "750-1405", tests: []},
      {filename: "18229-r122.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 1, module: "750-1405", tests: [], unknownTags: 6},
      {filename: "18229-r123.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 2, module: "750-1405", tests: []},
      {filename: "18229-r124.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 3, module: "750-1405", tests: []},
      {filename: "18229-r125.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 4, module: "750-1405", tests: []},
      {filename: "18229-r126.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 5, module: "750-530", tests: []},
      {filename: "18229-r127.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 6, module: "750-530", tests: []},
      {filename: "18229-r128.dxf", rackLetter: "R", rackNumber: 1, slotNumber: 7, module: "750-530", tests: []},

      {filename: "18229-r136a.dxf", rackLetter: "R", rackNumber: 2, module: "1734-AENT", tests: []},
      {filename: "18229-r137.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18229-r138.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 2, module: "1734-IB8S", tests: []},
      {filename: "18229-r139.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 3, module: "1734-OB8S", tests: []},
      {filename: "18229-r140.dxf", rackLetter: "R", rackNumber: 2, slotNumber: 4, module: "1734-OB8S", tests: []},

      {filename: "18229-r170a.dxf", rackLetter: "R", module: "ABB ACS", tests: []},
      {filename: "18229-r171a.dxf", rackLetter: "R", module: "ABB ACS", tests: []},
      {filename: "18229-r172a.dxf", rackLetter: "R", module: "ABB ACS", tests: []},
      {filename: "18229-r173a.dxf", rackLetter: "R", module: "ABB ACS", tests: []},
      {filename: "18229-r174a.dxf", rackLetter: "R", module: "ABB ACS", tests: []},
      {filename: "18229-r175a.dxf", rackLetter: "R", module: "ABB ACS", tests: []},
      {filename: "18229-r176a.dxf", rackLetter: "R", module: "ABB ACS", tests: []},
      {filename: "18229-r177a.dxf", rackLetter: "R", module: "ABB ACS", tests: []},
      {filename: "18229-r178a.dxf", rackLetter: "R", module: "ABB ACS", tests: []},
      {filename: "18229-r180a.dxf", rackLetter: "R", module: "POWERFLEX", tests: []},
      {filename: "18229-r181a.dxf", rackLetter: "R", module: "POWERFLEX", tests: []},


      
      {filename: "18229-k120b.dxf", rackLetter: "K", rackNumber: 1, module: "1734-AENT", tests: []},
      {filename: "18229-k121.dxf", rackLetter: "K", rackNumber: 1, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18229-k122.dxf", rackLetter: "K", rackNumber: 1, slotNumber: 2, module: "1734-OB2EP", tests: []},



      {filename: "18229-p120b.dxf", rackLetter: "P", rackNumber: 1, module: "750-366", tests: []},
      {filename: "18229-p121.dxf", rackLetter: "P", rackNumber: 1, slotNumber: 0, module: "750-1405", tests: []},
      {filename: "18229-p122b.dxf", rackLetter: "P", rackNumber: 1, slotNumber: 1, module: "750-1405", tests: []},
      {filename: "18229-p123b.dxf", rackLetter: "P", rackNumber: 1, slotNumber: 2, module: "750-530", tests: []},
      
      {filename: "18229-p136b.dxf", rackLetter: "P", rackNumber: 2, module: "1734-AENT", tests: []},
      {filename: "18229-p137.dxf", rackLetter: "P", rackNumber: 2, slotNumber: 1, module: "1734-IB8S", tests: []},
      {filename: "18229-p138.dxf", rackLetter: "P", rackNumber: 2, slotNumber: 2, module: "1734-IB8S", tests: []},
      {filename: "18229-p139.dxf", rackLetter: "P", rackNumber: 2, slotNumber: 3, module: "1734-OB8S", tests: []},

      {filename: "18229-p170b.dxf", rackLetter: "P", module: "ABB ACS", tests: []},

      {filename: "18229-t120b.dxf", rackLetter: "T", rackNumber: 1, module: "750-366", tests: []},
      {filename: "18229-t121b.dxf", rackLetter: "T", rackNumber: 1, slotNumber: 0, module: "750-455", tests: []},
      {filename: "18229-t122.dxf",  rackLetter: "T", module: "PWS", tests: []},
      {filename: "18229-t123b.dxf", rackLetter: "T", rackNumber: 1, slotNumber: 1, module: "750-1405", tests: []},
      {filename: "18229-t124.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 2, module: "750-1405", tests: []},
      {filename: "18229-t125.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 3, module: "750-1405", tests: []},
      {filename: "18229-t126.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 4, module: "750-530", tests: []},
      {filename: "18229-t127.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 5, module: "750-530", tests: []},
      {filename: "18229-t128.dxf",  rackLetter: "T", rackNumber: 1, slotNumber: 6, module: "750-530", tests: []},

      {filename: "18229-t136b.dxf", rackLetter: "T", rackNumber: 2, module: "1734-AENT", tests: []},
      {filename: "18229-t137.dxf",  rackLetter: "T", rackNumber: 2, slotNumber: 1, module: "MBS", tests: []},
      {filename: "18229-t138.dxf",  rackLetter: "T", rackNumber: 2, slotNumber: 2, module: "MBS", tests: []},
      {filename: "18229-t140.dxf",  rackLetter: "T", rackNumber: 2, slotNumber: 3, module: "1734-IB8S", tests: []},


    ],
    tests: [

    ]
  },

  
  // TODO
  {
    name: '18133 PTi',
    debug: false,
    path: "data/18133-PTi/",
    pages: [
      // {filename: "18133-a120.dxf", rackLetter: "A", rackNumber: 1, module: "750-366", tests: []},
      // {filename: "18133-a121.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 0, module: "750-498", tests: []},
      // {filename: "18133-a122.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 1, module: "750-498", tests: []},
      // {filename: "18133-a123a.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 2, module: "750-496", tests: []},
      // {filename: "18133-a124a.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 3, module: "750-496", tests: []},
      // {filename: "18133-a125.dxf", rackLetter: "A", module: "PWS", tests: []},
      // {filename: "18133-a126a.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 4, module: "750-1405", tests: []},
      // {filename: "18133-a127.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 5, module: "750-1405", tests: []},
      // {filename: "18133-a128.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 6, module: "750-530", tests: []},
      // {filename: "18133-a129.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 7, module: "750-530", tests: []},
      // {filename: "18133-a130.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 8, module: "750-530", tests: []},
      // {filename: "18133-a131.dxf", rackLetter: "A", rackNumber: 1, slotNumber: 9, module: "750-527", tests: []},
      
      // {filename: "18133-a136.dxf", rackLetter: "A", rackNumber: 2, module: "1734-AENT", tests: []},
      // {filename: "18133-a137a.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 1, module: "1734-IB8S", tests: []},
      // {filename: "18133-a138a.dxf", rackLetter: "A", rackNumber: 2, slotNumber: 2, module: "1734-OB8S", tests: []},
      
      // {filename: "18133-a170.dxf", rackLetter: "A", module: "ABB ACS 880", tests: []},
      
      // {filename: "18133-a220.dxf", rackLetter: "A", rackNumber: 4, module: "750-366", tests: []},
      // {filename: "18133-a221.dxf", rackLetter: "A", rackNumber: 4, slotNumber: 0, module: "750-498", tests: []},
      // {filename: "18133-a222.dxf", rackLetter: "A", rackNumber: 4, slotNumber: 1, module: "750-498", tests: []},
      // {filename: "18133-a223.dxf", rackLetter: "A", rackNumber: 4, slotNumber: 2, module: "750-498", tests: []},
      // {filename: "18133-a224a.dxf", rackLetter: "A", rackNumber: 4, slotNumber: 3, module: "750-496", tests: []},
      // {filename: "18133-a225a.dxf", rackLetter: "A", rackNumber: 4, slotNumber: 4, module: "750-555", tests: []},
      // {filename: "18133-a226.dxf",  module: "PWS", tests: []},
      // {filename: "18133-a227a.dxf", rackLetter: "A", rackNumber: 4, slotNumber: 5, module: "750-1405", tests: []},
      // {filename: "18133-a228a.dxf", rackLetter: "A", rackNumber: 4, slotNumber: 6, module: "750-515", tests: []},
      // {filename: "18133-a229.dxf",  module: "PWS", tests: []},
      // {filename: "18133-a230.dxf", rackLetter: "A", rackNumber: 4, slotNumber: 7, module: "750-530", tests: []},


      // {filename: "18133-a236.dxf", rackLetter: "A", module: "750-366", tests: []},
      // {filename: "18133-a237a.dxf", rackLetter: "A", rackNumber: 5, slotNumber: 0, module: "750-496", tests: []},
      // {filename: "18133-a238a.dxf", rackLetter: "A", rackNumber: 5, slotNumber: 1, module: "750-496", tests: []},
      // {filename: "18133-a239a.dxf", rackLetter: "A", rackNumber: 5, slotNumber: 2, module: "750-496", tests: []},
      // {filename: "18133-a240.dxf", module: "PWS", tests: []},
      // {filename: "18133-a241a.dxf", rackLetter: "A", rackNumber: 5, slotNumber: 3, module: "750-1405", tests: []},
      // {filename: "18133-a242.dxf", rackLetter: "A", rackNumber: 5, slotNumber: 4, module: "750-530", tests: []},
      // {filename: "18133-a243.dxf", rackLetter: "A", rackNumber: 5, slotNumber: 5, module: "750-530", tests: []},
      // {filename: "18133-a244.dxf", rackLetter: "A", rackNumber: 5, slotNumber: 6, module: "750-530", tests: []},
      // {filename: "18133-a245.dxf", rackLetter: "A", rackNumber: 5, slotNumber: 7, module: "750-530", tests: []},
      // {filename: "18133-a246.dxf", rackLetter: "A", rackNumber: 5, slotNumber: 8, module: "750-527", tests: []},
      
      // {filename: "18133-a252.dxf", rackLetter: "A", rackNumber: 6, module: "1734-AENT", tests: []},
      // {filename: "18133-a253a.dxf", rackLetter: "A", rackNumber: 6, slotNumber: 1, module: "1734-IB8S", tests: []},
      // {filename: "18133-a254.dxf", rackLetter: "A", rackNumber: 6, slotNumber: 2, module: "1734-OB8S", tests: []},

      // {filename: "18133-a271.dxf", rackLetter: "A", module: "ABB ACS 880", tests: []},

      // // B EXTRUDER
      // {filename: "18133-b120.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 0, module: "1794-IT8", tests: []},
      // {filename: "18133-b122.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 1, module: "1794-IT8", tests: []},
      // {filename: "18133-b124.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 2, module: "1794-IT8", tests: []},
      // {filename: "18133-b126.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 3, module: "1794-IF4I", tests: []},
      // {filename: "18133-b128.dxf", rackLetter: "B", rackNumber: 1, slotNumber: 4, module: "1794-IE4XOE2", tests: []},

      // {filename: "18133-b136.dxf", rackLetter: "B", rackNumber: 2, module: "1734-AENT", tests: []},
      // {filename: "18133-b137.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 1, module: "1734-IE8C", tests: []},
      // {filename: "18133-b138.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 2, module: "1734-IE8C", tests: []},
      // {filename: "18133-b139.dxf", module: "PWS", tests: []},
      // {filename: "18133-b140.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 3, module: "1734-IB8", tests: []},
      // {filename: "18133-b141.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 4, module: "1734-IB8", tests: []},
      // {filename: "18133-b142.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 5, module: "1734-IB8", tests: []},
      // {filename: "18133-b143.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 6, module: "1734-IB8", tests: []},
      // {filename: "18133-b144.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 7, module: "1734-OB8", tests: []},
      // {filename: "18133-b145.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 8, module: "1734-OB8", tests: [], unknownTags: 1},
      // {filename: "18133-b146.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 9, module: "1734-OB8", tests: []},
      // {filename: "18133-b147.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-b148.dxf", module: "PWS", tests: []},
      // {filename: "18133-b149.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 11, module: "1734-OB2EP", tests: []},
      // {filename: "18133-b150.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 12, module: "1734-OB2EP", tests: []},
      // {filename: "18133-b151.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 13, module: "1734-OB2EP", tests: []},
      // {filename: "18133-b152.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 14, module: "1734-OB2EP", tests: []},
      // {filename: "18133-b153.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 15, module: "1734-OB2EP", tests: []},
      // {filename: "18133-b154.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 16, module: "1734-OB2EP", tests: []},
      // {filename: "18133-b155.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 17, module: "1734-OB2EP", tests: []},
      // {filename: "18133-b156.dxf", module: "PWS", tests: []},
      // {filename: "18133-b157.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 18, module: "1734-IB8S", tests: []},
      // {filename: "18133-b158.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 19, module: "1734-OB8S", tests: []},
      
      // {filename: "18133-b170.dxf", rackLetter: "B", module: "ABB ACS 880", tests: []},



      {filename: "18133-b220.dxf", rackLetter: "B", rackNumber: 4, module: "750-363", tests: []},
      {filename: "18133-b221.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 0, module: "750-498", tests: []},
      {filename: "18133-b222.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 1, module: "750-498", tests: []},
      {filename: "18133-b223.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 2, module: "750-498", tests: []},
      {filename: "18133-b224.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 3, module: "750-496", tests: []},
      {filename: "18133-b225.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 4, module: "750-555", tests: []},
      {filename: "18133-b226.dxf", module: "PWS", tests: []},
      {filename: "18133-b227.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 5, module: "750-1405", tests: []},
      {filename: "18133-b228.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 6, module: "750-1405", tests: []},
      {filename: "18133-b229.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 7, module: "750-530", tests: []},
      {filename: "18133-b230.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 8, module: "750-530", tests: []},
      {filename: "18133-b231.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 9, module: "750-515", tests: []},
      {filename: "18133-b232.dxf", module: "PWS", tests: []},
      {filename: "18133-b233.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 10, module: "750-530", tests: []},
      {filename: "18133-b234.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 11, module: "750-530", tests: []},

      // {filename: "18133-b236.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 10, module: "750-OW4", tests: []},
      // {filename: "18133-b237.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 10, module: "750-OW4", tests: []},
      // {filename: "18133-b238.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 10, module: "750-OW4", tests: []},
      // {filename: "18133-b239.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 10, module: "750-OW4", tests: []},
      // {filename: "18133-b240.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 10, module: "750-OW4", tests: []},
      // {filename: "18133-b241.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 10, module: "750-OW4", tests: []},
      // {filename: "18133-b242.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 10, module: "750-OW4", tests: []},
      // {filename: "18133-b243.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 10, module: "750-OW4", tests: []},
      // {filename: "18133-b244.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 10, module: "750-OW4", tests: []},
      // {filename: "18133-b245.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 10, module: "750-OW4", tests: []},
      // {filename: "18133-b246.dxf", rackLetter: "B", rackNumber: 4, slotNumber: 10, module: "750-OW4", tests: []},

      // {filename: "18133-b252.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-b253.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-b254.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},

      // {filename: "18133-b271.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-b281.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},

      
      // {filename: "18133-k120.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-k121.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-k122.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},


      // {filename: "18133-r120.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r121.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r122b.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r123b.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r124b.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r125b.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r126b.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r127c.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r128b.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r129b.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r130a.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r131a.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r132a.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r133c.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r134c.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r135c.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r136a.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r137a.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},

      // {filename: "18133-r152b.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r153c.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r154b.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r155b.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r156b.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r157b.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r158b.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r159b.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},


      // {filename: "18133-r170a.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r171a.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r172a.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r173a.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-r174a.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},


      // {filename: "18133-v120.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-v121.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-v122.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-v123.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-v124.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-v136.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},
      // {filename: "18133-v137.dxf", rackLetter: "B", rackNumber: 2, slotNumber: 10, module: "1734-OW4", tests: []},



    ],
    tests: [
    ]
  },


  // {
  //   name: "Duplicate Test",
  //   notes: "YesPac Wago",
  //   debug: true,
  //   path: "data/18081-yespac-wago/",
  //   pages: [
  //     {filename: "18081-b120.dxf",  module: "ETHERNET ADAPTER", tests: []},
  //     {filename: "18081-b120.dxf",  module: "ETHERNET ADAPTER", tests: []},
  //     {filename: "18081-b121.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 0, module: "750-498", tests: []},
  //     {filename: "18081-b121.dxf",  rackLetter: "B", rackNumber: 1, slotNumber: 0, module: "750-498", tests: []},
  //   ],
  //   tests: [],
  // },
];

// projects = [];

// prepend the path to the page url
for (let project of projects) {
  for (let page of project.pages) {
    page.url = project.path + page.filename;
  }
}

// {url: "data/18062/18062-r133.dxf", module: ""},
// {url: "data/18062/18062-b224.dxf", module: "", notes: "BDS"},
// {url: "data/18062/18062-b228.dxf", module: "", notes: "B Die"},