import { Component } from "react";


import type {CsvFileMeta} from "./types";

import "./meta.css"


type MetaProps = {
    meta: CsvFileMeta,
}
type MetaState = {
}

export class AlarmCsvMetaComponent extends Component<MetaProps, MetaState> {

    render () {
        return (
            <p className="TagFileMeta">
                    Tag Export File For Alarm Generation: <span>{this.props.meta.filename}</span> by <span>{this.props.meta.user}</span> in <span>{this.props.meta.version}</span> on <span>{this.props.meta.date}</span>
            </p>
        )
    }
}