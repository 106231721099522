import { Component } from "react";
import type { AlarmTrigger } from './trigger';
import { AlarmMessageComponent } from "./message-render";

import "./trigger-render.css"

// icons
import {
    ChevronExpand,
    ChevronContract,
  } from 'react-bootstrap-icons';
import { AlarmLevelComponent } from "./level-render";

type TriggerProps = {
    trigger: AlarmTrigger;
    expand?: boolean;
}
type TriggerState = {
    expanded: boolean;
}

export class AlarmTriggerComponent extends Component<TriggerProps, TriggerState>  {
    constructor(props: TriggerProps) {
        super(props);
        this.state = {
            expanded: this.props.expand || false,
        }
    }

    toggleExpand() {
        this.setState({
            expanded: !this.state.expanded,
        })
    }

    renderMessages(): JSX.Element[] {
        let messages:JSX.Element[] = [];

        if (this.state.expanded) {
            for (let m in this.props.trigger.real_messages) {
                const message = this.props.trigger.real_messages[m];
                messages.push(<AlarmMessageComponent 
                    key={message.trigger.pvtag + m} 
                    message={message} 
                    bit={parseInt(m)} />);
            }
        }
        return messages;
    }

    render() {
        const bits_used = Object.keys(this.props.trigger.real_messages).length
        const expanded = this.state.expanded || this.props.expand;

        let classname = "";
        if (bits_used === 0) {
            classname += " Alarm-Trigger-empty";
        }
        else {
            classname += " Alarm-Trigger-used";
        }

        if (expanded) {
            classname += " Alarm-Trigger-expanded";
        }
        else {
            classname += " Alarm-Trigger-collapsed";
        }

        const trigger = this.props.trigger;

        const trigger_row = (<tr 
            key={"trigger-" + this.props.trigger.pvtag} 
            className={"AlarmsXML-Trigger " + classname} 
            onClick={() => this.toggleExpand()}>
            <td className="AlarmsXML-Trigger-expander">
                {bits_used === 0 ? null :
                    expanded ? <ChevronContract /> : <ChevronExpand />
                }
            </td>
            <td className="AlarmsXML-Trigger-Level">
                <AlarmLevelComponent level={this.props.trigger.template.level} />
            </td>
            <td className="AlarmsXML-Trigger-Label">
                {trigger.label}
            </td>
            <td className="AlarmsXML-Trigger-TypeName">
                {trigger.template.typename}
            </td>
            <td className="AlarmsXML-Trigger-BitsUsed">
                {bits_used}
            </td>
            <td className="AlarmsXML-Trigger-BitsAvailable">
                {trigger.template.index_type === "bit" ? trigger.template.bits : (<span>2<sup>{trigger.template.bits}</sup></span>)}
            </td>
            <td  className="AlarmsXML-Trigger-Tag">
                {trigger.pvtag}
            </td>
        </tr>)

        return ([trigger_row, ...this.renderMessages()]);
    }
}