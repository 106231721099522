import knownModules from '../filters/knownmodules';
import {DxfPageParse} from './parseBase';
import type {DxfParseEntity, DxfParseEntityText } from './parseBase';
import type {KnownModule, KnownModulePoint} from '../types/module';
import type {IO } from '../types/io';
import { Drive } from '../processors/drive-tags'
import { SecondaryTag } from '../filters/secondaries';
import type DxfPage from '../dxfpage';
import DxfPageParsePoint from './parsePoint';

export class DxfPageParseModule {
    
    moduleEntity?: DxfParseEntity;
    rackLabelEntity?: DxfParseEntity;
    IOpointEntities: Array<DxfParseEntityText> = [];
    ioArrow?: DxfParseEntity;
    module_decision_method: string = "";
    parsedIO: Array<DxfPageParsePoint> = [];
    
    /**
    * Longer description of the location of the module
    */
    rackLocation: string = "UNKNOWN";
    /**
    * Single letter description of the location of the module
    * Examples: A, B, C, R, S, T, V
    */
    rackLetter: string = "U";
    /**
    * Identifies the rack within the location
    */
    rackNumber: number = 0;
    /**
    * Identifies the slot position within the rack
    */
    slotNumber: number = 0;
    /**
    * Is downstream or not
    */
    ds: "" | "DS" = "";
    extruderPrefix: string = ""; // "BEX"

    /**
     * 1-3 letter system label
     * Example: "BEX", "RS", "TS", "BEX_VAC_", "BEX_SC_" "W"
     * 
     */
    systemLabel: string = "";
    
    
    module?: KnownModule;
    location_search_method?: string;
    io: Array<IO> = [];
    
    /**
    * Count of unknown IO
    */
    unknowns: number = 0;
    
    page: DxfPage;
    parsed: DxfPageParse;
    drive?: Drive;

    pointOffset: number = 0;
    
    constructor(parsed: DxfPageParse, page: DxfPage) {
        this.parsed = parsed;
        this.page = page;
        
        
        this.findRackLocation();
        
        if (typeof this.rackLocation !== "string" || this?.module?.ignore) {return;}
        
        this.determineModuleType();
        
        
        if ((this.module?.isAdapter || this.module?.ignore) && this.rackLetter === "U" ) {
            this.getRackLetterFromTitle();
        }
        
        if (! this.module) {
            this.page.error += "Module not found\n";
            return;
        }
        
        
        if (this.module.ignore) {
            console.log("Ignoring module " + this.module.description + "\n");
            return;
        }
        
        
        // console.log("FOUND MODULE", this.module, "OBJ", this);
        if (this.module.points || this.module.pointReg) {
            this.findModuleIO();
        }
        this.labelModuleTags();
        
        
        if (this.moduleEntity && this.module.isDrive) {
            this.drive = new Drive(this.moduleEntity, this.page);
        }
        
        const unknowns = this.io.filter(io => io.name?.includes("UNKNOWN"));
        this.unknowns = unknowns.length;
        if (unknowns.length) {
            this.page.warn += "This module has " + unknowns.length + " unknown aliases\n";
        }
    }
    
    
    
    
    // Formats the names for module-level secondary tags
    // Must create a copy of the objects before formatting
    labelModuleTags() {
        if ( this.module && Array.isArray( this.module.module_tags )) {
            let module_tags: SecondaryTag[] = [];
            for (let tag_unaltered of this.module.module_tags) {
                let tag_formatted = Object.assign({}, tag_unaltered);
                
                tag_formatted.name = this.formatNameTemplate(tag_unaltered.name);
                
                // Add Date to description
                // Format: Gen:2021-07-01
                const gen = new Date().toISOString().split("T")[0];
                const full_description = (tag_unaltered.description || "") + "\nGen:" + gen;
                tag_formatted.description = this.formatNameTemplate(full_description);
                
                module_tags.push(tag_formatted);
            }
            this.module.module_tags = module_tags;
        }
    }
    
    
    
    
    /**
    * @method Find Rack Location Text
    * @description
    * Searches text for the rack letter, slot number, and other title block info
    * This method performs the actual searches for the text
    */
    findRackLocationText() {
        // RS/TS, I/O RACK 1, GROUP 3 PWS
        let blankPWS = this.parsed.findTextEntity( /RACK ([0-9]+).*GROUP ([0-9]+) (PWS|FPD|FPS)/ );
        if (blankPWS) {
            this.location_search_method = "PWS";
            this.page.warn += "Module detected, but it is a Power Distribution Block\n"
            // this.warn += blankPWS[0] + "\n";
            
            this.module = {description: "PWS/FPD : " + blankPWS.entity.text, ignore: true}
            this.getRackLetterFromTitle();
            // this.warn += "Rack " + blankPWS[0] + parseInt(blankPWS[2]) + " Group " + blankPWS[3] + "\n"
            return;
        }
        
        // let test = this.findTextEntity( /RACK 1/ );
        // console.log(test);
        
        let blankEnet = this.parsed.findTextEntity(/RACK ([0-9]+).*(ENET ADAPT.|ETHERNET ADAPTER|FIELDBUS ADAPTER|FIELDBUS COUPLER|HEAD STATION|ETHERNET MODULE)$/ );
        
        if (blankEnet) {
            this.location_search_method = "ENET";
            this.page.warn += "Module detected, but it is an ethernet adapter\n"
            this.getRackLetterFromTitle();
            
            if (blankEnet.entity.text.includes("VAC")) {
                this.rackLetter = "V"
            }
            this.rackNumber = parseInt(blankEnet.match[1]);
            this.module = {description: "ETHERNET ADAPTER : " + blankEnet.entity.text, isAdapter: true}
            return;
        }
        
        
        this.ds = ""
        // VACUUM STA. I/O RACK 1, MOD. 1, VTM
        // WINDER I/O, RACK 1, MODULE 1
        let LocRackModule = this.parsed.findTextEntity( /(.*)[,]? I\/O[,]? RACK (.*)[,]? MOD[A-Z]*\.? (.*)/ );
        // console.log("Rack information: ", LocRackModule);
        if (LocRackModule) {
            this.location_search_method = "Rack Location, Number, Then Module";
            this.rackLocation = LocRackModule.match[1];
            this.rackLocation = this.rackLocation.replaceAll(",","");
            this.rackLetter = "Q";
            
            if (this.rackLocation.includes("EXT")) {
                let loc = this.rackLocation.replace(" DS", "");
                this.rackLetter = loc.slice(-1);
                this.systemLabel = this.rackLetter + "EX";
                
                if (this.rackLocation.includes("DS")) {
                    this.ds = "DS";
                    this.systemLabel = this.rackLetter + "DS";
                }
                
            }
            else if (this.rackLocation.includes("RS")) {
                this.rackLetter = "R";
                this.systemLabel = "RS";
            }
            else if (this.rackLocation.includes("TS")) {
                this.rackLetter = "T";
                this.rackLocation = "RS/TS";
                this.systemLabel = "TS";
            }
            else if (this.rackLocation.includes("TITAN")) {
                this.rackLetter = "K";
                this.systemLabel = "T";
            }
            else if (this.rackLocation.includes("WINDER")) {
                this.rackLetter = "W";
                this.systemLabel = "W";
            }
            else if (this.rackLocation.includes("VACUUM")) {
                this.rackLetter = "V";
                this.systemLabel = "V";
            }
            else if (this.rackLocation.includes("PR")) {
                this.rackLetter = "P";
                this.systemLabel = "PR";
            }
            else if (this.rackLocation.includes("DOWNSTREAM") || this.rackLocation.includes("DS")) {
                this.rackLetter = "S";
                this.ds = "DS";
                this.systemLabel = "DS";
            }
            
            this.rackNumber = parseInt(LocRackModule.match[2]);
            this.slotNumber = parseInt(LocRackModule.match[3]);
            return;
        }
        
        let rackLocMod = this.parsed.findTextEntity(/RACK ([0-9]+)([A-Z]).*MODULE ([0-9]+)/)
        if (rackLocMod) {
            this.location_search_method = "Rack Number, Location, then Module";
            this.rackNumber = parseInt(rackLocMod.match[1]);
            this.slotNumber = parseInt(rackLocMod.match[3]);
            this.rackLetter = rackLocMod.match[2];
            
            this.rackLocation = this.getRackLocationFromLetter(this.rackLetter);
            this.page.warn += "It's an older style title block.\n";
            return;
        }
        
        /* not preferred, but sometimes the title block doesn't show the extruder letter */
        /* @example: I/O RACK 1, MODULE 2 */
        /* Then check for APPLICATION box which should say something like 450 EXTRUDER A */
        let modNoRackLoc = this.parsed.findTextEntity(/^(I\/O )?RACK ([0-9]+).*MODULE ([0-9]+)/);
        if (modNoRackLoc) {
            this.location_search_method = "Module without Rack Location";
            this.rackNumber = parseInt(modNoRackLoc.match[2]);
            this.slotNumber = parseInt(modNoRackLoc.match[3]);
            
            this.getRackLetterFromTitle();
            return;
        }
        
        // search the list of text again if we didn't find the rack location
        // This allows for preferred methods to be tried first
        // Example: A2:DI:06 RACK 2 MODULE 6
        // Example: B3DI06
        // Example: B3DO10
        let pointLabel = this.parsed.findTextEntity( /([A-Z])([0-9])[:]?[A|D][I|O][:]?([0-9]{1,2})/ );
        if (pointLabel) {
            this.location_search_method = "Point Label";
            this.rackLetter = pointLabel.match[1];
            this.rackNumber = parseInt(pointLabel.match[2]);
            this.slotNumber = parseInt(pointLabel.match[3]);
            
            this.rackLocation = this.getRackLocationFromLetter(this.rackLetter);
            return;
        }
    }
    
    /**
    * @method Find Rack Location
    * @description
    * Searches text for the rack letter, slot number, and other title block info
    */
    findRackLocation() {
        this.findRackLocationText();
        
        // no matching location found
        if (! this.rackNumber && ! this.slotNumber) {
            // console.log(match)
            this.page.warn += "Could not find rack or module number from title block in plain text\n"
            this.page.warn += "Page may not have an I/O card or the title block format changed\n"
            return
        }
        
        this.io = [];
        
        // console.debug("Searching for downstream notation")
        if (this.rackLetter <= "H") { // IS EXTRUDER
            const reg = new RegExp("EXTRUDER "+ this.rackLetter+".*RACK "+ this.rackNumber+".*DOWNSTREAM", "i");
            if (this.parsed.findTextEntity(reg)) {
                this.ds = "DS";
            }
        }
        
        
        // VACUUM
        // fill out any missing data for vacuum stations
        if (this.rackLetter === "V") {
            const reg = new RegExp(/.* ([A-L]) .*VS/i);
            const result = this.parsed.findTextEntity(reg);
            if (result) {
                this.extruderPrefix = result.match[1] + "EX";
            }
            if (this.extruderPrefix) {
                // console.log("FOUND EXTRUDER", this.extruderPrefix);
            }
            else {
                this.extruderPrefix = "EX";
                this.page.warn += "Vacuum station does not seem to have an extruder\n";
            }
        }
    }
    
    /**
    * Get the rack location from the rack letter
    * @param rackLetter - the letter of the rack (A, B, C, R, T, W, V)
    * @returns Rack location description
    */
    getRackLocationFromLetter(rackLetter: string) {
        let rackLocation = "";
        
        if (rackLetter < 'M') {
            rackLocation = "EXTRUDER " + rackLetter;
            this.systemLabel = rackLetter + "EX";
        }
        else if (rackLetter === "R") {
            rackLocation = "RS/TS";
            this.systemLabel = "RS";
        }
        else if (rackLetter === "U") {
            rackLocation = "AUX";
            this.systemLabel = "AR";
        }
        else if (rackLetter === "W") {
            rackLocation = "WINDER";
            this.systemLabel = "W";
        }
        else if (rackLetter === "V" || this.rackLocation === "V") {
            rackLocation = "VACUUM";
            this.systemLabel = "V";
        }
        else {
            rackLocation = rackLetter;
        }
        return rackLocation;
    }
    
    getRackLetterFromTitle() {
        const ext_match = this.parsed.findTextEntity(/[0-9]{3,5} EXT.* ([A-K])/);
        if (ext_match) {
            this.rackLetter = ext_match.match[1];
            this.rackLocation = "EXTRUDER " + this.rackLetter;
            this.systemLabel = this.rackLetter + "EX";
            return;
        }
        
        const hvtse = this.parsed.findTextEntity(/(HVTSE|SGHS) ([A-H])/);
        if (hvtse) {
            this.rackLetter = hvtse.match[2];
            this.rackLocation = "EXTRUDER " + this.rackLetter;
            this.systemLabel = this.rackLetter + "EX";
            return;
        }
        
        const ext_title = this.parsed.findTextEntity(/EXT. ([A-Z]),? I\/O RACK [0-9]/i);
        if (ext_title) {
            this.rackLetter = ext_title.match[1];
            this.rackLocation = "EXTRUDER " + this.rackLetter;
            this.systemLabel = this.rackLetter + "EX";
            return;
        }
        
        if (this.parsed.findTextEntity(/^TEMPERATURE STATION/i) || this.parsed.findTextEntity(/^TS[,]? I\/O/i)) {
            this.rackLetter = "T";
            this.rackLocation = "TS";
            this.systemLabel = "TS";
            return;
        }
        
        if (this.parsed.findTextEntity(/^AR[,]? I\/O /i)) {
            this.rackLetter = "U";
            this.rackLocation = "AR";
            this.systemLabel = "AR";
            return;
        }
        
        if (this.parsed.findTextEntity(/^RS\/TS[,]? /i) || this.parsed.findTextEntity(/^RS[,]? I\/O/i)) {
            this.rackLetter = "R";
            this.rackLocation = "RS";
            this.systemLabel = "RS";
            return;
        }
        
        // DOWNSTREAM
        let ds_match = this.parsed.findTextEntity(/EXTRUDER[S]? ([A-Z]*) DS/i);
        if (ds_match) {
            this.rackLetter = "S";
            this.rackLocation = "DS " + (ds_match.match[1] || "");
            this.ds = "DS";
            this.systemLabel = (ds_match.match[1] || "")  + "DS";
            return;
        }
        let ds_match2 = this.parsed.findTextEntity(/^DOWNSTREAM ([A-Z])\/?([A-Z]?)$/i);
        if (ds_match2) {
            this.rackLetter = "S";
            let str = (ds_match2.match[1] || "") + (ds_match2.match[2] || "");
            this.rackLocation = "DS " + str;
            this.ds = "DS";
            this.systemLabel = str + "DS";
            return;
        }

        
        // PULL ROLL
        // EXAMPLE: PR/SHEAR, I/O RACK 1, HEAD STATION
        if (this.parsed.findTextEntity(/PR[,]? I\/O/i) || this.parsed.findTextEntity(/PR\/SHEAR[,]? I\/O/i)) {
            this.rackLetter = "P";
            this.rackLocation = "PR"
            this.systemLabel = "PR";
            return;
        }

        // This is a fallback and needs to be a lower priority because it can match with the terminal legend
        if (this.parsed.findTextEntity(/ROLL STAND/i)) {
            this.rackLetter = "R";
            this.rackLocation = "RS";
            this.systemLabel = "RS";
            return;
        }

        this.rackLetter = "U";
        this.rackLocation = "UNKNOWN";
        this.page.warn += "Location letter not embedded in title.\n";
    }
    
    
    //////////////////////////////////////////////////////
    // Test string for module
    //////////////////////////////////////////////////////
    // Tests module string for if it looks like a module.
    // If it is a module, attach the module info to it.
    //
    testStringForModule(entity, shortTrigger=true) {
        if (typeof entity !== "object") {return false;}
        const text = entity.text || entity.fieldType;
        if (typeof text !== "string") {return false}
        
        for (let m in knownModules) {
            const mod = knownModules[m];
            let trigger = mod.trigger;
            if (!shortTrigger) {trigger = mod.triggerLong}
            
            if ( trigger && text.match( trigger )) {
                // console.log("Found module", mod, text);
                entity.ebModule = mod;
                return true;
            }
        }
        return false;
    }
    
    /**
    * Looks for the address. This address is assigned to each INSERT module in the drawing.
    * The parent of the address should be an INSERT, which actually exists in the drawing.
    *  
    * This uses the label on the top left, where the start of the module is
    *  @returns The found entity containing an ebModule
    */
    determineModuleTypeByAddressInsertParent(): DxfParseEntity | undefined {
        // B2AI4  B1AI01
        // R1:DI:12
        // const address_regex = /^[A-Z][0-9][A|D][I|O][0-9]{1,2}$/;
        const address_regex = new RegExp(`^${this.rackLetter}${this.rackNumber}[:]?[A|D|R][I|O][:]?[0]?${this.slotNumber}$`);
        const results = this.parsed.findTextEntities(address_regex);

        const debug = false;
        if (debug) {
            console.log("Searching for address to determine type", address_regex, results);
        }

        for (const result of results) {
            const entity = result.entity;

            if (debug) {
                console.log("Checking entity: ", entity.text, entity.position, entity.type, entity.dxfSection, entity);
                console.log("Checking entity parent: ", entity.parent?.type, entity.parent);
            }
            
            if (entity.dxfSection?.includes("entities")
            && entity.type === "TEXT"
            && entity.parent?.type === "INSERT"
            && entity.position.y > 5) {

                if (debug) {
                    console.log("Found possible match", entity.text, entity.position, entity);
                }

                let to_filter: DxfParseEntity[] = []
                const siblings = entity?.parent?.children || [];
                to_filter = siblings;

                
                const parts = to_filter.filter((s) => {
                    this.testStringForModule(s, true);
                    return typeof s.ebModule !== "undefined";
                })
                to_filter = parts;

                if (debug) {
                    console.log("Parts:", parts);
                    console.log("Siblings:", siblings);
                    console.log(siblings[0]);
                    console.log(siblings[0].ebModule);
                }

                const entities_positioned_in_legend = parts.filter((v) => {
                    return v.type === "TEXT" && v?.position?.y > 3.5
                })
                if (debug) {
                    console.log("Positioned:", entities_positioned_in_legend);
                }

                if (entities_positioned_in_legend.length > 0) {
                    to_filter = entities_positioned_in_legend;
                }

                // console.log("Parts:", parts)
                const entities_unique_positioned = to_filter.filter((value, index, self) => {
                    return self.findIndex((v) => v.ebModule === value.ebModule) === index;
                });
                if (debug) {
                    console.log("Unique Parts:", entities_unique_positioned);
                }
    
                // console.log("Unique Texts:", unique_texts);
                if (entities_unique_positioned.length === 1) {
                    return entities_unique_positioned[0];
                }
                
                const unique_parts = entities_unique_positioned.filter((v) => {
                    return v.type === "PART" && ! v?.fieldType?.includes("IO BLOCK")
                })
                // console.log("Unique Parts:", unique_parts);
                if (unique_parts.length === 1) {
                    // console.log(parts);
                    return unique_parts[0];
                }
            }
        }
        return undefined;
    }
    
    //////////////////////////////////////////////////////
    // Determine Module Type
    //////////////////////////////////////////////////////
    // Search by location or group for the module model
    //
    determineModuleType() {
        let foundLongModules: DxfParseEntityText[] = [];
        let foundModules: DxfParseEntityText[] = [];
        let foundAdapters: DxfParseEntityText[] = [];
        let foundEntity: DxfParseEntity | undefined;
        
        const debug = false;
        
        if (debug) {
            console.log("Location search method:", this.location_search_method);
        }
        
        // label all modules and put them in a list
        for (const entity of this.parsed.texts) {
            if ( this.testStringForModule(entity, false)) {
                if (this.location_search_method === "ENET" && entity.ebModule?.isAdapter) {
                    // console.log("has adapter", entity);
                    foundAdapters.push(entity);
                }
                // If this is not supposed to be an adatper, ignore all adapters
                else {
                    if (entity.position.x > 17 && entity.position.y > 3.5) {
                        foundLongModules.push(entity);
                    }
                    else if (debug) {
                        console.log("Skipping", entity);
                    }
                }
            }
            else if ( this.testStringForModule(entity, true) ) {
                foundModules.push(entity);
                // console.log("short", entity);
            }
        }
        
        // If this is an adapter, other lookups are harder but not needed usually
        // There's usually only one adapter in the drawing
        if (this.location_search_method === "ENET" ) {
            
            const unique_adapters = foundAdapters.filter((value, index, self) => {
                return self.findIndex((v) => v.ebModule?.description === value.ebModule?.description) === index;
            });
            
            if (debug) {
                console.log("Found Adapters:", foundAdapters);
                console.log("Unique Adapters:", unique_adapters);
            }
            
            if (unique_adapters.length === 1) {
                foundEntity = foundAdapters[0];
                this.module_decision_method = "only adapter found";
            }
            foundLongModules = foundAdapters;
            foundModules = foundModules.filter((v) => {
                return v.ebModule?.isAdapter;
            });
        }
        
        
        
        if (debug) {
            console.log("All long modules in Document: ", foundLongModules);
            console.log("All Modules in Document: ", foundModules);
        }

        if ( ! foundEntity) {
            if ( foundLongModules.length === 0) {
                // filter duplicates of the same module
                const unique_modules = foundModules.filter((value, index, self) => {
                    return self.findIndex((v) => v.ebModule?.description === value.ebModule?.description) === index;
                });
                if (unique_modules.length === 1) {
                    foundEntity = unique_modules[0];
                }
            }
        }
        
        // filter by one or the other
        // if (foundLongModules.length === 0 && foundModules.length > 0) {
        //   foundLongModules = foundModules;
        //   this.module_decision_method = "only long module found"
        // }
        
        if ( ! foundEntity) {
            foundEntity = this.determineModuleTypeByAddressInsertParent();
            if (foundEntity) {
                this.module_decision_method = "address insert parent";

            }
        }
        
        // Try to find module by looking at the rack layout in the bottom left of the schematics
        if (! foundEntity) {
            foundEntity = this.determineModuleTypeRackLabel(foundModules);
            const warn = "Using rack diagram to determine module type"
            this.page.warn += warn + "\n";
            // console.log(warn, foundEntity);
        }
        
        // console.log("Found Entity:", foundEntity)
        
        // See if the short modules have only a single true entity
        // Block inserts can often not actually exist
        if (!foundEntity && foundModules.length > 1) {
            let entities = foundModules.filter((m) => {
                return m.dxfSection === "entities"
            });
            // console.log(entities);
            if (entities.length === 1) {
                foundEntity = entities[0];
                this.module_decision_method = "only one module in entities";
            }
        }
        // console.log("Found Entity:", foundEntity)
        
        // If only a single module was found using one of the two ways, use that
        if (!foundEntity) {
            const unique_long_modules = foundLongModules.filter((value, index, self) => {
                return self.findIndex((v) => v.ebModule === value.ebModule) === index;
            });
            if (unique_long_modules.length === 1) {
                foundEntity = unique_long_modules[0];
                this.module_decision_method = "only unique module in long modules";
            }
            else if (foundLongModules.length === 0 && foundModules.length === 1) {
                foundEntity = foundModules[0];
                this.module_decision_method = "only one module in short modules and no long modules";
            }
        }
        
        // console.log("Found Entity:", foundEntity)
        
        
        if (!foundEntity && foundLongModules.length > 1) {
            // Filter based on whether or not their table entry shows a softID
            let has_table_record = foundLongModules.filter((m) => {
                if (!m.ownerHandle) {return false;}
                const owner_entity = this.parsed.entityHandles[m.ownerHandle];
                const softID = owner_entity?.softID || "0";
                return softID > "0";
            })
            if (has_table_record.length === 1) {
                const warn = "Using table record to filter blocks to ones that are used";
                this.page.warn += warn + "\n";
                const ownerHandle = has_table_record[0]?.ownerHandle;
                // if (ownerHandle) { console.log(warn, this.entityHandles[ownerHandle]) }
                foundEntity = has_table_record[0];
                
                this.module_decision_method = "only module with a table record";
            }
            else {
                // console.log("Has table record", has_table_record);
            }
        }
        // console.log("Found Entity:", foundEntity)
        
        if (!foundEntity && foundLongModules.length > 1) {
            console.log()
        }
        
        // console.log("Found Entity:", foundEntity)
        
        if (!foundEntity && foundLongModules.length > 1) {
            // try to filter based on whether or not they show up in the dictionary
            let in_dict = foundLongModules.filter((m) => {
                const dict_items = this.parsed.dxf?.objects?.DICTIONARY.filter((d) => {
                    return d?.soft_owner.indexOf(m.ownerHandle) > -1
                })
                // console.log("DICT_ITEMS", m.text, m, dict_items);
                return dict_items?.length;
            })
            if (in_dict.length === 1) {
                const warn = "Found module by filtering to what's in the DXF dictionary";
                this.page.warn += warn + "\n";
                // console.log(warn, foundLongModules);
                foundEntity = in_dict[0];
                this.module_decision_method = "only module in dictionary";
            }
            // console.log("Found too many modules by long trigger", foundLongModules.length);
            // console.log("longs: ", foundLongModules);
        }
        
        foundLongModules = DxfPageParse.filterDuplicates(foundLongModules);
        foundModules = DxfPageParse.filterDuplicates(foundModules);
        
        // console.log("Found Entity:", foundEntity)
        
        if (! foundEntity && ! foundModules.length && ! foundLongModules.length) {
            this.page.error += "No recognized module types found in document text\n";
            console.log("No recognized module types found in document text", foundModules);
            return;
        }
        
        // if all short modules found have the same INSERT parent,
        // it's probably the one that's visible
        if ( !foundEntity && foundModules.length >= 1 ) {
            let insertParentHandle: string | undefined;
            let matchedEntity: DxfParseEntityText | undefined;
            let allMatch = true;
            
            for (let m of foundModules) {
                if (m?.parent?.type === "INSERT") {
                    // console.log("Found inserted", m);
                    if (typeof insertParentHandle === "undefined") {
                        insertParentHandle = m.parent.handle;
                        matchedEntity = m;
                    }
                    else {
                        if (insertParentHandle !== m.parent.handle) {
                            // console.log("not all parents match", insertParentHandle, m.parent.handle);
                            allMatch = false;
                            break;
                        }
                        if (matchedEntity?.ebModule?.description !== m.ebModule?.description) {
                            allMatch = false;
                            // console.log("not all module types match", matchedEntity?.ebModule?.description, " === ", m.ebModule?.description);
                        }
                    }
                }
            }
            if (allMatch && matchedEntity) {
                foundEntity = matchedEntity;
                this.module_decision_method = "Shared INSERT parents";
            }
        }
        // console.log("Found Entity:", foundEntity)
        
        // Use the only inserted part if there is only one
        if (! foundEntity ) {
            const insertedParts = foundModules.filter((m) => {
                return m?.parent?.type === "INSERT" && m.type === "PART";
            });
            // Filter by unique fieldType
            const uniqueInsertedParts = insertedParts.filter((module, index, self) => {
                return self.findIndex((m) => {
                    return m.fieldType === module.fieldType;
                }) === index;
            });
            // console.log("Found inserted parts", insertedParts, uniqueInsertedParts);
            if (uniqueInsertedParts.length === 1) {
                foundEntity = insertedParts[0];
                this.module_decision_method = "Only one inserted part";
            }
        }
        
        
        if (! foundEntity) {
            foundModules = DxfPageParse.sortInY( foundModules );
            
            let foundEntity = foundModules.slice(-1)[0];
            let nextEntity = foundModules.slice(-2,-1)[0];
            
            // console.log("Checking for Overlapping", foundEntity, nextEntity, foundModules);
            if (typeof nextEntity !== "undefined") {
                // remove those from block overlay if this one is not alone
                // foundModules = foundModules.filter(m => ! m.dxfSection.includes("block"));
                // foundEntity = foundModules.slice(-1)[0];
                // nextEntity = foundModules.slice(-2,-1)[0];
                
                if ( typeof foundEntity === "object" && DxfPageParse.isNear(foundEntity, nextEntity, 100.0, 0.2)) {
                    this.page.warn += "Overlapping module descriptions.\n"
                    // console.warn("Overlapping modules: ", foundModules);
                    // console.warn(foundEntity, foundEntity?.ebModule?.ignore);
                    // console.warn(nextEntity?.ebModule?.ignore);
                    
                    if ( foundEntity?.ebModule?.ignore ) {
                        if (nextEntity?.ebModule?.ignore !== true) {foundEntity = nextEntity}
                        else if (foundModules[0].ebModule?.ignore !== true) {foundEntity = nextEntity}
                    }
                    // console.log("Overlapping module descriptions.", foundEntity, nextEntity)
                }
            }
        }
        
        // If nothing else works, take a guess at one of the long module names found
        if ( !foundEntity && foundLongModules.length > 1) {
            foundEntity = foundLongModules[ foundLongModules.length - 1];
            // foundEntity = foundLongModules[ 0 ];
            const warn = "Guessing with last long module as last resort"
            this.page.warn += warn + "\n"
            // console.log(warn, foundLongModules);
        }
        
        
        this.moduleEntity = foundEntity;
        
        if (debug) {
            console.log("Used search method: ", this.module_decision_method, foundEntity);
        }
        
        if ( typeof this.moduleEntity === "object") {
            // console.log("io arrow", this.ioArrow);
            // console.log("module entity", this.moduleEntity);
            // console.log("rack label entity", this.rackLabelEntity);
            
            // console.log(this.moduleEntity?.position?.x || this.rackLabelEntity?.position?.x);
            // console.log(this.ioArrow?.position?.x);
            
            this.module = this.moduleEntity?.ebModule;
            // console.log( "FOUND MODULE: - USING MODULE ENTITY ", this.moduleEntity, foundModules, this.module );
            
            this.module = Object.assign({}, this.module);
            return this.module;
        }
        this.page.error += "No known modules recognized\n";
    }
    
    /**
    * Determine Module Type Rack Label
    * @param foundModule - Array of DxfParseEntityText
    * @returns DxfParseEntityText
    * 
    * @description Search for module type based on known
    * unique identifier for the slot number and rack
    * 
    * Module ID is seperated from Type in different elements
    * Search by siblings in group
    */
    determineModuleTypeRackLabel(foundModule: DxfParseEntityText[]) {
        // const moduleStr = "MODULE " + this.slotNumber;
        // Check for AI, AO, DI, DO
        const dido = this.parsed.findTextEntity(new RegExp("^(" + this.rackLetter + this.rackNumber + "[A|D|R][I|O][0]?" + this.slotNumber + "[ |/]?){1,2}$"));
        const module_match = this.parsed.findTextEntity(new RegExp("^MODULE " + this.slotNumber + "[ ]?$"));
        // console.log("Searching for module ", dido, this.slotNumber)
        const found = dido || module_match;
        if ( found ) {
            const module = this.searchForModuleAroundLabel(found.entity);
            
            if (module) {
                this.rackLabelEntity = found.entity;
                // console.log("found", module, entity);
                return module;
            }
        }
        
        // console.log("Failed to find a module");
        return undefined;
    }
    
    /**
    * Search for Module Around Label
    * @param label - DxfParseEntityText
    * @returns DxfParseEntity
    * 
    * Look at the bottom left of the schematic for the module layout
    * Look around the given label to see if there is a module type listed there
    */
    searchForModuleAroundLabel(label: DxfParseEntity): DxfParseEntity | undefined {
        
        const debug = false;
        // if this text element has siblings
        
        if (! label?.parent?.children?.length) {return}
        if ( label.parent.children.length <= 1) {return}
        
        // let siblings = DxfPage.filterDuplicates(label.parent.children);
        let siblings = label.parent.children;
        
        // If expecting adapter, filter to only adapters
        if ( this.location_search_method === "ENET" ) {
            siblings = siblings.filter( sibling => {
                return sibling.ebModule?.isAdapter === true;
            })
        }
        
        if (debug) {  
            console.log(label.text, label, "Siblings", siblings);
        }
        
        // console.log(label, "siblings: ", siblings);
        
        let label_index = -1;
        let prev_from_label: DxfParseEntity | undefined;
        let sibling_module_entities: DxfParseEntity[] = [];
        
        
        // for each sibling in the group
        for (let s in siblings) {
            const sibling = siblings[s];
            // find self in sibling list so the previous one can be used
            
            const isFound =this.testStringForModule(sibling);
            // if (sibling.text?.includes("OB2")) {console.log("SHOULD BE: ", sibling.position, sibling, isFound)}
            if (isFound) {
                sibling_module_entities.push(sibling)
            }
            if (label === sibling) {
                label_index = parseInt(s);
                prev_from_label = sibling_module_entities[ label_index - 1];
                // console.log("Found self in siblings list", sibling_module_entities, label);
            }
        }
        
        if (debug) {
            console.log("Found siblings that have modules", sibling_module_entities)
        }
        
        // console.log("siblings: ", label.text, label.position, sibling_module_entities);
        // if (label_index === -1) {
        //   console.log("Did not find self in sibling list. This is probably an issue.", label);
        // }
        // console.log("Found modules", sibling_module_entities, label_index)
        
        // Self should have been found
        if (sibling_module_entities.length === 1) {
            // Use the most recent found module
            // console.log("Found modules", sibling_module_entities, label_index)
            this.module_decision_method = "Rack label at bottom of schematic, only module sibling found"
            return sibling_module_entities[0];
        }
        else if (sibling_module_entities.length > 1) {
            // search by position with siblings if futher filtering is required
            
            let x = label.position.x;
            let y = label.position.y;
            let pos_filtered = DxfPageParse.boxSelect(sibling_module_entities, {
                xmin: x - .75,
                xmax: x + .75,
                ymin: y - 2,
                ymax: y + 0.25,
            }, false);
            // console.log("Found multiple possible siblings, filtered by position", label.position, bb, pos_filtered, sibling_module_entities);
            if (pos_filtered.length >= 1) {
                // console.log("Found modules filtered by position", pos_filtered);
                
                // if only one UNIQUE module remains, use it
                // filter by ebModule description
                
                if (debug) {
                    console.log("Found modules filtered by position", pos_filtered);
                }
                
                let unique_modules: DxfParseEntity[] = pos_filtered.filter((e, i, a) => {
                    return a.findIndex(t => t.ebModule?.description === e.ebModule?.description) === i;
                });
                if (unique_modules.length === 1) {
                    this.module_decision_method = "Rack label at bottom of schematic, filtered by position with unique name";
                    return unique_modules[0];
                }
                else if (debug) {
                    console.log("Too many modules still remain", unique_modules)
                }
                
                // if (pos_filtered.length > 1) {
                //   console.log("Too many modules still remain", pos_filtered)
                // }
                // return pos_filtered[0];
            }
            // else if (pos_filtered.length === 0) {
            //   pos_filtered = this.boxSelect(this.texts, bb, false);
            //   console.log("Searching all texts, not just siblings", pos_filtered);
            // }
            // if (prev_from_label) {
            //   // guess which label is the right one by being adjacent to the label entity
            //   // console.log("Using the previous sibling from the label", prev_from_label)
            //   return prev_from_label;
            // }
            // else if (sibling_module_entities.length) {
            //   // Could not filter by position or guess by the previous to the label, so guessing the last
            //   // console.log("Guessing the first sibling for module")
            //   return sibling_module_entities[ sibling_module_entities.length - 1 ];
            // }
        }
    }
    
    
    
    /**
    * Find Module IO
    * @description Find all inputs and/or outputs on the modules
    */
    findModuleIO() {
        this.IOpointEntities = [];
        
        if (typeof this.module !== "object") {
            return;
        }
        
        if (typeof this.module.pointReg === "object") {
            // console.log("Searching for IO points", this.module.pointReg);
            // console.log("Found IO points", this.IOpointEntities);
            const results = this.parsed.findTextEntities(this.module.pointReg);
            const entities = results.map( result => result.entity );
            this.IOpointEntities = entities;
        }
        
        // labels must be positioned on screen
        let onScreen = this.IOpointEntities.filter(
            item => {
                const keep = item.position.y > 0.15 && item.position.x > 0.15 && item.position.y < 21.0 && item.position.x < 40;
                if (! keep) { console.log("Removing off-screen point", item.position, item) }
                return keep;
            }
        )
            
        if (this.module.points === this.IOpointEntities.length) {
            // No need to filter if the number of points found is the same as the number of points expected

            // Check for the highest input number in case there are duplicates of the same point
            let highestInput = 0;
            for (let i = 0; i < this.IOpointEntities.length; i++) {
                const point = this.IOpointEntities[i];
                const match = point.text.match(/(\d+)/);
                if (! match) {
                    continue;
                }
                const input = parseInt(match[1]);
                if (input > highestInput) {
                    highestInput = input;
                }
            }

            // console.log("Highest input", highestInput, this.IOpointEntities);
            if (highestInput < this.module.points - 1) {
                this.page.warn += "Fewer IO points on screen than expected.\nAttempting filter.\n";

                let filter = ""
                if (this.module.description.includes("32")) {
                    filter = "32";
                }
                if (this.module.description.includes("16")) {
                    filter = "16";
                }
                if (this.module.description.includes("8")) {
                    filter = "8";
                }
                if (this.module.description.includes("4")) {
                    filter = "4";
                }
                if (this.module.description.includes("2")) {
                    filter = "2";
                }

                if (filter) {
                    const moduleInsert = this.IOpointEntities.filter(
                        (item) => {
                            const keep = item.dxfSection?.includes(filter);
                            // if (! keep) { console.log("Removing point", item.text, item) }
                            return keep;
                        }
                    );
                    if (moduleInsert.length > 0) {
                        this.IOpointEntities = moduleInsert;
                    }
                }
            }
        }
        else if (onScreen.length === 0) {
            this.page.warn += "No IO points on screen.\nUsing full list of points.\n";
        }
        else if (this.module.points && onScreen.length < this.module.points) {
            this.page.warn += "Fewer IO points on screen than expected.\nUsing full list of points.\n";
        }
        else {
            this.IOpointEntities = onScreen;
        }
        
        if (this.IOpointEntities.length < 2) {
            this.page.error = "No point I/O text found in DXF \n";
            return;
        }
        
        // split IO points in half horizontally
        const allPointsBB = DxfPageParse.boundingBox( this.IOpointEntities );
        const pointsSplit = DxfPageParse.splitBBinX( allPointsBB );
        let leftPoints = DxfPageParse.boxSelect( this.IOpointEntities, pointsSplit.l, true);
        let rightPoints = DxfPageParse.boxSelect( this.IOpointEntities, pointsSplit.r, true);
        
        // console.log("Found IO points", this.IOpointEntities, leftPoints, rightPoints)
        
        // Safety modules list twice so remove extras
        if (this.module.pointEvenTrim) {
            if ( this.module.safetyType !== "Safety") {
                console.log("Warning: Module uses even point trim and is not safety");
            }
            leftPoints = DxfPageParse.filterEven( leftPoints );
            rightPoints = DxfPageParse.filterEven( rightPoints );
        }
        
        const totalFoundPoints = leftPoints.length + rightPoints.length;

        // filter down points more if too many were found
        if (this.module.points && this.module.points < totalFoundPoints) {
            
            let expected_module_parent_name = this?.module?.trigger || this.moduleEntity?.name || this.moduleEntity?.text || "";
            
            if (! DxfPageParse.entitiesHaveSameOwner(leftPoints) ) {
                const warn = "Left points I/O do not all share the same owner";
                this.page.warn += warn + "\n";
                // console.log(warn, leftPoints);
                leftPoints = DxfPageParse.filterByOwnerName(leftPoints, expected_module_parent_name, this.module.points / 2);
            }
            if (! DxfPageParse.entitiesHaveSameOwner(rightPoints) ) {
                const warn = "Right points I/O do not all share the same owner"
                this.page.warn += warn + "\n";
                // console.log(warn, rightPoints);
                rightPoints = DxfPageParse.filterByOwnerName(rightPoints, expected_module_parent_name, this.module.points / 2);
            }
        }
        
        // console.log("Found IO points", this.IOpointEntities.length, "Split into", leftPoints.length, "and", rightPoints.length, "points", this.module.points, this.moduleEntity?.name, this.moduleEntity?.text, this.moduleEntity?.position)
        
        
        // left and right column positions will act as starting points for description search
        let rightPos = rightPoints[0]?.position.x;
        let leftPos = leftPoints[0]?.position.x;
        
        if (rightPos > 30) {
            this.page.warn += "Right IO points are too far right.\n";
            // Shift the points to the left
            const adjust = leftPos - 12;
            rightPos = rightPos - adjust;
            leftPos = leftPos - adjust;
        }
        
        // determine which direction the description is from the IO point
        // search for descriptions in that direction
        if (this.module.direction === "in") {
            this.labelRack(leftPoints, 0, leftPos, false);
            this.labelRack(rightPoints, rightPos - leftPos, rightPos, false);
        }
        else if (this.module.direction === "out") {
            this.labelRack(leftPoints, rightPos - leftPos, 0, false);
            this.labelRack(rightPoints, rightPos + (rightPos - leftPos), rightPos - leftPos, false);
        }
        else if (this.module.direction === "in|out") {
            this.labelRack(leftPoints, 0, leftPos, false);
            this.labelRack(rightPoints, (2*leftPos + rightPos)/3, leftPos + rightPos, true);
        }
    }
        
        
    //////////////////////////////////////////////////////
    // LABEL RACK
    //////////////////////////////////////////////////////
    // Given point locations and edges for search
    // Build a list of items to include in the description
    labelRack(pointEntities: DxfParseEntityText[], left: number, right: number, isRightSpecial: boolean) {
        let sortedPoints = DxfPageParse.sortSimilarInY( pointEntities, 4, true );
        sortedPoints = DxfPageParse.filterDuplicates( sortedPoints, 0.75, 0.75 );
        // console.log("Sorted points: ", sortedPoints);
        
        if ( typeof this.module !== "object" ) {
            console.log("Cannot label rack if module is not known");
            return;
        }
        
        // console.log("SORTED POINTS",sortedPoints);
        
        let vspace: number|undefined = undefined; // vertical space between each IO point
        // console.log(sortedPoints);
        for (const es in sortedPoints) {
            const e = parseInt(es);
            if ( e === 0) {continue;}
            const newSpace = sortedPoints[e].position.y - sortedPoints[e-1].position.y
            // console.log( vspace, newSpace );
            
            // Prevent duplicates from throwing off vertical space calculation by skipping them
            if (Math.abs(newSpace) > 0.1) {
                if (typeof vspace === "undefined") {
                    vspace = newSpace;
                }
                else {
                    vspace = (newSpace + vspace) / 2;
                }
                // console.log("vspace", vspace, newSpace, sortedPoints[e].text, sortedPoints[e-1].text);
            }
        }
        vspace = vspace || 4;
        
        // Read up slightly more than down.
        // Labels tend to be above wires.
        const vspaceDown = vspace / 1.6; // was 1.8
        const vspaceUp = vspace - vspaceDown;
        let previous: DxfPageParsePoint | undefined = undefined;
        
        
        
        
        // let lastPointNum: number | undefined = undefined;
        for (let e in sortedPoints) {
            const entity = sortedPoints[e];
            
            // Search with a box select for all texts near this point
            const bounds = {
                xmin: left,
                xmax: right,
                ymin: entity.position.y - (vspaceUp),
                ymax: entity.position.y + (vspaceDown)
            }
            let labels = this.parsed.boxSelect( bounds, false );

            // Append any text that is on multiple entities but actually just several lines of the same label
            // if output, find right most text
            let labels_sorted_in_x = DxfPageParse.sortInX( labels.filter((l) => {
                // hide all line numbers from the left of the page
                return l.text.match(/[A-Z][0-9]{4,6}/) === null;
            }) );
            let possible_label: DxfParseEntityText | undefined = undefined;
            if (this.module.direction === "out" || (this.module.direction === "in|out" && isRightSpecial)) {
                possible_label = labels_sorted_in_x[labels_sorted_in_x.length - 1];
            }
            else if (this.module.direction === "in" || this.module.direction === "in|out") {
                possible_label = labels_sorted_in_x[0];
            }
            if (possible_label) {
                labels.push( ...this.parsed.getProbableNewlineTexts(possible_label, 0.2, 0.25, 0.3, vspace) );
            }


            // if input, find left most text


            const plain = new DxfPageParsePoint(this, this.module, entity, labels, isRightSpecial, bounds)
            if (plain.looksLabelled) {
                previous = plain;
                this.parsedIO.push(plain);
                this.io.push(...plain.parsedIO);
            }
            else if (this.module.safetyType === "Safety" && this.module.pairs) {
                // Extra safety logic to group labels
                // Some inputs have to share labels, if the label is between two points
                if (parseInt(e) % 2 === 0) {
                    bounds.ymax += vspace;
                }
                else {
                    bounds.ymin -= vspace;
                }
                if (this.module.direction === "in") {
                    bounds.xmax = (left + right) / 2;
                }
                if (this.module.direction === "out") {
                    bounds.xmax = (left + right) / 2;
                }

                let safety_labels = this.parsed.boxSelect( bounds, false );

                const safety = new DxfPageParsePoint(this, this.module, entity, safety_labels, isRightSpecial, bounds, previous)
                previous = safety;
                this.parsedIO.push(safety);
                this.io.push(...safety.parsedIO);
            }



            // remove duplicate if there is a duplicate that has a label.
            // This is mostly for OB2EP modules which have two points with the same label
            this.io = this.io.filter(i => {
                return i.labelEntities.length || ! this.io.find(o => o.aliasTo === i.aliasTo && o.labelEntities.length)
            });
        }

        // Sort the labelled points based on point number, not position
        this.io = this.io.sort((a,b) => {
            if (a.direction !== b.direction) {
                return (a.direction > b.direction) ? 1 : -1;
            }
            if (a.pointNum === b.pointNum) {
                return (a.multi > b.multi) ? 1 : -1;
            }
            if (a.pointNum > b.pointNum) {
                return 1;
            }
            else {
                return -1;
            }
        });
    }
    
            
            
    /**
    * Format Name Template
    * 
    * @param name Template for the name
    * @param m RegExp match to fill into the template (optional)
    * 
    * Replaces:
    * {0} with the first match, {1} with the second match, etc
    * {0L} with the first match converted to a letter (1=A, 2=B, etc)
    * {0_} with camelCase converted to under_scores of the first match
    * {0:Cap} with the first match converted to Capitalized
    * {L} rack label
    * {LL} two-letter system label
    * {N} rack number
    * {S} slot number
    * {E} extruder prefix
    * {FILE} file name
    * {P} point number
    * {DESC} description
    */
    formatNameTemplate(name: string, m?: ReturnType<String["match"]>, io?: IO): string {
        // FOUND MATCHING KNOWN IO
        // Replace text templates

        // predetermine how many matches are expected based on the template string name
        // if there aren't enough matches, fill in the rest with empty strings so they get deleted
        const matches_expected = name.match(/{[0-9]+/g);
        let matches_needed = 0;
        if (matches_expected) {
            // find the highest number
            let highest = 0;
            matches_expected.forEach(m => {
                const num = parseInt(m.replace("{", ""));
                if (num > highest) {
                    matches_needed = num;
                }
            });
        }
        matches_needed += 1;

        // fill out the rest of the match array, or create one if necessary
        if (! m) {
            m = new Array(matches_needed).fill("") as RegExpMatchArray;
        }
        else {
            const len = m.length;
            for (let i = len; i < matches_needed; i++) {
                m[i] = "";
            }
        }

        // console.log(m);
        
        // Use matches from regex to replace string template
        // Convert match numbers to letters if #L is specified
        const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        if (Array.isArray(m)) {
            m.forEach((match, idx) => {
                if (idx === 0 ) {return}

                // override undefined matches with empty string so they get deleted
                if (typeof match === "undefined") {
                    match = "";
                }
                name = name.replace("{" +idx+"}", match);
                
                // console.log("match", match, idx, name);
                
                const matchI = parseInt(match);
                if (! isNaN(matchI)) { // Convert 1 to A and 2 to B, etc
                    name = name.replace("{"+idx+"L}", alphabet[matchI-1]);
                }
                else { // fallback to string replacement if already a letter
                    name = name.replace("{"+idx+"L}", match);
                }
                if (typeof match === "string") {
                    name = name.replace("{"+idx+"_}", match.replaceAll(/[^0-9A-Za-z]/ig, "_"));
                    name = name.replace("{"+idx+":Cap}", match.charAt(0).toUpperCase() + match.slice(1).toLowerCase());
                }
            })
        }
        
        let rackLetter = this.rackLetter
        if (rackLetter === "S") {rackLetter = "DS"}
        name = name.replace("{L}", rackLetter);
        name = name.replace("{N}", this.rackNumber.toString());
        name = name.replace("{S}", this.slotNumber.toString());
        name = name.replace("{S:2}", (this.slotNumber+"").padStart(2, '0') );
        name = name.replace("{E}", this.extruderPrefix);
        name = name.replace("{LL}", this.systemLabel);
        name = name.replace("{FILE}", "SchemPg:" + this.page.filename.split('.')[0]);
        if (typeof io === "object") {
            name = name.replace("{P}", (io.pointNum).toString());
            name = name.replace("{P:2}", (io.pointNum+"").padStart(2, '0') );
            name = name.replace("{EVEN}", (io.pointNum % 2).toString());
            name = name.replace("{ODD}", (io.pointNum % 2 === 0).toString());
            name = name.replace("{EVEN+1}", (io.pointNum % 2 + 1).toString());
            name = name.replace("{TYPE}", io.type.toUpperCase());
            name = name.replace("{DIR}", io.direction.toUpperCase());

            // force valid tag name
            
            // Remove any starting underscores
            name = name.replace(/^_+/, '');
            
            // Remove anydouble underscores
            name = name.replace(/_+/g, '_');
        }
        // formatting with module info only
        else {
            name = name.replace("{DIR}", (this.module?.direction || '').toUpperCase());
            name = name.replace("{DESC}",this.module?.description || '')
        }
        
        return name;
    }
}
