
import type {AlarmsXmlTrigger} from "./xml-format-trigger";
import type {AlarmsXmlMessage} from "./xml-format-message";


// https://www.npmjs.com/package/xml-js
// uses compact JSON format
export let alarmsXML = {
    _declaration: {
        _attributes: {
            version: "1.0",
            encoding: "UTF-8"
        }
    },
    alarms: {
        _attributes: {
            version: "1.0",
            product: "{E44CB020-C21D-11D3-8A3F-0010A4EF3494}",
            id: "Alarms",
        },
        alarm: {
            _attributes: {
                "history-size": "8000",
                "capacity-high-warning": "90",
                "capacity-high-high-warning": "99",
                "display-name": "",
                "hold-time": "500",
                "max-update-rate": "1.00",
                "embedded-server-update-rate": "1.00",
                "silence-tag": "",
                "remote-silence-exp": "",
                "remote-ack-all-exp": "",
                "status-reset-tag": "",
                "remote-status-reset-exp": "",
                "close-display-tag": "",
                "remote-close-display-exp": "",
                "use-alarm-identifier": "false",
                "capacity-high-warning-tag": "",
                "capacity-high-high-warning-tag": "",
                "capacity-overrun-tag": "",
                "remote-clear-history-exp": "",
            },
            triggers: {trigger: [] as AlarmsXmlTrigger[]},
            messages: {message: [] as AlarmsXmlMessage[]},
        }
    }
};