import type {KnownIO} from "../types/io";

let knownIoExtruder: Array<KnownIO> = [
	////////////////////////////////////
	// EXTRUDER
	//


	// THERMOCOUPLES

	{
		examples: [
			"ZONE 5V THERMOCOUPLE",
		],
		description: [
			/ZONE ([0-9]+)V? .* VENT .*(TEMPERATURE|THERMOCOUPLE)/i,
			/ZONE ([0-9]+)V .*(TEMPERATURE|THERMOCOUPLE)/i,
		],
		name: "Zone{L}{1}V_TC",
		direction: "in",
		thermocouple: true,
		extruder: true,
	},

	{
		examples: [
			"ZONE BSC1 FT20-160 SCREEN CHANGER INLET BODY TEMPERATURE T/C",
			"ZONE BSC2 FT20-160 SCREEN CHANGER OUTLET BODY TEMPERATURE T/C",
		],
		description: /ZONE ([A-M])[D]?SC([0-9]+)/i,
		name: "Zone{1}SC{2}_TC",
		thermocouple: true,
		direction: "in",
		extruder: true,
	},
	{
		examples: [
			"ZONE ADS1 THERMOCOUPLE TYPE J",
			"ZONE BS1 UPSTREAM FLANGE TEMPERATURE T/C"
		],
		description: /ZONE ([A-M])[D]?S([0-9]+)/i,
		name: "Zone{1}S{2}_TC",
		thermocouple: true,
		direction: "in",
		extruder: true,
	},
	{
		examples: [
			"ZONE A9 THERMOCOUPLE TYPE J", // 18221-s121 downstream
		],
		description: /ZONE ([A-M])([0-9]+)/i,
		name: "Zone{1}S{2}_TC",
		thermocouple: true,
		direction: "in",
		extruder: true,
		downstream: "DS",
	},
	{
		description: /ZONE DS([0-9]+)/i,
		name: "Zone{L}S{1}_TC",
		thermocouple: true,
		direction: "in",
		extruder: true,
	},

	{
		description: /ZONE FB([0-9]+).*/i,
		name: "ZoneFB{1}_TC",
		thermocouple: true,
		direction: "in",
		extruder: true,
	},

	{
		examples: [
			"Y DIE ZONE 1 THERMOCOUPLE TYPE J",
			"ZONE Z1 DIE THERMOCOUPLE",
			"DIE ZONE Y1 THERMOCOUPLE TYPE J"
		],
		description: [
			/ZONE [Z|Y]?([0-9]+).*DIE.*/i,
			/DIE ZONE [Z|Y]?([0-9]+)/i,
		],
		name: "ZoneY{1}_TC",
		thermocouple: true,
		direction: "in",
		extruder: true,
	},

	{
		description: /ZONE ([A-I])([0-9]+)[^V]/i,
		examples: [
			"ZONE A8 SCREEN CHANGER INLET ADAPTER TEMPERATURE T/C"
		],
		name: "Zone{1}{2}_TC",
		thermocouple: true,
		direction: "in",
		extruder: true,
	},
	{
		description: /ZONE ([0-9]+)[^V]/i,
		examples: [
			"ZONE 9 BARREL TEMPERATURE",
			"ZONE 10 SCREEN CHANGER TEMPERATURE",
			"ZONE 22 VALVE BODY 1",
			"ZONE 24 8\" ENTRANCE ADAPTOR",
			"ZONE 1 THERMOCOUPLE",
		],
		name: "Zone{L}{1}_TC",
		thermocouple: true,
		direction: "in",
		extruder: true,
	},

	{
		description: /FEED BARREL TEMP/i,
		examples: [
			"FEED BARREL TEMPERATURE",
		],
		name: "Zone{L}0_TC",
		thermocouple: true,
		direction: "in",
		extruder: true,
	},

	// MELT 2 is the one located at the end of the downstream
	{
		description: [
			/EXTRUDER ([A-H]) MELT (TEMPERATURE|THERMOCOUPLE)/i,
			/([A-H]) MELT (TEMPERATURE|THERMOCOUPLE)/i,
		],
		examples: [
			"A MELT THERMOCOUPLE TYPE J TC1 A",
		],
		name: "{1}EX_Melt2_TC",
		direction: "in",
		downstream: "DS",
		thermocouple: true,
		extruder: true,
	},
	{
		description: /MELT (TEMPERATURE|THERMOCOUPLE)/i,
		name: "{L}EX_Melt2_TC",
		direction: "in",
		downstream: "DS",
		thermocouple: true,
		extruder: true,
	},
	// MELT 1 is the one located on the extruder, not in the downstream
	{
		description: /MELT (TEMPERATURE|THERMOCOUPLE)/i,
		name: "{L}EX_Melt1_TC",
		direction: "in",
		downstream: false,
		thermocouple: true,
		extruder: true,
	},


	{
		examples: [
			"GEAR BOX LUBE OIL TEMPERATURE",
			"GEAR BOX THERMOCOUPLE TYPE J",
		],
		description: /GEAR BOX/i,
		name: "{L}EX_LubeOil_TC",
		direction: "in",
		thermocouple: true,
		extruder: true,
	},

	{
		examples: [
			"OIL STATION COOLING OIL TEMPERATURE",
			"BARREL COOLING OIL TEMPERATURE",
		],
		description: /COOLING OIL TEMP/i,
		name: "{L}EX_Barrel_OilPump_TC",
		direction: "in",
		thermocouple: true,
		extruder: true,
	},
	{
		examples: [
			"COOLING WATER TANK TEMPERATURE T/C",
			"COOLING TANK HIGH TEMPERATURE T/C",
			"WATER VALVE THERMOCOUPLE TYPE J",
		],
		description: [
			/COOLING.*TANK.*TEMPERATURE/i,
			/WATER VALVE/i,
		],
		name: "{L}EX_CoolWater_TC",
		direction: "in",
		thermocouple: true,
		extruder: true,
	},




	//////////////
	// PRESSURE

	{
		examples: [
			"EXTRUDER A GEAR PUMP SUCTION PRESSURE 4-20mA : 0-7500 PSI A2PT",
			"EXTRUDER B GEAR PUMP SUCTION PRESSURE 4-20mA : 0-7500 PSI B2PT",
			"B GEAR PUMP INLET PRESSURE 4-20mA 0-7500 PSI",
		],
		description: [
			/EXTRUDER ([A-H]) GEAR PUMP (SUCTION|INLET) PRESSURE/i,
			/([A-H]) GEAR PUMP (SUCTION|INLET) PRESSURE/i,
		],
		name: "{1}EX_Suction_PressureRaw",
		analog: true,
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"GEAR PUMP SUCTION PRESSURE",
			"GEAR PUMP INLET PRESSURE 4-20mA 0-7500 PSI",
		],
		description: /GEAR PUMP (SUCTION|INLET) PRESSURE/i,
		name: "{L}EX_Suction_PressureRaw",
		analog: true,
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"EXTRUDER C GEAR PUMP SUCTION PRESSURE TRANSMITTER AUTOZERO CONTACTS",
			"EXTRUDER B GEAR PUMP SUCTION PRESSURE TRANSMITTER AUTOZERO CONTACTS",
			"B GEAR PUMP INLET PRESSURE AUTO ZERO",
		],
		description: [
			/([A-H]) GEAR PUMP (SUCTION|INLET) PRESSURE.* AUTO[-| ]?ZERO/i,
		],
		name: "{1}EX_Suction_Zero_Coil",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"GEAR PUMP INLET PRESSURE AUTO ZERO",
			"GEAR PUMP INLET PRESSURE\nAUTO ZERO",
			"AUTO ZERO PRESSURE GEAR PUMP INLET",
		],
		description: [
			/GEAR PUMP (SUCTION|INLET) PRESSURE.*[ |\n]AUTO[-| ]?ZERO/i,
			/AUTO[-| ]?ZERO PRESSURE GEAR PUMP INLET/i,
		],
		name: "{L}EX_Suction_Zero_Coil",
		type: "BOOL",
		direction: "out",
		extruder: true
	},


	{
		examples: [
			"EXTRUDER A GEAR PUMP DISCHARGE PRESSURE 4-20mA : 0-7500 PSI A3PT",
			"EXTRUDER B GEAR PUMP DISCHARGE PRESSURE 4-20mA : 0-7500 PSI B3PT",
			"B GEAR PUMP OUTLET PRESSURE 4-20mA 0-7500 PSI"
		],
		description: /([A-H]) GEAR PUMP (DISCHARGE|OUTLET) PRESSURE/i,
		name: "{1}EX_Discharge_PressureRaw",
		analog: true,
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"GEAR PUMP DISCHARGE PRESSURE",
			"GEAR PUMP OUTLET PRESSURE 4-20mA 0-7500 PSI",
		],
		description: /GEAR PUMP (DISCHARGE|OUTLET) PRESSURE/i,
		name: "{L}EX_Discharge_PressureRaw",
		analog: true,
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"EXTRUDER C GEAR PUMP DISCHARGE PRESSURE TRANSMITTER AUTOZERO CONTACTS",
			"EXTRUDER A GEAR PUMP DISCHARGE PRESSURE TRANSMITTER AUTOZERO CONTACTS",
			"B GEAR PUMP OUTLET PRESSURE AUTO ZERO",
		],
		description: [
			/([A-H]) GEAR PUMP (OUTLET|DISCHARGE) PRESSURE.* AUTO[-| ]?ZERO/i,
		],
		name: "{1}EX_Discharge_Zero_Coil",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"GEAR PUMP OUTLET PRESSURE AUTO ZERO"
		],
		description: [
			/GEAR PUMP (OUTLET|DISCHARGE) PRESSURE.*[ |\n]AUTO[-| ]?ZERO/i,
		],
		name: "{L}EX_Discharge_Zero_Coil",
		type: "BOOL",
		direction: "out",
		extruder: true
	},

	{
		examples: [
			"B GEAR BARREL HEAD PRESSURE 4-20mA 0-7500 PSI",
			"A BARREL HEAD PRESSURE 4-20mA 0-7500"
		],
		description: /([A-I]) BARREL HEAD PRESSURE/i,
		name: "{1}EX_Head_PressureRaw",
		analog: true,
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"BARREL HEAD PRESSURE",
			"BARREL PRESSURE 4-20mA 0-7500 PSI",
			"EXTRUDER C BARREL HEAD PRESSURE 4-20mA : 0-7500 PSI",
		],
		description: /BARREL( HEAD)? PRESSURE/i,
		name: "{L}EX_Head_PressureRaw",
		analog: true,
		direction: "in",
		extruder: true
	},
	
	{
		examples: [
			"B HEAD PRESSURE PRESSURE AUTO ZERO", // yeah, it's doubled...
			"EXTRUDER A BARREL HEAD PRESSURE TRANSDUCER AUTO-ZERO CALIBRATION TO",
		],
		description: [
			/(^| )([A-K]) (BARREL )?HEAD PRESSURE.* AUTO[ |-]ZERO/i,
		],
		name: "{2}EX_Head_Zero_Coil",
		type: "BOOL",
		direction: "out",
		extruder: true,
	},
	{
		examples: [
			"EXTRUDER C BARREL HEAD PRESSURE TRANSMITTER AUTOZERO CONTACTS",
			"AUTO ZERO PRESSURE BARREL",
			"BARREL PRESSURE AUTO ZERO"
		],
		description: [
			/BARREL( HEAD)? PRESSURE.*[ |\n]AUTO[ |-]?ZERO/i,
			/AUTO[-| ]?ZERO PRESSURE BARREL/i,
		],
		name: "{L}EX_Head_Zero_Coil",
		type: "BOOL",
		direction: "out",
		extruder: true
	},

	{
		description: /STATIC MIXER DISCHARGE PRESSURE/i,
		name: "{L}EX_Melt_PressureRaw",
		analog: true,
		direction: "in",
		extruder: true
	},
	{
		description: /STATIC MIXER DISCHARGE PRESSURE.* AUTO[ |-]?ZERO/i,
		name: "{L}EX_Melt_Zero_Coil",
		type: "BOOL",
		direction: "out",
		extruder: true
	},



	{
		description: /VENT ([0-9]+) VACUUM TRANSDUCER/i,
		name: "{L}EX_VacPump_Vent{1}_Pressure_Raw",
		analog: true,
		direction: "in",
		extruder: true
	},
	{
		description: /VENT ([0-9]+) VACUUM .* AUTO[ |-]?ZERO/i,
		name: "{L}EX_VacPump_Vent{1}_Pressure_ZeroCoil",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	
	{
		// should be below other auto-zero coils
		description: /AUTO[-]?ZERO CALIBRATION/i,
		examples: [
			"EXTRUDER B AUTOZERO CALIBRATION CONTACTOR",
			"PRESSURE TRANSMITTER AUTOZERO CALIBRATION CONTACTOR",
		],
		name: "{L}EX_Pressure_Zero_Coil",
		type: "BOOL",
		direction: "out",
		extruder: true
	},



	
	////////////////
	// ANALOG CURRENT

	///////////////////
	// DOWNSTREAM CURRENT
	{
		description: [
			/ZONE [A-M]?FB([0-9]+)([A-C]?) .* HEATER CURRENT H([1|3])/i
		],
		examples: [
			"ZONE FB1 UFI FEEDBLOCK HEATER CURRENT H1 4-20mA : 0-50A",
			"ZONE FB1 FEEDBLOCK ZONE 1 HEATER CURRENT H1 4-20mA : 0-50A"
		],
		name: "ZoneFB{1}{2}_Heat_CurrentH{3}",
		analog: true,
		direction: "in",
		extruder: true,
	},

	{
		description: /ZONE ([A-M])[D]?S([0-9]+)([A-C]?) .* HEATER CURRENT H([1|3])/i,
		examples: [
			"ZONE BDS5 EXTRUDER B EH-80 SCREEN CHANGER SLIDE PLATE HALF (CYLINDER END) HEATER CURRENT H1 4-20mA : 0-50A",
			"ZONE BDS1 EXTRUDER B BARREL FLANGE HEATER CURRENT H1 4-20mA : 0-50A",
			"ZONE BDS2 EXTRUDER B GNEUSS RSF175 SCREEN CHANGER ZONE 1 HEATER CURRENT H1 4-20mA : 0-50A",
			"ZONE BDS4a EXTRUDER B GNEUSS RSF175 SCREEN CHANGER ZONE 3 (INLET BLOCK) HEATER CURRENT H1 4-20mA : 0-50A",
			"ZONE AS1 GEAR PUMP VALVED ADAPTER HEATER CURRENT H1 4-20mA : 0-50A"
		],
		name: "Zone{1}S{2}{3}_Heat_CurrentH{4}",
		analog: true,
		direction: "in",
		extruder: true,
	},

	
	{
		description: /ZONE DS([0-9]+)([A-C]?) .* HEATER CURRENT H([1|3])/i,
		examples: [
			"ZONE BDS1 SCREEN CHANGER INLET ADAPTER HEATER CURRENT H1 4-20mA : 0-50A",
			"ZONE DS1 SCREEN CHANGER INLET ADAPTER HEATER CURRENT H1 4-20mA : 0-50A",
		],
		name: "Zone{L}S{1}{2}_Heat_CurrentH{3}",
		analog: true,
		direction: "in",
		extruder: true,
	},

	
	///////////////////
	// DIE CURRENT
	{
		description: /ZONE Y([0-9]+) AMP METER/i,
		examples: [
			"ZONE Y1 AMP METER 0-50A",
		],
		name: "ZoneY{1}_Heat_CurrentH1",
		analog: true,
		direction: "in",
		extruder: true,
	},
	{
		description: /ZONE [Z|Y]([0-9]+) .*DIE.* HEATER CURRENT H([1|3])/i,
		examples: [
			"ZONE Z1 DIE ZONE #1 BODY HEATERS HEATER CURRENT H1 4-20mA : 0-50A",
			"ZONE Y7 DIE ZONE #7 HEATER CURRENT H1 4-20mA : 0-50A",
		],
		name: "ZoneY{1}_Heat_CurrentH{2}",
		analog: true,
		direction: "in",
		extruder: true,
	},

	
	{
		description: /ZONE [Z]?([0-9]+) .* CURRENT H([1|3])/i,
		name: "Zone{L}{1}_Heat_CurrentH{2}",
		analog: true,
		direction: "in",
		extruder: true,
	},

	
	{
		examples: [
			"ZONE A1 AMP METER 0-50A",
		],
		description: [
			/ZONE ([A-M])([0-9]+) AMP METER/i,
		],
		name: "Zone{1}S{2}_Heat_CurrentH1",
		analog: true,
		direction: "in",
		downstream: "DS",
		extruder: true,
	},

	
	{
		examples: [
			"ZONE ASC1 MAAG CSC-176 SCREEN CHANGER TOP HOUSING HEATER CURRENT H1 4-20mA : 0-50A CURRENT",
		],
		description: /ZONE ([A-I])SC([0-9]+) .* CURRENT H([1|3])/i,
		name: "Zone{1}SC{2}_Heat_CurrentH{3}",
		analog: true,
		direction: "in",
		extruder: true,
	},
	{
		examples: [
			"ZONE A1 BARREL HEATER CURRENT H1 4-20mA : 0-50A",
		],
		description: [
			/ZONE ([A-I])([0-9]+) .* CURRENT H([1-3])/i,
		],
		name: "Zone{1}{2}_Heat_CurrentH{3}",
		analog: true,
		direction: "in",
		extruder: true,
	},
	{
		examples: [
			"ZONE 13 45 ANGLE BLOCK CURRENT",
			"ZONE 1 AMP METER 0-50A MAIN ENCL"
		],
		description: [
			/ZONE [Z]?([0-9]+) .* CURRENT/i,
			/ZONE [Z]?([0-9]+) AMP METER/i,
		],
		name: "Zone{L}{1}_Heat_CurrentH1",
		analog: true,
		direction: "in",
		extruder: true,
	},

	// MAIN BARREL
	{
		examples: [
			"ZONE 4V BARREL VENT ZONE HEATER CURRENT H1 4-20mA : 0-50A 4VCT",
		],
		description: [
			/ZONE [A-I]?([0-9]+).*VENT.* HEATER CURRENT H([1|3])/i,
		],
		name: "Zone{L}{1}V_Heat_CurrentH{2}",
		analog: true,
		direction: "in",
		extruder: true,
	},
	{
		examples: [
			"ZONE 5V AMP METER 0-50A AMP METER 5VCT",
		],
		description: [
			/ZONE ([0-9]+)V AMP METER/i,
		],
		name: "Zone{L}{1}V_Heat_CurrentH1",
		analog: true,
		direction: "in",
		extruder: true,
	},
	{
		description: /ZONE ([0-9]+)([A-H]?) .*HEATER CURRENT H([1|3])/i,
		examples: [
			"ZONE 12a MAAG EX-140 GEAR PUMP HEATER CURRENT H1 4-20mA : 0-50A",
			"ZONE 9 BARREL HEATER CURRENT H1 4-20mA : 0-50A",
		],
		name: "Zone{L}{1}{2}_Heat_CurrentH{3}",
		analog: true,
		direction: "in",
		extruder: true,
	},




	// SSR
	{
		description: /ZONE ([A-M])SC([0-9]+)([a-e]?)[^V].*(SOLID STATE RELAY|SSR)/i,
		examples: [
			"ZONE BSC1 FT20-160 SCREEN CHANGER INLET BODY SOLID STATE RELAY (30A)",
			"ZONE BSC3 FT20-160 SCREEN CHANGER SLIDE PLATE 1 SOLID STATE RELAY (30A)",
		],
		name: "Zone{1}SC{2}{3}_Heat_SSR",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		description: /ZONE ([A-M])[D]?S([0-9]+)([a-e]?)[^V].*(SOLID STATE RELAY|SSR)/i,
		examples: [
			"ZONE BDS5 EXTRUDER B EH-80 SCREEN CHANGER SLIDE PLATE HALF (CYLINDER END) SOLID STATE RELAY (30A)",
			"ZONE BDS6 EXTRUDER B GEAR PUMP ADAPTER SOLID STATE RELAY (30A)",
		],
		name: "Zone{1}S{2}{3}_Heat_SSR",
		type: "BOOL",
		direction: "out",
		extruder: true
	},

	{
		examples: [
			"ZONE A5V HEAT ON SSR",
		],
		description: /ZONE ([A-J])([0-9]+)V HEAT.* (SOLID STATE RELAY|SSR)/i,
		name: "Zone{1}{2}V_Heat_SSR",
		type: "BOOL",
		direction: "out",
		extruder: true
	},

	{
		examples: [
			"ZONE 5 BARREL VENT STACK SOLID STATE RELAY (30A)",
			"ZONE 5V HEAT ON SSR",
		],
		description: [
			/ZONE ([0-9]+)( BARREL )?V.*(SOLID STATE RELAY|SSR)/i,
		],
		name: "Zone{L}{1}V_Heat_SSR",
		type: "BOOL",
		direction: "out",
		extruder: true
	},

	{
		examples: [
			"ZONE AZ1 HEAT ON SSR",
		],
		description: [
			/ZONE ([A-I])Z?([0-9]+)([A-J]?) HEAT.* (SOLID STATE RELAY|SSR)/i,
		],
		name: "Zone{1}S{2}{3}_Heat_SSR",
		type: "BOOL",
		direction: "out",
		downstream: "DS",
		extruder: true
	},
	{
		examples: [
			"ZONE AZ1 HEAT ON SSR",
			"ZONE A8 SCREEN CHANGER INLET FLANGE SOLID STATE RELAY (30A)",
		],
		description: [
			/ZONE ([A-I])Z?([0-9]+)([A-J]?).* (SOLID STATE RELAY|SSR)/i,
		],
		name: "Zone{1}{2}{3}_Heat_SSR",
		type: "BOOL",
		direction: "out",
		extruder: true
	},

	{
		examples: [
			"ZONE 5 BARREL SOLID STATE RELAY (30A)",
			"ZONE 12b MAAG EX-140 GEAR PUMP SOLID STATE RELAY (60A)",
			"Zone 14 FeedBlock FB1 Solide State Relay",
			"ZONE 3 HEAT ON SSR",
			"ZONE AZ1 HEAT ON SSR",
			"FUTURE ZONE 14 HEAT ON SSR", // 18122-b152
			// Don't match with DIE ZONE 3 HEAT ON SSR
		],
		description: [
			/^ZONE ([0-9]+)([A-G]*)[^V].*(SOLID STATE RELAY|SSR)/i,
			/^ZONE ([0-9]+)([A-G]*) HEAT ON (SOLID STATE RELAY|SSR)/i,
			/^FUTURE ZONE ([0-9]+)([A-G]*) HEAT ON (SOLID STATE RELAY|SSR)/i,
		],
		name: "Zone{L}{1}{2}_Heat_SSR",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		description: /ZONE [A-M]?[D]?S([0-9]+).*(SOLID STATE RELAY|SSR)/i,
		name: "Zone{L}S{1}_Heat_SSR",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"ZONE FB1 SOLID STATE RELAY", // made up
			"ZONE DB1 DISTRIBUTION BLOCK ZONE 1 SOLID STATE RELAY (30A)",
		],
		description: /ZONE (FB|DB)([0-9]+).*(SOLID STATE RELAY|SSR)/i,
		name: "ZoneY{1}{2}_Heat_SSR",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"ZONE Z3 DIE ZONE #3 BODY HEATERS SOLID STATE RELAY (30A)",
			"DIE ZONE 1 HEAT ON SSR",
			"ZONE Y1 HEAT ON SSR",
			"ZONE Y1 DIE ZONE #1 BODY HEATERS SOLID STATE RELAY (30A) Y1SSR"
		],
		description: [
			/ZONE [Z|Y]?([0-9]+).*DIE .*(SSR|SOLID STATE RELAY|HEATER)/i,
			/DIE ZONE [Z|Y]?([0-9]+).* (SSR|SOLID STATE RELAY|HEATER)/i,
			/ZONE Y([0-9]+).* (SSR|SOLID STATE RELAY|HEATER)/i,
		],
		name: "ZoneY{1}_Heat_SSR",
		type: "BOOL",
		direction: "out",
		extruder: true
	},

	// BLOWER MOTORS
	{
		examples: [
			"ZONE 1 BARREL HEATER BLOWER MOTOR SOLID STATE CONTACTOR",
			"ZONE 1 COOLING MOTOR SSC TO ON",
			"ZONE 3 COOLING MOTOR\nSSC TO ON"
		],
		description: [
			// /ZONE ([0-9]+) BARREL HEATER BLOWER MOTOR SOLID STATE CONTACTOR/i,
			/ZONE [A-I]?Z?([0-9]+) .*(COOLING|BLOWER) MOTOR[ |\n](SSC|SOLID STATE CONTACTOR)/,
		],
		name: "Zone{L}{1}_Cool_Blower_Coil",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"ZONE 1 BARREL HEATER BLOWER MOTOR OVERLOAD",
			"ZONE 1 BARREL HEATER BLOWER MOTOR OL-AUX",
			"ZONE 7 BARREL HEATER BLOWER MOTOR OL-AUX",
			"ZONE 1 BARREL HEATER BLOWER MOTOR OL-AUX",
			"ZONE AZ1 COOLING SSC FAULT",
			"ZONE A1 BARREL HEATER BLOWER MOTOR 11M-SSC AUX 11M-SSC AUX 4030847 A1:DI:1300",
			"ZONE A9 BARREL HEATER BLOWER MOTOR 19M-SSC AUX 19M-SSC AUX",
		],
		description: [
			/ZONE [A-I]?Z?([0-9]+) BARREL HEATER BLOWER MOTOR.* (OVERLOAD|OL-AUX|AUX)/i,
			/ZONE ([0-9]+) BARREL HEATER BLOWER MOTOR OL/i,
			/ZONE [A-I]?Z?([0-9]+) COOLING SSC FAULT/i,
		],
		name: "Zone{L}{1}_Cool_Blower_OVLD",
		type: "BOOL",
		direction: "in",
		extruder: true
	},


	// SAFETY INPUTS
	{
		description: /EXTRUDER ([A-H]).* DOWNSTREAM EMERGENCY STOP CIRCUIT/i,
		examples: [
			"EXTRUDER B DOWNSTREAM EMERGENCY STOP CIRCUIT",
		],
		name: "{1}EX_DS_Estop_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		extruder: true
	},
	{
		description: /DOWNSTREAM( A\/B)? EMERGENCY STOP CIRCUIT/i,
		examples: [
			"DOWNSTREAM EMERGENCY STOP CIRCUIT",
			"DOWNSTREAM A/B EMERGENCY STOP CIRCUIT"
		],
		name: "DS_Estop_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		extruder: true
	},
	{
		description: [
			/EXTRUDER.*EMERGENCY STOP CIRCUIT/,
			/EMERGENCY STOP SAFETY INPUT/i
		],
		examples: [
			"EXTRUDER B EMERGENCY STOP CIRCUIT",
			"EMERGENCY STOP SAFETY INPUT DUAL CHANNEL"
		],
		name: "{L}EX_Estop_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		extruder: true
	},

	{
		examples: [
			"EXTRUDER B MAGNETIC DRAWER KEYED SAFETY SWITCH KSS",
			"EXTRUDER A MAGNET DRAWER KEYED SAFETY SWITCH SWITCH PROVIDED BY CUSTOMER",
			"MAGNETIC DRAWER INPUT CH1",
			"MAGNETIC DRAWER SAFETY INPUT"
		],
		description: [
			/EXTRUDER .* MAGNET.* DRAWER KEYED SAFETY SWITCH/i,
			/MAGNETIC DRAWER (SAFETY )?INPUT/i
		],
		name: "{L}EX_FeedMagDrawer_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		extruder: true
	},
	{
		description: /EXTRUDER.*(PLC CONTROL|MAIN CONTROL|BARREL) ENCLOSURE E-STOP/i,
		examples: [
			"EXTRUDER B BARREL ENCLOSURE E-STOP PBL",
			"EXTRUDER B MAIN CONTROL ENCLOSURE E-STOP",
			"EXTRUDER B PLC CONTROL ENCLOSURE E-STOP PBL",
		],
		name: "{L}EX_Enclosure_Estop_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		extruder: true
	},
	{
		examples: [
			"EXTRUDER A BARREL ENCLOSURE E-STOP PBL INDICATOR",
		],
		description: /EXTRUDER.*(PLC CONTROL|MAIN CONTROL|BARREL) ENCLOSURE E-STOP.* IND/i,
		name: "{L}EX_Enclosure_Estop_PBL",
		type: "BOOL",
		direction: "out",
		safetyType: "Standard",
	},


	{
		examples: [
			"EXTRUDER A GEARBOX E-STOP PBL",
			"EXTRUDER B GEAR BOX E-STOP PBL",
		],
		description: /EXTRUDER.* GEAR[ ]?BOX E-STOP.*/i,
		name: "{L}EX_Gearbox_Estop_PB",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
	},
	{
		examples: [
			"EXTRUDER A GEARBOX E-STOP PBL INDICATOR",
			"EXTRUDER B GEAR BOX E-STOP PBL INDICATOR",
		],
		description: /EXTRUDER.* GEAR[ ]?BOX E-STOP.* IND/i,
		name: "{L}EX_Gearbox_Estop_PBL",
		type: "BOOL",
		direction: "out",
		safetyType: "Standard",
	},

	{
		examples: [
			"EXTRUDER A DRIVE ENCLOSURE E-STOP PBL INDICATOR",
		],
		description: /EXTRUDER.* DRIVE ENCLOSURE E-STOP.* IND/i,
		name: "{L}EX_Drive_Estop_PBL",
		type: "BOOL",
		direction: "out",
	},


	{
		description: /BLENDER.* E-STOP/i,
		examples: [
			"EXTRUDER B BLENDER SYSTEM E-STOP",
		],
		name: "{L}EX_Blender_Estop_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		extruder: true
	},


	// SAFETY OUTPUTS
	{
		examples: [
			"EXTRUDER B GEAR PUMP DRIVE SAFE TORQUE-OFF SIGNAL",
			"EXTRUDER B GEAR PUMP AC DRIVE SAFE TORQUE-OFF SIGNAL",
			"EXTRUDER A GEAR PUMP AC DRIVE STO",
			"EXTRUDER B GEAR PUMP AC DRIVE STO",
			"SAFETY OUTPUT MAIN ENCL TO A GEAR PUMP DRIVE",
			"SAFETY OUTPUT TO A GEAR PUMP DRIVE",
		],
		description: [
			/EXTRUDER ([A-H]).*GEAR PUMP.* (SAFE TORQUE[-| ]OFF|STO)/i,
			/SAFETY OUTPUT.* TO ([A-H]) GEAR PUMP/i,
		],
		name: "{1}GP_Drive_STO_Ch{EVEN+1}",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		extruder: true,
	},
	{
		examples: [
			"GEAR PUMP AC DRIVE SAFE TORQUE-OFF SIGNAL",
			"SAFETY OUTPUT TO GEAR PUMP DRIVE",
		],
		description: [
			/EXTRUDER.*(GEAR|PUMP).*SAFE TORQUE[-| ]OFF/i,
			/GEAR PUMP.* SAFE TORQUE[-| ]OFF/i,
			/SAFETY.* GEAR PUMP DRIVE/i,
		],
		name: "{L}GP_Drive_STO_Ch{EVEN+1}",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		extruder: true
	},
	{
		examples: [
			"EXTRUDER DRIVE SAFE-TORQUE-OFF SIGNAL",
			"EXTRUDER B MAIN DRIVE AC DRIVE SAFE TORQUE-OFF SIGNAL",
			"SAFETY OUTPUT TO EXTRUDER DRIVE ENCL",
			"EXTRUDER A AC DRIVE STO CH. 1 SAFETY RELAY"
		],
		description: [
			/EXTRUDER.* DRIVE .*SAFE[-| ]TORQUE[-| ]OFF/i,
			/SAFETY.* TO EXTRUDER DRIVE/i,
			/EXTRUDER [A-G] AC DRIVE STO/i,
		],
		name: "{L}EX_Drive_STO_Ch{EVEN+1}",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		extruder: true
	},

	{
		examples: [
			"MAIN ENCL\nFEED ENABLE",
		],
		description: [
			/FEED ENABLE/i,
		],
		name: "{L}EX_FeedEnable_Coil",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		extruder: true
	},
	{
		examples: [
			"COLOR FEEDER INTERPOSING RELAY", // 18199-a145
		],
		description: [
			/COLOR FEEDER.* RELAY/i,
		],
		name: "{L}EX_FeedColor_Coil",
		type: "BOOL",
		direction: "out",
		safetyType: "Standard",
		extruder: true
	},

	{
		examples: [
			"HOPPER BLOWER MOTOR MMSP AUX", // 18199-b142
		],
		description: [
			/HOPPER BLOWER MOTOR MMSP/i,
		],
		name: "{L}EX_Feed_HopperBlower_MMSP",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		extruder: true
	},
	{
		examples: [
			"HOPPER BLOWER MOTOR CONTACTOR AUX", // 18199-b142
		],
		description: [
			/HOPPER BLOWER MOTOR CONT/i,
		],
		name: "{L}EX_Feed_HopperBlower_Aux",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		extruder: true
	},
	{
		examples: [
			"HOPPER BLOWER MOTOR", // 18199-b142
		],
		description: [
			/HOPPER BLOWER MOTOR/i,
		],
		name: "{L}EX_Feed_HopperBlower_Coil",
		type: "BOOL",
		direction: "out",
		safetyType: "Standard",
		extruder: true
	},


	{
		
		description: /EXTRUDER [A-G] GEARBOX E-STOP PBL/i,
		examples: [
			"EXTRUDER A GEARBOX E-STOP PBL",
		],
		name: "{L}EX_Gearbox_Estop_PB",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		extruder: true,
	},
	{
		
		description: /EXTRUDER .* FEED THROAT E-STOP/i,
		examples: [
			"EXTRUDER B FEED THROAT E-STOP PBL",
		],
		name: "{L}EX_FThroat_Estop_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		extruder: true
	},
	{
		description: /EXTRUDER.*DRIVE ENCLOSURE E-STOP/i,
		examples: [
			"EXTRUDER B DRIVE ENCLOSURE E-STOP PBL",
		],
		name: "{L}EX_Drive_Estop_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		extruder: true
	},

	{
		examples: [
			"EXTRUDER A E-STOP RELAY 1",
		],
		description: [
			/EXTRUDER ([A-M]) E-STOP RELAY ([0-9]+)/i,
		],
		name: "{1}EX_EStop{2}_Coil",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		extruder: true
	},
	{
		examples: [
			"DOWNSTREAM E-STOP RELAY 1 ESR-S1",
		],
		description: [
			/E-STOP RELAY ([0-9]+)/i,
		],
		name: "{L}EX_EStop{1}_Coil",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		extruder: true
	},
	{
		examples: [
			"MAIN ENCL\nE-STOP SWITCHED",
			"E-STOP SWITCHED MAIN ENCL E-STOP SWITCHED ESR-B1"
		],
		description: [
			/MAIN ENCL.*[\n]?E-STOP SWITCHED/i,
			/E-STOP SWITCHED/i,
		],
		name: "{L}EX_EStop{S}_Coil",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		extruder: true
	},
	{
		description: /EXTRUDER ([A-M]) E-STOP RELAY/i,
		name: "{1}EX_EStop{EVEN+1}_Coil",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		extruder: true
	},

	{
		description: /EXTRUDER [A-M] DOWNSTREAM E-STOP RELAY/i,
		examples: [
			"EXTRUDER B DOWNSTREAM E-STOP RELAY",
		],
		name: "{L}EX_DS_EStop{EVEN+1}_Coil",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		extruder: true
	},




	{
		description: /EXTRUDER.* MAGNETIC DRAWER SAFETY RELAY/i,
		examples: [
			"EXTRUDER B MAGNETIC DRAWER SAFETY RELAY",
		],
		name: "{L}EX_FeedMagDrawer_Coil",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		extruder: true
	},
	{
		description: /EXTRUDER ([A-I]) MAGNETIC DRAWER SAFETY RELAY/i,
		examples: [
			"EXTRUDER B MAGNETIC DRAWER SAFETY RELAY FEEDBACK",
		],
		name: "{L}EX_FeedMagDrawer_RelayAuxEDM",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		extruder: true
	},


	{
		
		examples: [
			"E-STOP RELAYS",
			"EXTRUDER G E-STOP RELAY OK",
		],
		description: /E-STOP RELAY/i,
		name: "{L}EX_Estop_RelayAuxEDM",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		extruder: true
	},

	{
		
		examples: [
			"DOWNSTREAM E-STOP RELAY 1 SAFETY RELAY FEEDBACK ESR-S1",
			"DOWNSTREAM E-STOP RELAY 2 SAFETY RELAY FEEDBACK ESR-S2",
			"EXTRUDER B DOWNSTREAM E-STOP RELAY FEEDBACK ESR-B3",
		],
		description: [
			/DOWNSTREAM E-STOP RELAY[ ]?([0-9]?)/i,
		],
		name: "{L}EX_Estop_DS_Relay{1:Cap}AuxEDM",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		extruder: true
	},
	{
		
		examples: [
			"E-STOP RELAYS",
			"EXTRUDER G E-STOP RELAY OK",
			"E-STOP RELAY EDM", // 18122-b161
			"E-STOP EDM SAFETY INPUT MAIN ENCL E-STOP",
			"EXTRUDER B E-STOP RELAY FEEDBACK",
		],
		description: [
			/E-STOP RELAY[ ]?([0-9]?)/i,
			/E-STOP EDM.* ([A-Z]+) ENCL/i,
		],
		name: "{L}EX_Estop_Relay{1:Cap}AuxEDM",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		extruder: true
	},

	{
		description: /EXTRUDER.* JUNCTION BOX E-STOP/i,
		name: "{L}EX_Junction_Estop_PB",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		extruder: true
	},
	{
		examples: [
			"EXTRUDER G MOTOR BASE E-STOP PBL",
		],
		description: /EXTRUDER.* MOTOR BASE E-STOP/i,
		name: "{L}EX_Motor_Base_Estop_PB",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		extruder: true
	},




	{
		description: /EXTRUDER ([A-H]) DOWNSTREAM .* ENCLOSURE E-STOP/i,
		examples: [
			"EXTRUDER B DOWNSTREAM MAIN CONTROL ENCLOSURE E-STOP",
		],
		name: "{1}DS_Enclosure_Estop_PB",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		extruder: true
	},
	
	{
		description: /DOWNSTREAM (CONTROL )?ENCLOSURE E-STOP/i,
		examples: [
			"EXTRUDERS A/B DOWNSTREAM CONTROL ENCLOSURE E-STOP PBL",
			"DOWNSTREAM CONTROL ENCLOSURE E-STOP PBL",
			"DOWNSTREAM ENCLOSURE E-STOP PBL"
		],
		name: "DS_Enclosure_Estop_PB",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		extruder: true
	},
	{
		description: /DOWNSTREAM (CONTROL )?ENCLOSURE E-STOP/i,
		examples: [
			"EXTRUDERS A/B DOWNSTREAM CONTROL ENCLOSURE E-STOP PBL",
			"DOWNSTREAM CONTROL ENCLOSURE E-STOP PBL",
			"DOWNSTREAM ENCLOSURE E-STOP PBL"
		],
		name: "DS_Enclosure_Estop_PBL",
		type: "BOOL",
		direction: "out",
		safetyType: "Standard",
		extruder: true
	},
	
	{
		description: /DOTECO.* ENCLOSURE E-STOP/i,
		name: "{L}EX_Doteco_Estop",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		extruder: true
	},
	{
		examples: [
			"EXTRUDER B BLENDER SYSTEM E-STOP PBL",
		],
		description: /BLENDER.* E-STOP/i,
		name: "{L}EX_Blender_Estop",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		extruder: true
	},


	{
		description: /DRIVE ENCL.* E-STOP/i,
		name: "{L}EX_Drive_Estop",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		extruder: true
	},
	{
		examples: [
			"EXTRUDER B BARREL ENCLOSURE E-STOP PBL",
			"EXTRUDER B MAIN CONTROL ENCLOSURE E-STOP",
			"MAIN ENCL E-STOP PRESSED",
			"EXTRUDER B PLC CONTROL ENCLOSURE E-STOP PBL",
		],
		description: /(MAIN|BARREL|PLC CONTROL).*ENCL.* E-STOP/i,
		name: "{L}EX_Enclosure_Estop_PB",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		extruder: true
	},


	{
		description: /FEED THROAT E-STOP/i,
		examples: [
			"EXTRUDER B FEED THROAT E-STOP PBL",
			"FEED THROAT E-STOP PRESSED",
		],
		name: "{L}EX_FThroat_Estop_PB",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		extruder: true
	},
	{
		description: /FEED THROAT E-STOP/i,
		examples: [
			"EXTRUDER B FEED THROAT E-STOP PBL INDICATOR",
		],
		name: "{L}EX_FThroat_Estop_PBL",
		type: "BOOL",
		direction: "out",
		safetyType: "Standard",
		extruder: true
	},
	
	{
		description: /FEED THROAT COOLING WATER SOL/i,
		examples: [
			"FEED THROAT COOLING WATER SOLENOID VALVE",
		],
		name: "{L}EX_FThroat_CoolSol",
		type: "BOOL",
		direction: "out",
		extruder: true
	},


	{
		description: /E-STOP RESERVED/i,
		name: "{L}EX_Estop_Reserved_{N}_{S}_{P}",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		description: /RESERVED INPUT/i,
		name: "{L}EX_Reserved_{N}_{S}_{P}",
		type: "BOOL",
		direction: "in",
		extruder: true
	},

	


	{
		examples: [
			"LUBE OIL PRESSURE LOW ALARM",
			"EXTRUDER GEARBOX MINIMUM OIL PRESSURE SWITCH",
			"GEAR BOX LUBE INLET MIN. PRESSURE OK / NOT LOW",
			"THRUST BEARING LOW PRESSURE SWITCH",
			"GEARBOX LOW PRESSURE",
		],
		description: [
			/(LUBE|GEAR[ ]?BOX).*PRESSURE LOW/i,
			/(LUBE|GEAR[ ]?BOX).*LOW PRESSURE/i,
			/(LUBE|GEAR[ ]?BOX).*MIN.*PRESSURE/i,
			/THRUST BEARING LOW PRESSURE/i,
		],
		name: "{L}EX_Lube_Pressure_AboveMin",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"EXTRUDER GEARBOX MAXIMUM OIL PRESSURE SWITCH",
			"GEAR BOX LUBE OUTLET MAX. PRESSURE OK / NOT BLOCKED",
			"EXTRUDER LUBE PUMP HIGH PRESSURE SWITCH", //18059-b137
			"THRUST BEARING HIGH PRESSURE SWITCH",
			"GEARBOX HGIH PRESSURE",
		],
		description: [
			/GEAR[ ]?BOX.* (MAX|HIGH).* PRESSURE/i,
			/LUBE PUMP HIGH PRESS/i,
			/THRUST BEARING HIGH PRESSURE/i,
		],
		name: "{L}EX_Lube_Pressure_BelowMaximum",
		type: "BOOL",
		direction: "in",
		extruder: true
	},



	{
		description: /MAX TORQUE .*SWITCH/i,
		name: "{L}EX_Torque_Clutch_Prox",
		type: "BOOL",
		direction: "in",
		extruder: true
	},

	{
		examples: [
			"EXTRUDER GEARBOX LUBE OIL MAXIMUM TEMPERATURE",
			"GEAR BOX TEMPERATURE OK",
			"EXTRUDER GEARBOX SAFETY THERMOSTAT (OPENS >80C)",
		],
		description: /GEAR[ ]?BOX .*(TEMPERATURE|THERMOSTAT)/i,
		name: "{L}EX_Lube_Temperature_SwOK",
		type: "BOOL",
		direction: "in",
		extruder: true
	},


	{
		description: /MAGNETIC DRAWER MSR/i,
		name: "{L}EX_Barrel_JBox_Estop",
		type: "BOOL",
		direction: "in",
		extruder: true
	},

	{
		examples: [
			"ENCLOSURE INTERIOR TEMPERATURE",
		],
		description: /ENCLOSURE.* TEMP/i,
		name: "{L}EX_Enclosure{N}_Temp_TC",
		thermocouple: true,
		direction: "in",
		extruder: true,
	},
	{
		examples: [
			"AIR CONDITIONER HIGH TEMPERATURE",
			"AIR CONDITIONER ALARM",
			"MAIN ENCL HEAT EXCHANGER FAULT",
			"HEAT EXCHANGER ALARM",
			"EXTRUDER B MAIN ENCLOSURE TEMPERATURE OK",
		],
		description: [
			/AIR COND.* (HIGH TEMPERATURE|ALARM)/i,
			/HEAT EXCHANGER (FAULT|ALARM)/i,
			/MAIN ENCLOSURE.*TEMP/i,
		],
		name: "{L}EX_Enclosure{N}_Temp_Sw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		description: /EXTRUDER [A-M] GEARBOX SAFETY.*THERM/i,
		name: "{L}EX_Lube_Temp_Sw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},

	{
		examples: [
			"GEAR PUMP COOLING SOLENOID"
		],
		description: [
			/GEAR PUMP COOLING SOLENOID/i,
		],
		name: "{L}GP_Cooling_Sol",
		type: "BOOL",
		direction: "out",
		extruder: true,
	},

	{
		examples: [
			"A GEAR PUMP LOCKOUT CONTACTOR ON / ENABLED"
		],
		description: [
			/([A-M]).* GEAR PUMP LOCKOUT CONTACTOR ON/i,
		],
		name: "{1}GP_Lockout_Aux",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"GEAR PUMP LOCKOUT SAFETY CONTACTOR AUX. CONTACT",
		],
		description: [
			/GEAR PUMP LOCKOUT SAFETY CONTACTOR AUX/i,
		],
		name: "{L}GP_Lockout_Aux",
		type: "BOOL",
		direction: "in",
		extruder: true
	},

	{
		examples: [
			"A SCREEN CHANGER RUPTURE DISC OK DOWNSTREAM OF BARREL",
		],
		description: [
			/([A-Z]).* RUPTURE DISC OK DOWNSTREAM OF BARREL/i,
		],
		name: "{1}EX_Head_Rupture_Disc_Sw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"A SCREEN CHANGER RUPTURE DISC OK DOWNSTREAM OF SCREEN CHANGER",
		],
		description: [
			/([A-Z]).* RUPTURE DISC OK DOWNSTREAM OF SCREEN CHANGER/i,
		],
		name: "{1}EX_Suction_Rupture_Disc_Sw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"A GEAR PUMP RUPTURE DISC OK DOWNSTREAM OF GEAR PUMP 18",
		],
		description: [
			/([A-Z]).* RUPTURE DISC OK DOWNSTREAM OF GEAR PUMP/i,
		],
		name: "{1}EX_Discharge_Rupture_Disc_Sw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},


	{
		examples: [
			"BARREL RUPTURE DISC OK\nBETWEEN ZONE 8 AND SC",
			"BARREL RUPTURE DISC OK\nBETWEEN SC AND PUMP",
			"BARREL RUPTURE DISC OK\nBETWEEN PUMP AND ZONE 11",
		],
		description: [
			/RUPTURE DISC.*BETWEEN ZONE ([0-9]+) AND ([0-9]{1,2}|[A-Z]{1,2})/i,
			/RUPTURE DISC.*BETWEEN (.*) AND ZONE ([0-9]+)/i,
			/RUPTURE DISC.*BETWEEN ZONE ([0-9]+) AND ZONE ([0-9]+)/i,
			/RUPTURE DISC.*BETWEEN (.*) AND ([0-9]{1,2}|[A-Z]{1,2})/i,
		],
		name: "{L}EX_Rupture_Disc_{1_}_TO_{2_}_Sw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},

	{
		examples: [
			"GEAR PUMP RUPTURE DISC LS",
			"GEAR PUMP RUPTURE DISC OK\nDOWNSTREAM OF GEAR PUMP"
		],
		description: [
			/GEAR PUMP RUPTURE DISC/i
		],
		name: "{L}EX_Rupture_Disc_Sw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},

	{
		examples: [
			"RUPTURE DISC LIMIT SWITCH",
			"BARREL RUPTURE DISC LS",
			"BARREL RUPTURE DISC OK",
		],
		description: /RUPTURE DISC L/i,
		name: "{L}EX_BarrelRupture_Disc_Sw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"SCREEN CHANGER RUPTURE DISC OK\nDOWNSTREAM OF SCREEN",
		],
		description: /SCREEN CHANGER RUPTURE DISC/i,
		name: "{L}EX_MeltRupture_Disc_Sw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},


	// SIMPLE OUTPUTS
	{
		examples: [
			"FEED GATE CLOSE SOLENOID",
			"FEED GATE TO CLOSE SOL",
			"FEEDGATE CLOSE SOLENOID",
			
			"FEED GATE OPEN SOLENOID",
			"FEED GATE TO OPEN SOL",
			"FEEDGATE OPEN SOLENOID",
		],
		description: [
			/FEED[ ]?GATE.* (CLOSE|OPEN) SOL/i,
		],
		name: "{L}EX_FeedGate_{1:Cap}Sol",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"FEED GATE ENABLE",
		],
		description: [
			/FEED[ ]?GATE.* ENABLE/i,
		],
		name: "{L}EX_FeedGate_Enable",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		extruder: true
	},
	{
		examples: [
			"FEED GATE RELAY EDM",
			"FEED ENABLE EDM SAFETY",
		],
		description: [
			/FEED[ ]?GATE.* EDM/i,
			/FEED ENABLE EDM/i,
		],
		name: "{L}EX_FeedGate_EnableRelayAuxEDM",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		extruder: true
	},
	{
		examples: [
			"HOPPER PURGE GATE TO OPEN SOL",
		],
		description: [
			/PURGE GATE.* (CLOSE|OPEN) SOL/i,
		],
		name: "{L}EX_HopperPurgeGate_{1:Cap}Sol",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"PURGE ENABLE",
		],
		description: [
			/PURGE ENABLE/i,
		],
		name: "{L}EX_HopperPurgeGate_EnableRelay",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		extruder: true
	},
	{
		examples: [
			"PURGE ENABLE EDM SAFETY",
		],
		description: [
			/PURGE ENABLE EDM/i,
		],
		name: "{L}EX_HopperPurgeGate_EnableRelayAuxEDM",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		extruder: true
	},
	{
		examples: [
			"HOPPER VIBRATOR SOLENOID",
			"HOPPER VIBRATOR ON MAIN ENCL"
		],
		description: /HOPPER VIBRATOR (SOLENOID|ON)/i,
		name: "{L}EX_HopperVibrator",
		type: "BOOL",
		direction: "out",
		extruder: true
	},



	// THIS IS NOT DESCRIPTIVE ENOUGH
	{
		examples: [
			"BARREL COOLING OIL STATION GENERAL ALARM",
		],
		description: /BARREL COOLING OIL.* GENERAL ALARM/i,
		name: "{L}EX_CoolOil_Alarm",
		type: "BOOL",
		direction: "in",
		extruder: true
	},



	
	{
		examples: [
			"BYPASS BARREL COOLING SOLENOID VALVE",
		],
		description: /BYPASS BARREL COOLING SOL/i,
		name: "{L}EX_BarrelCoolOil_BypassSol",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"BARREL OIL COOLING VALVE POSITION REFERENCE 2...10V",
		],
		description: /BARREL OIL COOLING VALVE/i,
		name: "{L}EX_BarrelCoolOil_ValveReference",
		analog: true,
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"BARREL OIL COOLING VALVEPOSITION FEEDDBACK 2...10V",
		],
		description: /BARREL OIL COOLING VALVE/i,
		name: "{L}EX_BarrelCoolOil_ValveFeedback",
		analog: true,
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"BARREL COOLING OIL STATION PUMP MOTOR DISCONNECT",
		],
		description: /COOLING OIL.* PUMP.* DISCONNECT/i,
		name: "{L}EX_BarrelCoolOil_Pump_Disc",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"BARREL COOLING OIL PUMP MOTOR MMSP",
		],
		description: /BARREL COOLING OIL.* PUMP.* MMSP/i,
		name: "{L}EX_BarrelCoolOil_Pump_MMSP",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"BARREL COOLING OIL PUMP MOTOR CONTACTOR",
		],
		description: /BARREL COOLING OIL.* PUMP.* CONT.*/i,
		name: "{L}EX_BarrelCoolOil_Pump_Aux",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"BARREL COOLING OIL PUMP MOTOR CONTACTOR",
		],
		description: /BARREL COOLING OIL.* PUMP.* CONT.*/i,
		name: "{L}EX_BarrelCoolOil_Pump_Coil",
		type: "BOOL",
		direction: "out",
		extruder: true
	},



	{
		examples: [
			"BARREL COOLING OIL STATION LOW LEVEL",
		],
		description: /COOLING OIL.* LOW LEVEL/i,
		name: "{L}EX_BarrelCoolOil_Low",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"BARREL COOLING OIL STATION HIGH TEMPERATURE",
		],
		description: /COOLING OIL.* HIGH TEMP/i,
		name: "{L}EX_BarrelCoolOil_HighTemp",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		description: /BARREL COOLING OIL STATION UNIT STOP/i,
		name: "{L}EX_BarrelCoolOil_Stop",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		description: /BARREL COOLING OIL STATION UNIT START/i,
		name: "{L}EX_BarrelCoolOil_Start",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		description: /BARREL COOLING OIL STATION UNIT COOLING COMMAND/i,
		name: "{L}EX_BarrelCoolOil_Cooling_Sol",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"BARREL COOLING BYPASS CYLINDER SOLENOID VALVE",
			"BYPASS BARREL OIL COOLING SOLENOID VALVE"

		],
		description: [
			/BARREL COOLING BYPASS.* SOL.* VALVE/i,
			/BYPASS BARREL OIL COOLING SOL.* VALVE/i
		],
		name: "{L}EX_BarrelCoolOil_Bypass",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"ZONE 5 BARREL W/VENT STACK COOLING SOLENOID",
			"ZONE 6 BARREL COOLING SOLENOID",
			"ZONE 4 BARREL COOLING SOLENOID",
			"ZONE 1 BARREL COOLING SOLENOID",
			"ZONE 1 BARREL COOLING SOLENOID VALVE",
			"ZONE 1 BARREL COOLING SOLENOID",
		],
		description: /ZONE ([0-9]+) BARREL .*COOLING SOL/i,
		name: "Zone{L}{1}_Cool_Sol",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"ZONE 1 COOLING SSC FAULT",
			"ZONE 2 COOLING SSC FAULT",
		],
		description: /ZONE ([0-9]+) .*COOLING (SSR|SSC) FAULT/i,
		name: "Zone{L}{1}_Cool_Sol_Fault",
		type: "BOOL",
		direction: "in",
		extruder: true
	},


	{
		examples: [
			"FEED BARREL COOLING SOLENOID VALVE",
		],
		description: /FEED BARREL .*COOLING SOL/i,
		name: "Zone{L}0_Cool_Sol",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"SCREW COOLING SOLENOID",
		],
		description: /SCREW COOL.*SOL/i,
		name: "{L}EX_ScrewCool_Sol",
		type: "BOOL",
		direction: "out",
		extruder: true
	},











	// MOTOR INPUTS

	// Autodie must be above Extruder Blower Motor
	{
		examples: [
			"AUTODIE BLOWER MOTOR MMSP AUX CONTACT",
			"AIR BAR BLOWER MOTOR MMSP AUX CONTACT",
		],
		description: /(BAR|DIE) BLOWER.* MMSP/i,
		name: "Die_Blower_MMSP",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"AUTODIE BLOWER MOTOR CONTACTOR",
			"AIR BAR BLOWER MOTOR CONTACTOR",
		],
		description: /(BAR|DIE) BLOWER.* CONTACTOR/i,
		name: "Die_Blower_Aux",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		// 
		description: /(BAR|DIE) BLOWER.* CONTACTOR/i,
		name: "Die_Blower_Coil",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"EXTRUDER BLOWER MOTOR MMSP AUX",
			"MOTOR BLOWER MMSP",
			"EXTRUDER G BLOWER MOTOR MMSP",
			"DRIVE MOTOR FAN CB ON", // 18122-b149.dxf
			"EXTRUDER MOTOR BLOWER OVERLOAD AUX", // 18199-b142
			"EXTRUDER MOTOR COOLING FAN CB ON/OK"
		],
		description: [
			/(EXTRUDER.*|MOTOR) BLOWER.* (MMSP|OVERLOAD)/i,
			/^DRIVE MOTOR.* FAN[ |\n]CB/i,
			/EXTRUDER MOTOR COOLING FAN CB/i,
		],
		name: "{L}EX_Blower_MMSP",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"MOTOR BLOWER CONTACTOR",
			"EXTRUDER BLOWER MOTOR CONTACTOR AUX",
			"EXTRUDER G BLOWER MOTOR CONTACTOR",
			"DRIVE MOTOR FAN CR ON", // 18122-b149.dxf
			"EXTRUDER MOTOR COOLING FAN CR ON",
		],
		description: [
			/(EXTRUDER.*|MOTOR) BLOWER.* CONTACTOR/i,
			/^DRIVE MOTOR.* FAN[ |\n]CR/i,
			/EXTRUDER MOTOR COOLING FAN CR/i,
		],
		name: "{L}EX_Blower_Aux",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"EXTRUDER MOTOR BLOWER",
			"BLOWER MOTOR CONTACTOR",
			"DRIVE MOTOR COOLING FAN CR TO ON",
		],
		description: [
			/(EXTRUDER|BLOWER) MOTOR (BLOWER|CONTACTOR)/i,
			/^DRIVE MOTOR.* FAN[ |\n]CR/i,
		],
		name: "{L}EX_Blower_Coil",
		type: "BOOL",
		direction: "out",
		extruder: true
	},




	{
		examples: [
			"EXTRUDER A GEAR PUMP MOTOR TEMPERATURE SWITCH",
			"EXTRUDER B GEAR PUMP MOTOR TEMPERATURE SWITCH",
			"A GEAR PUMP MOTOR TEMP OK MOTOR TEMPERATURE",
			"B GEAR PUMP MOTOR TEMP OK MOTOR TEMPERATURE",
		],
		description: [
			/EXTRUDER ([A-H]) GEAR PUMP.* TEMP.* SWITCH/i,
			/([A-H]) GEAR PUMP.* TEMP.* OK/i,
		],
		name: "{1}GP_MtrThermo_Sw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"EXTRUDER MOTOR TEMP SWITCH",
			"EXTRUDER MOTOR TEMPERATURE SWITCH",
			"DRIVE MOTOR TEMPERATURE OK",
			"EXTRUDER A MOTOR TEMPERATURE SWITCH"
		],
		description: /(EXTRUDER|^DRIVE)[ ]?[A-I]? MOTOR TEMP.* (SWITCH|OK)/i,
		name: "{L}EX_MtrThermostat_Sw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"GEAR PUMP MOTOR TEMPERATURE SWITCH",
			"GEAR PUMP MOTOR TEMP SWITCH",
			"GEAR PUMP MOTOR TEMPERATURE OK",
		],
		description: /GEAR PUMP MOTOR TEMP.* (SWITCH|OK)/i,
		name: "{L}GP_MtrThermo_Sw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},


	{
		examples: [
			"LUBE OIL CIRC. PUMP MMSP",
			"LUBE PUMP MOTOR CB TRIPPED",
		],
		description: /(LUBE|GEARBOX).*PUMP( MOTOR)?[ |\n](MMSP|CB)/i,
		name: "{L}EX_LubePump_MMSP",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"LUBE OIL PUMP CONTACTOR AUX",
			"LUBE OIL CIRC. PUMP CONTACTOR",
			"LUBE PUMP MOTOR CR NOT ON",
		],
		description: [
			/(LUBE|GEARBOX).*PUMP( MOTOR)? CONTACTOR/i,
			/(LUBE|GEARBOX).*PUMP( MOTOR)?[ |\n]CR/i,
		],
		name: "{L}EX_LubePump_Aux",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"OIL CIRC. PUMP CONTACTOR",
			"GEAR BOX LUBE PUMP MOTOR CR TO ON"
		],
		description: [
			/(^|LUBE |GEAR[ ]?BOX )OIL .*PUMP( MOTOR)?/i,
			/GEAR[ ]?BOX LUBE[ ]?PUMP.*[ |\n]CR/i,
		],
		name: "{L}EX_LubePump_Coil",
		type: "BOOL",
		direction: "out",
		extruder: true
	},


	{
		examples: [
			"WATER COOLED VENT PLUG SOLENOID",
		],
		description: /WATER COOLED VENT PLUG SOLENOID/i,
		name: "{L}EX_Vent_CoolWater_Sol",
		type: "BOOL",
		direction: "out",
		extruder: true
	},

	{
		examples: [
			"COOLING WATER PUMP MMSP AUX",
			"COOLING PUMP MOTOR MMSP",
		],
		description: /COOLING( WATER)? PUMP .*MMSP/i,
		name: "{L}EX_CoolWater_MMSP",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"COOLING WATER PUMP CONTACTOR AUX",
			"COOLING PUMP MOTOR CONTACTOR",
		],
		description: /COOLING( WATER)? PUMP .*CONT.*/i,
		name: "{L}EX_CoolWater_Aux",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"COOLING WATER PUMP CONTACTOR",
			"COOLING WATER PUMP",
			"COOLING PUMP CONTACTOR",
		],
		description: [
			/COOLING PUMP .*CONTACTOR/i,
			/COOLING WATER PUMP/i,
		],
		name: "{L}EX_CoolWater_Coil",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"WATER COOLING TO ON SOL",
		],
		description: [
			/WATER COOLING.* SOL/,
		],
		name: "{L}EX_CoolWater_Sol",
		type: "BOOL",
		direction: "out",
		extruder: true
	},








	{
		examples: [
			"HEAT ENABLE 2 CONTACTOR ON",
			"HEAT ENABLE 4 CONTACTOR ON",
			"HEAT ENABLE 1 CR ON",
		],
		description: [
			/HEAT ENABLE ([0-9]+) CONTACTOR ON/i,
			/HEAT ENABLE ([0-9]?)[ ]?CR ON/i,
		],
		name: "{L}EX_Heat{1}_Aux",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"BARREL HEATERS OVER TEMPERATURE SAFETY CONTACTOR",
			"BARREL HEATERS OVER TEMPERATURE CONTACTOR",
			"HEAT ENABLE CR ON",
		],
		description: [
			/BARREL HEATERS OVER TEMPERATURE.* CONTACTOR/i,
		],
		name: "{L}EX_BarrelHeat_Aux",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"BARREL HEATERS ZONES 1-6 OVER TEMPERATURE SAFETY CONTACTOR AUX",
			"BARREL HEATERS ZONES 7-15 OVER TEMPERATURE SAFETY CONTACTOR AUX",
		],
		description: [
			/BARREL HEATERS ZONES ([0-9]+)-([0-9]+) OVER TEMPERATURE SAFETY CONTACTOR AUX/i,
			
		],
		name: "{L}EX_Barrel_{1}_to_{2}_Heat_Aux",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"BARREL HEATERS ZONES 1-6 OVER TEMPERATURE SAFETY CONTACTOR CONTROL RELAY",
			"BARREL HEATERS ZONES 7-15 OVER TEMPERATURE SAFETY CONTACTOR CONTROL RELAY",
		],
		description: [
			/BARREL HEATERS ZONES ([0-9]+)-([0-9]+) OVER TEMPERATURE SAFETY CONTACTOR/i,
			
		],
		name: "{L}EX_Barrel_{1}_to_{2}_Heat_Coil",
		type: "BOOL",
		direction: "out",
		extruder: true
	},


	{
		examples: [
			"EXTRUDER A DOWNSTREAM HEATERS OVER TEMPERATURE SAFETY CONTACTOR AUX CON",
			"EXTRUDER B DOWNSTREAM HEATERS OVER TEMPERATURE SAFETY CONTACTOR AUX CON",
		],
		description: [
			/EXTRUDER ([A-M]) DOWNSTREAM.*HEATERS OVER TEMPERATURE.* CONTACTOR/i,
		],
		name: "{1}EX_DSHeat_Aux",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"DOWNSTREAM & DIE HEATERS OVER TEMPERATURE CONTACTOR",
			"DOWNSTREAM & DIE HEATERS OVER TEMPERATURE SAFETY CONTACTOR",
		],
		description: [
			/DOWNSTREAM.*HEATERS OVER TEMPERATURE.* CONTACTOR/i,
		],
		name: "{L}EX_DSHeat_Aux",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"BARRELL HEAT ON",
			"HEAT ENABLE CR",
			"BARREL HEATERS OVER TEMPERATURE SAFETY CONTACTOR",
		],
		description: [
			/BARREL HEATERS OVER TEMPERATURE.* CONTACTOR/i,
			/BARREL.* HEAT ON/i,
			/HEAT ENABLE.* ON/i,
		],
		name: "{L}EX_BarrelHeat_Coil",
		type: "BOOL",
		direction: "out",
		extruder: true
	},

	{
		examples: [
			"EXTRUDER A DOWNSTREAM HEATERS OVER TEMPERATURE SAFETY CONTACTOR",
			"EXTRUDER B DOWNSTREAM HEATERS OVER TEMPERATURE SAFETY CONTACTORN",
		],
		description: [
			/EXTRUDER ([A-M]) DOWNSTREAM HEATERS OVER TEMPERATURE SAFETY CONTACTOR/i,
		],
		name: "{1}EX_DSHeat_Coil",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"DOWNSTREAM HEATERS OVER TEMPERATURE SAFETY CONTACTOR",
			"DOWN STREAM HEAT ON",
		],
		description: [
			/DOWNSTREAM.*HEATERS OVER TEMPERATURE.* CONTACTOR/i,
			/DOWN[ ]?STREAM HEAT ON/i,
		],
		name: "{L}EX_DSHeat_Coil",
		type: "BOOL",
		direction: "out",
		extruder: true
	},

	{
		examples: [
			"FEEDBLOCK & DIE HEATERS OVER TEMPERATURE SAFETY CONTACTOR AUX",
		],
		description: /DIE.*HEATERS OVER TEMPERATURE.* CONT/i,
		name: "Die_Heat_Aux",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"FEEDBLOCK & DIE HEATERS OVER TEMPERATURE SAFETY CONTACTOR CONTROL RELAY",
			"DIE HEAT ON",
		],
		description: [
			/DIE.*HEATERS OVER TEMPERATURE.* CONTACTOR/i,
			/DIE HEAT ON/,
		],
		name: "Die_Heat_Coil",
		type: "BOOL",
		direction: "out",
		extruder: true
	},



	// SCREEN CHANGER
	{
		examples: [
			"EXTRUDER B FT20-160 SCREEN CHANGER E-STOP PB1",
			"EXTRUDER B FT20-160 SCREEN CHANGER E-STOP PB2",
		],
		description: [
			/EXTRUDER ([A-M]) .*SCREEN CHANGER E[ |-]STOP( PB)?([0-9])/i,
		],
		name: "{1}EXSC_{3}EstopPB",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"EXTRUDER A SCREEN CHANGER HYDRAULIC PUMP MMSP AUX CONTACT 3MMSP AUX",
		],
		description: [
			/EXTRUDER ([A-M]) SCREEN CHANGER HYDRAULIC PUMP MMSP/i,
		],
		name: "{1}EXSC_HydPump_MMSP",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"EXTRUDER A SCREEN CHANGER HYDRAULIC PUMP CONTACTOR AUX CONTACT", 
		],
		description: [
			/EXTRUDER ([A-M]) SCREEN CHANGER HY.* PUMP CONTACTOR/i,
		],
		name: "{1}EXSC_HydPump_Aux",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"SCREEN CHANGER HYDRAULIC PUMP MMSP",
			"SCREEN CHANGER HPU PUMP MMSP AUX 5MMSP AUX ",
			"HPU MOTOR CB ON",
		],
		description: [
			/SCREEN CHANGER (HPU|HYDRAULIC) PUMP MMSP/i,
			/HPU MOTOR CB ON/i,
		],
		name: "{L}EXSC_HydPump_MMSP",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"SCREEN CHANGER HYDRAULIC PUMP CONTACTOR", 
			"SCREEN CHANGER HYRAULIC PUMP CONTACTOR AUX", // 18059-b138 TYPO
			"HPU MOTOR CR ON",
			"SCREEN CHANGER HPU PUMP CONTACTOR AUX",
		],
		description: [
			/SCREEN CHANGER (HY.*|HPU) PUMP CONTACTOR/i,
			/HPU MOTOR CR ON/i,
		],
		name: "{L}EXSC_HydPump_Aux",
		type: "BOOL",
		direction: "in",
		extruder: true
	},


	{
		description: /SCREEN CHANGER JUNCTION BOX E-STOP/i,
		name: "{L}EXSC_JBox_Estop_PB",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"SCREEN CHANGER PUSH-BUTTON PANEL E-STOP",
			"EXTRUDER B MAAG CSC-148/BF SCREEN CHANGER PENDANT E-STOP PB",
		],
		description: [
			/SCREEN CHANGER PUSH-BUTTON PANEL E-STOP/i,
			/SCREEN CHANGER PENDANT E-STOP/i,
		],
		name: "{L}EXSC_Pendant_Estop_PB",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"DIE LH E-STOP PRESSED",
			"DIE RH E-STOP PRESSED",
			"DIE PLATFROM LH E-STOP PRESSED",
			"DIE PLATFROM RH E-STOP PRESSED", // 18122-b135.dxf
			"EXTRUDER B DIE ACCESS PLATFORM LEFT SIDE E-STOP PBL", // 18085-b226a.dxf
			"EXTRUDER B DIE ACCESS PLATFORM RIGHT SIDE E-STOP PBL",
		],
		description: /DIE.* (LH|RH|LEFT|RIGHT)( SIDE)? E-STOP/i,
		name: "{L}EX_Die_{1:Cap}_Estop_PB",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
		extruder: true
	},

	{
		description: /SCREEN CHANGER PROTECTIVE COVER 1 SAFETY/i,
		name: "{L}EXSC_SafetyDoor_Left_Sw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		description: /SCREEN CHANGER PROTECTIVE COVER 2 SAFETY/i,
		name: "{L}EXSC_SafetyDoor_Right_Sw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},

	{
		examples: [
			"SCREEN CHANGER GUARD SWITCHES CH1",
			"SCREEN CHANGER GUARD SWITCHES CH2",
		],
		description: [
			/SCREEN CHANGER GUARD SWITCHES/i,
		],
		name: "{L}EXSC_GuardSwitch_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		extruder: true,
		safetyType: "Safety",
	},

	{
		examples: [
			"EXTRUDER B EH-45 SCREEN CHANGER COVERS CLOSED",
			"EXTRUDER A EH-25 SCREEN CHANGER COVERS CLOSED",
			"A SCREEN CHANGER COVERS CLOSED",
			"B SCREEN CHANGER COVERS CLOSED",
		],
		description: [
			/EXTRUDER ([A-H]) .*SCREEN CHANGER (GUARD SAFETY|COVERS CLOSED)/i,
			/([A-H]) .*SCREEN CHANGER (GUARD SAFETY|COVERS CLOSED)/i,
		],
		name: "{1}EXSC_DoorsClosed_Sw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"EH-45 SCREEN CHANGER COVERS CLOSED",
			"S.C. DOOR LIMIT SWITCH",
		],
		description: [
			/SCREEN CHANGER (GUARD SAFETY|COVERS CLOSED)/i,
			/S\.?C\.? DOOR.* SWITCH/i,
		],
		name: "{L}EXSC_DoorsClosed_Sw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},

	{
		examples: [
			"EXTRUDER B HYDRAULIC PUMP DISCONNECT AUX.",
			"HPU MOTOR DISCONNECT ON B CART ENCL"
		],
		description: [
			/EXT.* ([A-H]) HYDRAULIC PUMP DISCONNECT AUX/i,
			/HPU MOTOR DISCONNECT ON ([A-H]) CART ENCL/i,
		],
		name: "{1}EXSC_HydPump_DisconnectOn",
		type: "BOOL",
		direction: "in",
		extruder: true
	},

	{
		examples: [
			"HPU MOTOR DISCONNECT ON", // 18122-b135.dxf
		],
		description: [
			/SCREEN CHANGER HYDRAULIC PUMP DISCONNECT AUX/i,
			/HPU MOTOR DISCONNECT ON/i,
		],
		name: "{L}EXSC_HydPump_DisconnectOn",
		type: "BOOL",
		direction: "in",
		extruder: true
	},

	{
		description: /SCREEN CHANGER PENDANT AUTO/i,
		name: "{L}EXSC_Auto_Mode_SS",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		description: /SCREEN CHANGER PENDANT PISTON SELECT SS/i,
		name: "{L}EXSC_BoltB_Select_SS",
		type: "BOOL",
		direction: "in",
		extruder: true
	},

	{
		description: [
			/SCREEN CHANGER PENDANT EXTEND PISTON PB/i,
			/SCREEN CHANGER SCREEN PISTON MOVE OUT/i
		],
		name: "{L}EXSC_Bolt_Move_Out_PB",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"EXTRUDER A MAAG CSC-200 SCREENN CHANGER PENDANT RETRACT PISTON PB",
		],
		description: [
			/SCREEN.* CHANGER PENDANT RETRACT PISTON PB/i,
			/SCREEN CHANGER SCREEN PISTON MOVE IN/i
		],
		name: "{L}EXSC_Bolt_Move_In_PB",
		type: "BOOL",
		direction: "in",
		extruder: true
	},

	{
		description: /SCREEN CHANGER SCREEN 2 SCREEN PISTON A MOVE OUT/i,
		name: "{L}EXSC_Screen2_Move_Out_PB",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		description: /SCREEN CHANGER SCREEN 2 SCREEN PISTON A MOVE IN/i,
		name: "{L}EXSC_Screen2_Move_In_PB",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		description: /SCREEN CHANGER SCREEN 4 SCREEN PISTON B MOVE OUT/i,
		name: "{L}EXSC_Screen4_Move_In_PB",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		description: /SCREEN CHANGER SCREEN 4 SCREEN PISTON B MOVE IN/i,
		name: "{L}EXSC_Screen4_Move_Out_PB",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		description: /SCREEN CHANGER SELECTION I SCREEN PISTON A SELECTED/i,
		name: "{L}EXSC_BoltA_Select_SS",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		description: /SCREEN CHANGER SELECTION II SCREEN PISTON B SELECTED/i,
		name: "{L}EXSC_BoltB_Select_SS",
		type: "BOOL",
		direction: "in",
		extruder: true
	},

	{
		description: /SCREEN CHANGER.* OPERATING MODE/i,
		name: "{L}EXSC_Operating_Mode_PB",
		type: "BOOL",
		direction: "in",
		extruder: true
	},


	{
		examples: [
			"EXTRUDER B MAAG CSC-148/BF SCREEN CHANGER BACKFLUSH VALVE PROX SW",
		],
		description: /EXT.* ([A-H]) .*SCREEN CHANGER BACKFLUSH VALVE PROX/i,
		name: "{1}EXSC_Flush_Pos_Sw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"SCREEN CHANGER BLOCK VALVE SCREENS 1 IN BACKFLUSH POSITION",
		],
		description: /SCREEN CHANGER BLOCK VALVE SCREENS 1.* IN BACKFLUSH POSITION/i,
		name: "{L}EXSC_BoltA_Flush_Pos_Sw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		description: /SCREEN CHANGER BLOCK VALVE SCREENS 1.* IN BLOCK POSITION/i,
		name: "{L}EXSC_BoltA_Block_Pos_Sw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},

	{
		description: /SCREEN CHANGER BLOCK VALVE SCREENS 2.* IN BACKFLUSH POSITION/i,
		name: "{L}EXSC_BoltB_Flush_Pos_Sw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		description: /SCREEN CHANGER BLOCK VALVE SCREENS 2.* IN BLOCK POSITION/i,
		name: "{L}EXSC_BoltB_Block_Pos_Sw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},


	{
		examples: [
			"EXTRUDER A SCREEN CHANGER HYDRAULIC PUMP CONTACTOR",
		],
		description: /EXTRUDER ([A-M]) SCREEN CHANGER (HYDRAULIC PUMP|HPU)/i,
		name: "{1}EXSC_HydPump_Coil",
		type: "BOOL",
		direction: "out",
		safetyType: "Standard",
		extruder: true
	},
	
	{
		examples: [
			"SCREEN CHANGER HYDRAULIC PUMP CONTACTOR",
			"SCREEN CHANGER HYDRAULIC PUMP",
			"SCREEN CHANGER HPU MOTOR CR TO ON"
		],
		description: /SCREEN CHANGER (HYDRAULIC PUMP|HPU)/i,
		name: "{L}EXSC_HydPump_Coil",
		type: "BOOL",
		direction: "out",
		safetyType: "Standard",
		extruder: true
	},
	
	{
		examples: [
			"EXTRUDER B HSC-80 SCREEN CHANGER ENABLE RELAY",
			"B SCREEN CHANGER ENABLE CR"
		],
		description: /(^| )([A-J]) .*SCREEN CHANGER .*ENABLE/i,
		name: "{2}EXSC_Enable",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"EEH-45 SCREEN CHANGER ENABLE RELAY",
		],
		description: /.*SCREEN CHANGER .*ENABLE/i,
		name: "{L}EXSC_Enable",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"MAIN ENCL HPU ENABLE",
		],
		description: /HPU ENABLE/i,
		name: "{L}EXSC_Enable_RelayCoil",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		downstream: "DS",
		extruder: true
	},
	{
		examples: [
			"HPU ENABLE RELAY EDM MAIN ENCL HPU ENABLE",
		],
		description: /HPU ENABLE RELAY/i,
		name: "{L}EXSC_Enable_RelayAuxEDM",
		type: "BOOL",
		direction: "in",
		downstream: "DS",
		extruder: true
	},
	{
		examples: [
			"HPU HIGH PRESSURE ENABLE",
		],
		description: [
			/HPU HIGH PRESSURE ENABLE/i,
		],
		name: "{L}EXSC_Dump_Sol_EnabledCoil",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		extruder: true
	},
	
	{
		examples: [
			"A SCREEN CHANGER AT EXTEND / FREE END",
			"A SCREEN CHANGER AT RETRACT / CYL. END"
		],
		description: [
			/([A-M]) SCREEN CHANGER AT (EXTEND|RETRACT)/i,
		],
		name: "{1}EXSC_Cylinder{2:Cap}ed",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"EXTRUDER B HSC-80 SCREEN CHANGER HYDRAULIC UNIT ACCUMULATOR DUMP VALVE SOLENOID",
			"EXTRUDER C EH-60 SCREEN CHANGER HYDRAULIC UNIT ACCUMULATOR DUMP VALVE SOLENOID",
			"EXTRUDER A SCREEN CHANGER HYDRAULIC PUMP ACCUMULATOR DUMP VALVE SOLENOID",
			"EXTRUDER B SCREEN CHANGER HYDRAULIC PUMP ACCUMULATOR DUMP VALVE SOLENOID",
			"HPU DUMP VALVE HI PRESSURE ENABLE CR"
		],
		description: [
			/EXTRUDER ([A-J]).*SCREEN CHANGER .*DUMP VALVE/i,
			/EXT.* ([A-H]) SCREEN.* HYDRAULIC PUMP ACCUM.* DUMP/i,
		],
		name: "{1}EXSC_Dump_Sol",
		type: "BOOL",
		direction: "out",
		extruder: true
	},

	{
		examples: [
			"EXTRUDER A SCREEN CHANGER HYDRAULIC UNIT ACCUMULATOR DUMP VALVE SOLENOID",
		],
		description: [
			/SCREEN CHANGER .*DUMP VALVE/i,
			/HPU DUMP VALVE/i,
		],
		name: "{L}EXSC_Dump_Sol",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		description: [
			/SCREEN CHANGER EXTEND PISTON 1 RELAY/i,
			/SCREEN CHANGER SCREEN PISTON A MOVE IN X\+ DIRECTION RELAY/i
		],
		name: "{L}EXSC_BoltA_Extend",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		description: [
			/SCREEN CHANGER RETRACT PISTON 1 RELAY/i,
			/SCREEN CHANGER SCREEN PISTON A MOVE IN X- DIRECTION RELAY/i
		],
		name: "{L}EXSC_BoltA_Retract",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		description: [
			/SCREEN CHANGER EXTEND PISTON 2 RELAY/i,
			/SCREEN CHANGER SCREEN PISTON B MOVE IN X\+ DIRECTION RELAY/i
		],
		name: "{L}EXSC_BoltB_Extend",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		description: [
			/SCREEN CHANGER RETRACT PISTON 2 RELAY/i,
			/SCREEN CHANGER SCREEN PISTON B MOVE IN X- DIRECTION RELAY/i
		],
		name: "{L}EXSC_BoltB_Retract",
		type: "BOOL",
		direction: "out",
		extruder: true
	},


	{
		examples: [
			"EXTRUDER B FT20-160 SCREEN CHANGER BLOCK VALVE SOLENOID", // 18085-b229a
		],
		description: [
			/EXTRUDER ([A-I]) SCREEN CHANGER BLOCK VALVE SOLENOID/,
		],
		name: "{1}EXSC_Block_Valve_Release",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"SCREEN CHANGER RELEASE FOR MOVING SCREEN PISTONS RELAY",
			"EXTRUDER B FT20-160 SCREEN CHANGER BLOCK VALVE SOLENOID", // 18085-b229a
		],
		description: [
			/SCREEN CHANGER RELEASE FOR MOVING SCREEN PISTONS RELAY/i,
			/SCREEN CHANGER BLOCK VALVE SOLENOID/,
		],
		name: "{L}EXSC_Block_Valve_Release",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		description: /SCREEN CHANGER RELEASE FOR MOVING BLOCK VALVES RELAY/i,
		name: "{L}EXSC_BackFlush_Bypass",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"SCREEN CHANGER BLOCK VALVE SCREENS 1/3 MOVE IN Y- DIRECTION (TOP, BACKFLUSH POSITION) RELAY",
		],
		description: /SCREEN CHANGER .* 1.* MOVE IN.* BACKFLUSH.*RELAY/i,
		name: "{L}EXSC_BackFlush_Gate1Open",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"SCREEN CHANGER BLOCK VALVE SCREENS 1/3 MOVE IN Y+ DIRECTION (BOTTOM, BLOCK POSITION) RELAY",
		],
		description: /SCREEN CHANGER .* 1.* MOVE IN.* BLOCK.*RELAY/i,
		name: "{L}EXSC_BackFlush_Gate1Close",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"SCREEN CHANGER BLOCK VALVE SCREENS 2/4 MOVE IN Y- DIRECTION (TOP, BACKFLUSH POSITION) RELAY",
		],
		description: /SCREEN CHANGER .* 2.* MOVE IN.* BACKFLUSH.*RELAY/i,
		name: "{L}EXSC_BackFlush_Gate2Open",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"SCREEN CHANGER BLOCK VALVE SCREENS 2/4 MOVE IN Y+ DIRECTION (BOTTOM, BLOCK POSITION) RELAY",
		],
		description: /SCREEN CHANGER .* 2.* MOVE IN.* BLOCK.*RELAY/i,
		name: "{L}EXSC_BackFlush_Gate2Close",
		type: "BOOL",
		direction: "out",
		extruder: true
	},

	{
		examples: [
			"SCREEN CHANGER SCREEN PISTON MOVE OUT INDICATOR (CLEAR)",
		],
		description: /SCREEN CHANGER SCREEN PISTON MOVE OUT INDICATOR/i,
		name: "{L}EXSC_Move_Out_Ind_LT",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"SCREEN CHANGER SCREEN PISTON MOVE IN INDICATOR (CLEAR)",
		],
		description: /SCREEN CHANGER SCREEN PISTON MOVE IN INDICATOR/i,
		name: "{L}EXSC_Move_In_Ind_LT",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"SCREEN CHANGER MANUAL MODE SELECTED INDICATOR (CLEAR)",
		],
		description: /SCREEN CHANGER MANUAL MODE SELECTED INDICATOR/i,
		name: "{L}EXSC_Manual_Mode_LT",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"SCREEN CHANGER BACKFLUSH MODE SELECTED INDICATOR (CLEAR)",
		],
		description: /SCREEN CHANGER BACKFLUSH MODE SELECTED INDICATOR/i,
		name: "{L}EXSC_Backflush_Mode_LT",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"SCREEN CHANGER SCREEN CHANGE MODE SELECTED INDICATOR (CLEAR)",
		],
		description: /SCREEN CHANGER SCREEN CHANGE MODE SELECTED INDICATOR/i,
		name: "{L}EXSC_ScreenChange_Mode_LT",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"SCREEN CHANGER SCREEN 2 SCREEN PISTON A IN MOVEMENT (CLEAR)",
		],
		description: /SCREEN CHANGER SCREEN 2 SCREEN PISTON A IN MOVEMENT/i,
		name: "{L}EXSC_Scrn2_Move_LT",
		type: "BOOL",
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"SCREEN CHANGER SCREEN 4 SCREEN PISTON B IN MOVEMENT (CLEAR)",
		],
		description: /SCREEN CHANGER SCREEN 4 SCREEN PISTON B IN MOVEMENT/i,
		name: "{L}EXSC_Scrn4_Move_LT",
		type: "BOOL",
		direction: "out",
		extruder: true
	},

	{
		examples: [
			"EXTRUDER A MAAG CSC-200 SCREEN CHANGER SCREEN PISTON A DISTANCE MEASUREMENT",
			"EXTRUDER A MAAG CSC-200 SCREEN CHANGER SCREEN PISTON B DISTANCE MEASUREMENT LVDT-A15612 BALLUFF TRANSSONAR LINEAR TRANSDUCER",
		],
		description: /SCREEN .*PISTON ([A-D]) /i,
		name: "{L}EXSC_Bolt{1}_Position_Raw",
		analog: true,
		direction: "in",
		extruder: true
	},

	{
		examples: [
			"EXTRUDER B SCREEN CHANGER HYDRAULIC PRESSURE ",
		],
		description: /SCREEN CHANGER HYDRAULIC PRESSURE/i,
		name: "{L}EXSC_HydPump_PressureRaw",
		analog: true,
		direction: "in",
		extruder: true
	},
	
	{
		examples: [
			"SCREEN CHANGER HYDRAULIC PUMP ACCUMULATOR PRESSURE SWITCH \"BELOW PRESSURE\"",
			"HPU PRESSURE BELOW PRESSURE",
		],
		description: [
			/SCREEN CHANGER HYDRAULIC.* PRESSURE SW.*BELOW/i,
			/HPU PRESSURE BELOW PRESSURE/,
		],
		name: "{L}EXSC_HydPump_PressureSwLow",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"SCREEN CHANGER HYDRAULIC PUMP ACCUMULATOR PRESSURE SWITCH \"AT PRESSURE\"",
			"HPU PRESSURE ABOVE PRESSURE",
		],
		description: [
			/SCREEN CHANGER HYDRAULIC.* PRESSURE SW.*AT PRESS/i,
			/HPU PRESSURE ABOVE PRESSURE/,
		],
		name: "{L}EXSC_HydPump_PressureSwCharged",
		type: "BOOL",
		direction: "in",
		extruder: true
	},

	{
		examples: [
			"EXTRUDER A SCREEN CHANGER HYDRAULIC PUMP ACCUMULATOR PRESSURE SW",
			"EXTRUDER B SCREEN CHANGER HYDRAULIC PUMP ACCUMULATOR PRESSURE SW"
		],
		description: /EXT.* ([A-G]) SCREEN CHANGER HYDRAULIC.* PRESSURE SW/i,
		name: "{1}EXSC_HydPump_PressureSw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},
	{
		examples: [
			"SCREEN CHANGER HYDRAULIC PRESSURE SWITCH",
			"HPU PRESSURE SWITCH OK",
		],
		description: [
			/SCREEN CHANGER HYDRAULIC.* PRESSURE SW/i,
			/HPU PRESSURE SWITCH OK/i,
		],
		name: "{L}EXSC_HydPump_PressureSw",
		type: "BOOL",
		direction: "in",
		extruder: true
	},




	{
		examples: [
			"EXTRUDER B MAAG CSC-148/BF SCREEN CHANGER HYDRAULIC UNIT PROPORTIONAL VALVE",
		],
		description: [
			/EXTRUDER ([A-H]).* SCREEN CHANGER HYDRAULIC UNIT PROPORTIONAL VALVE/i,
		],
		name: "{1}EXSC_PropValve",
		analog: true,
		direction: "out",
		extruder: true
	},
	{
		examples: [
			"SCREEN CHANGER HYDRAULIC UNIT PROPORTIONAL VALVE",
		],
		description: [
			/SCREEN CHANGER HYDRAULIC UNIT PROPORTIONAL VALVE/i,
		],
		name: "{L}EXSC_PropValve",
		analog: true,
		direction: "out",
		extruder: true
	},
	{
		description: /SCREEN CHANGER HYDRAULIC UNIT PROPORTIONAL VALVE/i,
		name: "{L}EXSC_PropValve_Open",
		type: "BOOL",
		direction: "out",
		extruder: true
	},



	////////////////////////////////////
	// SPARE
	//

	{
		description: [
			/SPARE T\/C INPUT/i,
			/SPARE THERMOCOUPLE/i,
		],
		examples: [
			"SPARE THERMOCOUPLE TYPE J",
			"SPARE T/C INPUT",
		],
		name: "{L}EXZone_Spare_TC_Rack{N}_S{S}_{P}",
		thermocouple: true,
		direction: "in",
		extruder: true,
	},
	{
		description: [
			/SPARE/i,
			/RESERVED/i,
	],
		name: "{L}EX_Spare_Rack{N}_S{S}_{P}_{DIR}_{TYPE}",
		extruder: true
	},
]

export  {knownIoExtruder as default}
