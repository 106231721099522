
import * as helpers from '../ParseHelpers'

export default function EntityParser() {}

EntityParser.ForEntityName = 'TEXT';

EntityParser.prototype.parseEntity = function(scanner, curr) {
    var entity;
    entity = { 
        type: curr.value,
        dxfStartPos: scanner._pointer,
        dxfEndPos: 0,
    };


    curr = scanner.next();
    while(curr !== 'EOF') {

        if(curr.code === 0) break;
        switch(curr.code) {
          case 7:
              entity.font = curr.value;
              break;
          case 8:
              entity.layer = curr.value;
              break;
          case 10: // X coordinate of 'first alignment point'
              entity.startPoint = helpers.parsePoint(scanner);
              break;
          case 11: // X coordinate of 'second alignment point'
              entity.endPoint = helpers.parsePoint(scanner);
              break;
          case 40: // Text height
              entity.textHeight = curr.value;
              break;
          case 41:
              entity.xScale = curr.value;
              break;
          case 44:
              entity.lineHeight = curr.value;
              break;
          case 50: // Rotation in degrees
              entity.rotation = curr.value;
              break;
          case 1: // Text
              entity.text = curr.value;
              break;
          case 2:
              entity.fieldType = curr.value;
              break;
          case 71:
              entity.textDir = curr.value;
              break;
          // NOTE: 72 and 73 are meaningless without 11 (second alignment point)
          case 70:
          case 72: // Horizontal alignment
              entity.halignCode = curr.value;
              if (curr.value === 0) {
                entity.halign = "left";
              }
              else if (curr.value === 1) {
                entity.halign = "center";
              }
              else if (curr.value === 2) {
                entity.halign = "right";
              }
              break;
          case 73:
          case 74:
              entity.valignCode = curr.value;
              if (curr.value === 0) {
                entity.valign = "baseline"
              }
              else if (curr.value === 1) {
                entity.valign = "bottom"
              }
              else if (curr.value === 2) {
                entity.valign = "middle"
              }
              else if (curr.value === 3) {
                entity.valign = "top"
              }
              break;
          case 210:
              entity.extrude = {x: curr.value};
              break;
          case 220:
              entity.extrude.y = curr.value;
              break;
          case 230:
              entity.extrude.z = curr.value;
              break;
          case 280:
              entity.version = curr.value;
              break;
          default: // check common entity attributes
              // console.log("Unkown code for TEXT", curr);
              var found = helpers.checkCommonEntityProperties(entity, curr);
              // if (! found) {console.log("TEXT could not find code", curr, entity)}
              break;
        }
        curr = scanner.next();
    }
    entity.dxfEndPos = scanner._pointer;
    return entity;
};
