import { AlarmMessageID } from "./types";
import { AlarmsXmlMessage } from "./xml-format-message";
import type { AlarmTrigger } from "./trigger";
import type { AlarmLevel } from "./level";


export type TriggerMessageTemplate = {
    // typename: TagDataTypeName,
    message: string,

    // tagname: PartialTagname,
    /**
     * zero-indexed 
     **/ 
    bit_index: number,
    // trigger_tag: TriggerTemplate,

    /**
     * Overrides the color settings for level for the trigger. 
     * Can't be filtered by level if used.
     */
    level?: AlarmLevel,
}


export class AlarmMessage {
    static index = 0;
    id: AlarmMessageID;
    trigger: AlarmTrigger;
    template: TriggerMessageTemplate;
    message_formatted: string;

    public get level(): AlarmLevel {
        return this.template.level || this.trigger.template.level;
    }

    constructor(trigger: AlarmTrigger, template: TriggerMessageTemplate) {
        AlarmMessage.index += 1;
        this.id = "M" + AlarmMessage.index;
        this.trigger = trigger;
        this.template = template;

        this.message_formatted = this.format_message(this.template.message);
    }

    format_message(message: string): string {
        message = message.replaceAll("$N"," ");
        return message;
    }

    toXmlObject(): AlarmsXmlMessage {
        let safe_text = this.message_formatted.replaceAll("<", "&lt;"); 
        safe_text = safe_text.replaceAll(">", "&gt;");

        return {_attributes: {
            id: this.id,
            "trigger-value": (this.template.bit_index + 1) + "",
            "trigger": "#" + this.trigger.id,
            identifier: "",
            backcolor: this.level.backcolor,
            forecolor: this.level.forecolor,
            text: this.trigger.label + ": " + safe_text,
            audio: "false",
            display: "true",
            print: "false",
            "message-to-tag": "false",
        }}
    }
}