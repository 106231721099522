import type {KnownIO} from "../types/io";

let knownIoTitan: Array<KnownIO> = [

	
	{
		examples: [
			"TITAN EMERGENCY STOP PBL",
		],
		description: /EMERGENCY STOP PB/i,
		name: "Titan_Estop_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		titan: true
	},
	{
		examples: [
			"TITAN EMERGENCY STOP PBL INDICATOR",
		],
		description: /EMERGENCY STOP PBL/i,
		name: "Titan_Estop_PBL",
		type: "BOOL",
		direction: "out",
		safetyType: "Standard",
		titan: true
	},


	{
		examples: [
			"TITAN ALARM TOWER SOUND MODULE (HORN)",
		],
		description: /TOWER.*HORN/i,
		name: "Titan_Horn",
		type: "BOOL",
		direction: "out",
		titan: true
	},
	{
		examples: [
			"TITAN ALARM TOWER LIGHT MODULE (RED LIGHT)",
		],
		description: /TOWER LIGHT.*RED/i,
		name: "Titan_Red_TowerLight",
		type: "BOOL",
		direction: "out",
		titan: true
	},


	////////////////////////////////////
	// SPARE
	//
	{
		description: /SPARE T\/C INPUT/i,
		name: "{L}_Spare_TC_Rack{N}_S{S}_{P}",
		type: "INT",
		direction: "in",
		titan: true,
	},
	{
		description: /SPARE T\/C INPUT/i,
		name: "{L}_Spare_Sbreak_Rack{N}_S{S}_{P}",
		type: "BOOL",
		direction: "in",
		titan: true
	},
	{
		description: /SPARE/i,
		name: "{L}_Spare_Rack{N}_S{S}_{P}_in_int",
		type: "INT",
		direction: "in",
		titan: true
	},
	{
		description: /SPARE/i,
		name: "{L}_Spare_Rack{N}_S{S}_{P}_out_int",
		type: "INT",
		direction: "out",
		titan: true
	},
	{
		description: /SPARE/i,
		name: "{L}_Spare_Rack{N}_S{S}_{P}_in",
		type: "BOOL",
		direction: "in",
		titan: true
	},
	{
		description: /SPARE/i,
		name: "{L}_Spare_Rack{N}_S{S}_{P}_out",
		type: "BOOL",
		direction: "out",
		titan: true
	},
]

export  {knownIoTitan as default}
