import type { KnownModule } from "../types/module";

// - V Out: _InvalidVoltageValue
// - V In: _InvalidVoltageReading
// - Cur Out: _InvalidCurrentValue
// - Cur In: _InvalidCurrentReading
// - TC In: _InvalidTemperatureReading

let knownModuleWago: Array<KnownModule> = [
    {
        trigger: /750-366/,
        // Rack at bottom:
        // 750-366 2-PORT ETHERNET ADAPTER
        // Top:
        // 750-366 FIELDBUS COUPLER ETHERNET/IP
        triggerLong: /750-366 (2[ |-]PORT ETHERNET ADAPTER|FIELDBUS COUPLER ETHERNET)/,
        description: "Wago 750-366 2-PORT ETHERNET ADAPTER",
        points: 0,
        pointsStartAt: 0,
        isAdapter: true,
        module_tags: [
            {
                name: "BusAdapter_{L}{N}",
                type: "Bus_Adapter",
                safetyType: "Standard",
                description: "Rack {L}{N}\n{DESC}\n{FILE}",
            },
        ]
    },
    {
        trigger: /750-363/,
        // Rack at bottom:
        // 750-366 2-PORT ETHERNET ADAPTER
        // Top:
        // 750-366 FIELDBUS COUPLER ETHERNET/IP
        triggerLong: /750-363 (2[ |-]PORT ETHERNET ADAPTER|FIELDBUS COUPLER ETHERNET)/,
        description: "Wago 750-363 2-PORT ETHERNET ADAPTER",
        points: 0,
        pointsStartAt: 0,
        isAdapter: true,
        module_tags: [
            {
                name: "BusAdapter_{L}{N}",
                type: "Bus_Adapter",
                safetyType: "Standard",
                description: "Rack {L}{N}\n{DESC}\n{FILE}",
            },
        ]
    },

    {
        trigger: /750-498/,
        triggerLong: /750-498.*8[ |-]CHANNEL.*THERMOCOUPLE/,
        // triggerModule: /-498/,
        // triggerRack: /750/,
        description: "Wago 750-498\n8 CHANNEL ANALOG/THERMOCOUPLE INPUT MODULE",
        pointReg: /\+TC[0-9]/,
        points: 8,
        pointsStartAt: 0,
        aliasTo: [
            "MappedRack_{L}{N}_Slot{S:2}.CH{P}_FilteredTemperatureF",
            {aliasTo:  "MappedRack_{L}{N}_Slot{S:2}.CH{P}_InvalidTemperatureReading", name: "{0:name}_Invalid", direction: "in"},
        ],
        type: [
            "REAL",
            "BOOL"
        ],
        direction: "in",
        thermocouple: true,
        safetyType: "Standard",
        module_tags: [
            {
                name: "MappedRack_{L}{N}_Slot{S:2}",
                type: "Wago_8CH_TC_PTi",
                safetyType: "Standard",
                description: "Rack {L}{N} Slot {S}\n{DESC}\n{FILE}",
            },
        ]
    },

    {
        trigger: /750-496/,
        triggerLong: /750-496.*8[ |-]CHANNEL/,
        description: "Wago 750-496\n8 CHANNEL SINGLE-ENDED 4-20mA ANALOG INPUT MODULE",
        pointReg: /^AI[ ]?[0-9]$/,
        points: 8,
        pointsStartAt: 0,
        aliasTo: [
            "MappedRack_{L}{N}_Slot{S:2}.CH{P}_FilteredCurrentIn",
            {aliasTo:  "MappedRack_{L}{N}_Slot{S:2}.CH{P}_InvalidCurrentReading", name: "{0:name}_Invalid", direction: "in"},
        ],
        type: [
            "REAL",
            "BOOL",
        ],
        direction: "in",
        analog: true,
        safetyType: "Standard",
        module_tags: [
            {
                name: "MappedRack_{L}{N}_Slot{S:2}",
                type: "Wago_8CH_AnalogCurrentIn_PTi",
                safetyType: "Standard",
                description: "Rack {L}{N} Slot {S}\n{DESC}\n{FILE}",
            },
        ]
    },


    {
        trigger: /750-455/,
        triggerLong: /750-455.*4[ |-]CHANNEL/,
        description: "Wago 750-455\n4 CHANNEL SINGLE-ENDED 4-20mA ANALOG INPUT MODULE",
        pointReg: /^AI[ ]?[0-9]$/,
        points: 4,
        pointsStartAt: 0,
        aliasTo: [
            "MappedRack_{L}{N}_Slot{S:2}.CH{P}_FilteredCurrentIn",
            {aliasTo:  "MappedRack_{L}{N}_Slot{S:2}.CH{P}_InvalidCurrentReading", name: "{0:name}_Invalid", direction: "in"},
        ],
        type: [
            "REAL",
            "BOOL",
        ],
        direction: "in",
        analog: true,
        safetyType: "Standard",
        module_tags: [
            {
                name: "MappedRack_{L}{N}_Slot{S:2}",
                type: "Wago_4CH_AnalogCurrentIn_PTi",
                safetyType: "Standard",
                description: "Rack {L}{N} Slot {S}\n{DESC}\n{FILE}",
            },
        ]
    },
    
    {
        trigger: /750-453/,
        triggerLong: /750-453.*4[ |-]CHANNEL/,
        description: "Wago 750-453\n4 CHANNEL SINGLE-ENDED 0-20mA ANALOG INPUT MODULE",
        pointReg: /^AI[ ]?[0-9]$/,
        points: 4,
        pointsStartAt: 0,
        aliasTo: [
            "MappedRack_{L}{N}_Slot{S:2}.CH{P}_FilteredCurrentIn",
            {aliasTo:  "MappedRack_{L}{N}_Slot{S:2}.CH{P}_InvalidCurrentReading", name: "{0:name}_Invalid", direction: "in"},
        ],
        type: [
            "REAL",
            "BOOL",
        ],
        direction: "in",
        analog: true,
        safetyType: "Standard",
        module_tags: [
            {
                name: "MappedRack_{L}{N}_Slot{S:2}",
                type: "Wago_4CH_AnalogCurrentIn_PTi",
                safetyType: "Standard",
                description: "Rack {L}{N} Slot {S}\n{DESC}\n{FILE}",
            },
        ]
    },



    
    {
        trigger: /750-465/,
        triggerLong: /750-465.*2[ |-]CHANNEL/,
        description: "Wago 750-465\n2 CHANNEL SINGLE-ENDED 0-20mA ANALOG INPUT MODULE",
        pointReg: /^AI[ ]?[0-9]$/,
        points: 2,
        pointsStartAt: 0,
        aliasTo: [
            "MappedRack_{L}{N}_Slot{S:2}.CH{P}_FilteredCurrentIn",
            {aliasTo:  "MappedRack_{L}{N}_Slot{S:2}.CH{P}_InvalidCurrentReading", name: "{0:name}_Invalid", direction: "in"},
        ],
        type: [
            "REAL",
            "BOOL",
        ],
        direction: "in",
        analog: true,
        safetyType: "Standard",
        module_tags: [
            {
                name: "MappedRack_{L}{N}_Slot{S:2}",
                type: "Wago_2CH_AnalogCurrentIn_PTi",
                safetyType: "Standard",
                description: "Rack {L}{N} Slot {S}\n{DESC}\n{FILE}",
            },
        ]
    },
    {
        trigger: /750-466/,
        triggerLong: /750-466.*2[ |-]CHANNEL/,
        description: "Wago 750-466\n2 CHANNEL SINGLE-ENDED 4-20mA ANALOG INPUT MODULE",
        pointReg: /^AI[ ]?[0-9]$/,
        points: 2,
        pointsStartAt: 0,
        aliasTo: [
            "MappedRack_{L}{N}_Slot{S:2}.CH{P}_FilteredCurrentIn",
            {aliasTo:  "MappedRack_{L}{N}_Slot{S:2}.CH{P}_InvalidCurrentReading", name: "{0:name}_Invalid", direction: "in"},
        ],
        type: [
            "REAL",
            "BOOL",
        ],
        direction: "in",
        analog: true,
        safetyType: "Standard",
        module_tags: [
            {
                name: "MappedRack_{L}{N}_Slot{S:2}",
                type: "Wago_2CH_AnalogCurrentIn_PTi",
                safetyType: "Standard",
                description: "Rack {L}{N} Slot {S}\n{DESC}\n{FILE}",
            },
        ]
    },



    
    {
        trigger: /750-555/,
        triggerLong: /750-555.*4[ |-]CHANNEL/,
        description: "Wago 750-555\n4 CHANNEL 4-20mA ANALOG OUTPUT MODULE",
        pointReg: /^AO[ ]?[0-9]$/,
        points: 8,
        pointsStartAt: 0,
        aliasTo: [
            "MappedRack_{L}{N}_Slot{S:2}.CH{P}_MilliAmpsOut",
        ],
        type: [
            "REAL",
            "BOOL",
        ],
        direction: "out",
        analog: true,
        safetyType: "Standard",
        module_tags: [
            {
                name: "MappedRack_{L}{N}_Slot{S:2}",
                type: "Wago_4CH_AnalogCurrentOut_PTi",
                safetyType: "Standard",
                description: "Rack {L}{N} Slot {S}\n{DESC}\n{FILE}",
            },
        ]
    },


    {
        trigger: /750-557/,
        triggerLong: /750-557.*4[ |-]CHANNEL/,
        description: "Wago 750-557\n4 CHANNEL +/-10V ANALOG OUTPUT MODULE",
        pointReg: /^AO[ ]?[0-9]$/,
        points: 8,
        pointsStartAt: 0,
        aliasTo: [
            "MappedRack_{L}{N}_Slot{S:2}.CH{P}_VoltsOut",
            {aliasTo:  "MappedRack_{L}{N}_Slot{S:2}.CH{P}_InvalidVoltageValue", name: "{0:name}_Invalid", direction: "in"},
        ],
        type: [
            "REAL",
            "BOOL",
        ],
        direction: "out",
        analog: true,
        safetyType: "Standard",
        module_tags: [
            {
                name: "MappedRack_{L}{N}_Slot{S:2}",
                type: "Wago_4CH_AnalogVoltOut_PTi",
                safetyType: "Standard",
                description: "Rack {L}{N} Slot {S}\n{DESC}\n{FILE}",
            },
        ]
    },


    {
        trigger: /750-562/,
        triggerLong: /750-562.*2[ |-]CHANNEL/,
        description: "Wago 750-562\n2 CHANNEL +/-10V ANALOG OUTPUT MODULE",
        pointReg: /^\+ AO[ ]?[0-9]$/,
        points: 8,
        pointsStartAt: 0,
        aliasTo: [
            "MappedRack_{L}{N}_Slot{S:2}.CH{P}_VoltsOut",
            {aliasTo:  "MappedRack_{L}{N}_Slot{S:2}.CH{P}_InvalidVoltageValue", name: "{0:name}_Invalid", direction: "in"},
        ],
        type: [
            "REAL",
            "BOOL",
        ],
        direction: "out",
        analog: true,
        safetyType: "Standard",
        module_tags: [
            {
                name: "MappedRack_{L}{N}_Slot{S:2}",
                type: "Wago_2CH_AnalogVoltOut_PTi",
                safetyType: "Standard",
                description: "Rack {L}{N} Slot {S}\n{DESC}\n{FILE}",
            },
        ]
    },

    {
        trigger: /750-471/,
        triggerLong: /750-471.*4[-| ]CHANNEL VOLTAGE\/CURRENT.*INPUT/i,
        description: "Wago 750-471\n4 CHANNEL VOLTAGE/CURRENT DIFF. ANALOG INPUT",
        pointReg: /^\+AI[ ]?[0-9]$/,
        points: 4,
        pointsStartAt: 0,
        aliasTo: [
            "MappedRack_{L}{N}_Slot{S:2}.CH{P}_FilteredIn",
            {aliasTo:  "MappedRack_{L}{N}_Slot{S:2}.CH{P}_InvalidReading", name: "{0:name}_Invalid", direction: "in"},
        ],
        type: [
            "REAL",
            "BOOL",
        ],
        direction: "in",
        analog: true,
        safetyType: "Standard",
        module_tags: [
            {
                name: "MappedRack_{L}{N}_Slot{S:2}",
                type: "Wago_4CH_AnalogIn_PTi",
                safetyType: "Standard",
                description: "Rack {L}{N} Slot {S}\n{DESC}\n{FILE}",
            },
        ]
    },
    {
        trigger: /750-564/,
        triggerLong: /750-564.*4[-| ]CHANNEL VOLTAGE\/CURRENT.*OUTPUT/i,
        description: "Wago 750-564\n4 CHANNEL VOLTAGE/CURRENT ANALOG OUTPUT",
        pointReg: /^\+AO[ ]?[0-9]$/,
        points: 4,
        pointsStartAt: 0,
        aliasTo: [
            "MappedRack_{L}{N}_Slot{S:2}.CH{P}_Out",
            {aliasTo:  "MappedRack_{L}{N}_Slot{S:2}.CH{P}_InvalidValue", name: "{0:name}_Invalid", direction: "in"},
        ],
        type: [
            "REAL",
            "BOOL",
        ],
        direction: "out",
        analog: true,
        safetyType: "Standard",
        module_tags: [
            {
                name: "MappedRack_{L}{N}_Slot{S:2}",
                type: "Wago_2CH_AnalogOut_PTi",
                safetyType: "Standard",
                description: "Rack {L}{N} Slot {S}\n{DESC}\n{FILE}",
            },
        ]
    },

    
    {
        trigger: /750-497/,
        triggerLong: /750-497.*8[ |-]CHANNEL/i,
        description: "Wago 750-497\n8 CHANNEL SINGLE-ENDED 0-10V ANALOG INPUT MODULE",
        pointReg: /^AI[ ]?[0-9]$/,
        points: 8,
        pointsStartAt: 0,
        aliasTo: [
            "MappedRack_{L}{N}_Slot{S:2}.CH{P}_FilteredVoltsIn",
            {aliasTo:  "MappedRack_{L}{N}_Slot{S:2}.CH{P}_InvalidVoltageReading", name: "{0:name}_Invalid", direction: "in"},
        ],
        type: [
            "REAL",
            "BOOL",
        ],
        direction: "in",
        analog: true,
        safetyType: "Standard",
        module_tags: [
            {
                name: "MappedRack_{L}{N}_Slot{S:2}",
                type: "Wago_8CH_AnalogVoltIn_PTi",
                safetyType: "Standard",
                description: "Rack {L}{N} Slot {S}\n{DESC}\n{FILE}",
            },
        ]
    },


    {
        trigger: /750-597/,
        triggerLong: /750-597.*8[ |-]CHANNEL/i,
        description: "Wago 750-597\n8 CHANNEL VOLTAGE ANALOG OUTPUT",
        pointReg: /^AO[ ]?[0-9]$/,
        points: 8,
        pointsStartAt: 0,
        aliasTo: [
            "MappedRack_{L}{N}_Slot{S:2}.CH{P}_VoltsOut",
            {aliasTo:  "MappedRack_{L}{N}_Slot{S:2}.CH{P}_InvalidVoltageValue", name: "{0:name}_Invalid", direction: "in"},
        ],
        type: [
            "REAL",
            "BOOL",
        ],
        direction: "out",
        analog: true,
        safetyType: "Standard",
        module_tags: [
            {
                name: "MappedRack_{L}{N}_Slot{S:2}",
                type: "Wago_8CH_AnalogVoltOut_PTi",
                safetyType: "Standard",
                description: "Rack {L}{N} Slot {S}\n{DESC}\n{FILE}",
            },
        ]
    },


    {
        trigger: /750-459/,
        triggerLong: /750-459.*4[ |-]CHANNEL/i,
        description: "Wago 750-459\n4 CHANNEL SINGLE-ENDED 0-10V ANALOG INPUT MODULE",
        pointReg: /^AI[ ]?[0-9]$/,
        points: 4,
        pointsStartAt: 0,
        aliasTo: [
            "MappedRack_{L}{N}_Slot{S:2}.CH{P}_FilteredVoltsIn",
            {aliasTo:  "MappedRack_{L}{N}_Slot{S:2}.CH{P}_InvalidVoltageReading", name: "{0:name}_Invalid", direction: "in"},
        ],
        type: [
            "REAL",
            "BOOL",
        ],
        direction: "in",
        analog: true,
        safetyType: "Standard",
        module_tags: [
            {
                name: "MappedRack_{L}{N}_Slot{S:2}",
                type: "Wago_4CH_AnalogVoltIn_PTi",
                safetyType: "Standard",
                description: "Rack {L}{N} Slot {S}\n{DESC}\n{FILE}",
            },
        ]
    },



    {
        trigger: /750-515/,
        triggerLong: /750-515.*4[ |-]CHANNEL/,
        description: "Wago 750-515\n4 CHANNEL RELAY OUTPUT MODULE",
        pointReg: /^[0-4]$/,
        points: 4,
        pointsStartAt: 0,
        pti_partnum: "4036712",
        aliasTo: [
            "MappedRack_{L}{N}_Slot{S:2}.OutputData.{P}",
        ],
        type: [
            "BOOL",
        ],
        direction: "out",
        safetyType: "Standard",
        module_tags: [
            {
                name: "MappedRack_{L}{N}_Slot{S:2}",
                type: "Wago_4CH_DigOut_PTi",
                safetyType: "Standard",
                description: "Rack {L}{N} Slot {S}\n{DESC}\n{FILE}",
            },
        ]
    },

    {
        trigger: /750-527/,
        triggerLong: /750-527.*4[ |-]CHANNEL.*SOLID STATE/,
        description: "Wago 750-527\n4 CHANNEL SOLID STATE OUTPUT",
        pointReg: /^DO [0-4]$/,
        points: 4,
        pointsStartAt: 0,
        pti_partnum: "4036711",
        aliasTo: [
            "MappedRack_{L}{N}_Slot{S:2}.OutputData.{P}",
        ],
        type: [
            "BOOL",
        ],
        direction: "out",
        safetyType: "Standard",
        module_tags: [
            {
                name: "MappedRack_{L}{N}_Slot{S:2}",
                type: "Wago_4CH_DigOut_PTi",
                safetyType: "Standard",
                description: "Rack {L}{N} Slot {S}\n{DESC}\n{FILE}",
            },
        ]
    },

    
    {
        trigger: /750-530/,
        triggerLong: /750-530.*8[ |-]CHANNEL/,
        description: "Wago 750-530\n8 CHANNEL 24VDC SOURCING DIGITAL OUTPUT MODULE",
        pointReg: /^DO[ ]?[0-8]$/,
        points: 8,
        pointsStartAt: 0,
        pti_partnum: "4036709",
        aliasTo: [
            "MappedRack_{L}{N}_Slot{S:2}.OutputData.{P}",
        ],
        type: [
            "BOOL",
        ],
        direction: "out",
        safetyType: "Standard",
        module_tags: [
            {
                name: "MappedRack_{L}{N}_Slot{S:2}",
                type: "Wago_8CH_DigOut_PTi",
                safetyType: "Standard",
                description: "Rack {L}{N} Slot {S}\n{DESC}\n{FILE}",
            },
        ]
    },
    

    {
        trigger: /750-1405/,
        triggerLong: /750-1405.*16[ |-]CHANNEL SINKING DIGITAL INPUT/,
        pti_partnum: "4036708",
        // triggerModule: /-515/,
        description: "Wago 750-1405\n16 CHANNEL SINKING DIGITAL INPUT MODULE",
        pointReg: /^DI[ ]?[0-9]+$/,
        points: 16,
        pointsStartAt: 0,
        aliasTo: [
            "MappedRack_{L}{N}_Slot{S:2}.InputData.{P}",
        ],
        type: [
            "BOOL",
        ],
        direction: "in",
        safetyType: "Standard",
        module_tags: [
            {
                name: "MappedRack_{L}{N}_Slot{S:2}",
                type: "Wago_16CH_DigIn_PTi",
                safetyType: "Standard",
                description: "Rack {L}{N} Slot {S}\n{DESC}\n{FILE}",
            },
        ]
    },
    {
        trigger: /750-430/,
        triggerLong: /750-430 8[ |-]CHANNEL SINKING DIGITAL INPUT/,
        pti_partnum: "4036707",
        description: "Wago 750-430\n8 CHANNEL SINKING DIGITAL INPUT MODULE",
        pointReg: /^DI[ ]?[0-8]$/,
        points: 8,
        pointsStartAt: 0,
        aliasTo: [
            "MappedRack_{L}{N}_Slot{S:2}.InputData.{P}",
        ],
        type: [
            "BOOL",
        ],
        direction: "in",
        safetyType: "Standard",
        module_tags: [
            {
                name: "MappedRack_{L}{N}_Slot{S:2}",
                type: "Wago_8CH_DigIn_PTi",
                safetyType: "Standard",
                description: "Rack {L}{N} Slot {S}\n{DESC}\n{FILE}",
            },
        ]
    },

    {
        trigger: /750-1504/,
        triggerLong: /750-1504 8[ |-]CHANNEL SOURCING DIGITAL OUTPUT/,
        pti_partnum: "4036710",
        description: "Wago 750-1504\n8 CHANNEL SOURCING DIGITAL OUTPUT MODULE",
        pointReg: /^DO[ ]?[0-8]+$/,
        points: 16,
        pointsStartAt: 0,
        aliasTo: [
            "MappedRack_{L}{N}_Slot{S:2}.OutputData.{P}",
        ],
        type: [
            "BOOL",
        ],
        direction: "out",
        safetyType: "Standard",
        module_tags: [
            {
                name: "MappedRack_{L}{N}_Slot{S:2}",
                type: "Wago_16CH_DigOut_PTi",
                safetyType: "Standard",
                description: "Rack {L}{N} Slot {S}\n{DESC}\n{FILE}",
            },
        ]
    },
]

export default knownModuleWago;