import type {KnownIO} from "../types/io";

let knownIoWinder: Array<KnownIO> = [
	// SAFETY
	{
		description: /WINDER EMERGENCY STOP CIRCUIT ([0-9]+)/i,
		name: "W_Estop{1}_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		winder: true
	},
	{
		examples: [
			"PENDANT 1 ENCLOSURE E-STOP PB",
		],
		description: /PENDANT ([0-9]) ENCLOSURE E-STOP PB/i,
		name: "W_Pendant{1}_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		winder: true
	},
	{
		examples: [
			"WINDER EMERGENCY STOP CIRCUIT",
			"EMERGENCY STOP CIRCUIT TO L-W10502, L-W10503, FROM L-W11516, L-W11517",
		],
		description: /EMERGENCY STOP CIRCUIT/i,
		name: "W_Estop_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		winder: true
	},
	{	
		examples: [
			"CART 1 ENCLOSURE E-STOP PBL",
			"CART 2 ENCLOSURE E-STOP PBL",
		],
		description: /CART ([1-9]|[A-Z]) ENCLOSURE E-STOP PB/i,
		name: "WS{1L}_Cart_Estop_PB_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		winder: true,
		safetyType: "Safety",
	},

	{
		examples: [
			"WINDER SYSTEM RESET PBL",
		],
		description: /SYSTEM RESET PB/i,
		name: "W_Estop_Reset_PB",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		winder: true
	},

	{
		examples: [
			"ACCUMULATOR GATE SWITCH DOOR CLOSED",
		],
		description: /ACCUMULATOR GATE SWITCH.*DOOR/i,
		name: "W_AccumGateClosed_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		winder: true
	},
	{
		examples: [
			"ACCUMULATOR OPERATOR STATION/DOOR SWITCH (P7)",
		],
		description: /ACCUMULATOR.*OPERATOR.*DOOR/i,
		name: "W_AccumOperatorDoor_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		winder: true
	},
	{
		examples: [
			"ACCUMULATOR GATE SWITCH DOOR CLOSED SAFETY OUTPUTS",
		],
		description: /ACCUMULATOR.*DOOR/i,
		name: "W_AccumDoor_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		winder: true
	},
	{
		examples: [
			"ACCUMULATOR EMPTY SAFETY SWITCH",
		],
		description: /ACCUMULATOR EMPTY SAFETY SWITCH/i,
		name: "W_AccumEmpty_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		winder: true
	},
	{
		examples: [
			"ACCUMULATOR FULL SAFETY SWITCH",
		],
		description: /ACCUMULATOR FULL SAFETY SWITCH/i,
		name: "W_AccumFull_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		winder: true
	},

	{
		examples: [
			"WINDER SPINDLE B SLIDING DOOR SAFETY SWITCH",
			"WINDER SPINDLE A SLIDING DOOR SAFETY SWITCH",
			"WINDER SPINDLE A DOOR SAFETY SWITCH GSS (BARREL)",
		],
		description: /SPINDLE ([1-9]|[A-Z]).* DOOR/i,
		name: "WS{1L}_Door_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		winder: true
	},
	{
		examples: [
			"SPINDLE B JOG FOOT SWITCH",
			"SPINDLE A JOG FOOT SWITCH",
		],
		description: /SPINDLE ([1-9]|[A-Z]) JOG FOOT/i,
		name: "WS{1L}_FootSw_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		winder: true
	},
	

	{
		examples: [
			"PULL ROLL GUARD SAFETY SWITCHES",
			"PULL ROLL 1 GUARD SWITCH GSS ",
			"PULL ROLL 2 GUARD SWITCH GSS (BARREL)",
		],
		description: /PULL ROLL[ ]?([0-9]?) GUARD/i,
		name: "W_PullRoll{1}_Guard_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		winder: true
	},
	{
		examples: [
			"STAIR ACCESS GATE SAFETY SWITCH",
		],
		description: /STAIR ACCESS GATE/i,
		name: "W_Stair_Guard_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		winder: true
	},







	// SAFETY OUTPUT
	{
		examples: [
			"PULL ROLL AC DRIVE SAFE TORQUE-OFF SIGNAL",
			"PULL ROLL 1 AC DRIVE SAFE TORQUE-OFF SIGNAL (1DR) TO",
		],
		description: /PULL ROLL[ ]?([0-9]?) AC DRIVE (SAFE TORQUE|STO)/i,
		name: "W_PullRoll{1}_STO_Ch{EVEN+1}",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		winder: true
	},
	{
		examples: [
			"AUXILIARY NIP ROLL AC DRIVE SAFE TORQUE-OFF SIGNAL",
		],
		description: /AUX.* NIP ROLL AC DRIVE SAFE TORQUE/i,
		name: "W_PullRoll2_STO_Ch{EVEN+1}",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		winder: true
	},
	{
		examples: [
			"SPINDLE B AC DRIVE SAFE TORQUE-OFF SIGNAL",
			"SPINDLE A AC DRIVE SAFE TORQUE-OFF SIGNAL",
		],
		description: /SPINDLE ([1-9]|[A-Z]) AC DRIVE SAFE TORQUE/i,
		name: "WS{1L}_STO_Ch{EVEN+1}",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		winder: true
	},

	{
		examples: [
			"SLITTER AC DRIVE SAFE TORQUE-OFF SIGNAL",
		],
		description: /SLITTER AC DRIVE SAFE TORQUE/i,
		name: "W_Slitter_STO_Ch{EVEN+1}",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		winder: true
	},
	{
		examples: [
			"CART 2 TRACTION AC DRIVE SAFE TORQUE-OFF SIGNAL",
			"CART 1 TRACTION AC DRIVE SAFE TORQUE-OFF SIGNAL",
		],
		description: /CART ([1-9]|[A-Z]) TRACTION AC DRIVE SAFE TORQUE/i,
		name: "WS{1L}_Cart_STO_Ch{EVEN+1}",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		winder: true
	},

	{
		examples: [
			"WINDER E-STOP SAFETY RELAY",
			"WINDER E-STOP RELAYS WINDER ESR 1"
		],
		description: [
			/WINDER E-STOP SAFETY RELAY/i,
			/WINDER E-STOP RELAYS/i
		],
		name: "W_EStop_Coil",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		winder: true
	},
	{
		examples: [
			"ACCUMULATOR DOOR CLOSED SAFETY RELAY",
		],
		description: /ACCUMULATOR DOOR CLOSED SAFETY RELAY/i,
		name: "W_Accum_Safety_Coil",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		winder: true
	},
	{
		examples: [
			"CROSS-CUT SAFETY RELAY",
		],
		description: /CROSS-CUT SAFETY RELAY/i,
		name: "W_CrossCut_Safety_Coil",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		winder: true
	},
	{
		examples: [
			"ACCUMULATOR OPERATOR STATION/DOOR SWITCH (P7)",
			"ACCUMULATOR GATE SWITCH DOOR UNLOCK SOLENOID",
			"ACCUMULATOR DOOR GATE SWITCH DOOR UNLOCK SOL",
		],
		description: /ACCUMULATOR.* GATE SWITCH DOOR UNLOCK/i,
		name: "W_AccumDoor_Unlock_Sol",
		type: "BOOL",
		direction: "out",
		safetyType: "Safety",
		winder: true
	},









	// INPUTS
	{	
		examples: [
			"WINDER MAIN ENCLOSURE E-STOP PBL",
		],
		description: /MAIN ENCLOSURE E-STOP PB/i,
		name: "W_Enclosure_Estop",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"E-STOP RESERVED INPUT",
		],
		description: /E-STOP RESERVED/i,
		name: "W_EStop_Reserved_{N}_{S}_{P}",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"ACCUMULATOR INFEED PULL CORD COS",
		],
		description: /ACCUMULATOR INFEED PULL CORD/i,
		name: "W_Accum_Infeed_Estop_PullCord",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"ACCUMULATOR GATE SWITCH E-STOP PB",
			"ACCUMULATOR DOOR OPERATOR STATION/DOOR SWITCH E-STOP PB (P7)",
			"ACCUMULATOR DOOR OPERATOR STATION/ DOOR SWITCH E-STOP PBL (P7)", // 18085-w121a
		],
		description: [
			/ACCUMULATOR GATE SWITCH E-STOP/i,
			/ACCUMULATOR DOOR OPERATOR STATION.*E-STOP/i,
		],
		name: "W_Accum_Door_Estop_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"ACCUMULATOR OUTFEED/ SLITTER INFEED PULL CORD COS",
		],
		description: /ACCUMULATOR OUTFEED.*CORD/i,
		name: "W_Accum_Outfeed_Estop_PulLCord",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"SLITTER OPERATOR STATION/ JUNCTION BOX E-STOP PBL",
			"SLITTER J-BOX E-STOP PBL (P8)"
		],
		description: [
			/SLITTER OPERATOR STATION.*E-STOP/i,
			/SLITTER .*E-STOP PB/i
		],
		name: "W_Slitter_Estop_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},

	{	
		examples: [
			"CENTER SLITTER PULL CORD COS",
		],
		description: [
			/SLITTER PULL CORD/i,
		],
		name: "W_Slitter_Estop_PullCord",
		type: "BOOL",
		direction: "in",
		winder: true
	},

	{
		examples: [
			"PULL ROLL 1 JOG STATION E-STOP PBL (P5)",
			"PULL ROLL 2 JOG STATION E-STOP PBL (P6)",
		],
		description: /PULL ROLL ([1-9]) JOG STATION E-STOP/i,
		name: "W_PullRoll{1}_Jog_Estop_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{
		examples: [
			"PULL ROLL 2 JOG PB",
		],
		description: /PULL ROLL ([1-9]) JOG PB/i,
		name: "W_PullRoll{1}_Jog_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{
		examples: [
			"PULL ROLL 1 J-BOX E-STOP PBL (P3)",
		],
		description: /PULL ROLL ([1-9]) J-BOX E-STOP/i,
		name: "W_PullRoll{1}_Jbox_Estop_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{
		examples: [
			"PULL ROLL 2 INFEED PULL CORD COS",
		],
		description: /PULL ROLL ([1-9]) INFEED PULL/i,
		name: "W_PullRoll{1}_Infeed_Estop_PullCord",
		type: "BOOL",
		direction: "in",
		winder: true
	},


	{	
		examples: [
			"SPINDLE B THREADING OPERATOR STATION E-STOP PBL",
			"SPINDLE A THREADING OPERATOR STATION E-STOP PBL",
		],
		description: /SPINDLE ([A-Z]) THREADING OPERATOR STATION E-STOP/i,
		name: "W_Spindle{1}_Estop_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},

	{	
		examples: [
			"PULL ROLL 1 BRAKING RESISTOR THERMOSTAT 1BRTAS",
		],
		description: /PULL ROLL ([1-9]) BRAKING RESISTOR/i,
		name: "W_PullRoll{1}_Brake_Res_Sw",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"THERMOSTAT BRAKING RESISTOR",
		],
		description: /THERMOSTAT BRAKING RESISTOR/i,
		name: "W_Brake_Res_Sw",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	
	{	
		examples: [
			"PULL ROLL MOTOR THERMOSTAT (1TAS)",
			"PULL ROLL 1 MOTOR THERMOSTAT (1TAS)",
			"PULL ROLL 2 MOTOR THERMOSTAT (2TAS) FROM L-W10215",
		],
		description: /PULL ROLL[ ]?([0-9]?) MOTOR THERMOSTAT/i,
		name: "W_PullRoll{1}_Thermal_Sw",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"AUXILIARY NIP ROLL MOTOR THERMOSTAT (2TAS)",
		],
		description: /AUXILIARY NIP ROLL MOTOR THERMOSTAT/i,
		name: "W_PullRoll2_Thermal_Sw",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"SPINDLE B MOTOR THERMOSTAT (4TAS)",
			"SPINDLE A MOTOR THERMOSTAT (3TAS)",
		],
		description: /SPINDLE ([A-Z]) MOTOR THERMOSTAT/i,
		name: "WS{1}_Thermal_Sw",
		type: "BOOL",
		direction: "in",
		winder: true
	},

	{	
		examples: [
			"WINDER SPINDLE A SHAFT GUARD SAFETY SWITCH GSS (BARREL)",
			"WINDER SPINDLE B SHAFT GUARD SAFETY SWITCH GSS (BARREL)",
		],
		description: /SPINDLE ([A-Z]).* SHAFT.* GUARD/i,
		name: "WS{1}_DriveShaft_Guard_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		winder: true
	},
	{	
		examples: [
			"SPINDLE A DRIVE SHAFT LIMIT SWITCH",
			"SPINDLE D DRIVE SHAFT LIMIT SWITCH",
		],
		description: /SPINDLE ([A-Z]).* SHAFT.* SWITCH/i,
		name: "WS{1}_DriveShaft_Limit_Sw",
		type: "BOOL",
		direction: "in",
		winder: true
	},


	{
		examples: [
			"EDGE SLITTER MOTOR 1 THERMAL OVERLOAD 7MOL-1 AUX",
		],
		description: /SLITTER MOTOR[ ]?([0-9]?) THERMAL/i,
		name: "W_Slitter{1}_MMSP_Ok",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{
		examples: [
			"SLITTER MOTOR THERMOSTAT (7TAS)",
			"EDGE SLITTER MOTOR 1 THERMOSTAT (7-1 TAS) FROM L-W10417",
		],
		description: /SLITTER MOTOR[ ]?([0-9]?) THERMOSTAT/i,
		name: "W_Slitter{1}_MotorThermostatOK",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"MOTOR THERMOSTAT RESERVED INPUT",
		],
		description: /MOTOR THERMOSTAT RESERVED/i,
		name: "W_Thermal_Reserved_{N}_{S}_{P}",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"SPINDLE B ANTI STATIC BAR ION WARNING (DIRTY ELECTRODE)",
			"SPINDLE A ANTI STATIC BAR ION WARNING (DIRTY ELECTRODE)",
		],
		description: /SPINDLE ([A-Z]) ANTI STATIC.*DIRTY/i,
		name: "WS{1L}_AntiStat_Dirty",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"SPINDLE B ANTI STATIC BAR CONDITION WARNING",
			"SPINDLE A ANTI STATIC BAR CONDITION WARNING",
		],
		description: /SPINDLE ([A-Z]) ANTI STATIC.*WARNING/i,
		name: "WS{1L}_AntiStat_Warning",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"SPINDLE B ANTI STATIC BAR FAULTED ALARM",
			"SPINDLE A ANTI STATIC BAR FAULTED ALARM",
		],
		description: /SPINDLE ([A-Z]) ANTI STATIC.*FAULT/i,
		name: "WS{1L}_AntiStat_Fault",
		type: "BOOL",
		direction: "in",
		winder: true
	},


	{	
		examples: [
			"SLITTER OPEN PB",
		],
		description: /SLITTER OPEN PB/i,
		name: "W_SlitterOpen_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"SLITTER CLOSE PBL",
		],
		description: /SLITTER CLOSE PBL/i,
		name: "W_SlitterClose_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"SLITTER EDGE/ALL SELECTOR SW",
		],
		description: /SLITTER EDGE.* SELECTOR SW/i,
		name: "W_SlitterCenter_Sw",
		type: "BOOL",
		direction: "in",
		winder: true
	},

	{	
		examples: [
			"SPINDLE A THREADING OPERATOR STATION (P2)",
			"SPINDLE B THREADING OPERATOR STATION (P3)",
			"SPINDLE A JOG PB",
		],
		description: /SPINDLE A JOG PB/i,
		name: "W_Jog_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"ACCUMULATOR GATE SWITCH STOP/EMPTY PBL",
			"ACCUMULATOR DOOR GATE SWITCH STOP/EMPTY PBL"
		],
		description: /ACCUMULATOR.* GATE SWITCH STOP/i,
		name: "W_AccumDoor_Stop_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"ACCUMULATOR GATE SWITCH DOOR OPEN PBL",
			"ACCUMULATOR DOOR GATE SWITCH DOOR OPEN PBL",
		],
		description: /ACCUMULATOR.* GATE SWITCH DOOR OPEN PB/i,
		name: "W_AccumDoor_Open_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"CROSS-CUT LEFT SIDE PROX",
		],
		description: /CUT LEFT SIDE PROX/i,
		name: "W_CrossCut_Home_Sw",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"CROSS-CUT RIGHT SIDE PROX",
		],
		description: /CUT RIGHT SIDE PROX/i,
		name: "W_CrossCut_Away_Sw",
		type: "BOOL",
		direction: "in",
		winder: true
	},


	{	
		examples: [
			"CART 2 LOCKED PROX",
			"CART 1 LOCKED PROX",
		],
		description: /CART ([0-9]) LOCKED PROX/i,
		name: "WS{1L}_CartLock_Sw",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"CART 2 UNLOCKED PROX",
			"CART 1 UNLOCKED PROX",
		],
		description: /CART ([0-9]) UNLOCKED PROX/i,
		name: "WS{1L}_CartUnlock_Sw",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"PUSHER 2 RETRACTED PROX",
			"PUSHER 1 RETRACTED PROX",
		],
		description: /PUSHER ([0-9]) RETRACTED PROX/i,
		name: "WS{1L}_PusherRetract_Sw",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"PUSHER 2 EXTENDED PROX",
			"PUSHER 1 EXTENDED PROX",
		],
		description: /PUSHER ([0-9]) EXTENDED PROX/i,
		name: "WS{1L}_PusherExtend_Sw",
		type: "BOOL",
		direction: "in",
		winder: true
	},


	{	
		examples: [
			"CART 1 CRADLE RAISE PBL",
		],
		description: /CART ([1-9]|[A-Z]) CRADLE RAISE PB/i,
		name: "WS{1L}_CradleRaise_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"CART 1 CRADLE LOWER PB",
		],
		description: /CART ([1-9]|[A-Z]) CRADLE LOWER PB/i,
		name: "WS{1L}_CradleLower_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"CART 1 CART OUT PB",
		],
		description: /CART ([1-9]|[A-Z]) CART OUT PB/i,
		name: "WS{1L}_CartOut_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"CART 1 CART IN PB",
		],
		description: /CART ([1-9]|[A-Z]) CART IN PB/i,
		name: "WS{1L}_CartIn_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"CART 1 ROTATE TO UNLOAD PB",
		],
		description: /CART ([1-9]|[A-Z]) ROTATE TO UNLOAD PB/i,
		name: "WS{1L}_CradleRotate_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"CART 1 ROTATE TO RETRACT PB",
		],
		description: /CART ([1-9]|[A-Z]) ROTATE TO RETRACT PB/i,
		name: "WS{1L}_CradleReturn_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	

	{	
		examples: [
			"CART 1 ENCLOSURE E-STOP PBL",
		],
		description: /CART ([1-9]|[A-Z]) ENCLOSURE E-STOP PB/i,
		name: "WS{1L}_Cart_Estop_PB",
		type: "BOOL",
		direction: "in",
		winder: true,
		safetyType: "Standard",
	},


	{	
		examples: [
			"CART 1 CRADLE ROTATION TO RUN POSITION",
		],
		description: /CART ([1-9]|[A-Z]) CRADLE ROTATION TO RUN POSITION/i,
		name: "WS{1L}_CradleRotate_Sw",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"CART 1 OUTSIDE LIMIT",
		],
		description: /CART ([1-9]|[A-Z]) OUTSIDE LIMIT/i,
		name: "WS{1L}_CartOut_Sw",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"CART 1 INSIDE SLOW POSITION",
		],
		description: /CART ([1-9]|[A-Z]) INSIDE SLOW POSITION/i,
		name: "WS{1L}_CartSlow_Sw",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"CART 1 INSIDE LIMIT",
		],
		description: /CART ([1-9]|[A-Z]) INSIDE LIMIT/i,
		name: "WS{1L}_CartIn_Sw",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"CART 1 CRADLE RAISE LIMIT",
		],
		description: /CART ([1-9]|[A-Z]) CRADLE RAISE LIMIT/i,
		name: "WS{1L}_CradleRaise_Sw",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"CART 1 CRADLE LOWER LIMIT",
		],
		description: /CART ([1-9]|[A-Z]) CRADLE LOWER LIMIT/i,
		name: "WS{1L}_Cart_Lower_Lim_Sw",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"CART 1 CHUCK CLOSED SWITCH",
		],
		description: /CART ([1-9]|[A-Z]) CHUCK CLOSED SWITCH/i,
		name: "WS{1L}_ChuckClosed_Sw",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"CART 1 CHUCK OPEN SWITCH",
		],
		description: /CART ([1-9]|[A-Z]) CHUCK OPEN SWITCH/i,
		name: "WS{1L}_ChuckOpen_Sw",
		type: "BOOL",
		direction: "in",
		winder: true
	},

	{	
		examples: [
			"CART 1 CRADLE RAISE PUMP MMSP",
		],
		description: /CART ([1-9]|[A-Z]) CRADLE RAISE PUMP MMSP/i,
		name: "WS{1L}_CradlePump_MMSP",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"CART 1 CRADLE RAISE PUMP CONTACTOR AUX.",
		],
		description: /CART ([1-9]|[A-Z]) CRADLE RAISE PUMP CONTACTOR AUX./i,
		name: "WS{1L}_Cart_HydPump_Aux",
		type: "BOOL",
		direction: "in",
		winder: true
	},







	{	
		examples: [
			"PENDANT OPERATOR STATION (P1)",
			"WINDER OPERATOR PENDANT E-STOP PBL",
		],
		description: /WINDER OPERATOR PENDANT E-STOP/i,
		name: "W_Pendant_Estop",
		type: "BOOL",
		direction: "in",
		winder: true
	},

	{	
		examples: [
			"PULL ROLL 1 OPEN PB PENDANT OPERATOR STATION",
		],
		description: [
			/PULL ROLL[ ]?([0-9]?) (OPEN|CLOSE) PB PENDANT/i,
		],
		name: "W_Pendant_PullRoll{1}_{2:Cap}_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"PULL ROLL OPEN PB",
			"PULL ROLL CLOSE PBL",
			"PULL ROLL 1 OPEN PB",
		],
		description: [
			/PULL ROLL[ ]?([0-9]?) (OPEN|CLOSE) PB/i,
		],
		name: "W_PullRoll{1}_{2:Cap}_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"PENDANT OPERATOR STATION (P1)",
			"ACCUMULATOR EMPTY SELECTOR SWITCH SSL",
		],
		description: /ACCUMULATOR EMPTY SELECTOR SWITCH/i,
		name: "W_Accum_Stop_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"ACCUMULATOR START SELECTOR SWITCH SSL",
		],
		description: /ACCUMULATOR START SELECTOR SWITCH/i,
		name: "W_Accum_Start_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"SPINDLE A OPERATOR STATION (P4)",
			"SPINDLE B OPERATOR STATION (P5)",
			"ACCUMULATOR CUTOVER SELECTOR SWITCH SSL",
		],
		// RESUME
		description: /ACCUMULATOR CUTOVER.* (SELECTOR SWITCH|SSL)/i,
		name: "W_Accum_Cutover_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"ACCUMULATOR CUTOVER/RESUME SSL PENDANT OPERATOR STATION 1 (P1)",
		],
		// RESUME
		description: /ACCUMULATOR CUTOVER.* (SELECTOR SWITCH|SSL).* STATION ([0-9])/i,
		name: "W_Accum_Cutover{2}_PBL",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"ACCUMULATOR RESUME SELECTOR SWITCH SSL",
		],
		description: /ACCUMULATOR RESUME SELECTOR SWITCH/i,
		name: "W_Accum_Resume_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},

	{	
		examples: [
			"SPINDLE B E-STOP PBL",
			"SPINDLE A E-STOP PBL",
		],
		description: /SPINDLE ([1-9]|[A-Z]) E-STOP PB/i,
		name: "WS{1L}_Pendant_Estop",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"SPINDLE B START PBL",
			"SPINDLE A START PBL",
		],
		description: /SPINDLE ([1-9]|[A-Z]) START PBL/i,
		name: "WS{1L}_Start_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{	
		examples: [
			"SPINDLE B STOP PB",
			"SPINDLE A STOP PB",
		],
		description: /SPINDLE ([1-9]|[A-Z]) STOP PB/i,
		name: "WS{1L}_Stop_PB",
		type: "BOOL",
		direction: "in",
		winder: true
	},



	///////////////////////////////////////
	// OUTPUTS
	{	
		examples: [
			"ACCUMULATOR UNLOCK SOLENOID",
		],
		description: /ACCUMULATOR UNLOCK SOLENOID/i,
		name: "W_Accum_Unlock_Sol",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"ACCUMULATOR WORKING DSV",
		],
		description: /ACCUMULATOR WORKING DSV/i,
		name: "W_Accum_Working_Sol",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"ACCUMULATOR EMPTY DSV",
		],
		description: /ACCUMULATOR EMPTY DSV/i,
		name: "W_Accum_Empty_Sol",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"PULL ROLL CLOSE SOLENOID",
			"PULL ROLL 1 CLOSE SOLENOID",
			"PULL ROLL 2 CLOSE SOLENOID",
		],
		description: /PULL ROLL[ ]?([0-9]?) CLOSE SOL/i,
		name: "W_PullRoll{1}_Close_Sol",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"AUXILIARY NIP ROLL CLOSE SOLENOID",
		],
		description: /AUXILIARY NIP ROLL CLOSE SOL/i,
		name: "W_PullRoll2_Close_Sol",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"ACCUMULATOR OPERATOR STATION/DOOR SWITCH (P7)",
			"ACCUMULATOR GATE SWITCH DEVICE RESET",
		],
		description: /ACCUMULATOR GATE.* RESET/i,
		name: "W_AccumDoor_Reset",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"ACCUMULATOR GATE SWITCH STOP/EMPTY PBL INDICATOR",
			"ACCUMULATOR DOOR GATE SWITCH STOP/EMPTY PB INDICATOR [L-W10811] ACCUMULATOR DOOR OPERATOR STATION/DOOR SWITCH (P7)",
		],
		description: /ACCUMULATOR .*GATE SWITCH STOP.*IND/i,
		name: "W_AccumDoor_Stop_PBL",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"ACCUMULATOR GATE SWITCH DOOR OPEN PBL INDICATOR",
			"ACCUMULATOR DOOR GATE SWITCH DOOR OPEN PB INDICATOR [L-W10813]"
		],
		description: /ACCUMULATOR.* GATE SWITCH.* OPEN.*IND/i,
		name: "W_AccumDoor_Open_PBL",
		type: "BOOL",
		direction: "out",
		winder: true
	},


	{	
		examples: [
			"SLITTER 2 CLOSE SOLENOID",
			"SLITTER 3 CLOSE SOLENOID",
			"SLITTER 4 CLOSE SOLENOID",
			"SLITTER 5 CLOSE SOLENOID",
			"SLITTER 1 CLOSE SOLENOID",
		],
		description: /SLITTER ([1-9]+) CLOSE SOLENOID/i,
		name: "W_Slitter{1}_Close_Sol",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"SPINDLE B ANTI STATIC RELAY",
			"SPINDLE A ANTI STATIC RELAY",
		],
		description: /SPINDLE ([A-Z]|[0-9]) ANTI STATIC RELAY/i,
		name: "WS{1L}_AntiStat_Coil",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"SLITTER CLOSE PBL",
			"EDGE SLITTER CLOSE PB INDICATOR EDGE SLITTER OPERATOR STATION (P8)"
		],
		description: /SLITTER CLOSE (PBL|.*INDICATOR)/i,
		name: "W_Slitter_Close_PBL",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"EDGE SLITTER CLOSE SOLENOID",
			"CENTER SLITTER CLOSE SOLENOID"
		],
		description: /(EDGE|CENTER) SLITTER CLOSE SOL/i,
		name: "W_Slitter_{1:Cap}Close_Sol",
		type: "BOOL",
		direction: "out",
		winder: true
	},

	
	{	
		examples: [
			"CROSS-CUT CLAMP SOLENOID",
		],
		description: /CUT CLAMP SOL/i,
		name: "W_CrossCut_Clamp_Sol",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"CROSS-CUT MOVE RIGHT DSV",
		],
		description: /CUT MOVE RIGHT/i,
		name: "W_CrossCut_Away_Sol",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"CROSS-CUT MOVE LEFT DSV",
		],
		description: /CUT MOVE LEFT/i,
		name: "W_CrossCut_Home_Sol",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"MOVABLE GUARD SPINDLE B DSV",
			"MOVABLE GUARD SPINDLE A DSV",
		],
		description: /MOVABLE GUARD SPINDLE ([A-Z]|[0-9])/i,
		name: "WS{1L}_Guard_Sol",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"SPINDLE BANTI STATIC AIR VALVE SOLENOID D",
			"SPINDLE A ANTI STATIC AIR VALVE SOLENOID",
		],
		description: /SPINDLE ([A-Z]|[0-9])[ ]?ANTI STATIC AIR VALVE/i,
		name: "WS{1L}_AntiStat_Sol",
		type: "BOOL",
		direction: "out",
		winder: true
	},


	{	
		examples: [
			"CART 2 PUSHER EXTEND DSV",
			"CART 1 PUSHER EXTEND DSV",
		],
		description: /CART ([A-Z]|[0-9]) PUSHER EXTEND/i,
		name: "WS{1L}_PusherExtend_Sol",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"CART 2 PUSHER RETRACT DSV",
			"CART 1 PUSHER RETRACT DSV",
		],
		description: /CART ([A-Z]|[0-9]) PUSHER RETRACT/i,
		name: "WS{1L}_PusherRetract_Sol",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"CART 2 LOCK DSV",
			"CART 1 LOCK DSV",
		],
		description: /CART ([A-Z]|[0-9]) LOCK/i,
		name: "WS{1L}_CartLock_Sol",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"CART 2 UNLOCK DSV",
			"CART 1 UNLOCK DSV",
		],
		description: /CART ([A-Z]|[0-9]) UNLOCK/i,
		name: "WS{1L}_CartUnlock_Sol",
		type: "BOOL",
		direction: "out",
		winder: true
	},


	

	{	
		examples: [
			"WINDER FAN RELAY",
		],
		description: /WINDER FAN RELAY/i,
		name: "W_Fan_Coil",
		type: "BOOL",
		direction: "out",
		winder: true
	},

	{	
		examples: [
			"SYSTEM RESET PBL",
		],
		description: /SYSTEM RESET PB/i,
		name: "W_EStop_Reset_PBL",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"PULL ROLL CLOSE PBL",
			"PULL ROLL 1 CLOSE PBL INDICATOR",
			"PULL ROLL 2 CLOSE PBL INDICATOR",
		],
		description: /PULL ROLL[ ]?([0-9]?) CLOSE PB/i,
		name: "W_PullRoll{1}_Close_PBL",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"ACCUMULATOR EMPTY/START SSL",
		],
		description: /ACCUMULATOR EMPTY.*SSL/i,
		name: "W_Accum_Start_PBL",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"SPINDLE B ACCUMULATOR CUTOVER/RESUME SSL",
			"SPINDLE A ACCUMULATOR CUTOVER/RESUME SSL",
		],
		description: /SPINDLE ([1-9]|[A-Z]) ACCUMULATOR CUTOVER/i,
		name: "WS{1L}_Cutover_PBL",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"SPINDLE B START PBL",
			"SPINDLE A START PBL",
		],
		description: /SPINDLE ([1-9]|[A-Z]) START PB/i,
		name: "WS{1L}_Start_PBL",
		type: "BOOL",
		direction: "out",
		winder: true
	},


	{	
		examples: [
			"WINDER ALARM FLASHING LIGHT RED",
		],
		description: /WINDER ALARM.* LIGHT RED/i,
		name: "W_StackLight_Red_LT",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"SACK LIGHT AMBER",
		],
		description: /STACK LIGHT AMBER/i,
		name: "W_StackLight_Amber_LT",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"WINDER STARTED FLASHING LIGHT GREEN",
		],
		description: /WINDER STARTED.* LIGHT GREEN/i,
		name: "W_StackLight_Green_LT",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"WINDER ALARM BUZZER",
		],
		description: /.*BUZZER/i,
		name: "W_StackLight_Horn",
		type: "BOOL",
		direction: "out",
		winder: true
	},


	{	
		examples: [
			"CART 1 CHUCK OPEN DSV",
		],
		description: /CART ([1-9]|[A-Z]) CHUCK OPEN DSV/i,
		name: "WS{1L}_ChuckOpen_Sol",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"CART 1 CHUCK CLOSE DSV",
		],
		description: /CART ([1-9]|[A-Z]) CHUCK CLOSE DSV/i,
		name: "WS{1L}_ChuckClose_Sol",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"CART 1 CRADLE RAISE PUMP CONTACTOR",
		],
		description: /CART ([1-9]|[A-Z]) CRADLE RAISE PUMP CONTACTOR/i,
		name: "WS{1L}_CradlePump_Coil",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"CART 1 CRADLE RAISE PBL",
		],
		description: /CART ([1-9]|[A-Z]) CRADLE RAISE PBL/i,
		name: "WS{1L}_CradleRaise_PBL",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"CART 1 CRADLE ROTATE DSV",
		],
		description: /CART ([1-9]|[A-Z]) CRADLE ROTATE DSV/i,
		name: "WS{1L}_CradleRotate_Sol",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"CART 1 CRADLE ROTATE RETURN DSV",
		],
		description: /CART ([1-9]|[A-Z]) CRADLE ROTATE RETURN DSV/i,
		name: "WS{1L}_CradleReturn_Sol",
		type: "BOOL",
		direction: "out",
		winder: true
	},
	{	
		examples: [
			"CART 1 CRADLE LOWER SOLENOID",
		],
		description: /CART ([1-9]|[A-Z]) CRADLE LOWER SOLENOID/i,
		name: "WS{1L}_CradleLower_Sol",
		type: "BOOL",
		direction: "out",
		winder: true
	},



	// ANALOG INPUTS

	// CART 1 LOAD CELL
	{

		description: /CART ([1-9]|[A-B]) LOAD CELL/i,
		name: "WS{1L}_Cradle_WeightRaw",
		type: "INT",
		direction: "in",
		winder: true,
	},
	{
		description: /CART ([1-9]|[A-B]) LOAD CELL/i,
		name: "WS{1L}_Cradle_Weight_Status",
		type: "SINT",
		direction: "in",
		winder: true,
	},
	{
		description: /CART ([1-9]|[A-B]) LOAD CELL/i,
		name: "WS{1L}_Cradle_Weight_Fault",
		type: "BOOL",
		direction: "in",
		winder: true,
	},






	////////////////////////////////////
	// SPARE
	//
	{
		description: /SPARE T\/C INPUT/i,
		name: "{L}_Spare_TC_Rack{N}_S{S}_{P}",
		type: "INT",
		direction: "in",
		winder: true,
	},
	{
		description: /SPARE T\/C INPUT/i,
		name: "{L}_Spare_Sbreak_Rack{N}_S{S}_{P}",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{
		description: /SPARE/i,
		name: "{L}_Spare_Rack{N}_S{S}_{P}_in_int",
		type: "INT",
		direction: "in",
		winder: true
	},
	{
		description: /SPARE/i,
		name: "{L}_Spare_Rack{N}_S{S}_{P}_in_status_sint",
		type: "SINT",
		direction: "in",
		winder: true
	},
	{
		description: /SPARE/i,
		name: "{L}_Spare_Rack{N}_S{S}_{P}_out_int",
		type: "INT",
		direction: "out",
		winder: true
	},
	{
		description: /SPARE/i,
		name: "{L}_Spare_Rack{N}_S{S}_{P}_in",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{
		description: /SPARE/i,
		name: "{L}_Spare_Rack{N}_S{S}_{P}_out",
		type: "BOOL",
		direction: "out",
		winder: true
	},

	// RESERVED
	{
		description: /RESERVED/i,
		name: "{L}_Reserved_Rack{N}_S{S}_{P}_in",
		type: "BOOL",
		direction: "in",
		winder: true
	},
	{
		description: /RESERVED/i,
		name: "{L}_Reserved_Rack{N}_S{S}_{P}_out",
		type: "BOOL",
		direction: "out",
		winder: true
	},
]

export  {knownIoWinder as default}
