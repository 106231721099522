
import { PvTagPath, AlarmTriggerID, PartialTagname, TagDataTypeName } from "./types";
import { AlarmsXmlTrigger } from "./xml-format-trigger";
import { AlarmMessage } from "./message";
import type { AlarmLevel } from './level';
import type { TriggerMessageTemplate } from "./message";


/**
 * Number of bits in a tag of this type.
 */
 export const simple_types: {[key: TagDataTypeName]: number} = {
    "BOOL": 0,
    "INT": 16,
    "DINT": 32,
    "SINT": 8,
    "LINT": 64,
    "REAL": 0,
    "MODULE": 0,
}
export function is_simple_type(typename: TagDataTypeName) {
    return (typename in simple_types);
}

export type trigger_index = "bit" | "value"

export class TriggerTemplate {
    tagname: PartialTagname;
    description: string;
    level: AlarmLevel;
    bits: number;
    index_type: trigger_index;
    messages: TriggerMessageTemplate[];
    typename: TagDataTypeName;
    parent_type?: TagDataTypeName;

    constructor(tagname: PartialTagname, description: string, level: AlarmLevel, typename: TagDataTypeName, parent_type?: TagDataTypeName, index_type?: trigger_index) {
        this.tagname = tagname;
        this.description = description;
        this.level = level;
        this.index_type = index_type || "bit";
        this.bits = simple_types[typename];
        this.messages = [];
        this.typename = typename;
        this.parent_type = parent_type;
    }
}



export class AlarmTrigger {
    static index = 0;
    pvtag: PvTagPath;
    id: AlarmTriggerID;
    label: string;
    label_with_level: string;
    template: TriggerTemplate;
    messages: {[bit_index: number]: AlarmMessage} = {};
    // non-spare messages
    real_messages: {[bit_index: number]: AlarmMessage} = {};

    /**
     * 
     * @param plctag Full PLC side path to tag including scope and nesting
     * @param label 
     */
    constructor(plctag: PartialTagname, label: string, template: TriggerTemplate) {
        AlarmTrigger.index += 1;
        this.pvtag = "{[PV]" + plctag + "}";
        this.id = "T" + AlarmTrigger.index;
        this.template = template;
        this.label = this.reformat_label(label);
        this.label_with_level = this.label_with_level = this.template.level.humanName + " " + this.label;

        // Create messages for each bit that is explicitly labelled in the type defintition
        for (const message of this.template.messages) {
            const m = new AlarmMessage(this, message);
            this.messages[message.bit_index] = m;
            this.real_messages[message.bit_index] = m;
        }

        // Automatically create spares for any bits not labelled in the type definition
        if (template.index_type === "bit") {
            for (let i = 0; i < this.template.bits; i++) {
                if (! (i in this.messages)) {
                    this.messages[i] = new AlarmMessage(this, { 
                        bit_index: i, 
                        message: "Spare message from bit "+plctag+"."+i,
                    });
                }
            }
        }
    }

    reformat_label(label:string): string {
        // Remove the level's tag prefix from the label if it's just the tagname
        
        // order tag prefixes by length
        const prefixes = this.template.level.tag_prefixes.sort((a, b) => {
            return b.length - a.length;
        });
        // replace all
        for (const prefix of prefixes) {
            // const llower = label.toLowerCase();
            // const plower = prefix.toLowerCase();
            // if (llower.startsWith(plower)) {
            //     if (llower.startsWith(plower + "s")) {
            //         label = label.substring(prefix.length + 1);
            //     }
            //     else {
            //         label = label.substring(prefix.length);
            //     }
            // }

            // Remove the prefix from the label
            // if it's at the start of the line or at after a 
            label = label.replace(RegExp("^" + prefix + "s", "i"), "");
            label = label.replace(RegExp("\\." + prefix + "s", "i"), "");
            
            label = label.replace(RegExp("^" + prefix, "i"), "");
            label = label.replace(RegExp("\\." + prefix, "i"), "");
        }

        // Convert camelCase to spaced words
        label = label.replace(
            /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g, 
            '$1$4 $2$3$5'
        );

        // Convert under_scores to spaces
        label = label.replace(/_/g, ' ');

        // Remove AB's specific escape characters
        label = label.replace(/\$n/gi, ' ');
        label = label.replace(/\$r/gi, ' ');
        label = label.replace(/\$t/gi, ' ');
        label = label.replace(/\$l/gi, ' ');
        label = label.replace(/\$'/gi, "'");
        label = label.replace(/\$\$/gi, '$');
        
        // Add spaces between letters and numbers
        label = label.replace(/([a-z])([0-9])/gi, '$1 $2');
        label = label.replace(/([0-9])([a-z])/gi, '$1 $2');
        label = label.replace("Program:","")



        // Convert to Title Case
        label = label.replace(/\w\S*/g, (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });

        
        // Don't leave space between single letters followed by numbers
        label = label.replace(/ ([A-Z]) ([0-9])/, ' $1$2')

        // Capitalize some common abbreviations
        const words = [
            '[A-Z]EX',
            '[A-Z]GP',
            'DS',
            'RS',
            'HC',
            'FB',
            'PR',
            'WS[A-F]'
        ]
        // replace each word individually to avoid issues with overlapping spaces
        for (const word of words) {
            const reg = new RegExp("(^| )(" + word + ")( |$)", "ig");
            label = label.replaceAll(reg, (txt) => {
                return txt.toUpperCase();
            });
        }
        

        // Remove some superfluous words
        const replacements = {
            " P Roll": " Pull Roll",
            " Mgr": "s", 
            " Dcs": "",
            "Dcs ": "",
            "Estop": "E-Stop",
            "B Alarms": "BEX",
            "A Alarms": "AEX",
            "C Alarms": "CEX",
            "D Alarms": "DEX",
            "E Alarms": "EEX",
            "F Alarms": "FEX",
            "RS Alarms": "RS",
            "Bot ": "Bottom ",
            "Mid ": "Middle ",
            " Sc ": " SC ",
            "S Changer": "SC",
            " V( |$)": "V ",
            "Bus Rack": "Networked Rack",
        };
        for (const [pattern, replacement] of Object.entries(replacements)) {
            label = label.replace(RegExp(pattern, "ig"), replacement);
        }

        label = label.trim();

        // Prefix label with alarm class
        return label;
    }

    /**
     * @function toObjXml
     * @description Convert this trigger to an object that can be converted to XML
     */
    toXmlObject(): AlarmsXmlTrigger {
        return {_attributes: {
            id: this.id,
            type: this.template.index_type,
            exp: this.pvtag,
            label: this.label_with_level,
            "remote-ack-exp": "",
            "remote-ack-handshake-tag": "",
            "handshake-tag": "",
            "ack-tag": "",
            "ack-all-value": "0",
            "use-ack-all": "false",
            "message-tag": "", 
            "message-handshake-exp": "",
            "message-notification-tag": "",
        }}
    }
}
