import { Component } from 'react';
import DxfPage from './dxfpage';
// import * as stringify from 'csv-stringify'
// console.log(stringify)

import { FileDrop } from 'react-file-drop'

import MessageList from './components/messagelist'
import ModulePage from './components/modulepage'
import DownloadFile from './components/downloadfile'
import InstructionPrompt from './components/instruction-prompt'
import SecondaryTags from './components/secondaryTags'
import ProgressBar from './components/progressbar'

import {projects, PreloadPage} from './filters/preloads'
import './fileinput.css'

import createCsvTagSpreadsheet from './processors/csvSpreadsheet'
import createCsvTagAB from './processors/csvTagAB'
import Secondaries from './processors/addSecondaryIO'

import type {IO, DuplicateIO} from './types/io'
import type {SecondaryTag} from './filters/secondaries'
import DuplicatesList from './components/duplicates';
import AlarmsXML from './alarms';



// for environment variables
declare var process : {
  env: {
    NODE_ENV?: string
    JEST_WORKER_ID?: string
  }
}


// import L5K from './l5k/l5k.js'
// window.l5k = new L5K();

type FileInputProps = {
  preloads?: Array<PreloadPage>,
  preload?: boolean,
}
type FileInputState = {
  contents: string;
  filehover: boolean,
}


export default class FileInput extends Component {
  props!: FileInputProps;
  pages: Array<DxfPage> = [];
  preloaded: boolean = false; // Fully preloaded
  preloads: Array<PreloadPage> = [];
  secondaries: Secondaries;
  state: FileInputState = {
    contents: '',
    filehover: false,
  };

  primaryIO: Array<IO> = [];
  secondaryIO: Array<SecondaryTag> = [];
  delaySecondaryTO: null | ReturnType<typeof setTimeout> = null; // timeout for delay
  // allIO: Array<IO> = [];
  duplicates: Array<DuplicateIO> = [];

  filesToRead: Array<File> = [];

  is_dev: boolean = false;
  is_test: boolean = false;

  // Contains the text of the logix tags file if it was loaded
  logixCSV: string = "";
  logixCSVfilename: string = "";

  csv: string = ""; // csv string (file contents)
  import: string = ""; // import string (file contents)

  constructor(props: FileInputProps) {
    super(props);

    this.secondaries = new Secondaries();

    this.pages = [];

    // window.FileInput = this;
  }

  // Preload files if in development mode
  componentDidMount() {
    try {
      this.is_test = process.env.JEST_WORKER_ID !== undefined;
      this.is_dev = process.env.NODE_ENV === 'development' && !this.is_test;  
    }
    catch {
      return console.log("Error getting environment variables");
    }

    // console.log("is_test: ", this.is_test, "is_dev: ", this.is_dev);

    if ( this.is_dev ) {
      // console.log("Checking for Preloading files");
      if ( ! this.props.preloads ) {
        
        for (let i in projects) {
          const project = projects[i];
          if ( project.debug === true && this.is_dev) {
            this.preloads = [...this.preloads, ...project.pages];
          }
        }
        // console.log("Found preloads: ", this.preloads);

        this.preload();
      }
      // use direct data if specified
      else if ( Array.isArray(this.props.preloads) ) {
        this.preloads = [...this.props.preloads];
        this.preload();
      }
    }
    if (this.is_test && this.props.preloads) {
      this.preloads = [...this.props.preloads]
      this.preload() 
    }
  }

  // load the next file from the preload list
  preload() {
    if (this.preloads.length === 0) {
      this.preloaded = true;
      return; 
    }
    const preload = this.preloads.shift();
    const path = preload?.url;
    if (! path) {return;}

    // console.log("Preloading: ", path);
    if (preload.data) {
      // console.log("Using fixed data for ", path);
      this.handleText(preload.data, {
        filename: path.split('/').pop(),
        preload: preload
      });
      this.preload();
      return;
    }

    // read text from URL location
    var request = new XMLHttpRequest();
    // request.open('GET', 'data/17927-oda-dxf/17927-320.dxf', true); // IT8 TC Input
    request.open('GET', path, true);  // Input Module
    request.send(null);
    request.onreadystatechange = (e) => {
      if (request.readyState === 4 ) { // && request.status === 200
          // console.log("Retrieved file", request, e);
          const filename = request.responseURL.split('/').pop();
          this.handleText(request.responseText, {
            filename: filename,
            preload: preload,
          });

          this.preload();
      }
    }
  }

  //////////////////////////////////////////////////////
  // HANDLE TEXT
  //////////////////////////////////////////////////////
  //
  // File object formatting has been removed
  // txt is just a string
  // options describes the file in a universal way or is the response from conversion
  
  handleText(txt: string, options) {
    // type handleTextOptions = {
    //   filename: string,
    //   preload: PreloadPage,
    // }

    if ( ! this.preloads || (! this.is_dev || !this.is_test) ) {
      this.readNextFile();
    }
    else {
      console.log("Skipping next file load because only preloading", this.preloads);
    }

    // Handle loading tags from a csv file
    if (options.filename.toLowerCase().endsWith('.csv')) {
      this.logixCSV = txt;
      this.logixCSVfilename = options.filename;
      this.forceUpdate();
      return;
    }
    
    // Handle DXF schematic pages
    let dxf = new DxfPage(txt, options);
    this.pages.push(dxf);
    // console.log("DXF load finished", dxf);

    // window.dxf = dxf;

    this.primaryIO = [];
    for (let p in this.pages) {
      const page = this.pages[p];
      
      if (! page.module) {
        continue;
      }

      for (let i in page.module.io) {
        this.primaryIO.push( page.module.io[i] );
      }
    }

    
    // Sort the pages of the schematics by their page number
    this.pages = this.pages.sort((a,b) => {
      if (a.filename > b.filename) {
        return 1;
      }
      else if (a.filename === b.filename) {
        return 0;
      }
      else {
        return -1;
      }
    });

    let remaining =  this.filesToRead?.length || this.preloads?.length || 0;
    if (! remaining) {
      // ensures test stability when preloading files by skipping the delay
      if ( this.preloaded ) {
        this.delayedSecondaryGeneration();
      }
      // helps responsiveness when preloading files for main GUI
      else {
        this.delaySecondaryTO = setTimeout(() => this.delayedSecondaryGeneration(), 30);
      }
    }

    // is necessary because data changes without updating state like React expects
    // if ( (this.preloads?.length || 0) === 0) { // This seems like it could improve performance during tests, but it doesn't
    this.forceUpdate();
    // }
  }
  delayedSecondaryGeneration() {
    let moduleTags: SecondaryTag[] = [];
    let driveTags: SecondaryTag[] = [];
    for (const page of this.pages) {
      const module_tags = page.module?.module?.module_tags;
      if ( module_tags ) {
        moduleTags.push(...module_tags);
      }
      const drive = page.module?.drive;
      if ( drive ) {
        driveTags.push(...drive.secondaries);
      }
    }
    // console.log("Found module tags: ", moduleTags);


    this.secondaryIO = this.secondaries.generateSecondaries(this.primaryIO) || [];
    this.secondaryIO = [...this.secondaryIO, ...moduleTags, ...driveTags];

    this.updateSpreadsheets();

    this.checkDuplicates();

    // is necessary because data changes without updating state like React expects
    this.forceUpdate();
  }

  updateSpreadsheets() {
    this.csv = createCsvTagSpreadsheet(this.primaryIO, this.secondaryIO);
    this.import = createCsvTagAB(this.primaryIO, this.secondaryIO);
  }

  checkDuplicates() {
    this.duplicates = [];
    // rename primary tags
    for (let i = 0; i < this.primaryIO.length - 1; i++) {
      const io1 = this.primaryIO[i];
      // don't check for duplciates which have already been checked
      for (let j = i + 1; j < this.primaryIO.length; j++) {
        const io2 = this.primaryIO[j];
        // console.log("Checking for duplicates", io1.name, io2.name);
        if (io1.name === io2.name) {
          this.duplicates.push({
            original: io1.name,
            left: io1,
            right: io2,
          });
          io1.name += "_DPL" + (i+"").padStart(4,"0")
          io2.name += "_DPL" + (j+"").padStart(4,"0")
        }
      }
    }
    // rename secondary tags
    for (let i = 0; i < this.secondaryIO.length - 1; i++) {
      const io1 = this.secondaryIO[i];
      // don't check for duplciates which have already been checked
      for (let j = i + 1; j < this.secondaryIO.length; j++) {
        const io2 = this.secondaryIO[j];
        // console.log("Checking for duplicates", io1.name, io2.name);
        if (io1.name === io2.name) {
          this.duplicates.push({
            original: io1.name,
            left: io1,
            right: io2,
          });
          io1.name += "_DPL" + (i+"").padStart(4,"0")
          io2.name += "_DPL" + (j+"").padStart(4,"0")
        }
      }
    }
    // console.log("Found duplciates", this.duplicates);
  }

  //////////////////////////////////////////////////////
  // READ NEXT FILE
  //////////////////////////////////////////////////////
  //
  // Read the next file the user provided
  readNextFile() {
    const file = this.filesToRead.shift()

    // console.log("readNextFile", file);
    
    if (file instanceof File) {
      // console.log(file, typeof file);

      let ext = file.type || file.name.split('.').pop() || '';
      ext = ext.toLowerCase();

      // ext: "text/csv" || dxf || dwg
      // ext on windows: "application/vnd.ms-excel"
      if (ext === "dxf" || ext.includes("csv") || file.name.toLowerCase().endsWith(".csv")) {
        let fileData = new FileReader();
        fileData.onloadend = (e: Event) => {
          // console.log("file onLoad", e);
          if (e.target instanceof FileReader && typeof e.target.result === "string") {
            this.handleText(e.target.result, {
              filename: file.name,
            })
          }
        };
        fileData.readAsText(file);
      }
      else if (ext === "dwg") {
        let req = new XMLHttpRequest();
        let formData = new FormData();

        formData.append("userfile", file);
        req.open("POST", '/convert/dwg2dxf.py');
        req.timeout = 8000;
        req.send(formData);

        req.onreadystatechange = (e) => {
          if (req.readyState === 4 && req.status === 200) {
              // console.log("Retrieved Conversion", req);

              let resp = JSON.parse(req.responseText);
              // console.log("Received conversion response: ", resp);
              
              let text = "";
              if (resp.dxf) {
                text = atob(resp.dxf);
              }
              this.handleText(text, resp);
              // this.handleText(req.responseText);
          }
          else if (req.readyState === 4) {
            console.log("Failed to get conversion", req);
            this.readNextFile();
          }
          else if (req.readyState === 3) {
            // console.log("Waiting for file transfer", file.name);
          }
          else {
            // console.log("Unknown transfer state", req, file);
            return;
          }
        }
        req.ontimeout = (e) => {
          console.log("Failed to read file", file.name, e)
          this.readNextFile();
        }
      }
      else {
        console.log("Not a DWG or DXF file", ext, file.name)
        this.readNextFile();
      }
    }
  }

  //////////////////////////////////////////////////////
  // HANDLE CHANGE FILE
  //////////////////////////////////////////////////////
  //
  // When the file browser gets a new file
  handleChangeFile(files: FileList | null) {
    if (! (files instanceof FileList)) {
      return;
    }

    this.filesToRead = [];
    for (let f in files) {
      const file = files[f];
      // console.log("FILE CHANGE: ", typeof file, file)
      if (typeof file !== "number") {
        this.filesToRead.push(file);
      }
    }
    // this.filesToRead = files;

    // console.log(this);

    if (this.preloaded) {
      console.log("Resetting preloads because files were added");
      this.preloaded = false;
      this.pages = [];
      this.preloads = [];
    }

    this.readNextFile();
    setTimeout(() => {this.readNextFile()}, 250); // "Parallel" Process

    //fileData.readAsArrayBuffer(file);
  }

  //////////////////////////////////////////////////////
  // RENDER
  //////////////////////////////////////////////////////
  render() {

    // let progress = 0;
    let remaining =  this?.filesToRead?.length || this?.preloads?.length || 0;
    let processed = this.pages.length;
    // console.log("PROGRESS", this.pages.length, this?.filesToRead?.length, this?.preloads?.length, this?.filesToRead, this?.preloads)
    if (processed) {
      if (this.secondaryIO) {
        processed += 1;
      }
      else {
        remaining += 1;
      }
    }
    

    // DXF PAGES
    let pages: Array<JSX.Element> = [];
    for (let p in this.pages) {
      pages.push( <ModulePage page={this.pages[p]} key={this.pages[p].id} /> )
    }

    // let secondaries: Array<JSX.Element> = [];
    // for (let s in this.secondaryIO) {
    //   const io = this.secondaryIO[s];
    //   secondaries.push(<ModuleIoRow io={io} key={io.name}/>)
    // }

    // DOWNLOAD BUTTONS
    let downloads: JSX.Element[] = [];
    if (this.csv) {
      downloads.push(<DownloadFile
        filename="taglist-spreadsheet.csv"
        text="Tag Spreadsheet"
        title="This replicates the format seen in the controller tag list"
        type="text/csv"
        icon="CSV"
        key="spreadsheet"
        data ={this.csv} />);
      downloads.push(<DownloadFile
        filename="taglist-import.csv"
        title="This CSV should be able to be imported into Logix 5000 using Tools > Import > Tag"
        text="Logix Importable CSV"
        icon="PLC"
        key="PLCtags"
        type="text/csv"
        data ={this.import} />)
    }

    // FILE HOVER
    let filehover: JSX.Element | null = null; // A special popup widget
    if (this.state.filehover === true) {
      filehover = (
        <div className="FileHover-prompt">
          <h1>Drop files anywhere in this window</h1>
          <p>DWG files will be automatically uploaded to be converted to DXF for local processing</p>
        </div>
      )
    }
    // Add a special CSS class if a file dropper is being hovered over
    const addClasses = this.state.filehover ? "FileHover-active" : "FileHover-inactive";


    // RETURN HTML
    return (
      <div className={"FileInput " + addClasses}>

        <FileDrop
          onFrameDragEnter={(e) => {this.setState({filehover: true});}}
          onFrameDragLeave={(e) => {this.setState({filehover: false});}}
          onFrameDrop={(e) => {this.setState({filehover: false});}}
          onDrop={(files, e) => {this.setState({filehover: false}); this.handleChangeFile(files)}}
          >
        {filehover}

        <div className="FileBrowser-wrapper">
          <input type="file"
            accept=".dxf,.dwg,.csv"
            multiple
            id="dxf_files"
            name="dxf_files"
            onChange={
              e => this.handleChangeFile(e?.target?.files)
            }
              />
        </div>

        <AlarmsXML csv={this.logixCSV} filename={this.logixCSVfilename} key="ALM" />
        
        {downloads ? (
          <span className="downloads">
            {downloads}
          </span>
        ): null}
        

        {remaining ? (
          <ProgressBar remaining={remaining} processed={processed} />
        ): null}


        <DuplicatesList duplicates={this.duplicates} />

        <MessageList pages={this.pages} />
        

        {pages.length || this.logixCSVfilename !== "" ? (
          <div className="ModulePages">
            {pages}
          </div>
        ): <InstructionPrompt />}


        {this.secondaryIO.length ? (
          <SecondaryTags secondaries={this.secondaryIO} />
        ): null}



        </FileDrop>
      </div>
    )
  }
}
