import type {KnownIO} from "../types/io";

let knownIoVacuum: Array<KnownIO> = [
	
	{
		examples: [
			"EXTRUDER B VACUUM STATION EMERGENCY STOP CIRCUIT",
		],
		description: /EMERGENCY STOP CIRCUIT/i,
		name: "Vac_Enclosure_Estop_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		safetyType: "Safety",
		vacuum: true
	},


	{
		examples: [
			"EXTRUDER B VACUUM STATION CONTROL ENCLOSURE E-STOP PBL",
		],
		description: /CONTROL ENCLOSURE E-STOP/i,
		name: "{E}_VacEncl_Estop",
		type: "BOOL",
		direction: "in",
		vacuum: true
	},
	{
		examples: [
			"E-STOP RESERVED INPUT",
		],
		description: /E-STOP RESERVED/i,
		name: "{E}_Vac_Estop_Reserved{S}{P}",
		type: "BOOL",
		direction: "in",
		vacuum: true
	},
	{
		examples: [
			"CONDENSATE TANK FULL SWITCH",
		],
		description: /CONDENSATE TANK FULL/i,
		name: "{E}_Condensate_Water_High",
		type: "BOOL",
		direction: "in",
		vacuum: true
	},
	{
		examples: [
			"WATER LEVEL FULL SWITCH",
		],
		description: /WATER LEVEL FULL/i,
		name: "{E}_VacPump_Water_High",
		type: "BOOL",
		direction: "in",
		vacuum: true
	},
	{
		examples: [
			"WATER LEVEL LOW SWITCH",
		],
		description: /WATER LEVEL LOW/i,
		name: "{E}_VacPump_Water_Low",
		type: "BOOL",
		direction: "in",
		vacuum: true
	},



	{
		examples: [
			"ZONE 4V VACUUM PUMP MMSP AUX",
		],
		description: /(ZONE )?([0-9]+)[V]?.*PUMP.*MMSP/i,
		name: "{E}_VacPump{2}V_MMSP",
		type: "BOOL",
		direction: "in",
		vacuum: true
	},
	{
		// match if not specifically on a vacuum rack
		examples: [
			"ZONE 4V VACUUM PUMP MMSP AUX",
		],
		description: /(ZONE )?([0-9]+)[V]?.*VACUUM.*PUMP.*MMSP/i,
		name: "{L}_VacPump{2}V_MMSP",
		type: "BOOL",
		direction: "in",
	},

	{
		examples: [
			"VACUUM PUMP 1 MOTOR MMSP AUX",
		],
		description: /PUMP ([0-9]*).*MMSP/i,
		name: "{E}_VacPump{1}_MMSP",
		type: "BOOL",
		direction: "in",
		vacuum: true
	},
	{
		// match if not specifically on a vacuum rack
		examples: [
			"VACUUM PUMP MMSP",
			"VACUUM PUMP 1 MOTOR MMSP AUX",
		],
		description: /VACUUM PUMP ([0-9]*).*MMSP/i,
		name: "{L}_VacPump{1}_MMSP",
		type: "BOOL",
		direction: "in",
	},


	

	{
		examples: [
			"ZONE 4V VACUUM PUMP CONTACTOR",
		],
		description: /(ZONE )?([0-9]+)[V]?.*PUMP.*CONT/i,
		name: "{E}_VacPump{2}V_Aux",
		type: "BOOL",
		direction: "in",
		vacuum: true
	},
	{
		// match if not specifically on a vacuum rack
		examples: [
			"ZONE 4V VACUUM PUMP CONTACTOR",
		],
		description: /(ZONE )?([0-9]+)[V]?.*VACUUM.*PUMP.*CONT/i,
		name: "{L}_VacPump{2}V_Aux",
		type: "BOOL",
		direction: "in",
	},

	{
		examples: [
			"VACUUM PUMP 1 MOTOR CONTACTOR AUX",
		],
		description: /PUMP ([0-9]*).*CONT/i,
		name: "{E}_VacPump{1}_Aux",
		type: "BOOL",
		direction: "in",
		vacuum: true
	},
	{
		// match if not specifically on a vacuum rack
		examples: [
			"VACUUM PUMP 1 MOTOR CONTACTOR AUX",
			"VACUUM PUMP CONTACTOR",
		],
		description: /VACUUM PUMP ([0-9]*).*CONT/i,
		name: "{L}_VacPump{1}_Aux",
		type: "BOOL",
		direction: "in",
	},


	// OUTPUTS

	{
		examples: [
			"ZONE 4V VACUUM PUMP CONTACTOR",
		],
		description: /(ZONE )?([0-9]+)[V]?.*PUMP.*CONT/i,
		name: "{E}_VacPump{2}V_Coil",
		type: "BOOL",
		direction: "out",
		vacuum: true
	},
	{
		// match if not specifically on a vacuum rack
		examples: [
			"ZONE 4V VACUUM PUMP CONTACTOR",
		],
		description: /(ZONE )?([0-9]+)[V]?.*PUMP.*CONT/i,
		name: "{L}_VacPump{2}V_Coil",
		type: "BOOL",
		direction: "out",
	},

	{
		examples: [
			"VACUUM PUMP 1 MOTOR CONTACTOR",
			"VACUUM PUMP CONTACTOR",
		],
		description: /PUMP ([0-9]*).*CONT/i,
		name: "{E}_VacPump{1}_Coil",
		type: "BOOL",
		direction: "out",
		vacuum: true
	},
	{
		// match if not specifically on a vacuum rack
		examples: [
			"VACUUM PUMP 1 MOTOR CONTACTOR",
			"VACUUM PUMP CONTACTOR",
		],
		description: /VACUUM PUMP ([0-9]*).*CONT/i,
		name: "{L}_VacPump{1}_Coil",
		type: "BOOL",
		direction: "out",
	},
	{
		examples: [
			"VACUUM PUMP 1 AIR VALVE SOLENOID",
		],
		description: /PUMP ([0-9]+) AIR VALVE/i,
		name: "{E}_VacPump{1}_Sol", // BEX_VacPump1_Sol
		type: "BOOL",
		direction: "out",
		vacuum: true
	},
	{
		examples: [
			"WATER TANK FILL VALVE SOLENOID",
		],
		description: /WATER TANK FILL VALVE/i,
		name: "{E}_VacPump_Water_Fill", // BEX_VacPump_Water_Fill
		type: "BOOL",
		direction: "out",
		vacuum: true
	},
	{
		examples: [
			"VACUUM SYSTEM WATER SOLENOID VALVE",
		],
		description: /VACUUM.*WATER.*SOL/i,
		name: "{L}_VacPump_Water_Sol",
		type: "BOOL",
		direction: "out",
	},


	{
		examples: [
			"VENT 4V PLUG SOLENOID",
			"VENT 5V PLUG SOLENOID",
		],
		description: /VENT ([0-9]+)V PLUG SOL/i,
		name: "{L}_VentPlug_{1}V__Sol",
		type: "BOOL",
		direction: "out",
	},




	
	
	////////////////
	// ANALOG CURRENT
	{
		examples: [
			"VACUUM PUMP 1 WATER TEMPERATURE TRANSDUCER 4-20mA RANGE 0-50 C",
		],
		description: /VACUUM PUMP ([0-9]+).*WATER.*TEMP/i,
		name: "{E}_VacPump{1}_WaterTemp",
		analog: true,
		direction: "in",
		vacuum: true,
	},

	{
		examples: [
			"VACUUM PUMP 1 MOTOR CURRENT L1 0-20mA : 0-50A",
		],
		description: /VACUUM PUMP ([0-9]+).*MOTOR CURRENT/i,
		name: "{E}_VacPump{1}_Current",
		analog: true,
		direction: "in",
		vacuum: true,
	},

	////////////////////////////////////
	// SPARE
	//
	{
		description: /SPARE/i,
		name: "{E}{L}_Spare_Rack{N}_S{S}_{P}_{DIR}_{TYPE}",
		vacuum: true
	},
	{
		description: /SPARE/i,
		name: "{E}{L}_Spare_Rack{N}_S{S}_{P}_out",
		type: "BOOL",
		direction: "out",
		vacuum: true
	},
]

export  {knownIoVacuum as default}
