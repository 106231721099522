import { Component } from "react";
import { ChevronRight } from "react-bootstrap-icons";
import { AlarmLevelComponent } from "./level-render";
import type { AlarmMessage } from './message';

import './message-render.css';

type MyProps = {
    message: AlarmMessage;
    bit: number
}
type MyState = {
}

export class AlarmMessageComponent extends Component<MyProps, MyState>  {
    render() {

        return (
            <tr className="Alarm-Message">
                <td className="Alarm-Message-Expanded">
                    <ChevronRight />
                </td>
                <td className="AlarmsXML-Message-Level">
                    <AlarmLevelComponent level={this.props.message.level} />
                </td>
                <td className="Alarm-Message-Text" colSpan={5}>
                    <span className="Alarm-Message-Bit">
                        {this.props.bit}: 
                    </span>
                    {this.props.message.message_formatted}
                </td>
            </tr>
        );
    }
}