
import * as helpers from '../ParseHelpers'

export default function EntityParser() {}

EntityParser.ForEntityName = 'HATCH';

EntityParser.prototype.parseEntity = function(scanner, curr) {
    var entity = { 
        type: curr.value,
        dxfStartPos: scanner._pointer,
        dxfEndPos: 0,
    };

    curr = scanner.next();
    while(curr !== 'EOF') {
        if(curr.code === 0) break;

        switch(curr.code) {
            case 2:
                entity.fieldType = curr.value;
                break;
            case 3:
                entity.name = curr.value;
                break;
            case 10:
                entity.position = helpers.parsePoint(scanner);
                break;
            case 40:
                //Note: this is the text height
                entity.height = curr.value;
                break;
            case 41:
                entity.patternScale = curr.value;
                break;
            case 71:
                entity.associativity = curr.value;
                break;
            case 75:
                entity.hatchStyle = curr.value;
                break;
            case 76:
                entity.hatchType = curr.value;
                break;
            case 210:
                entity.extrusionDirectionX = curr.value;
                break;
            default:
                var found = helpers.checkCommonEntityProperties(entity, curr, "TEXT");
                // entity['code-' + curr.code] = curr.value;
                // if (! found) {console.log("HATCH could not find code", curr, entity)}
                break;
        }
        curr = scanner.next();
    }
    entity.dxfEndPos = scanner._pointer;

    // console.log("FOUND HATCH", entity);
    return entity;
};