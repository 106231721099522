

import type {PreloadPage} from './filters/preloads'
import {DxfPageParse} from './dxfpage/parseBase';
import {DxfPageParseModule} from './dxfpage/parseModule';



export default class DxfPage {
  warn: string = "";
  error: string = '';

  /**
   * File name of the data passed to the constructor through the options
   */
  filename: string = "";

  /**
   * File extension
   */
  extension: string = "";

  /**
   * Thumbnail image from the DWG file
   * Not available on DXF files
   */
  thumb: DxfThumbnail = "";

  /**
   * Unique identifier based on filename and date time
   */
  id: string = "";

  parsed: DxfPageParse;
  module?: DxfPageParseModule;


  //////////////////////////////////////////////////////
  // CONSTRUCTOR
  //////////////////////////////////////////////////////
  constructor(txt: string, options: DxfPageOptions) {
    (window as any).dxf = this; // supress typescript error. Attach this to the window object for debugging
    this.warn = "";
    this.filename = options?.filename?.toLowerCase() ?? '';
    this.extension = options?.filename?.split('.').pop() ?? '';
    this.error = options?.error ?? '';
    this.thumb = options?.thumb ?? '';
    this.parsed = DxfPageParse.blank();

    // set a default ID for React if no module is found
    this.id = "Module-" + this.filename + Date.now();

    const result = DxfPageParse.parse(txt);
    if (result instanceof Error) {
      this.error += result.message + "\n";

      if (typeof options.stdout === "string") {
          this.error += "STDOUT:\n" + options.stdout + "\n";
      }
      if (typeof options.stderr === "string") {
          this.error += "STDERR:\n" + options.stderr + "\n";
      }
      return;
    }
    this.parsed = result;

    const warnNotes = options?.preload?.notes;
    // console.log("WARN NOTES: ", warnNotes); 

    if (typeof warnNotes === "string" && warnNotes !== "") {
      // console.log("WARN NOTES: ", warnNotes);
      this.warn += "Preload note: " + warnNotes + "\n";
    }

    const knownModuleType = options?.preload?.module;
    if (typeof knownModuleType === "string" && knownModuleType !== "" ) {
      this.warn += "Preload says this module is: " + knownModuleType + "\n";
    }


    this.module = new DxfPageParseModule(this.parsed, this);
    if (! this.module.module ) {return}
    this.id = "Module-" +this.filename + this.module.rackLetter + ".r" + this.module.rackNumber + ".s" + this.module.slotNumber + "-" + Date.now();


    if (knownModuleType && ! this.module.module.description.match(knownModuleType)) {
      this.error += "Detected module type does not match known module type defined by preload\n";
      this.error += "Module should be " + knownModuleType + " but found " + this.module.module.description + "\n";
    }
  }
}

export type DxfThumbnail = string;
export type DxfPageOptions = {
  filename: string;
  error: string;

  /**
   * Thumbnail image
   */
  thumb: DxfThumbnail;

  /**
   * Standard output from LibreDWG conversion
   */
  stdout: string;

  /**
   * Standard error from LibreDWG conversion
   */
  stderr: string;
  preload: PreloadPage;
}
