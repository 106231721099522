import { Component } from "react";
import DownloadFile from "./components/downloadfile";
import Papa from "papaparse";

import type { CsvColumns } from "./types/csv";

import type { CsvFileMeta } from './alarms/types';

import {alarmLevels} from "./alarms/level";
import {alarmsXML} from "./alarms/xml-format";
import { AoiDefinitions } from "./alarms/aois";
import { AlarmTrigger, is_simple_type } from "./alarms/trigger";
import { TriggerTemplate } from "./alarms/trigger";
import type { AlarmMessage, TriggerMessageTemplate } from "./alarms/message";
import { AlarmTriggerComponent } from "./alarms/trigger-render";
import {get_udt_triggers} from './alarms/udt'
import {AlarmSummaryComponent} from './alarms/summary'
import {AlarmLevelFilterComponent} from './alarms/label-filter-render'


// icons
import {
    TagFill,
    UiRadios,
    Diagram3Fill,
    Box,
    ChevronExpand,
    ChevronContract,
    ChatLeftText,
    ChatLeft,
    Reception4

  } from 'react-bootstrap-icons';
  

import xmljs from "xml-js";
import { TriggerTags } from "./alarms/triggers";
import { AlarmCsvMetaComponent } from "./alarms/meta";
function objToXmlString(obj: xmljs.Element | xmljs.ElementCompact): string {
    const options: xmljs.Options.JS2XML = {
        spaces: 4,
        compact: true,
    }
    return xmljs.js2xml(obj, options);
}

type MyProps = {
    csv: string;
    filename: string;
}
type MyState = {
    generated: Date;
}

export default class AlarmsXML extends Component<MyProps, MyState>  {
    xml: string = "";
    data: CsvColumns[] = [];
    meta: CsvFileMeta = {};
    aois: AoiDefinitions = new AoiDefinitions();
    controller_triggers = new TriggerTags();
    triggers: AlarmTrigger[] = [];
    messages: AlarmMessage[] = [];


    constructor(props: MyProps) {
        super(props);
        this.init();
    }

    init() {
        this.aois = new AoiDefinitions();
        this.triggers = [];
        this.messages = [];
    }

    prase() {
        this.init();
        
        if (this.props.csv === "") {
            this.xml = "";
            return;
        }

        const header = [
            'TYPE',
            'SCOPE',
            'NAME',
            'DESCRIPTION',
            'DATATYPE',
            'SPECIFIER',
            'ATTRIBUTES'
        ];

        let parsed = Papa.parse(this.props.csv);
        
        if (parsed.errors.length) {
            console.log(parsed.errors);
            return;
        }
        this.data = parsed.data;

        // console.log( this.data );

        this.parseHeader();
        this.buildTypes();
        this.buildTriggersMessages();
        this.buildXML();
    }

    parseHeader() {
        const d = this.data;
        
        let i = 0;
        while (i < 10) {
            const row = d[i];
            if (row[0] === "remark") {
                const fields = row[1].split("=");
                if (fields.length === 2) {
                    const name = fields[0].trim();
                    const value = fields[1].trim();

                    this.meta.filename = this.props.filename;
                    if (name === "Date") {
                        this.meta.date = value;
                    }
                    else if (name === "Version") {
                        this.meta.version = value;
                    }
                    else if (name === "Owner") {
                        this.meta.user = value;
                    }
                    else if (name === "Company") {
                        this.meta.company = value;
                    }
                }
            }
            i++;
        }
        // console.log(this.meta);

        this.xml="<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n";
    }

    buildTypes() {
        for (const row of this.data) {
            // if this is an AOI
            if (row[0] === "TAG") {
                // TAG,HC_Zone:AOI,WarnWord,"","INT","","(RADIX := Octal, Usage := Output, ExternalAccess := Read Only, Required := false, Visible := true)"
                const is_aoi = row[1].includes(":");
                let scope = "";
                let tagname = row[2];

                if ( is_aoi ) {
                    // Add it to the type list if it's not already there
                    scope = row[1].split(":")[0];
                    this.aois.add_aoi( scope );
                }
                const description = row[3];

                // Ignore the array for the type for now
                // TODO: What if this IS an array?
                const datatype = row[4].split(['['])[0];

                // Ignore non-HMI-readable types
                if ( row[6].includes("Usage := InOut") ||
                     ! row[6].includes("ExternalAccess := Read")) {
                    continue;
                }

                // get any nested types for this AOI
                if ( is_aoi ) {
                    this.aois.add_nested_type( scope, tagname, datatype );
                }



                // check if this tag matches a known pattern for a trigger
                for (const l in alarmLevels) {
                    const level = alarmLevels[l];
                    for (const tag_prefix of level.tag_prefixes) {
                        
                        if (tagname.toLowerCase().startsWith(tag_prefix.toLowerCase())) {
                            
                            if ( ! is_aoi && row[1] !== "" ) {
                                scope = row[1];
                                tagname = "Program:" + scope + "." + tagname;
                            }
                            let trigger_template = new TriggerTemplate(tagname, description, level, datatype);
                            if (is_aoi) {
                                trigger_template.parent_type = scope;
                                this.aois.add_trigger( trigger_template );
                            }
                            else {
                                this.controller_triggers.add_trigger( trigger_template );
                            }
                            break;
                        }
                    }
                }
            }
            else if (row[0] === "COMMENT") {
                // controller scope: 
                //  COMMENT,,fltCritical_GearPumpLockout,"Lockout switch active while close to die. Cannot start.",,"fltCritical_GearPumpLockout.0"
                // program scope:
                //  COMMENT,AEXMaagDualBolt2ScreenSCNoBackflush,fltCriticalFaults,"Top Transducer Disconnected.  Position unknown.",,"fltCriticalFaults.0"
                // AOI scope (needs an instance)
                //  COMMENT,HC_Zone:AOI,WarnWord,"Temperature has deviated to warning threshold after achieving setpoint.",,"WarnWord.0"
                const is_aoi = row[1].includes(":");
                const scope = row[1].split(":")[0];
                let tagname = row[2];
                const bit_index = parseInt(row[5].split(".")[1]);
                const message = row[3];

                const message_template: TriggerMessageTemplate = {
                    message: message,
                    bit_index: bit_index,
                }
                
                if (is_aoi) {
                    this.aois.add_message( scope, tagname, message_template, false  );
                }
                else {
                    if ( scope !== "" ) {
                        tagname = "Program:" + scope + "." + tagname;
                    }
                    this.controller_triggers.add_message( tagname, message_template, false );
                }
            }
        }
        // console.log("aois", this.aois);
    }

    buildTriggersMessages() {
        this.triggers = this.controller_triggers.get_instances("","");
        this.messages = [];
        for (const trigger of this.triggers) {
            for (const m in trigger.messages) {
                const message = trigger.messages[m];
                this.messages.push(message);
            }
        }

        for (const row of this.data) {
            // If the row is an TAG
            if (row[0] === "TAG" && ! row[1].includes(":AOI")) {
                const scope = row[1];
                const tagname = row[2];
                const description = row[3];
                const tagtype = row[4];

                let fullpath = tagname;
                let label = tagname;

                // Ignore non-HMI-readable types
                if ( row[6].includes("Usage := InOut") ||
                     (! row[6].includes("ExternalAccess := Read"))) {
                    // console.log("Skipping non-HMI readable tag", row);
                    continue;
                }

                if (scope !== "") {fullpath = scope + fullpath}

                // TODO: Should tag descriptions be used for labels?
                // if (description.length < 25 && description.length > 2) { label = description }

                let found_triggers: AlarmTrigger[] = [];
                found_triggers = this.aois.get_triggers( tagtype, fullpath, label );
                // Check if this type is a UDT if it didn't show up in the AOIs list
                if (found_triggers.length === 0) {
                    found_triggers = get_udt_triggers( tagtype, fullpath, label );
                }

                this.triggers.push(...found_triggers);

                // add all messages for these triggers to the message list
                for (const trigger of found_triggers) {

                    // if (trigger.label.includes("Bay")) {console.log(trigger)}
                    for (const m in trigger.messages) {
                        const message = trigger.messages[m];
                        this.messages.push(message);
                    }
                }
            }
        }
        // console.log(this.triggers);
    }

    buildXML() {

        let xmlObj = Object.assign({}, alarmsXML)
        
        xmlObj.alarms.alarm.triggers.trigger = this.triggers.map(t => t.toXmlObject());
        xmlObj.alarms.alarm.messages.message = this.messages.map(m => m.toXmlObject());

        this.xml = objToXmlString(xmlObj);
        // console.log(xmlObj);

        // console.log(this.xml.includes("Fixed Conveyor Motor Disconnect Switch"))
    }



    render() {
        this.prase();

        if (this.xml === "") {
            return null;
        }

        let trigger_elements: JSX.Element[] = this.triggers.map(
            t => <AlarmTriggerComponent trigger={t} key={t.pvtag} />
        );

        const total_messages = this.messages.length.toLocaleString("en-US");
        const total_triggers = this.triggers.length.toLocaleString("en-US");

        return (
            <div className="AlarmsXML">
                <AlarmCsvMetaComponent meta={this.meta} />

                <h2>
                    {total_messages} PanelView Alarm Messages Created with {total_triggers} Tags
                    <DownloadFile
                        filename="PV-Alarms.xml"
                        text="Download Alarms"
                        title="Import this into FactoryTalk View Studio for the PanelView"
                        type="application/xml"
                        icon="ALM"
                        data ={this.xml} />
                </h2>

                <AlarmLevelFilterComponent filter_levels={['fltSafety', 'fltCritical', 'fltNonCritical']} filter_name="Faults" triggers={this.triggers} /> 
                <AlarmLevelFilterComponent filter_levels={['warn']} filter_name="Warnings" triggers={this.triggers} /> 


                <AlarmSummaryComponent triggers={this.triggers} />

                <table className="AlarmsXML-Triggers" cellPadding={0} cellSpacing={0}>
                    <thead>
                        <tr>
                        <th><ChevronExpand />Expand</th>
                        <th><Reception4 />Level</th>
                        <th><TagFill /> Label</th>
                        <th><Box /> Type</th>
                        <th>Used <ChatLeftText /></th>
                        <th><ChatLeft /> Bits</th>
                        <th><Diagram3Fill /> Tag</th>
                        </tr>
                    </thead>
                    <tbody>
                        {trigger_elements}
                    </tbody>
                </table>
            </div>
        );
    }
}