import type { KnownModule } from "../types/module";

import knownModuleAbFlex from "./knownmodules-ab-flex";
import knownModuleAbPoint from "./knownmodules-ab-point";
import knownModuleDrives from "./knownmodules-drives";
import knownModuleWago from "./knownmodules-wago";


let knownModules: Array<KnownModule> = [
  ...knownModuleAbFlex,
  ...knownModuleAbPoint,
  ...knownModuleWago,
  ...knownModuleDrives,
]




export  {knownModules as default}
