
import {secondaryIO} from '../filters/secondaries'
import type {SecondaryIO, SecondaryIoPair, SecondaryTag} from '../filters/secondaries'

import type {IO} from '../types/io'


export default class Secondaries {
 	secondaryTags: Array<SecondaryTag> = [];
	allIO: Array<IO> = [];
	
	generateSecondaries(allIO: Array<IO> = []): SecondaryTag[] {
		this.secondaryTags = [];
		this.allIO = allIO;

		// console.log("START generateSecondaries");



		for (let s in secondaryIO) {
			const secondary = Object.assign({}, secondaryIO[s]);

			let secondaries = this.addSecondary(secondary);
			if (secondaries) {
				this.secondaryTags.push(...secondaries);
			}
			// else {
			// 	console.log("No secondaries for ", secondary)
			// }
		}

		return this.secondaryTags;
	}


	addSecondary(secondary: SecondaryIO) {
		// Test if secondary io that should be created for this io point
		let newTags: Array<SecondaryTag> = [];
		for (let i in this.allIO) {
			const io = this.allIO[i];

			// TESTING FOR MATCH
			// console.log("TESTING FOR MATCH")
			let match = this.testParams(secondary.trigger, io);
			if (match) {
				let known: SecondaryIO = Object.assign(secondary, {found: io});

				// console.log("SEARCHING FOR PAIRED IO");
				let pairs = this.getPairs(known);

				known.pair = pairs;

				// console.log("CREATING SECONDARY TAGS", "known:", known);
				newTags.push(...this.createSecondaryTags(known) );
			}
		}
		return newTags;
	}


	createSecondaryTags(known: SecondaryIO): Array<SecondaryTag> {
		let tags: Array<SecondaryTag> = [];

		for (let t in known.tags) {
			let tag = known.tags[t];

			tag = this.formatParams(known, tag);
			// tag.aliasTo = "N/A (tag)";
			tag.safetyType = tag.safetyType || "Standard"
			tags.push(tag);
		}
		return tags;
	}

	getPairs(knowns: SecondaryIO): {[name: string]: IO} {
		let pairs = {};

		for (let p in knowns.pairs) {
			const pair = knowns.pairs[p];

			pairs[p] = this.getPair(pair, knowns);
		}
		return pairs;
	}
	getPair(pair: SecondaryIoPair, knowns: SecondaryIO) {
		// console.log("getPair", pair, knowns);
		let formattedPair = this.formatParams(knowns, pair);

		// console.log("getPair Formatted Pair", formattedPair)

		for (let i in this.allIO) {
			const io = this.allIO[i];

			// console.log("getPair searching for io", formattedPair, io);

			let match = this.testParams(formattedPair, io);
			// console.log("getPair match", match);
			if (match) {
				io.pairFormatted = formattedPair;
				return io;
			}
		}
	}


	// ADD SUPPORT FOR MATCH FORMATTING
	formatParams<Type>(knowns: SecondaryIO, params: Type): Type {
		let newParams = Object.assign({}, params);

		for (let p in params) {
			newParams[p] = this.formatParam(knowns, params[p]);
		}
		return newParams;
	}
	formatParam(knowns: SecondaryIO, param: any | string) {
		if (typeof param !== "string") {return param}
		let splits = param.split("{");
		if (splits.length <= 1) {return param}

		let newString = ""
		
		for (let s in splits) {
			const split = splits[s];

			if ( ! split.includes("}") ) {
				newString += split;
			}
			else {
				const splitAgain = split.split("}")
				let replacement = splitAgain.shift();

				// this is where the {trigger} gets swapped
				newString += this.getReplacement(knowns, replacement);

				// add the non-replacement patterns back in
				for (let plaintext in splitAgain) {
					newString += splitAgain[plaintext];
				}
			}
		}

		let regParts = newString.match(/^\/(.*?)\/([gim]*)$/);
		if (regParts) {
			try {
				return new RegExp(regParts[1], regParts[2]);
			}
			catch {
				return newString
			}
		}

		// console.log("FORMATTED PARAMETER", newString);
		return newString;
	}
	getReplacement(knowns, command) {
		// console.log("getReplacement", replacement, knowns);

		let replacement = command.replaceAll("'", '"')

		let prefill = '';
		let postfill = '';
		if (replacement[0] === '"') {
			let split = replacement.split('"');

			// console.log("PREFILL FOUND", split, replacement);

			prefill = split.shift()
			if (prefill === "") {
				prefill = split.shift()
			}
			replacement = split.join('"');

			// console.log("PREFILL", prefill, replacement, split);
		}
		if (replacement.slice(-1) === '"') {
			let split = replacement.split('"');
			// console.log("POSTFILL FOUND", split, replacement);

			postfill = split.pop();
			if (postfill === "") {
				// console.log("Pop again", postfill)
				postfill = split.pop();
				// console.log("Popped", postfill)
			}
			replacement = split.join('"');

			// console.log("POSTFILL", postfill, replacement, split);
		}



		let levels = replacement.split(".");

		let subKnown = knowns;
		for (let l in levels) {


			let level = levels[l];
			const split = level.split(':');
			level = split[0];
			let idx = split[1]

			// console.log(l, "Level", level, subKnown);

			if ( typeof subKnown === "undefined" ) {return ""}



			if ( level in subKnown ) {
				subKnown = subKnown[level];
				if (typeof subKnown === "undefined") {return ""}

				if (typeof idx !== "undefined") {
					if (idx === "CleanEnd") {
						const end = subKnown.slice(-1);
						if ( end === "-" || end === "_" || end === " ") {
							subKnown = subKnown.slice(0,-1);
						}
						continue
					}

					if (idx[0] === "L") {
						subKnown = subKnown.split('\n');
						idx = idx.slice(1);
						// console.log(subKnown, idx, subKnown[idx])
					}
					subKnown = subKnown.slice(idx).shift();
				}
			}
			else {
				// console.log("Level not in subknown", level, subKnown[level], subKnown);
				// console.log("Replacement: ", replacement, " originally ", command);
				// console.log("Levels not known: ", levels);
				// console.log("Knowns not found", knowns);
				// console.log("Prefill/Postfill", prefill, "...", postfill);
				return ""
			}
		}
		return prefill + subKnown + postfill;
	}

	/**
	 * 
	 * @param req The request object
	 * @param test 
	 * @returns 
	 */
	testParams(req, test: IO) {
		let match: Array<string>= [];

		// console.log("testParams req", req, "test", test);

		for (let p in req) {
			const param = req[p];

			// console.log("testParams testing parameter: ", p, param, test[p], test, req);

			if (param instanceof RegExp && (typeof test[p] === "string" || test[p] instanceof RegExp)) {
				match = test[p].match(param)
				// console.log("testParams match", match)
				if (! match) { return false}

				let matches = {};
				match.forEach((m, idx) => {
					matches[idx] = m;
				});
				test[p+"_match"] = matches;
				// req[p+"_match"] = matches;

				// console.log("Used Regex and added matches", test, req)
				
			}
			else {
				if (req[p] !== test[p]) {
					// console.log("Rejecting", test, "Requirements:", req, "Parameter", p);
					return false;
				}
				// console.log("parameter passed, ", p, param, req[p], test[p]);
			}
	 	}

	 	return match || true;
	}

}