import type {TagDataTypeName, PartialTagname} from './types';
import type {AlarmLevel} from './level';
import {alarmLevels} from './level';
import {AlarmTrigger} from './trigger';
import { TriggerTemplate, simple_types } from './trigger';
import { TriggerMessageTemplate } from './message';

type TemplateUDT_Message = {
    message: string;
    level: AlarmLevel;
}

type TemplateUDT_Trigger = {
    tagname_suffix: PartialTagname,
    typename: TagDataTypeName,
    level?: AlarmLevel,
    trigger_type_value ?: boolean,
    messages: {[trigger_int: number]: TemplateUDT_Message}, // offset by one bit
}

type TypeLookupUDT = {
    triggers: TemplateUDT_Trigger[];
}


const fltCritical = alarmLevels.fltCritical;        // eslint-disable-line @typescript-eslint/no-unused-vars
const fltNonCritical = alarmLevels.fltNonCritical;  // eslint-disable-line @typescript-eslint/no-unused-vars
const fltSafety = alarmLevels.fltSafety;            // eslint-disable-line @typescript-eslint/no-unused-vars
const warn = alarmLevels.warn;                      // eslint-disable-line @typescript-eslint/no-unused-vars
const diag = alarmLevels.diag;                      // eslint-disable-line @typescript-eslint/no-unused-vars
const info = alarmLevels.info;                      // eslint-disable-line @typescript-eslint/no-unused-vars


export function get_udt_triggers(tagtype: TagDataTypeName, tagname: PartialTagname, description: string): AlarmTrigger[] {
    let found_triggers: AlarmTrigger[] = [];

    if (! (tagtype in LookupUDTs)) {
        return found_triggers;
    }
    if ( tagname.toLowerCase().includes("reset") ) {
        return found_triggers;
    }

    const udt_type = LookupUDTs[tagtype];
    for (const trigger_udt_template of udt_type.triggers) {

        const messages:  TriggerMessageTemplate[] = []
        for (let m in trigger_udt_template.messages) {
            const message = trigger_udt_template.messages[m];
            messages.push({
                message: message.message,
                bit_index : parseInt(m) - 1,
                level: message.level,
            })
        }

        const trigger_tagname = tagname + trigger_udt_template.tagname_suffix;

        const trigger_template:TriggerTemplate = {
            tagname: trigger_tagname,
            description: description,
            level: trigger_udt_template.level || fltCritical,
            bits: simple_types[trigger_udt_template.typename],
            messages: messages,
            index_type: trigger_udt_template.trigger_type_value ? "value" : "bit",
            typename: trigger_udt_template.typename,
        }

        const trigger = new AlarmTrigger(trigger_tagname, tagname || description, trigger_template);
        found_triggers.push(trigger);
    }
    return found_triggers;
}

let LookupUDTs: {[key: TagDataTypeName]: TypeLookupUDT} = {
//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
// TDC EX_Alarms T8-T12 Faults & T191 WARNINGS
//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
"Bus_Rack": {
    triggers: [
        {
            tagname_suffix: ".FaultWord",
            typename: "DINT",
            level: fltCritical,
            messages: {
                1: {level: fltCritical, message: "Slot 0 Communication Loss"},
                2: {level: fltCritical, message: "Slot 1 Communication Loss"},
                3: {level: fltCritical, message: "Slot 2 Communication Loss"},
                4: {level: fltCritical, message: "Slot 3 Communication Loss"},
                5: {level: fltCritical, message: "Slot 4 Communication Loss"},
                6: {level: fltCritical, message: "Slot 5 Communication Loss"},
                7: {level: fltCritical, message: "Slot 6 Communication Loss"},
                8: {level: fltCritical, message: "Slot 7 Communication Loss"},
                9: {level: fltCritical, message: "Slot 8 Communication Loss"},
                10: {level: fltCritical, message: "Slot 9 Communication Loss"},
                11: {level: fltCritical, message: "Slot 10 Communication Loss"},
                12: {level: fltCritical, message: "Slot 11 Communication Loss"},
                13: {level: fltCritical, message: "Slot 12 Communication Loss"},
                14: {level: fltCritical, message: "Slot 13 Communication Loss"},
                15: {level: fltCritical, message: "Slot 14 Communication Loss"},
                16: {level: fltCritical, message: "Slot 15 Communication Loss"},
                17: {level: fltCritical, message: "Slot 16 Communication Loss"},
                18: {level: fltCritical, message: "Slot 17 Communication Loss"},
                19: {level: fltCritical, message: "Slot 18 Communication Loss"},
                20: {level: fltCritical, message: "Slot 19 Communication Loss"},
                21: {level: fltCritical, message: "Slot 20 Communication Loss"},
                22: {level: fltCritical, message: "Slot 21 Communication Loss"},
                23: {level: fltCritical, message: "Slot 22 Communication Loss"},
                24: {level: fltCritical, message: "Slot 23 Communication Loss"},
                25: {level: fltCritical, message: "Slot 24 Communication Loss"},
                26: {level: fltCritical, message: "Slot 25 Communication Loss"},
                27: {level: fltCritical, message: "Slot 26 Communication Loss"},
                28: {level: fltCritical, message: "Slot 27 Communication Loss"},
                29: {level: fltCritical, message: "Slot 28 Communication Loss"},
                30: {level: fltCritical, message: "Slot 29 Communication Loss"},
                31: {level: fltCritical, message: "Slot 30 Communication Loss"},
                32: {level: fltCritical, message: "Adapter communication loss"},
            }
        }
    ],
},
"DCI_STOP": {
    triggers: [
        {
            tagname_suffix: ".FaultCode",
            typename: "DINT",
            level: diag,
            trigger_type_value: true,
            messages: {
                32:    {level: diag, message: "The 'Input Status' went off. Typically communication, wiring, or card failure."},
                16384: {level: diag, message: "Channel A came on and B stayed off for too long"},
                16385: {level: diag, message: "Channel B came on and A stayed off for too long"},
                16386: {level: diag, message: "Lost channel A only while B remained on"},
                16387: {level: diag, message: "Lost channel B only while A remained on"},
            }
        }
    ]
},
"EX_Alarms": {
    triggers: [
        {
            tagname_suffix: "_PV[0]",
            typename: "DINT",
            level: fltCritical,
            messages: {
                1: {level: fltCritical, message: "Extruder Drive Communication Loss"},
                2: {level: fltCritical, message: "Gear Pump Drive Communication Loss"},
                3: {level: fltCritical, message: "I/O Adapter Rack B1 Communication Loss"},
                4: {level: fltCritical, message: "I/O Rack B1 Module 1 Communication Loss"},
                5: {level: fltCritical, message: "I/O Rack B1 Module 2 Communication Loss"},
                6: {level: fltCritical, message: "I/O Rack B1 Module 3 Communication Loss"},
                7: {level: fltCritical, message: "I/O Rack B1 Module 4 Communication Loss"},
                8: {level: fltCritical, message: "I/O Rack B1 Module 5 Communication Loss"},
                9: {level: fltCritical, message: "I/O Rack B1 Module 6 Communication Loss"},
                10: {level: fltCritical, message: "I/O Rack B1 Module 7 Communication Loss"},
                11: {level: fltCritical, message: "I/O Rack B1 Module 8 Communication Loss"},
                12: {level: fltCritical, message: "I/O Rack B1 Module 9 Communication Loss"},
                13: {level: fltCritical, message: "I/O Rack B1 Module 10 Communication Loss"},
                14: {level: fltCritical, message: "I/O Adapter Rack B2 Communication Loss"},
                15: {level: fltCritical, message: "I/O Rack B2 Module 1 Communication Loss"},
                16: {level: fltCritical, message: "I/O Rack B2 Module 2 Communication Loss"},
                17: {level: fltCritical, message: "I/O Rack B2 Module 3 Communication Loss"},
                18: {level: fltCritical, message: "I/O Rack B2 Module 4 Communication Loss"},
                19: {level: fltCritical, message: "I/O Rack B2 Module 5 Communication Loss"},
                20: {level: fltCritical, message: "I/O Rack B2 Module 6 Communication Loss"},
                21: {level: fltCritical, message: "I/O Rack B2 Module 7 Communication Loss"},
                22: {level: fltCritical, message: "I/O Rack B2 Module 8 Communication Loss"},
                23: {level: fltCritical, message: "I/O Rack B2 Module 9 Communication Loss"},
                24: {level: fltCritical, message: "I/O Rack B2 Module 10 Communication Loss"},
                25: {level: fltCritical, message: "I/O Adapter Rack B3 Communication Loss"},
                26: {level: fltCritical, message: "I/O Rack B3 Module 1 Communication Loss"},
                27: {level: fltCritical, message: "I/O Rack B3 Module 2 Communication Loss"},
                28: {level: fltCritical, message: "I/O Rack B3 Module 3 Communication Loss"},
                29: {level: fltCritical, message: "I/O Rack B3 Module 4 Communication Loss"},
                30: {level: fltCritical, message: "I/O Rack B3 Module 5 Communication Loss"},
                31: {level: fltCritical, message: "I/O Rack B3 Module 6 Communication Loss"},
                32: {level: fltCritical, message: "I/O Rack B3 Module 7 Communication Loss"},
            }
        },
        {
            tagname_suffix: "_PV[1]",
            typename: "DINT",
            level: fltCritical,
            messages: {
                1: {level: fltCritical, message: "I/O Adapter Rack B4 Communication Loss"},
                2: {level: fltCritical, message: "I/O Rack B4 Module 1 Communication Loss"},
                3: {level: fltCritical, message: "I/O Rack B4 Module 2 Communication Loss"},
                4: {level: fltCritical, message: "I/O Rack B4 Module 3 Communication Loss"},
                5: {level: fltCritical, message: "I/O Rack B4 Module 4 Communication Loss"},
                6: {level: fltCritical, message: "I/O Rack B4 Module 5 Communication Loss"},
                7: {level: fltCritical, message: "I/O Rack B4 Module 6 Communication Loss"},
                8: {level: fltCritical, message: "I/O Rack B4 Module 7 Communication Loss"},
                9: {level: fltCritical, message: "I/O Rack B4 Module 8 Communication Loss"},
                10: {level: fltCritical, message: "I/O Rack B4 Module 9 Communication Loss"},
                11: {level: fltCritical, message: "I/O Rack B4 Module 10 Communication Loss"},
                12: {level: fltCritical, message: "I/O Adapter Rack B5 Communication Loss"},
                13: {level: fltCritical, message: "I/O Rack B5 Module 1 Communication Loss"},
                14: {level: fltCritical, message: "I/O Rack B5 Module 2 Communication Loss"},
                15: {level: fltCritical, message: "I/O Rack B5 Module 3 Communication Loss"},
                16: {level: fltCritical, message: "I/O Rack B5 Module 4 Communication Loss"},
                17: {level: fltCritical, message: "I/O Rack B5 Module 5 Communication Loss"},
                18: {level: fltCritical, message: "I/O Rack B5 Module 6 Communication Loss"},
                19: {level: fltCritical, message: "I/O Rack B5 Module 7 Communication Loss"},
                20: {level: fltCritical, message: "I/O Rack B5 Module 8 Communication Loss"},
                21: {level: fltCritical, message: "I/O Rack B5 Module 9 Communication Loss"},
                22: {level: fltCritical, message: "I/O Rack B5 Module 10 Communication Loss"},
                23: {level: fltCritical, message: "I/O Adapter Rack B6 Communication Loss"},
                24: {level: fltCritical, message: "I/O Rack B6 Module 1 Communication Loss"},
                25: {level: fltCritical, message: "I/O Rack B6 Module 2 Communication Loss"},
                26: {level: fltCritical, message: "I/O Rack B6 Module 3 Communication Loss"},
                27: {level: fltCritical, message: "I/O Rack B6 Module 4 Communication Loss"},
                28: {level: fltCritical, message: "I/O Rack B6 Module 5 Communication Loss"},
                29: {level: fltCritical, message: "I/O Rack B6 Module 6 Communication Loss"},
                30: {level: fltCritical, message: "I/O Rack B6 Module 7 Communication Loss"},
                31: {level: fltCritical, message: "I/O Rack B6 Module 8 Communication Loss"},
                32: {level: fltCritical, message: "I/O Rack B6 Module 9 Communication Loss"},
                
            }
        },
        {
            tagname_suffix: "_PV[2]",
            typename: "DINT",
            level: fltCritical,
            messages: {
                1: {level: fltCritical, message: "Head Pressure Transducer Disconnected"},
                2: {level: fltCritical, message: "Suction Pressure Transducer Disconnected"},
                3: {level: fltCritical, message: "Discharge Pressure Transducer Disconnected"},
                4: {level: fltCritical, message: "Melt Pressure Transducer Disconnected"},
                5: {level: warn, message: "Head Pressure Warning"},
                6: {level: fltCritical, message: "Head Pressure High"},
                7: {level: fltCritical, message: "Suction Pressure Low"},
                8: {level: fltCritical, message: "Suction Pressure High"},
                9: {level: fltCritical, message: "Discharge Pressure High"},
                10: {level: fltCritical, message: "Magnet Drawer Open"},
                11: {level: fltCritical, message: "Extruder Drive Fault"},
                12: {level: fltCritical, message: "Gear Pump Drive Fault"},
                13: {level: fltCritical, message: "Lube Oil Pressure Low"},
                14: {level: fltCritical, message: "Lube Oil Pressure High"},
                15: {level: fltCritical, message: "Lube Oil Temperature High"},
                16: {level: fltCritical, message: "Extruder Motor Temperature High"},
                17: {level: fltCritical, message: "Gear Pump Motor Temperature High"},
                18: {level: fltCritical, message: "Enclosure Temperature High"},
                19: {level: fltCritical, message: "Screen Changer Delta Pressure High"},
                20: {level: warn, message: "Maximum Backflushes, Please Change Screens"},
                21: {level: fltCritical, message: "Screen Changer Hydraulic Pressure High"},
                22: {level: fltCritical, message: "Screen Changer Position Transducer Disconnected"},
                23: {level: fltCritical, message: "Downstream Enclosure Temperature High"},
                24: {level: fltCritical, message: "GP Configuration Missing in AOI"},
                25: {level: fltCritical, message: "Extruder Drive configuration missing in AOI"},
                26: {level: fltCritical, message: "Custom Alarm 4"},
                27: {level: fltCritical, message: "Custom Alarm 5"},
                28: {level: fltCritical, message: "Custom Alarm 6"},
                29: {level: fltCritical, message: "Rupture Disc Blown"},
                30: {level: fltCritical, message: "Blender Communication Loss"},
                31: {level: fltCritical, message: "Blender Alarm"},
                32: {level: fltCritical, message: "Extruder E-Stop Relay Tripped"},
            }
        },
        {
            tagname_suffix: "_PV[3]",
            typename: "DINT",
            level: fltCritical,
            messages: {
                1: {level: fltCritical, message: "Extruder Motor Blower Overload"},
                2: {level: fltCritical, message: "Lube Pump Motor Overload"},
                3: {level: fltCritical, message: "Water Pump Motor Overload"},
                4: {level: fltCritical, message: "Die Blower Overload"},
                5: {level: fltCritical, message: "Screen Changer Hydraulic Pump Overload"},
                6: {level: fltCritical, message: "Screen Changer Hydraulic Pump Disconnect Off"},
                7: {level: fltCritical, message: "Barrel Zone 1 Blower Overload"},
                8: {level: fltCritical, message: "Barrel Zone 2 Blower Overload"},
                9: {level: fltCritical, message: "Barrel Zone 3 Blower Overload"},
                10: {level: fltCritical, message: "Barrel Zone 4 Blower Overload"},
                11: {level: fltCritical, message: "Barrel Zone 5 Blower Overload"},
                12: {level: fltCritical, message: "Barrel Zone 6 Blower Overload"},
                13: {level: fltCritical, message: "Barrel Zone 7 Blower Overload"},
                14: {level: fltCritical, message: "Barrel Zone 8 Blower Overload"},
                15: {level: fltCritical, message: "Vacuum Pump 1 Motor Overload"},
                16: {level: fltCritical, message: "Vacuum Pump 2 Motor Overload"},
                17: {level: fltCritical, message: "Vacuum Pump 3 Motor Overload"},
                18: {level: fltCritical, message: "Vacuum Pump 1 Thermocouple Break"},
                19: {level: fltCritical, message: "Vacuum Pump 2 Thermocouple Break"},
                20: {level: fltCritical, message: "Vacuum Pump 3 Thermocouple Break"},
                21: {level: fltCritical, message: "Vacuum Pump 1 Temperature High"},
                22: {level: fltCritical, message: "Vacuum Pump 2 Temperature High"},
                23: {level: fltCritical, message: "Vacuum Pump 3 Temperature High"},
                24: {level: fltCritical, message: "Vacuum System Water Level Low"},
                25: {level: fltCritical, message: "Vacuum System Condensate Tank Full"},
                26: {level: fltCritical, message: "Vacuum Vent 1 Pressure Low"},
                27: {level: fltCritical, message: "Vacuum Vent 2 Pressure Low"},
                28: {level: fltCritical, message: "Torque Clutch Proximity Switch"},
                29: {level: fltCritical, message: "Barrel Cooling Unit Alarm"},
                30: {level: fltCritical, message: "Custom Alarm 7"},
                31: {level: warn, message: "Vacuum system water level high warning"},
                32: {level: fltCritical, message: "Vacuum system water level high shutdown"},
            }
        },
        {
            tagname_suffix: "_PV[4]",
            typename: "DINT",
            level: fltCritical,
            messages: {
                1: {level: fltCritical, message: "Zone 1 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                2: {level: fltCritical, message: "Zone 2 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                3: {level: fltCritical, message: "Zone 3 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                4: {level: fltCritical, message: "Zone 4 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                5: {level: fltCritical, message: "Zone 5 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                6: {level: fltCritical, message: "Zone 6 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                7: {level: fltCritical, message: "Zone 7 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                8: {level: fltCritical, message: "Zone 8 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                9: {level: fltCritical, message: "Zone 9 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                10: {level: fltCritical, message: "Zone 10 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                11: {level: fltCritical, message: "Zone 11 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                12: {level: fltCritical, message: "Zone 12 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                13: {level: fltCritical, message: "Zone 13 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                14: {level: fltCritical, message: "Zone 14 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                15: {level: fltCritical, message: "Zone 15 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                16: {level: fltCritical, message: "Zone 16 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                17: {level: fltCritical, message: "Zone 17 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                18: {level: fltCritical, message: "Zone 18 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                19: {level: fltCritical, message: "Zone 19 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                20: {level: fltCritical, message: "Zone 20 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                21: {level: fltCritical, message: "Zone 21 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                22: {level: fltCritical, message: "Zone 22 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                23: {level: fltCritical, message: "Zone 23 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                24: {level: fltCritical, message: "Zone 24 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                25: {level: fltCritical, message: "Zone 25 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                26: {level: fltCritical, message: "Zone 26 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                27: {level: fltCritical, message: "Zone 27 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                28: {level: fltCritical, message: "Zone 28 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                29: {level: fltCritical, message: "Zone 29 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                30: {level: fltCritical, message: "Zone 30 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                31: {level: fltCritical, message: "Zone 31 temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
                32: {level: fltCritical, message: "Vent Zone temperature control fault: See temperature control screen for details on thermocouple, temperature, current monitoring, or control issue"},
            }
        },
        {
            tagname_suffix: "_PV[5]",
            typename: "DINT",
            level: fltCritical,
            messages: {
                1: {level: warn, message: "Zone 1 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                2: {level: warn, message: "Zone 2 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                3: {level: warn, message: "Zone 3 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                4: {level: warn, message: "Zone 4 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                5: {level: warn, message: "Zone 5 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                6: {level: warn, message: "Zone 6 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                7: {level: warn, message: "Zone 7 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                8: {level: warn, message: "Zone 8 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                9: {level: warn, message: "Zone 9 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                10: {level: warn, message: "Zone 10 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                11: {level: warn, message: "Zone 11 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                12: {level: warn, message: "Zone 12 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                13: {level: warn, message: "Zone 13 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                14: {level: warn, message: "Zone 14 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                15: {level: warn, message: "Zone 15 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                16: {level: warn, message: "Zone 16 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                17: {level: warn, message: "Zone 17 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                18: {level: warn, message: "Zone 18 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                19: {level: warn, message: "Zone 19 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                20: {level: warn, message: "Zone 20 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                21: {level: warn, message: "Zone 21 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                22: {level: warn, message: "Zone 22 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                23: {level: warn, message: "Zone 23 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                24: {level: warn, message: "Zone 24 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                25: {level: warn, message: "Zone 25 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                26: {level: warn, message: "Zone 26 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                27: {level: warn, message: "Zone 27 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                28: {level: warn, message: "Zone 28 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                29: {level: warn, message: "Zone 29 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                30: {level: warn, message: "Zone 30 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                31: {level: warn, message: "Zone 31 temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
                32: {level: warn, message: "Vent Zone temperature control warning: See temperature control screen for details on thermocouple, current monitoring, or control issue"},
            }
        }

    ]
},
//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
// TDC RS_Alarms T1-T5
//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

"RS_Alarms": {
    triggers: [
        {
            tagname_suffix: "_PV[0]",
            typename: "DINT",
            level: fltCritical,
            messages: {
                1: {level: fltCritical, message: "Roll 1 Drive Communication Loss"},
                2: {level: fltCritical, message: "Roll 2 Drive Communication Loss"},
                3: {level: fltCritical, message: "Roll 3 Drive Communication Loss"},
                4: {level: fltCritical, message: "Roll 4 Drive Communication Loss"},
                5: {level: fltCritical, message: "Roll 5 Drive Communication Loss"},
                6: {level: fltCritical, message: "Roll 6 Drive Communication Loss"},
                7: {level: fltCritical, message: "Roll 7 Drive Communication Loss"},
                8: {level: fltCritical, message: "Pull Roll Drive Communication Loss"},
                9: {level: fltCritical, message: "Pull Roll 2 Drive Communication Loss"},
                10: {level: fltCritical, message: "Traction Drive Communication Loss"},
                11: {level: fltCritical, message: "Height Adjust Drive Communication Loss"},
                12: {level: fltCritical, message: "Carriage Drive Communication Loss"},
                13: {level: fltCritical, message: "Hydraulic Pump Drive Communication Loss"},
                14: {level: fltCritical, message: "Custom1 Comm Loss"},
                15: {level: fltCritical, message: "Custom2 Comm Loss"},
                16: {level: fltCritical, message: "Custom3 Comm Loss"},
                17: {level: fltCritical, message: "Custom4 Comm Loss"},
                18: {level: fltCritical, message: "Custom5 Comm Loss"},
                19: {level: fltCritical, message: "Custom6 Comm Loss"},
                20: {level: fltCritical, message: "Custom7 Comm Loss"},
                21: {level: fltCritical, message: "Custom8 Comm Loss"},
                22: {level: fltCritical, message: "Custom9 Comm Loss"},
                23: {level: fltCritical, message: "Custom10 Comm Loss"},
                24: {level: fltCritical, message: "Custom11 Comm Loss"},
                25: {level: fltCritical, message: "Custom12 Comm Loss"},
                26: {level: fltCritical, message: "Custom13 Comm Loss"},
                27: {level: fltCritical, message: "Custom14 Comm Loss"},
                28: {level: fltCritical, message: "Custom15 Comm Loss"},
                29: {level: fltCritical, message: "Custom16 Comm Loss"},
                30: {level: fltCritical, message: "Custom17 Comm Loss"},
                31: {level: fltCritical, message: "RMC Controller Communication Loss"},
                32: {level: fltCritical, message: "IV PLC Communication Loss"},
            }
        },
        {
            tagname_suffix: "_PV[1]",
            typename: "DINT",
            level: fltCritical,
            messages: {
                1: {level: fltCritical, message: "I/O Adapter Rack R1 Communication Loss"},
                2: {level: fltCritical, message: "I/O Rack R1 Module 1 Communication Loss"},
                3: {level: fltCritical, message: "I/O Rack R1 Module 2 Communication Loss"},
                4: {level: fltCritical, message: "I/O Rack R1 Module 3 Communication Loss"},
                5: {level: fltCritical, message: "I/O Rack R1 Module 4 Communication Loss"},
                6: {level: fltCritical, message: "I/O Rack R1 Module 5 Communication Loss"},
                7: {level: fltCritical, message: "I/O Rack R1 Module 6 Communication Loss"},
                8: {level: fltCritical, message: "I/O Rack R1 Module 7 Communication Loss"},
                9: {level: fltCritical, message: "I/O Rack R1 Module 8 Communication Loss"},
                10: {level: fltCritical, message: "I/O Rack R1 Module 9 Communication Loss"},
                11: {level: fltCritical, message: "I/O Rack R1 Module 10 Communication Loss"},
                12: {level: fltCritical, message: "I/O Adapter Rack R2 Communication Loss"},
                13: {level: fltCritical, message: "I/O Rack R2 Module 1 Communication Loss"},
                14: {level: fltCritical, message: "I/O Rack R2 Module 2 Communication Loss"},
                15: {level: fltCritical, message: "I/O Rack R2 Module 3 Communication Loss"},
                16: {level: fltCritical, message: "I/O Rack R2 Module 4 Communication Loss"},
                17: {level: fltCritical, message: "I/O Rack R2 Module 5 Communication Loss"},
                18: {level: fltCritical, message: "I/O Rack R2 Module 6 Communication Loss"},
                19: {level: fltCritical, message: "I/O Rack R2 Module 7 Communication Loss"},
                20: {level: fltCritical, message: "I/O Rack R2 Module 8 Communication Loss"},
                21: {level: fltCritical, message: "I/O Rack R2 Module 9 Communication Loss"},
                22: {level: fltCritical, message: "I/O Rack R2 Module 10 Communication Loss"},
                23: {level: fltCritical, message: "I/O Adapter Rack R3 Communication Loss"},
                24: {level: fltCritical, message: "I/O Rack R3 Module 1 Communication Loss"},
                25: {level: fltCritical, message: "I/O Rack R3 Module 2 Communication Loss"},
                26: {level: fltCritical, message: "I/O Rack R3 Module 3 Communication Loss"},
                27: {level: fltCritical, message: "I/O Rack R3 Module 4 Communication Loss"},
                28: {level: fltCritical, message: "I/O Rack R3 Module 5 Communication Loss"},
                29: {level: fltCritical, message: "I/O Rack R3 Module 6 Communication Loss"},
                30: {level: fltCritical, message: "I/O Rack R3 Module 7 Communication Loss"},
                31: {level: fltCritical, message: "I/O Rack R3 Module 8 Communication Loss"},
                32: {level: fltCritical, message: "I/O Rack R3 Module 9 Communication Loss"},
            }
        },
        {
            tagname_suffix: "_PV[4]",
            typename: "DINT",
            level: fltCritical,
            messages: {
                1: {level: fltCritical, message: "Roll 1 Temperature Control Unit Fault"},
                2: {level: fltCritical, message: "Roll 2 Temperature Control Unit Fault"},
                3: {level: fltCritical, message: "Roll 3 Temperature Control Unit Fault"},
                4: {level: fltCritical, message: "Roll 4 Temperature Control Unit Fault"},
                5: {level: fltCritical, message: "Roll 5 Temperature Control Unit Fault"},
                6: {level: fltCritical, message: "Spare Temperature Control Unit Fault"},
                7: {level: fltCritical, message: "Custom Alarm 3"},
                8: {level: fltCritical, message: "Custom Alarm 4"},
                9: {level: fltCritical, message: "Custom Alarm 5"},
                10: {level: fltCritical, message: "Custom Alarm 6"},
                11: {level: fltCritical, message: "Custom Alarm 7"},
                12: {level: warn, message: "Alarm Reset PB Pressed"},
                13: {level: fltCritical, message: "Custom Alarm 9"},
                14: {level: fltCritical, message: "Custom Alarm 10"},
                15: {level: fltCritical, message: "Custom Alarm 11"},
                16: {level: fltCritical, message: "Custom Alarm 12"},
                17: {level: fltCritical, message: "Custom Alarm 13"},
                18: {level: fltCritical, message: "Custom Alarm 14"},
                19: {level: fltCritical, message: "Custom Alarm 15"},
                20: {level: fltCritical, message: "Custom Alarm 16"},
                21: {level: fltCritical, message: "Custom Alarm 17"},
                22: {level: fltCritical, message: "Custom Alarm 18"},
                23: {level: fltCritical, message: "Custom Alarm 19"},
                24: {level: fltCritical, message: "Custom Alarm 20"},
                25: {level: fltCritical, message: "Custom Alarm 21"},
                26: {level: fltCritical, message: "Custom Alarm 22"},
                27: {level: fltCritical, message: "Custom Alarm 23"},
                28: {level: fltCritical, message: "Custom Alarm 24"},
                29: {level: fltCritical, message: "Custom Alarm 25"},
                30: {level: fltCritical, message: "Custom Alarm 26"},
                31: {level: fltCritical, message: "Lamination Low Diameter,  Change Roll"},
                32: {level: fltCritical, message: "Roll Drives DC Bus Variation. Check DC Bus Fusing"},
            }
        },
        
        {
            tagname_suffix: "_PV[3]",
            typename: "DINT",
            level: fltCritical,
            messages: {
                1: {level: fltCritical, message: "I/O Adapter Rack R1 Communication Loss"},
                2: {level: fltCritical, message: "I/O Rack R1 Module 1 Communication Loss"},
                3: {level: fltCritical, message: "I/O Rack R1 Module 2 Communication Loss"},
                4: {level: fltCritical, message: "I/O Rack R1 Module 3 Communication Loss"},
                5: {level: fltCritical, message: "I/O Rack R1 Module 4 Communication Loss"},
                6: {level: fltCritical, message: "I/O Rack R1 Module 5 Communication Loss"},
                7: {level: fltCritical, message: "I/O Rack R1 Module 6 Communication Loss"},
                8: {level: fltCritical, message: "I/O Rack R1 Module 7 Communication Loss"},
                9: {level: fltCritical, message: "I/O Rack R1 Module 8 Communication Loss"},
                10: {level: fltCritical, message: "I/O Rack R1 Module 9 Communication Loss"},
                11: {level: fltCritical, message: "I/O Rack R1 Module 10 Communication Loss"},
                12: {level: fltCritical, message: "I/O Adapter Rack R2 Communication Loss"},
                13: {level: fltCritical, message: "I/O Rack R2 Module 1 Communication Loss"},
                14: {level: fltCritical, message: "I/O Rack R2 Module 2 Communication Loss"},
                15: {level: fltCritical, message: "I/O Rack R2 Module 3 Communication Loss"},
                16: {level: fltCritical, message: "I/O Rack R2 Module 4 Communication Loss"},
                17: {level: fltCritical, message: "I/O Rack R2 Module 5 Communication Loss"},
                18: {level: fltCritical, message: "I/O Rack R2 Module 6 Communication Loss"},
                19: {level: fltCritical, message: "I/O Rack R2 Module 7 Communication Loss"},
                20: {level: fltCritical, message: "I/O Rack R2 Module 8 Communication Loss"},
                21: {level: fltCritical, message: "I/O Rack R2 Module 9 Communication Loss"},
                22: {level: fltCritical, message: "I/O Rack R2 Module 10 Communication Loss"},
                23: {level: fltCritical, message: "I/O Adapter Rack R3 Communication Loss"},
                24: {level: fltCritical, message: "I/O Rack R3 Module 1 Communication Loss"},
                25: {level: fltCritical, message: "I/O Rack R3 Module 2 Communication Loss"},
                26: {level: fltCritical, message: "I/O Rack R3 Module 3 Communication Loss"},
                27: {level: fltCritical, message: "I/O Rack R3 Module 4 Communication Loss"},
                28: {level: fltCritical, message: "I/O Rack R3 Module 5 Communication Loss"},
                29: {level: fltCritical, message: "I/O Rack R3 Module 6 Communication Loss"},
                30: {level: fltCritical, message: "I/O Rack R3 Module 7 Communication Loss"},
                31: {level: fltCritical, message: "I/O Rack R3 Module 8 Communication Loss"},
                32: {level: fltCritical, message: "I/O Rack R3 Module 9 Communication Loss"},
            }
        },
        
        {
            tagname_suffix: "_PV[2]",
            typename: "DINT",
            level: fltCritical,
            messages: {
                1: {level: fltCritical, message: "Roll 1 Drive Fault"},
                2: {level: fltCritical, message: "Roll 2 Drive Fault"},
                3: {level: fltCritical, message: "Roll 3 Drive Fault"},
                4: {level: fltCritical, message: "Roll 4 Drive Fault"},
                5: {level: fltCritical, message: "Roll 5 Drive Fault"},
                6: {level: fltCritical, message: "Roll 6 Drive Fault"},
                7: {level: fltCritical, message: "Roll 7 Drive Fault"},
                8: {level: fltCritical, message: "Pull Roll Drive Fault"},
                9: {level: fltCritical, message: "Pull Roll 2 Drive Fault"},
                10: {level: fltCritical, message: "Traction Drive Fault"},
                11: {level: fltCritical, message: "Height Drive Fault"},
                12: {level: fltCritical, message: "Carriage Drive Fault"},
                13: {level: fltCritical, message: "Hydraulic Pump Drive Fault"},
                14: {level: fltCritical, message: "Hydraulic Oil Level Low"},
                15: {level: fltCritical, message: "Hydraulic Oil Temperature High"},
                16: {level: fltCritical, message: "Hydraulic Low Pressure"},
                17: {level: fltCritical, message: "Roll Stack Air Pressure Low"},
                18: {level: warn, message: "Pull Roll Air Pressure Low"},
                19: {level: fltCritical, message: "Pull Roll Wrap Detected"},
                20: {level: fltCritical, message: "Roll 1 Motor Temperature High"},
                21: {level: fltCritical, message: "Roll 2 Motor Temperature High"},
                22: {level: fltCritical, message: "Roll 3 Motor Temperature High"},
                23: {level: fltCritical, message: "Roll 4 Motor Temperature High"},
                24: {level: fltCritical, message: "Roll 5 Motor Temperature High"},
                25: {level: fltCritical, message: "Roll 6 Motor Temperature High"},
                26: {level: fltCritical, message: "Roll 7 Motor Temperature High"},
                27: {level: fltCritical, message: "Pull Roll Motor Temperature High"},
                28: {level: fltCritical, message: "Pull Roll 2 Motor Temperature High"},
                29: {level: fltCritical, message: "Roll Stand Brake Resistor Temperature High"},
                30: {level: fltCritical, message: "Pull Roll Brake Resistor Temperature High"},
                31: {level: fltCritical, message: "Enclosure Temperature High"},
                32: {level: fltCritical, message: "Roll Stand E-Stop Relay Tripped"},
            }
        },

        
        {
            tagname_suffix: "_PV[5]",
            typename: "DINT",
            level: fltCritical,
            messages: {
                1: {level: fltCritical, message: "Roll 1 Cooling Pump Overload"},
                2: {level: fltCritical, message: "Roll 2 Cooling Pump Overload"},
                3: {level: fltCritical, message: "Roll 3 Cooling Pump Overload"},
                4: {level: fltCritical, message: "Roll 4 Cooling Pump Overload"},
                5: {level: fltCritical, message: "Roll 5 Cooling Pump Overload"},
                6: {level: fltCritical, message: "Spare Cooling Pump Overload"},
                7: {level: fltCritical, message: "Roll 1 Zone Fault: Check Thermocouple or High Temperature"},
                8: {level: fltCritical, message: "Roll 2 Zone Fault: Check Thermocouple or High Temperature"},
                9: {level: fltCritical, message: "Roll 3 Zone Fault: Check Thermocouple or High Temperature"},
                10: {level: fltCritical, message: "Roll 4 Zone Fault: Check Thermocouple or High Temperature"},
                11: {level: fltCritical, message: "Roll 5 Zone Fault: Check Thermocouple or High Temperature"},
                12: {level: fltCritical, message: "Spare Zone Fault: Check Thermocouple or High Temperature"},
                13: {level: fltCritical, message: "Temperature Station High Water Pressure Warning"},
                14: {level: fltCritical, message: "Temperature Station High Water Pressure Shutdown"},
                15: {level: fltCritical, message: "Temperature Station Low Air Pressure Warning"},
                16: {level: fltCritical, message: "Temperature Station Low Air Pressure Shutdown"},
                17: {level: fltCritical, message: "Cooling Pump Phase Loss Detected, Check Fuse(s)"},
                18: {level: fltCritical, message: "Too Many Pumps Selected"},
                19: {level: fltCritical, message: "Coater Pump Overload"},
                20: {level: fltCritical, message: "Coater Blower Overload"},
                21: {level: fltCritical, message: "Coater Fluid Level Low"},
                22: {level: warn, message: "Coater Infeed Guard Open"},
                23: {level: warn, message: "Pull Roll Infeed Guard Open"},
                24: {level: warn, message: "Pull Roll Outfeed Open"},
                25: {level: fltCritical, message: "Rolls Locked Out"},
                26: {level: fltCritical, message: "Roll Gates Open"},
                27: {level: fltCritical, message: "Roll Gates Bypassed"},
                28: {level: fltCritical, message: "Safety Mat Tripped"},
                29: {level: fltCritical, message: "Traction/Height Interlock Bypassed"},
                30: {level: fltCritical, message: "Roll Cleaning Selected, Rolls Will Run In Reverse"},
                31: {level: fltCritical, message: "Custom Alarm 1"},
                32: {level: warn, message: "PLC Battery Low, Replace Battery"},

            }
        },
    ]
},
}










