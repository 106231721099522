import papa from 'papaparse'
import type { IO } from '../types/io';
import type { SecondaryTag } from '../filters/secondaries';

export default function createCsvTagSpreadsheet(aliases: IO[], tags: SecondaryTag[] = []) {
  let spreadsheet = [[
    "Name",
    "Scope",
    "...",
    "Alias To",
    "Alias To",
    "Data Type",
    "Is Safety",
    "Description",
    "R/W",
    "...",
    "Display Format",
  ]];

  for(let i in aliases) {
    const io = aliases[i];

    let sl: string[] = [];

    let displayFormat = "";
    if (io.type === "INT") {
      displayFormat = "Decimal"
    }

    sl.push(io.name);
    sl.push("<controller>");
    sl.push("0");
    sl.push(io.aliasTo);
    sl.push(io.aliasTo);
    sl.push(io.type);
    sl.push(io.safetyType);
    sl.push(io.description);
    sl.push("Read/Write");
    sl.push("0");
    sl.push(displayFormat);
    spreadsheet.push(sl)
  }

  // for (let t in tags) {
  //   const tag = tags[t];
  // }

  return papa.unparse(spreadsheet);
}
