import { Component } from 'react';

import './progressbar.css'


interface ProgressBarProps {
  processed: number;
  remaining: number;
}

export default class ProgressBar extends Component<ProgressBarProps> {
  render() {
    const processed = this.props.processed;
    const remaining = this.props.remaining;
    const total = processed + remaining;

    const percent = processed / total;

    return (
      <div className="ProgressBar">
        {processed} / {total} files : {Math.round(percent * 100) }%

        <div className="ProgressBar-Full">
          <div className="ProgressBar-Progress" style={{width: (percent * 100) + "%"}}>
            <div className="ProgressBar-Progress-Inner"></div>
          </div>
        </div>
      </div>
    )
  }
}