import type { SecondaryTag } from "./secondaries";

export type Location = "RS" | "EXT" | "ANY" | "W";

export type KnownDriveUse = {
    examples: string[];
    description: string;
    use_name_search: RegExp[];
    use_name: string;
    location: Location;
    tags: SecondaryTag[];
}

export let knownDriveUses: Array<KnownDriveUse> = [
    {
        examples: [
            "HVTSE 105mm B - MAIN AC DRIVE 500 HP ABB AC DRIVE ACS880-07-0635A-5 (4028178) (SEE DWG. 18081-B102 FOR WIRING)",
            "EXTRUDER B 4500 - MAIN DRIVE 250 HP ABB AC DRIVE ACS880-07-302A-5 (4035783) (SEE DWG. 18081-B102 FOR POWER WIRING)",
            "1DR EXTRUDER B SGHS3000 - MAIN DRIVE 500 HP ABB AC DRIVE ACS880-07-0635A-5 (4028178) (SEE DWG. 18081-B102 FOR POWER WIRING)",
        ],
        description: "Extruder Drive",
        use_name_search: [
            / ([A-H]) .*MAIN AC DRIVE /i,
            /EXTRUDER ([A-H]) (SGHS)?[0-9]{3,4}.* (MAIN|EXTRUDER)/i,
        ],
        use_name: "{1:Cap}EXDrive",
        location: "EXT",
        tags: [
            {
                name: "{use_name}",
                type: "EXDrive_Universal",
                description: "Universal VFD Extruder Drive Handler\n\n{details}\nSchemPg:{pg}"
            },
        ]
    },
    {
        examples: [
            "HVTSE 105mm B - GEAR PUMP DRIVE 30 HP YASKAWA AC DRIVE GA800 GA80U4044ABM (4036599) (SEE DWG. 18081-B202 FOR POWER WIRING)",
            "SGHS EXTRUDER B - GEAR PUMP DRIVE"
        ],
        description: "Gear Pump Drive",
        use_name_search: [
            / ([A-H]) .*GEAR PUMP/i,
        ],
        use_name: "{1:Cap}GPDrive",
        location: "EXT",
        tags: [
            {
                name: "{use_name}",
                type: "GPDrive_Universal",
                description: "Universal VFD Gear Pump Drive Handler\n\n{details}\nSchemPg:{pg}"
            },
        ]
    },

    
    {
        examples: [
            "TOP OF SHEET BLOWERS AC DRIVE",
            "BOTTOM OF SHEET BLOWERS AC DRIVE",
        ],
        description: "Sheet Blower",
        use_name_search: [
            /(TOP|BOTTOM) OF SHEET BLOWERS AC DRIVE/i,
        ],
        location: "ANY",
        use_name: "AR_{1:Cap}SheetBlower",
        tags: [
            {
                name: "{use_name}",
                type: "Blower",
                description: "Universal VFD Blower Handler\n\n{details}\nSchemPg:{pg}"
            },
        ]
    },


    {
        examples: [
            "SPINDLE A",
        ],
        description: "Winder Spindle",
        use_name_search: [
            /^SPINDLE ([A-Z])/i,
        ],
        use_name: "WinderSpindle_{1:Cap}",
        location: "W",
        tags: [
            {
                name: "{use_name}",
                type: "RollDrive",
                description: "Universal VFD Roll Handler\n\n{details}\nSchemPg:{pg}"
            },
        ]
    },
    {
        examples: [
            "1DR ROLL STAND - TOP ROLL 5 HP AC DRIVE ACS880-01-07A6-5 (4026447) (SEE DWG. 18081-R102 FOR POWER WIRING)",
            "1DR ROLL STAND - ROLL #1 5 HP AC DRIVE ACS880-01-07A6-5 (4026447) (SEE DWG. 18081-R102 FOR POWER WIRING)",
            "1DR TOP ROLL ABB ACS 880-01-07A6-5 4026447",
        ],
        description: "Roll Drive",
        use_name_search: [
            /ROLL STAND - (AUX) ([A-Z]+[ ]?[0-9]?) ROLL[ ]([0-9]?)/i,
            /ROLL STAND - ([A-Z]+[ ]?[0-9]?) ROLL[ ]([0-9]?)/i,
            /ROLL STAND - ()ROLL #([0-9])/i,
            /(TOP|BOTTOM|MIDDLE|PULL)[ ]?([0-9]?) ROLL[ ]?([0-9]?)/i,
        ],
        use_name: "RollDrive_{1:Cap}{2:Cap}{3:Cap}",
        location: "ANY",
        tags: [
            {
                name: "{use_name}",
                type: "RollDrive",
                description: "Universal VFD Roll Handler\n\n{details}\nSchemPg:{pg}"
            },
        ]
    },

    {
        examples: [
            "HYDRAULIC PUMP AC DRIVE WIRING",
        ],
        description: "Hydraulic Pump",
        use_name_search: [
            /HYDRAULIC PUMP/i,
        ],
        location: "RS",
        use_name: "RS_HydPump",
        tags: [
            {
                name: "{use_name}",
                type: "HydPump",
                description: "Universal VFD Chrome Roll Hydraulic Pump Handler\n\n{details}\nSchemPg:{pg}"
            },
        ]
    },

    {
        examples: [
            "EDGE SLITTER DRIVE AC DRIVE WIRING",
        ],
        description: "Edge Slitter",
        use_name_search: [
            /EDGE SLITTER/i,
        ],
        location: "ANY",
        use_name: "RS_EdgeSlitter",
        tags: [
            {
                name: "{use_name}",
                type: "EdgeSlitter",
                description: "Universal VFD Edge Slitter Handler\n\n{details}\nSchemPg:{pg}"
            },
        ]
    },

    {
        examples: [
            "TRACTION DRIVE AC DRIVE WIRING",
        ],
        description: "Traction Drive",
        use_name_search: [
            /TRACTION DRIVE/i,
        ],
        location: "RS",
        use_name: "RS_TractionDrive",
        tags: [
            {
                name: "{use_name}",
                type: "TractionDrive",
                description: "Universal VFD Traction Drive Handler\n\n{details}\nSchemPg:{pg}"
            },
        ]
    },

    {
        examples: [
            "HUDRAULIC AUTO GAP, RMC151 CPU"
        ],
        description: "Hydraulic Auto Gap",
        use_name_search: [
            /HYDRAULIC AUTO GAP/i,
        ],
        location: "ANY",
        use_name: "RS_HydAutoGap",
        tags: [
            {
                name: "BusMon_{use_name}",
                type: "Bus_Adapter",
                description: "Connection Monitor for Hydraulic Auto Gap\n{details}\nSchemPg:{pg}",
            },
        ]
    }
];