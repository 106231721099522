import { Component } from 'react';

import {alarmLevels} from '../alarms/level'

export default class InstructionPrompt extends Component {

    render() {
        
        let levelDescriptions: JSX.Element[] = [];
        for (let l in alarmLevels) {
            const level = alarmLevels[l];
            let prefixes: JSX.Element[] = [];
            for (const prefix of level.tag_prefixes) {
                prefixes.push(<li className="level-tag-prefix" key={prefix}>{prefix}</li>);
            }
            levelDescriptions.push(
                <li key={l}>
                <span className="level-humanName">{level.humanName}: </span>
                <span className="level-Description">{level.description}</span>
                <ul className="level-Prefixes">
                    {prefixes}
                </ul>
                </li>
            )
        }

        return (
        <div className="ModulePages-Blank">
          <h3>Choose Files or drag and drop to begin</h3>
          <div className="FileInput-Instructions">
            <h3>Convert electrical schematics to PLC aliases</h3>
            <p>Use .DWG or .DXF files of I/O to generate AB PLC tag aliases and some AOI instances</p>
            <h3>Generate HMI PanelView Alarms from PLC comments</h3>
            <p>Use .CSV files of tag comments exported from Studio 5000/Logix to create PanelView alarms XML</p>
            <ul>
              <li>Each trigger integer must have its individual bit descriptions with the exact message.</li>
              <li>Trigger integers may be in AOIs or controller scope</li>
              <li>Each tag name must match or start with its corresponding level:</li>
              <ul>
                {levelDescriptions}
              </ul>
            </ul>
            <p>If you run into the maximum alarm limit of 10,000, it can be increased in:</p>
            <pre>C:\Program Files (x86)\Rockwell Software\RSView Enterprise\AlarmMessages.xml</pre>
          </div>
        </div>
        )
    }
}