import { Component } from "react";

import type {AlarmTrigger} from "./trigger";

import './label-filter-render.css';

// icons
import {
    Clipboard,
    ClipboardCheck,
} from 'react-bootstrap-icons';

type MyProps = {
    filter_levels: string[];
    triggers: AlarmTrigger[],
    filter_name: string;
}
type MyState = {
    filter_string: string;
    clicked: boolean;
}


export class AlarmLevelFilterComponent extends Component<MyProps, MyState>  {
    

    constructor(props: MyProps) {
        super(props);

        this.state = {
            filter_string: this.createFilterString(),
            clicked: false,
        }
        this.createFilterString();
    }

    createFilterString(): string {
        

        console.log("filtering for: ", this.props.filter_levels)

        console.log(this.props.triggers.filter((a) => {
            return a.pvtag.includes("Sheer");
        }))

        // console.log(sorted_triggers);

        let labels: string[] = [];
        for (const trigger of this.props.triggers) {
            // console.log(trigger.template.level.machineName, this.props.filter_levels, this.props.filter_levels.includes(trigger.template.level.machineName) )
            if ( this.props.filter_levels.includes(trigger.template.level.machineName) ) {
                // do not add duplicate
                if (! labels.includes(trigger.label_with_level)) {
                    labels.push(trigger.label_with_level);
                }
            }
        }
        
        let sorted_labels = labels.sort();

        let str = "";
        for(const label of sorted_labels) {
            // for some reason, no spaces between commas.
            // ensure there is no trailing comma
            if (str !== "") {str += ",";}
            str += label;
        
        }

        return str;
    }


    copyClick(event: React.MouseEvent<HTMLButtonElement>) {
        navigator.clipboard.writeText(this.state.filter_string);
        this.setState({clicked: true});
    }

    render() {
        // console.log(this.state);
        return (
            <div className="LabelFilter">
                <h5 className="LabelFilter-Name">{this.props.filter_name} Filter</h5>
                <div className="LabelFilter-Body">
                        
                    <div className="LabelFilter-Text">
                        {this.state.filter_string}
                    </div>
                    <button className={"LabelFilter-Copy " + (this.state.clicked ? "Clicked": "Unclicked")} onClick={(e) => this.copyClick(e)} 
                        title="Click this to copy. Then paste this filter in the alarm element on the [ALARM] and [ALARM HISTORY] pages.">
                        {this.state.clicked ? <ClipboardCheck /> : <Clipboard />}
                    </button>
                </div>
            </div>
        );
    }
}