import papa from 'papaparse'
import type { IO } from '../types/io';
import type { SecondaryTag } from '../filters/secondaries';


export default function createCsvTagAB(aliases: IO[], tags: SecondaryTag[] = []) {

  let tagImport: string[][] = [];


  // ALIASES
  // Example: 
  // TYPE,SCOPE,NAME,DESCRIPTION,DATATYPE,SPECIFIER,ATTRIBUTES
  // ALIAS,,AEX_VacPump1_Aux,"","","Rack_V2:3:I.1","(RADIX := Decimal, ExternalAccess := Read/Write)"
  for(let i in aliases) {
    const io = aliases[i];

    // let displayFormat = "";
    // if (io.type === "INT") {
    //   displayFormat = "Decimal"
    // }

    let il: string[] = [];
    il.push("ALIAS");
    il.push("");
    il.push( io.name );
    il.push( io.description.replaceAll("\n", "$N") );
    il.push(''); // il.push(io.type);
    il.push( io.aliasTo );
    il.push("(RADIX := Decimal, ExternalAccess := Read/Write)");
    tagImport.push(il)
  }


  // TAGS
  // Example: 
  // TYPE,SCOPE,NAME,DESCRIPTION,DATATYPE,SPECIFIER,ATTRIBUTES
  // TAG,,AEX_Tune,"","AutoTune","","(Class := Standard, Constant := false, ExternalAccess := Read/Write)"
  // TAG,,AEX_Vac_Estop,"","DCI_STOP","","(Class := Safety, Constant := false, ExternalAccess := Read/Write)"
  // TAG,,AEX_WiggleTime_Remain,"","REAL","","(Class := Standard, RADIX := Float, Constant := false, ExternalAccess := Read/Write)"
  // TAG,,A_Blender_Run,"","BOOL","","(Class := Standard, RADIX := Decimal, Constant := false, ExternalAccess := Read/Write)"
  for (let t in tags) {
    const io = tags[t];


    let attributes = "(Class := ";
    if (io.safetyType === "Safety") {
      attributes += "Safety";
    }
    else {
      attributes += "Standard"
    }
    attributes += ", "
    if (io.type === "REAL") {
      attributes += "RADDIX := Float, "
    }
    attributes += "Constant := false, ";
    attributes += "ExternalAccess := Read/Write)"


    let description = io.description.replaceAll("\n", "$N");
    // description = description.replaceAll("°", "$B0")

    let il: string[] = [];
    il.push("TAG");
    il.push("");
    il.push( io.name );
    il.push( description );
    il.push( typeof io.type === "string" ? io.type : io.io?.type || "");
    il.push( io.aliasTo || "" );
    il.push(attributes);
    tagImport.push(il);
  }



  let csv = "";
  csv =  'remark,"CSV-Import-Export"\r\n';
  csv += 'remark,"Date = Tue Apr 06 08:37:28 2021"\r\n';
  csv += 'remark,"Owner = Evan"\r\n';
  csv += 'remark,"Company = PTi"\r\n';
  csv += '0.3\r\n';
  csv += "TYPE,SCOPE,NAME,DESCRIPTION,DATATYPE,SPECIFIER,ATTRIBUTES\r\n";
  csv += papa.unparse(tagImport);
  csv += "\r\n";

  return csv;
}
