import type { SecondaryTag } from "./secondaries";

export type KnownDriveHW = {
    description: string;
    template: string;

    examples: string[];
    hw_text: RegExp[];
    hw_not?: string[]; // Description of other hardware that should prevent this one
    drive: RegExp[];
    tags: SecondaryTag[];
}


export let knownDriveHW: Array<KnownDriveHW> = [

    ///////////////////////////////
    // AB POWERFLEX
    {
        description: "POWERFLEX ETHERNET",
        drive: [
            /POWERFLEX 5/i,
        ],
        examples: [
            "FROM ETHERNET SWITCH L-R11911",
        ],
        hw_text: [
            /(FROM.* ETHERNET .*)/i,
            /(.*TO ETHERNET SWITCH.*)/i,
        ],
        template: "ENIP {1}",
        tags: [
            {
                name: "{use_name}_VFD_Mapped",
                type: "VFD_POWERFLEX_EIP",
                description: "POWERFLEX ENET MAPPED\n{details}\nSchemPg:{pg}",
            },
            {
                name: "{use_name}_VFD_Mapper",
                type: "VFD_ENET_MAP_AB_POWERFLEX",
                description: "POWERFLEX ENET MAPPER\n{details}\nSchemPg:{pg}",
            },
            {
                name: "BusAdapter_{use_name}",
                type: "Bus_Adapter",
                safetyType: "Standard",
                description: "Connection Monitor for PowerFlex 525\n{details}\nSchemPg:{pg}",
            },
        ]
    },

    ///////////////////////////////
    // ABB ACS 880
    {
        description: "ABB ACS ETHERNET",
        drive: [
            /ACS 880/i,
        ],
        examples: [
            "FROM DOWNSTREAM ETHERNET SWTICH, L-B21145",
        ],
        hw_text: [
            /(FROM.* ETHERNET .*)/i,
            /(.*TO ETHERNET SWITCH.*)/i,
        ],
        hw_not: [
            "ABB ACS MODBUS",
        ],
        template: "ENIP {1}",
        tags: [
            {
                name: "{use_name}_VFD_Mapped",
                type: "VFD_ACS880",
                description: "ACS880 ETHERNET MAPPED\n{details}\nSchemPg:{pg}",
            },
            {
                name: "{use_name}_VFD_Mapper",
                type: "VFD_ENET_MAP_ABB_ACS880",
                description: "ACS880 ETHERNET MAPPER\n{details}\nSchemPg:{pg}",
            },
            {
                name: "BusAdapter_{use_name}",
                type: "Bus_Adapter",
                safetyType: "Standard",
                description: "Connection Monitor for ACS880\n{details}\nSchemPg:{pg}",
            },
        ]
    },
    {
        description: "ABB ACS MODBUS",
        drive: [
            /ACS 880/i,
        ],
        examples: [
            "FROM EIP TO MODBUS GATEWAY, L-R11948",
            "FROM MIDDLE ROLL AC DRIVE MODBUS LINK, L-R17110",
            "TO MIDDLE ROLL AC DRIVE MODBUS LINK, L-R17108",
            "FROM MODBUS GATEWAY, L-R11147"
        ],
        hw_text: [
            /(FROM EIP TO MODBUS GATEWAY.*)/i,
            /(FROM MODBUS GATEWAY)/i,
            /(PROSOFT EIP TO MODBUS.*)/i,
            /(FROM [.*] AC DRIVE MODBUS LINK)/i,
        ],
        template: "MODBUS {1}",
        tags: [
            {
                name: "{use_name}_VFD_Mapped",
                type: "VFD_ACS880",
                description: "ACS880 MODBUS MAPPED\n{details}\nSchemPg:{pg}",
            },
            {
                name: "{use_name}_VFD_Mapper",
                type: "VFD_MBS_MAP_ABB_ACS880",
                description: "ACS880 MODBUS MAPPER\n{details}\nSchemPg:{pg}",
            }
        ]
    },

    {
        description: "FEN-31 HTL ENCODER",
        template: "FEN-31 HTL ENCODER",
        drive: [/ACS 880/i],
        examples: [
            "FEN-31 HTL-ENCODER INTERFACE"
        ],
        hw_text: [
            /FEN-31 HTL-ENCODER/i,
        ],
        tags: [],
    },

    ////////////////////////////////
    // YASKAWA GA800
    {
        description: "YASKAWA ETHERNET",
        drive: [
            /YASKAWA GA800/i,
        ],
        examples: [
            "FROM DOWNSTREAM ETHERNET SWTICH, L-B21145",
            "TO ETHERNET SWITCH",
        ],
        hw_text: [
            /(FROM.* ETHERNET .*)/i,
            /(.*TO ETHERNET SWITCH.*)/i,
        ],
        template: "ENIP {1}",
        tags: [
            {
                name: "{use_name}_VFD_Mapped",
                type: "VFD_YASKAWA_GA800_EIP",
                description: "YASKAWA GA800 ENET MAPPED\n{details}\nSchemPg:{pg}",
            },
            {
                name: "{use_name}_VFD_Mapper",
                type: "VFD_ENET_MAP_YASKAWA_GA800",
                description: "YASKAWA GA800 ENET MAPPER\n{details}\nSchemPg:{pg}",
            },
            {
                name: "BusAdapter_{use_name}",
                type: "Bus_Adapter",
                safetyType: "Standard",
                description: "Connection Monitor for GA800\n{details}\nSchemPg:{pg}",
            },
        ]
    },
    {
        description: "YASKAWA SI-EN3 ETHERNET/IP MODULE",
        template: "SI-EN3 ENIP MODULE",
        drive: [/YASKAWA GA800/i,],
        examples: [
            "SI-EN3 ETHERNET/IP MODULE"
        ],
        hw_text: [
            /SI-EN3/i,
        ],
        tags: [],
    },


    ///////////////////////////////////////////
    // UNIVERSAL
    {
        description: "STO",
        template: "{1}",
        drive: [/[.]*/i],
        examples: [
            "STO SIGNAL FROM L-R14204"
        ],
        hw_text: [
            /(STO SIGNAL FROM.*)/i,
        ],
        tags: [],
    },

    { 
        description: "ProsfotModbusGateway",
        template: "{1}",
        drive: [/[.]*/i],
        examples: [
            "PROSOFT EIP TO MODBUS GATEWAY", // 18140-b170
        ],
        hw_text: [
            /(PROSOFT EIP TO MODBUS GATEWAY.*)/i,
        ],
        tags: [
            {
                name: "BusAdapter_{use_name}",
                type: "Bus_Adapter",
                safetyType: "Standard",
                description: "Connection Monitor for Prosoft Modbus Gateway\nNote: Does not monitor Modbus side of connection\n{details}\nSchemPg:{pg}",
            },
        ],
    }
];
