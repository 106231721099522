import type { RGBHEX, AlarmSeverity } from './types';

/**
 * @classdesc An internal classification that describes the severity of the alarm
 */
export type AlarmLevel = {
    humanName: string;
    machineName: string;
    description: string;
    letter: string;
    
    severity: AlarmSeverity;
    backcolor: RGBHEX;
    forecolor: RGBHEX;

    /**
     * Tag descriptions or names starting with this will be selected as ana alarm trigger.
     */
    tag_prefixes: string[];
};

export let alarmLevels: {[key: string]: AlarmLevel}  = {
    fltSafety: {
        humanName: "Safety Fault",
        machineName: "fltSafety",
        letter: "S",
        severity: 100,
        backcolor: "#FF0000",
        forecolor: "#FFFF00",
        description: "A human-safety function that will shut down the machine",
        tag_prefixes: ["fltSafety", "fltSafetyFault"],
    },
    fltCritical: {
        humanName: "Critical Fault",
        machineName: "fltCritical",
        letter: "F",
        severity: 80,
        backcolor: "#FF0000",
        forecolor: "#FFFFFF",
        description: "A critical failure that must shut down the machine",
        tag_prefixes: ["fltCritical", "FaultWord", "fltCriticalFault"],
    },
    fltNonCritical: {
        humanName: "Non-Critical Fault",
        machineName: "fltNonCritical",
        letter: "N",
        severity: 60,
        backcolor: "#EE2222",
        forecolor: "#FFCCCC",
        description: "A non-critical component has faulted that may not necessarily shut down the machine",
        tag_prefixes: ["fltNonCritical", "fltNonCriticalFault"],
    },
    warn: {
        humanName: "Warning",
        machineName: "warn",
        letter: "W",
        severity: 40,
        backcolor: "#FFFF00",
        forecolor: "#444400",
        description: "A warning that may indicate an issue but is does not immediately require a shutdown.",
        tag_prefixes: ["wrnWarning", "warn", "warning"],
    },
    diag: {
        humanName: "Diag",
        machineName: "diag",
        letter: "D",
        severity: 30,
        backcolor: "#EEEEEE",
        forecolor: "#222222",
        description: "A detailed message to help maintenance investigate a problem with the machine. Hidden by default in filtered views.",
        tag_prefixes: ["diag"],
    },
    info: {
        humanName: "Info",
        machineName: "info",
        letter: "I",
        severity: 20,
        backcolor: "#CCCCCC",
        forecolor: "#333333",
        description: "An informational message meant to inform operator about an ordinary condition. Hidden by default in filtered alarm views.",
        tag_prefixes: ["info", "information"],
    },
};