
import type { PartialTagname } from "./types";
import type { TriggerTemplate } from "./trigger";
import type { TriggerMessageTemplate } from "./message";
import { is_simple_type, simple_types, AlarmTrigger } from "./trigger";


export class TriggerTags {
    tags: {[key: PartialTagname]: TriggerTemplate} = {};
    constructor() {
        this.tags = {};
    }

    add_trigger(trigger: TriggerTemplate) {
        
        // ONLY simple types can be triggers
        if (! is_simple_type(trigger.typename)) {return}
                
        // Only integer types can be triggers. Technically BOOL could be, lets not.
        const bits_in_tag = simple_types[trigger.typename];
        if ( bits_in_tag < 1 ) { return }

        this.tags[trigger.tagname] = trigger;
    }

    add_message(tagname, message_template: TriggerMessageTemplate, forceIfMissing) {
        
        if ((forceIfMissing===false) && ! (tagname in this.tags)) {
            // console.log("Ignoring this message because it does not have a trigger", typename, tagname, bit_index, message);
            return;
        };

        const trigger = this.tags[tagname];
        trigger.messages.push(message_template);

    }

    get_instances(parent_path: PartialTagname, label: string): AlarmTrigger[] {
        let triggers: AlarmTrigger[] = [];
        for (const tagname in this.tags) {
            const trigger_template = this.tags[tagname];
            let fullpath = parent_path ? parent_path+"."+tagname : tagname;
            triggers.push(new AlarmTrigger(fullpath, label || trigger_template.tagname, trigger_template));
        }
        return triggers;
    }
}