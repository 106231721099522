import type { KnownModule } from "../types/module";

/**
 * Allen Bradley 1734 - POINT IO
 */
let knownModuleAbPoint: Array<KnownModule> = [
  {
    trigger: /1734[-| ]AENT/,
    triggerLong: /1734-AENT[R]? POINT I\/O/,
    description: "1734-AENT(R) POINT I/O ETHERNET ADAPTER",
    points: 0,
    isAdapter: true,
    module_tags: [
      {
          name: "BusRack_{L}{N}",
          type: "Bus_Rack",
          safetyType: "Standard",
          description: "Rack {L}{N}\n{DESC}\n{FILE}",
      },
  ]
  },
  {
    trigger: /1734[-| ]ARM/,
    description: "POINT I/O ADDRESS RESERVE",
    ignore: true,
    points: 0,
  },
  {
    // 1734-EP24DC POINT I/O 24VDC EXPANSION POWER SUPPLY
    trigger: /1734-EP24DC/,
    description: "1734-EP24DC 24VDC POWER EXPANSION (PWS/FPD)",
    ignore: true,
    points: 0,
  },

  {
    trigger: /ILX3[4-5]-MBS485/,
    triggerLong: /ILX3[4-5]-MBS485 PROSOFT POINT I\/O MODBUS 485/,
    description: "ILX34-MBS485 PROSOFT MODBUS 485",
    points: 0,
    ignore: true,
  },

  {
    trigger: /1734[-| ]IB8S/,
    triggerLong: /1734[-| ]IB8S POINT GUARD I\/O SAFETY DIGITAL INPUT/,
    triggerModule: /IB8S/,
    triggerRack: /1734/,
    description: "1734-IB8S\nSAFETY INPUT",
    pointReg: new RegExp('SAFETY INPUT[ ]?[0-9]+'),
    points: 8,
    pti_partnum: "4025164",
    aliasTo: [
      "hwRack_{L}{N}:{S}:I.Pt0{P}Data"
    ],
    type: [
      "BOOL"
    ],
    direction: "in",
    safetyType: "Safety",
    pairs: true,
  },

  {
    trigger: /1734[-| ]OB8S/,
    triggerLong: /1734[-| ]OB8S POINT GUARD I\/O SAFETY DIGITAL OUTPUT/,
    triggerModule: /OB8S/,
    triggerRack: /1734/,
    description: "1734-OB8S\nSAFETY OUTPUT",
    pointReg: new RegExp('SAFETY OUTPUT[ ]?[0-9]+'),
    points: 8,
    pti_partnum: "4025165",
    aliasTo: [
      "hwRack_{L}{N}:{S}:O.Pt0{P}Data",
      {aliasTo: "hwRack_{L}{N}:{S}:I.Pt0{P}ReadBack", name: "{0:name}_ReadBack", direction: "in"}
    ],
    type: [
      "BOOL",
      "BOOL"
    ],
    direction: "out",
    safetyType: "Safety",
    pairs: true,
  },


  {
    trigger: /1734[-| ]IB8/, //  POINT I/O
    triggerLong: /1734[-| ]IB8 POINT I\/O 24VDC 8 CHANNEL SINK INPUT/,
    triggerModule: /IB8/,
    triggerRack: /1734/,
    description: "1734-IB8\nPOINT I/O",
    pointReg: new RegExp('^INPUT[ ]?[0-9]+'),
    points: 8,
    aliasTo: [
      "hwRack_{L}{N}:{S}:I.{P}"
    ],
    type: [
      "BOOL"
    ],
    direction: "in",
    safetyType: "Standard"
  },

  
  {
    trigger: /1734[-| ]IE8C/,
    triggerLong: /1734[-| ]IE8C.*8 CHANNEL CURRENT/,
    triggerModule: /IE8C/,
    triggerRack: /1734/,
    description: "1734-IE8C\nANALOG CURRENT INPUT POINT I/O",
    pointReg: new RegExp('CURRENT INPUT [0-9]+'),
    points: 8,
    aliasTo: [
      "hwRack_{L}{N}:{S}:I.Ch{P}Data",
      {aliasTo: "hwRack_{L}{N}:{S}:I.Ch{P}Status", name: "{0:name}_Status", direction: "in"},
      {aliasTo: "hwRack_{L}{N}:{S}:I.Ch{P}Fault", name: "{0:name}_Fault", direction: "in"},
    ],
    type: [
      "INT",
      "SINT",
      "BOOL"
    ],
    direction: "in",
    analog: true,
    safetyType: "Standard"
  },
  {
    trigger: /1734[-| ]IE4C/,
    triggerLong: /1734[-| ]IE4C.*ANALOG CURRENT/,
    triggerModule: /IE4C/,
    triggerRack: /1734/,
    description: "1734-IE4C\nANALOG CURRENT INPUT POINT I/O",
    pointReg: new RegExp('CURRENT INPUT [0-9]+'),
    points: 4,
    aliasTo: [
      "hwRack_{L}{N}:{S}:I.Ch{P}Data",
      {aliasTo: "hwRack_{L}{N}:{S}:I.Ch{P}Status", name: "{0:name}_Status", direction: "in"},
      {aliasTo: "hwRack_{L}{N}:{S}:I.Ch{P}Fault", name: "{0:name}_Fault", direction: "in"},
    ],
    type: [
      "INT",
      "SINT",
      "BOOL"
    ],
    direction: "in",
    safetyType: "Standard"
  },
  {
    trigger: /1734[-| ]IE2V/,
    triggerLong: /1734[-| ]IE2V.*VOLTAGE INPUT/,
    triggerModule: /IE2V/,
    triggerRack: /1734/,
    description: "1734-IE2V\n2 VOLTAGE INPUT ANALOG MODULE",
    pointReg: new RegExp('VOLTAGE INPUT [0-9]+'),
    points: 2,
    aliasTo: [
      "hwRack_{L}{N}:{S}:I.Ch{P}Data",
      {aliasTo: "hwRack_{L}{N}:{S}:I.Ch{P}Status", name: "{0:name}_Status", direction: "in"},
      {aliasTo: "hwRack_{L}{N}:{S}:I.Ch{P}Fault", name: "{0:name}_Fault", direction: "in"},
    ],
    type: [
      "INT",
      "SINT",
      "BOOL"
    ],
    direction: "in",
    analog: true,
    safetyType: "Standard"
  },

  {
    trigger: /1734[ |-]OW4/,
    triggerLong: /1734[ |-]OW4.*4 RELAY OUTPUT/,
    triggerModule: /OW4/,
    triggerRack: /1734/,
    description: "1734-OW4\nPOINT I/O 4 RELAY OUTPUT MODULE",
    pointReg: new RegExp('OUTPUT[ ]?[0-9]+A'),
    points: 4,
    aliasTo: [
      "hwRack_{L}{N}:{S}:O.{P}"
    ],
    type: [
      "BOOL"
    ],
    direction: "out",
    safetyType: "Standard"
  },
  {
    trigger: /1734[ |-]OB2EP/,
    triggerLong: /1734[ |-]OB2EP.* OUTPUT/,
    triggerModule: /OB2EP/,
    triggerRack: /1734/,
    description: "1734-OB2EP\nPOINT I/O 2 CHANNEL PROTECTED OUTPUT",
    pointReg: new RegExp('OUTPUT[ ]?[0-9]+'),
    points: 2,
    aliasTo: [
      "hwRack_{L}{N}:{S}:O.{P}"
    ],
    type: [
      "BOOL"
    ],
    direction: "out",
    safetyType: "Standard"
  },
  {
    trigger: /1734[-| ]OB8/,
    triggerLong: /1734[-| ]OB8 POINT I\/O 8.*SOURCING OUTPUT/,
    triggerModule: /OB8/,
    triggerRack: /1734/,
    description: "1734-OB8\nPOINT I/O",
    pointReg: new RegExp('^OUTPUT[ ]?[0-9]+'),
    points: 8,
    aliasTo: [
      "hwRack_{L}{N}:{S}:O.{P}"
    ],
    type: [
      "BOOL"
    ],
    direction: "out",
    safetyType: "Standard"
  },

  {
    trigger: /1734[ |-]IT2/,
    triggerModule: /IT2/,
    triggerRack: /1734/,
    description: "1734-IT2I/C\n2 CHANNEL THERMOCOUPLE INPUT",
    pointReg: new RegExp('[0-9]+.*INPUT[ ]?CHANNEL.*HIGH'),
    points: 2,
    aliasTo: [
      "hwRack_{L}{N}:{S}:I.Ch{P}Data",
      {aliasTo: "hwRack_{L}{N}:{S}:I.Ch{P}Overrange", name: "{0:name}_Sbreak", direction: "in"},
    ],
    type: [
      "INT",
      "BOOL"
    ],
    direction: "in",
    thermocouple: true,
    safetyType: "Standard"
  },
]

export default knownModuleAbPoint;