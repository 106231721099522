import type {KnownIO} from "../types/io";

let knownIoUniversal: Array<KnownIO> = [

	
	{
		examples: [
			"MAIN ENCL A/C TEMP OK",
		],
		description: /ENCL.*TEMP.*/i,
		name: "{LL}_{L}{N}_Encl_Temp_OK",
		type: "BOOL",
		direction: "in",
		safetyType: "Standard",
	},
	{
		examples: [
			"SURGE PROTECTOR R111SP-1 OK",
            "SURGE PROTECTOR R112SP-1 OK",
			"EXTRUDER A 480W POWER SUPPLY SURGE PROTECTOR ONLINE",
			"TITAN PLUS SURGE PROTECTOR ONLINE K1:SI:0104" // 18229-k121 on safety input
		],
		description: /SURGE PROTECTOR (.*)[ ]?(OK|ONLINE)/i,
		name: "{LL}{L}{N}_SurgeProtector{1_}OK",
		type: "BOOL",
		direction: "in",
		// safetyType: "Standard",
	},

	
    
	{
		description: /E-STOP RES[E]?RVED/i,
		name: "{LL}_Estop_Reserved_{N}_{S}_{P}",
		type: "BOOL",
		direction: "in",
	},
	{
		description: /E-STOP/i,
		name: "{LL}_Estop_UNKNOWN_{N}_{S}_{P}_Estop_PB",
		type: "BOOL",
		direction: "in",
	},
]

export  {knownIoUniversal as default}
