import type { AlarmTrigger } from "./trigger";
import type { PartialTagname, TagDataTypeName } from "./types";
import type { TriggerTemplate } from "./trigger";
import type { TriggerMessageTemplate } from "./message";
import { simple_types, is_simple_type } from "./trigger"
import { TriggerTags } from "./triggers";


export class AoiDefinition {
    nested_types: {[tagname: PartialTagname]: TagDataTypeName} = {};
    trigger_tags = new TriggerTags();
    typename: TagDataTypeName;

    constructor(typename: TagDataTypeName) {
        this.typename = typename;
    }
}

export class AoiDefinitions {
    types: { [key: TagDataTypeName]: AoiDefinition } = {};
    /**
     * Should compile a list of AOIs, or should compile a list of simple Tags
     */
    controller = false;

    constructor(controller = false) {
        this.controller = controller;
    }

    /**
     * @method add_aoi
     * Use when finding a new "AOINAME:AOI" or when adding a UDT
     * Can call repeatedly for the same item
     * @example: TAG,AOINAME:AOI,fltCriticalFault 
     * typename: AOINAME
     */
    add_aoi(typename: TagDataTypeName) {
        // AOIs must be complex types
        // Controller tags must be simple types
        const is_simple = is_simple_type(typename);
        if (is_simple && this.controller === false) {return}
        if ((! is_simple || simple_types[typename] < 1) && this.controller === true) {return}
        if (! (typename in this.types)) {
            this.types[typename] = new AoiDefinition(typename);
        }
    }

    /**
     * @method add_nested_type
     * Use when adding a nested type to an AOI or UDT
     * @example:
     * TAG,HC_Zone:AOI,Tzone,"","TEMP_PID","",""
     * typename: HC_Zone
     * tagname: Tzone
     * nested_type: TEMP_PID
     */
    add_nested_type(typename: TagDataTypeName, tagname: PartialTagname, nested_type: TagDataTypeName) {
        // Do not add if it's a simple type
        if (is_simple_type(nested_type)) {return}
        this.types[typename].nested_types[tagname] = nested_type;
    }

    /**
     * @method add_trigger
     * @description .parent_type must be defined in TriggerTemplate
     * Use when finding a matching trigger in an AOI or UDT
     * @example: TAG,AOINAME:AOI,fltCriticalFault 
     */
    add_trigger(trigger: TriggerTemplate) {
        const parent_type = trigger.parent_type
        if (typeof parent_type === "undefined" || ! (parent_type in this.types)) {
            console.log("Trying to add trigger to AOI that does not have a parent type", trigger);
            return;
        }
        this.types[parent_type].trigger_tags.add_trigger(trigger);
    }

    /**
     * @method add_message
     * Use when adding a message to a trigger
     * @param forceIfMissing: true will add the message even if the trigger is missing
     * This might be used if the tagname doesn't match a pattern, but the description does
     * @example: 
     *  COMMENT,Blower:AOI,fltCriticalFault,"Blower motor contactor.",,"fltCriticalFault.0"
     *  typename: Blower
     *  tagname: fltCriticalFault
     *  bit_index: 0 (from fltCriticalFault.0)
     *  message: "Blower motor contactor."
     */ 
    add_message(typename: TagDataTypeName, tagname: PartialTagname, message_tempalte: TriggerMessageTemplate, forceIfMissing = false) {
        // Blank type names mean they don't belong to an AOI
        if ( typename === "" ) {
            // console.log("Blank tag type for", tagname);
            return;
        }
        if ( ! (typename in this.types)) {
            if (forceIfMissing) {
                this.add_aoi(typename);
            }
            else {
                console.log("This type did not exist: ", typename, tagname);
                return;
            }
        }
        let aoi = this.types[typename];
        aoi.trigger_tags.add_message(tagname, message_tempalte, forceIfMissing);
    }

    /**
     *  Built all triggers for an instance of a type including nested ones 
     * 
     * @param typename The type to build triggers for
     * @param parent_path The full path of the aoi to get triggers for. Should include any parent AOI tags.
     * @param label The label that should show up before the message
     **/
    get_triggers(typename: TagDataTypeName, parent_path: PartialTagname, label: string): AlarmTrigger[] {
        let found_triggers: AlarmTrigger[] = [];

        if (! (typename in this.types)) {
            return found_triggers;
        }
        const aoi = this.types[typename];
        // console.log("Building triggers for", typename, fullpath, aoi.trigger_tags);
        
        const triggers = aoi.trigger_tags.get_instances(parent_path, label);
        found_triggers.push(...triggers);

        for (let nested_tagname in aoi.nested_types) {
            const nested_typename = aoi.nested_types[nested_tagname]
            const nested_triggers = this.get_triggers(nested_typename, parent_path+"."+nested_tagname, label+" "+nested_tagname);
            found_triggers.push(...nested_triggers);
        }
        return found_triggers;
    }
}
