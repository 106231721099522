let descriptionRemoves = [
"LINE",
"SPARELINE",
"ORG",
"GRN",
"WHT",
"W1",
"W2",
"W3",
"W4",
"W5",
"W6",
"W7",
"W8",
"W9",
"W10",
"W11",
"W12",
"W13",
"W14",
"W15",
"W16",
"B1",
"B2",
"B3",
"B4",
"B5",
"B6",
"B7",
"B8",
"B9",
"B10",
"B11",
"B12",
"B13",
"B14",
"B15",
"B16",
"RED",
"RED 1",
"RED 2",
"RED 3",
"RED 4",
"RED 5",
"RED 6",
"RED 7",
"RED 8",
"RED 9",
"RED 10",
"RED 11",
"RED 12",
"RED 13",
"RED 14",
"BRN",
"BN",
"BLU",
"-BU",
"+RD",
"BR+",
"CAL1",
"CAL2",
"-14U1",
"-15U1",
"18 BLU",
"GRY",
"BLK",
"WH",
"BLU/WHT",
"RD",
"OR",
"BK",
"BU",
"GR",
"PNK",
"(GRN)",
"(WHT)",
"(5 PIN CONNECTOR)",
"+ WHT",
"- RED",
"CT",
"CREV_TRIANGLE",
"AUTOZERO BLU",
"AUTOZERO ORG",
"SUPPLY+ RED",
"SIGNAL- BLK",
"N.C. WHT",
"N.C. GRN",
"REV_TRIANGLE",
"(BLK)",
"(BRN)",
"(GRY)",
"(YEL)",
"(BLU)",
"YEL",
"N/C WHT",
"PE",
"UA",
"P2",
"P7",
"P8",
"S2",
"S3",
"X3.2",
"C1",
"X3.4",
"Extruder_Junction Box 4_Terminal",
"J-BOX 4",
"-F1-UF1",
"-UF1",
"-UM1-41.4",
"-UM1-41.3",
"-UM1-41.2",
"-UM1-41.1",
"-UM1-42.4",
"-UM1-42.3",
"-UM1-42.2",
"-UM1-42.1",
"-UF1-42.4",
"-UF1-42.2",
"-UF1-42.3",
"-UF1-42.1",
"-UF1-41.4",
"-UF1-41.2",
"-UF1-41.3",
"-UF1-41.1",
"-X501",
"-X402",
"31-T1",
"ON",
"G0",
"Us",
"AUT",
"RES",
"SHLD",
"NETWORK STATUS",
"NOT USED",
"GND",
"SGND",
"CHASSIS GND",
"REV",
"OUTPUT 1",
"4-20mA",
"0V",
"OV",
"10 TO 30V",
"DC COM",
"DC COMMON",
"CONN",
"CON_LEFT",
"A2",
"A1",
"REV1",
"RELEASED FOR BUILD",
"COMMON",
"NETWORK STATUS",
"NETWORK ACTIVITY STATUS",
"NETWORK ADDRESS",
"ETHERNET/IP CONNECTOR 1",
"ETHERNET/IP CONNECTOR 2",
"0-10Vdc",
"+24Vdc",
"+24",
"BC+24",
"SWITCH +24V DC",
"BC-COM",
"6A",
"24V",
"24VAC-N",
"24VDC, DC COM",
"24VDC",
"+24 VDC",
"24VDC SUPPLY",
"24VDC UNSWITCHED",
"24VDC SWITCHED FROM",
"+V1",
"+V2",
"+VDC",
"2 PORT JACK STRIP",
"3 PORT JACK STRIP",
"4 PORT JACK STRIP",
"5 PORT JACK STRIP",
"6 PORT JACK STRIP",
"7 PORT JACK STRIP",
"8 PORT JACK STRIP",
"9 PORT JACK STRIP",
"10 PORT JACK STRIP",
"ARMOURED CABLE",
"FROM SENSOR",
"AC",
"DC",
"DC-COM",
"CR",
"C2",
"COM",
"COM1",
"COM2",
"PIN E (AUTOZERO)",
"PIN F (AUTOZERO)",
"PIN 5",
"SET 2",
"SET 1",
"REVISIONS",
"DESCRIPTION",
"Downstream Low Power Terminal",
"BLUTERM01",
"Extruder_High_Power_Terminal_Block",
"Extruder_Low_Power_Terminal_Block",
"ZONE",
"TO",
"NaK FILLED MELT PRESSURE TRANSMITTER (4...20mA OUTPUT)",
"SUN XMD-01",
"XMD SERIES, 12-PIN CABLE, SINGLE OUTPUT WITH DIN CONNECTOR",
"XMD SERIES, 12-PIN CABLE, SINGLE OUTPUT WITH DIN CONNECTOR ",
"SIGNAL - (4-20mA)",
"SUPPLY + (10...30VDC)",
"ENABLE (+24VDC)",
"OV SUPPLY GND (RAIL GND)",
"UNIVERSAL INPUT 1 (4 TO 20mA)",
"+24VDC SUPPLY PWR (+VBATT)",
"X0",
"Downstream Cart Junction Box Terminal",
"Extruder_Main_Cabinet_Terminal_Block",
"Drive_Cabinet_Terminal",
"PRESSURE TRANSMITTER",
"OUT CH.0 V",
"OUT CH.0 V COM",
"TO",
"(-10EVBY)",
"(-10EV1)",
"(-10EV2)",
"(-10EV3)",
"(-10EV4)",
"(-10EV5)",
"(-10EV6)",
"(-10EV7)",
"(-10EV8)",
"(-10EV9)",
"(-10EV10)",
"(-10EV11)",
"(-10EV12)",
"(-10EV13)",
"(-10EV14)",
"(-10EV15)",
"TEXT_3L",
"DESC03",
"LINE_REF",
"WIREWAYLOC",
"LOC",
"ZAMBELLO_TYP_LOC_REF",
"TB_A",
"TB_LETTER",
"DESC",
"ID",
"_4TERM02",
"_3TERM01",
"3TERM01",
"CR_NO",
"TN",
"CR_NO_pti",
"CR_NC_pti",
"FS_NO_pti",
"PB_LT_pti",
"ABU_pti",
"GS_2NC",
"BA",
"1FUSE01",
"FB_1P",
"CRXREF",
"CRL-REF_NO_pti",
"CRL-REF_NC_pti",
"CR_COILpti",
"SOL_pti",
"FS_NC_pti",
"A1TERM01",
"A2TERM02",
"_TERM02",
"_TERM01",
"-TERM01",
"+TERM01",
"1?TERM01",
"1TERM01",
"1TERM02",
"2TERM02",
"2?TERM02",
"3TERM02",
"4TERM02",
"5TERM02",
"6TERM02",
"7TERM02",
"8TERM02",
"9TERM02",
"WHT+",
"BLK_",
"B/R+",
"WIREWAY",
"PL_MF_pti",
// "ARMOURED CABLE",
"Info Rt Arrow_pti_D",
"Info Lt Arrow_pti_D",
"8 PORT JACK STRIP",
"12 PORT JACK STRIP",
"TB",
"PT_1",
"GND_Equip",
"CBL_Shield",
"16 BLU",
"18 WHT/BLU",
"PIN",
"PIN 1",
"PIN 2",
"PIN 3",
"PIN 4",
"HE",
"HF",
"HD",
"H1+",
"H2+",
"H3+",
"H4+",
"H5+",
"H6+",
"H7+",
"H8+",
"H9+",
"H10+",
"H11+",
"H12+",
"H13+",
"H14+",
"CASE",
"MODULE 0",
"MODULE 1",
"MODULE 2",
"MODULE 3",
"MODULE 4",
"MODULE 5",
"MODULE 6",
"MODULE 7",
"MODULE 8",
"MODULE 9",
"MODULE 10",
"TERM01",
"TERM02",
"61TERM01",
"62TERM02",
"LS_NC_pti",
"PL_FM_pti",
"SRXREF",
"SR",
"PTI",
"8 A",
"8A",
"PS_NC_pti",
"TEXT_3C",
"RATING",
"TS_NC",
"TS_NC_pti",
"PS_NO_pti",
"N/C RED",
"(RED)",
"1M",
"FROM",
"SCREW TERMINAL",
"Screen_Changer_Terminal",
"16 WHT/BLU",
"Extruder_PLC_Cabinet_Teminal_Block",
"Extruder_Junction Box 2_Terminal",
"CT_A",
"Slsw4",
"Nopb2",
"0X",
"00X",
"X00",
"CLR",
"22 AWG 3 COND SHLD",
"AH_pti",
];


let simpleFilters = {};

for (let fltr of descriptionRemoves) {
    simpleFilters[fltr] = true;
}

export  {simpleFilters as default}