
import { Component } from "react";
import type { AlarmLevel } from './level';

import './level-render.css';

type MyProps = {
    level: AlarmLevel;
}
type MyState = {
}


export class AlarmLevelComponent extends Component<MyProps, MyState>  {
    render() {
        const level = this.props.level;

        const style = {
            color: level.forecolor,
            backgroundColor: level.backcolor,
        }
        return (
            <div 
              className="Alarm-Level"
              style={style}
              title={level.humanName} >
                <span className="Alarm-Level-Letter">
                    {level.letter}
                </span>
            </div>
        )
    }

}