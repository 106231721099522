import { Component } from 'react';
import { SecondaryTag } from '../filters/secondaries';

interface TagRowProps {
  io: SecondaryTag;
}

export default class TagRow extends Component<TagRowProps> {
  render() {
    const io = this.props.io;
    let name = io.name || "UNKNOWN"
    
    // console.log(io.name, io, this);

    const description = io.description.split('\n').map((item, key) => {
      return <span key={key}>{item}<br/></span>
    })

    return (
      <tr>
        <td className="left">{name}</td>
        <td>{typeof io.type === "string" ? io.type : io.io?.type}</td>
        <td>{io.safetyType}</td>
        <td>{description}</td>
      </tr>
    )
  }
}