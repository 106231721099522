
import type {KnownDriveUse} from '../filters/drive-uses'
import type {KnownDriveHW} from '../filters/drive-hw'

import type { DxfParseEntity } from '../dxfpage/parseBase'
import type { KnownModule } from '../types/module'


import type {IO} from '../types/io'
import type {SecondaryTag} from '../filters/secondaries'

import {knownDriveUses, Location} from '../filters/drive-uses'
import {knownDriveHW} from '../filters/drive-hw'
import DxfPage from '../dxfpage'

type NamePatterns = {
    use_name?: string;
    use_text?: string;
    known_hws?: string;
    drive_desc_short?: string;
    drive_desc_long?: string;
    details?: string;
    pg?: string;
}

export class Drive {
    trigger_entity: DxfParseEntity;
    module?: KnownModule;
    known_use?: KnownDriveUse;
    known_hw: KnownDriveHW[] = [];
    page: DxfPage;
    patterns: NamePatterns;
    secondaries: SecondaryTag[] = [];


    constructor(trigger_entity: DxfParseEntity, page: DxfPage) {
        this.trigger_entity = trigger_entity;
        this.page = page;

        // if (! trigger_entity.ebModule) {
        //     console.log("Drive is missing module from constructed entity");
        // }
        // this.module = trigger_entity.ebModule;

        const trigger_text = trigger_entity.text || "";
        let trigger_match: string[] = [];
        if (typeof this.module?.trigger === "string" || this.module?.trigger instanceof RegExp) {
            trigger_match = trigger_text.match(this.module.trigger) || [];
        }

        this.patterns = {
            pg: this.page.filename.split('.')[0],
            known_hws: "",
            drive_desc_short: this.formatNameTemplate(
                    trigger_entity.ebModule?.description || "",
                    trigger_match
            ),
            drive_desc_long: trigger_text,
        }

        this.find_known_use();
        this.find_hardware();
        this.create_details();
        this.generateSecondaries();
    }

    find_known_use() {
        for (let known_use of knownDriveUses) {
            if (! this.check_location(known_use.location)) {continue}


            for (const search of known_use.use_name_search) {
                const found = this.page.parsed.findTextEntity(search);
                if (found) {
                    this.known_use = known_use;
                    this.patterns.use_text = found.entity.text;
                    this.patterns.use_name = this.formatNameTemplate(known_use.use_name, found.match);
                    return;
                }

            }
        }
    }

    check_location(location: Location): boolean {
        if (location === "ANY") {return true}

        for (const entity of this.page.parsed.texts) {
            const text = entity.text;

            if (location === "W") {
                if (text.includes("WINDER")) {
                    return true;
                }
            }

            if (location === "RS") {
                if (text.includes("RS/TS")) {
                    return true;
                }
                if (text.includes("RS Multi")) {
                    return true;
                }
                if (text.includes("ROLL STAND")) {
                    return true;
                }
            }

            if (location === "EXT") {
                if (text.includes("EXTRUDER")) {
                    return true;
                }
                if (text.includes("HVTSE")) {
                    return true;
                }
            }
        }
        return false;
    }

    find_hardware() {

        for (const hardware of knownDriveHW ) {
            for (const drive_pattern of hardware.drive) {
                if (this.trigger_entity.ebModule?.description.match(drive_pattern)) {
                    let matches = this.find_hardware_text(hardware);
                    
                    // Prevent this hardware from being detected if another one is. 
                    // This was added due to modbus couplers showing up with Ethernet cables.
                    if (hardware.hw_not) {
                        for (let n in hardware.hw_not) {
                            const check_ignore = hardware.hw_not[n];
                            for (let h in knownDriveHW) {
                                // console.log( knownDriveHW[h].description.includes(check_ignore), check_ignore,  knownDriveHW[h].description, knownDriveHW[h], this.find_hardware_text(knownDriveHW[h]) )
                                if (knownDriveHW[h].description.includes(check_ignore) && this.find_hardware_text(knownDriveHW[h])) {
                                    matches = null;
                                }
                            }
                        }
                        
                    }
                    if (matches) {
                        this.known_hw.push( hardware );
                        if (typeof this.patterns.known_hws === "string") {
                            this.patterns.known_hws += this.formatNameTemplate(hardware.template, matches) + "\n";
                        }
                        break;
                    }
                }
            }
        }
    }
    find_hardware_text(hardware: KnownDriveHW): string[]|null {
        for (const search of hardware.hw_text) {
            const result = this.page.parsed.findTextEntity(search);
            if (result) {
                // search nearby
                const nearby = this.page.parsed.getProbableNewlineTexts(result.entity, 0.3, 1, 0, 1);
                console.log(nearby);
                
                // collapse each nearby[n].text into a single string
                for (let n in nearby) {
                    if (nearby[n].text === "LINE_REF") {
                        continue;
                    }
                    result.match[0] += " " + nearby[n].text;
                    result.match[1] += " " + nearby[n].text;
                    console.log(result.match);
                }

                return result.match;
            }
        }
        return null;
    }

    create_details() {
        this.patterns.details = this.patterns.drive_desc_long + "\n";
        // for (const hardware of this.known_hw) {
        //     this.patterns.details += hardware.description + "\n";
        // }
        if (this.patterns.drive_desc_long !== this.patterns.use_text) {
            this.patterns.details += this.patterns.use_text + "\n";
        }

        this.patterns.details += this.patterns.known_hws;
    }

    formatNameTemplate(template: string, matches: string[]): string {
        let name = template;

        for (let p in this.patterns) {
            // console.log(p, this.patterns[p]);
            name = name.replace("{" + p + "}", this.patterns[p]);
        }

        if (!Array.isArray(matches)) {matches = [];}

        // extend matches so that blank strings are at the end for 5 matches
        if (matches.length < 5) {
            for (let i = matches.length; i < 5; i++) {
                matches.push("");
            }
        }

        matches.forEach((match, idx) => {
            if (idx === 0 ) {return}
            name = name.replace("{" +idx+"}", match);

            if (typeof match === "string") {
                let underscored = match.replaceAll(" ", "_")
                name = name.replace("{"+idx+"_}", underscored);
                name = name.replace("{"+idx+":Cap}", underscored.charAt(0).toUpperCase() + underscored.slice(1).toLowerCase());
            }
        })

        return name;
    }

    format_tag(unformatted: SecondaryTag): SecondaryTag {
        let formatted: SecondaryTag = Object.assign({}, unformatted);

        for (const idx in formatted) {
            formatted[idx] = this.formatNameTemplate( formatted[idx], [])
        }

        if ( !formatted.safetyType ) {
            formatted.safetyType = "Standard";
        }

        // Add Date to description
        // Format: Gen:2021-07-01
        const gen = new Date().toISOString().split("T")[0];
        formatted.description += `\nGen:${gen}`

        return formatted;
    }

    generateSecondaries() {
        if (this.known_use) {
            for (const tag of this.known_use.tags) {
                this.secondaries.push( this.format_tag(tag) )
            }
        }

        for (const hardware of this.known_hw) {
            for (const tag of hardware.tags) {
                this.secondaries.push( this.format_tag(tag) );
            }
        }
    }
}

