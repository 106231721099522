import { Component } from "react";

import type {AlarmLevel} from "./level";
import type {AlarmTrigger} from "./trigger";

import './summary.css'

// icons
import {
    TagFill,
    Reception4,
    ChatLeftText,
    ChatLeft,
    PlusLg,
} from 'react-bootstrap-icons';

import { AlarmLevelComponent } from "./level-render";

type RowProps = {
    name: string,
    data: SummaryData,
    level?: AlarmLevel,
}
type RowState = {}

class AlarmSummaryRow extends Component<RowProps, RowState> {
    render() {
        return (
            <tr>
                <td>
                    {this.props.level ? 
                        <AlarmLevelComponent level={this.props.level} /> 
                        :
                        <div className="Alarm-Level-empty">Σ</div>
                    }
                </td>
                <td className="SummaryName">{this.props.name}</td>
                <td className="SummaryTriggers">{this.props.data.count_triggers}</td>
                <td className="SummaryMessages">{this.props.data.count_messages}</td>
                <td className="SummarySpares">{this.props.data.count_spares}</td>
                <td className="SummaryTotals">{this.props.data.count_spares + this.props.data.count_messages}</td>
            </tr>
        )
    }
}



type LevelData = {
    level: AlarmLevel,
    data: SummaryData,
}
type SummaryData = {
    count_spares: number,
    count_messages: number,
    count_triggers: number,
}

type SummaryProps = {
    triggers: AlarmTrigger[],
}
type SummaryState = {
}

export class AlarmSummaryComponent extends Component<SummaryProps, SummaryState>  {
    levels: {[key: string]: LevelData} = {};
    total: SummaryData = {
        count_spares: 0,
        count_messages: 0,
        count_triggers: 0,
    }

    create_level_data(level: AlarmLevel) {
        const n = level.machineName;

        if (! this.levels[n]) {
            this.levels[n] = {
                level: level,
                data: {
                    count_spares: 0,
                    count_messages: 0,
                    count_triggers: 0,
                }
            }
        }
    }

    summarize() {
        this.levels = {};
        this.total = {
            count_spares: 0,
            count_messages: 0,
            count_triggers: 0,
        };

        for (let trigger of this.props.triggers) {
            this.total.count_triggers += 1;

            let t_level = trigger.template.level;
            this.create_level_data(t_level);

            for (let bit in trigger.real_messages) {
                const message = trigger.real_messages[bit];
                const level = message.level;

                this.create_level_data(level);

                this.levels[level.machineName].data.count_messages += 1;
                this.total.count_messages += 1;
            }
            const spares = Object.keys(trigger.messages).length - Object.keys(trigger.real_messages).length;
            this.levels[t_level.machineName].data.count_spares += spares;
            this.levels[t_level.machineName].data.count_triggers += 1;
            this.total.count_spares += spares;
        }
    }

    render(): JSX.Element {
        this.summarize();

        const levels = Object.values(this.levels).sort((a, b) => { return b.level.severity - a.level.severity; });

        const rows = levels.map((level: LevelData) => { return(
            <AlarmSummaryRow name={level.level.humanName} level={level.level} data={level.data} key={level.level.machineName}/>
        )})

        return (
            <div className="AlarmLevelSummary">
                <table className="AlarmLevelSummary-Table" cellPadding={0} cellSpacing={0}>
                    <thead>
                        <tr>
                            <th><Reception4 /></th>
                            <th>Level</th>
                            <th><TagFill /> Tags</th>
                            <th><ChatLeftText /> Found Messages</th>
                            <th><ChatLeft /> Spare Messages</th>
                            <th><PlusLg /> Total Messages</th>
                        </tr>
                    </thead>
                    <tbody>
                        <AlarmSummaryRow name="Total" data={this.total} />
                        <>
                            {rows}
                        </>
                    </tbody>
                </table>
            
            </div>
        );
    }   
}