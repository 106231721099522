import { Component } from 'react';
import { IO, DuplicateIO } from '../types/io';

import './duplicates.css'

import {ExclamationOctagonFill} from 'react-bootstrap-icons';

type Props = {
    duplicates: Array<DuplicateIO>
}

export default class DuplicatesList extends Component<Props> {

    generate_rows(): JSX.Element[] {
        let duplicate_list: JSX.Element[] = [];
        for (let d in this.props.duplicates) {
            const duplicate = this.props.duplicates[d];
            duplicate_list.push(
            <tr key={d + "-left"}>
                <td rowSpan={2} className="Duplicate-Name">{duplicate.original}</td>
                <td className="Duplicate-NewName">{duplicate.left.name}</td>
                <td className="Duplicate-Alias">{duplicate.left.aliasTo}</td>
                <td className="Duplicate-Description">{duplicate.left.description}</td>
            </tr>
            )
            duplicate_list.push(
            <tr key={d + "-right"}>
                <td className="Duplicate-NewName">{duplicate.right.name}</td>
                <td className="Duplicate-Alias">{duplicate.right.aliasTo}</td>
                <td className="Duplicate-Description">{duplicate.right.description}</td>
            </tr>
            )
        }
        return duplicate_list;
    }

    render() {
        // don't display if there are no duplciates
        if (this.props.duplicates.length === 0) {
            return null;
        }
        
        return (
            <div className="Duplicate-Tags">
                <h1><span><ExclamationOctagonFill /></span>{this.props.duplicates.length} Duplicate Tags</h1>
                <p>The following tags were generated with the same tag names</p>
                <p>Attempting to import this list will mean half or more of these tags will be skipped!</p>
                <table cellPadding={0} cellSpacing={0} >
                    <thead>
                    <tr>
                        <th>Generated Name</th>
                        <th>Unique Name</th>
                        <th>Alias</th>
                        <th>Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.generate_rows()}
                    </tbody>
                </table>
            </div>
        )
    }
}