import type {KnownIO} from "../types/io";

let knownIoUnwind: Array<KnownIO> = [
    {
		examples: [
			"UNWIND STATION 1 ULTRASONIC SENSOR",
			"UNWIND STATION 2 ULTRASONIC SENSOR",
		],
		description: [
			/UNWIND STATION ([0-9]) ULTRASONIC/i,
		],
		name: "{LL}_Unwind{1}_Ultrasonic",
        analog: true,
		direction: "in",
	},

    {
		examples: [
			"UNWIND 1 TENSION REFERENCE 0-10VDC",
			"UNWIND 2 TENSION REFERENCE 0-10VDC",
		],
		description: [
			/UNWIND ([0-9]) TENSION REFERENCE/i,
		],
		name: "{LL}_Unwind{1}_Tension",
        analog: true,
		direction: "out",
	},

    {
		examples: [
			"LAMINATION RESERVOIR TANK AIR PRESSURE SWITCH (LOW PRESSURE)",
		],
		description: [
			/LAMINATION RESERVOIR TANK AIR PRESSURE/i,
		],
		name: "{LL}_Lamination_AirPressure_AboveMin",
		type: "BOOL",
		direction: "in",
	},

    
    {
        
		examples: [
            // THIS IS A TERRIBLE NAME. IT'S JUST THE SAME AS THE CURRENT
			"ZONE 1 HEATING ZONE 1 PRE-LAMINATION NIP HEATERS CURRENT H1 0-20mA : 0-50A",
            "ZONE 5 HEATING ZONE 5 COLD LAMINATION NIP HEATERS CURRENT H1 0-20mA : 0-50A",
		],
		description: /ZONE [#]?([0-9]+).* HEATER[S]/i,
		name: "PR_Zone{1}_TC",
		thermocouple: true,
		direction: "in",
		pullroll: true,
	},
    {
        
		examples: [
			"ZONE 1 HEATING ZONE 1 PRE-LAMINATION SHEET HEATERS SAFETY THERMOCOUPLE 0-650C : 4-20mA",
            "ZONE 2 HEATING ZONE 2 PRE-LAMINATION SHEET HEATERS SAFETY THERMOCOUPLE 0-650C : 4-20mA",
		],
		description: /ZONE [#]?([0-9]+).* HEATING.* SAFETY.* THERMOCOUPLE/i,
		name: "PR_Zone{1}_SafetyTC",
		analog: true,
		direction: "in",
		pullroll: true,
	},

    {
		examples: [
			"ZONE 1 HEATING ZONE 1 PRE-LAMINATION NIP HEATERS CURRENT H1 0-20mA : 0-50A",
            "ZONE 5 HEATING ZONE 5 COLD LAMINATION NIP HEATERS CURRENT H1 0-20mA : 0-50A",
		],
		description: /ZONE [#]?([0-9]+).* HEATER[S]? CURRENT H([1-3])/i,
		name: "PR_Zone{1}_Current_H{2}_Raw",
		analog: true,
		direction: "in",
		pullroll: true,
	},
    {
		examples: [
			"ZONE 1 HEATING ZONE 1 PRE-LAMINATION NIP HEATERS SOLID STATE RELAY (30A)",
			"ZONE 4 HEATING ZONE 4 COLD LAMINATION NIP HEATERS SOLID STATE RELAY (30A)",
		],
		description: /ZONE ([0-9]+).* HEAT.* (SSR|SOLID STATE)/i,
		name: "PR_Zone{1}_Heat_SSR",
		type: "BOOL",
		direction: "out",
		pullroll: true,
	},
    {
        examples: [
            "LAMINATION HEATERS OVER TEMPERATURE SAFETY CONTACTOR AUX. CONTACT",
        ],
		description: /HEATERS OVER TEMPERATURE.* CONTACTOR/i,
		name: "{LL}_Heat_Aux",
		type: "BOOL",
		direction: "in",
	},
    {
        examples: [
            "LAMINATION HEATERS OVER TEMPERATURE SAFETY CONTACTOR INTERPOSING RELAY",
        ],
		description: /HEATERS OVER TEMPERATURE.* CONTACTOR/i,
		name: "{L}R_Heat_Coil",
		type: "BOOL",
		direction: "out",
	},


    {
		examples: [
			"UPPER UNWIND BUMP NIP ROLL CLOSE RELAY",
            "LOWER UNWIND BUMP NIP ROLL CLOSE RELAY",
		],
		description: [
			/(UPPER|LOWER) UNWIND.* CLOSE RELAY/i,
		],
		name: "{L}R_Splice{1:Cap}_Close_Sol",
		type: "BOOL",
		direction: "out",
	},
    {
		examples: [
			"UPPER SPLICE ROLL OPEN PB",
            "UPPER SPLICE ROLL CLOSE PBL",
            "LOWER SPLICE ROLL OPEN PB",
            "LOWER SPLICE ROLL CLOSE PBL",
		],
		description: [
			/(UPPER|LOWER) SPLICE .*(OPEN|CLOSE) PB/i,
		],
		name: "{L}R_Splice{1:Cap}_{2:Cap}_PB",
		type: "BOOL",
		direction: "in",
	},
    {
		examples: [
			"UPPER SPLICE ROLL CLOSE PBL INDICATOR",
            "LOWER SPLICE ROLL CLOSE PBL INDICATOR",
            "SPLICE ROLL CLOSE PBL INDICATOR (WHITE) ROLL STAND SPLICE ROLL OPERATOR CONTROL STATION"
		],
		description: [
			/(UPPER|LOWER)?[ ]?SPLICE.*(OPEN|CLOSE) PBL/i,
		],
		name: "{LL}_Splice{1:Cap}_{2:Cap}_PBL",
		type: "BOOL",
		direction: "out",
	},

    {
		examples: [
			"PULL ROLL MOTOR THERMOSTAT",
		],
		description: [
			/(PULL) ROLL MOTOR (TEMP|THERMOSTAT)/i,
		],
		name: "{LL}_{1:Cap}_MtrThermo_Sw",
		type: "BOOL",
		direction: "in",
	},


    
    {
        examples: [
            "UNWINDER NON-OPERATOR SIDE E-STOP PBL",
        ],
        description: [
            /UNWINDER NON[-| ]OPERATOR SIDE E-STOP/i,
        ],
        name: "{LL}_Unwind_NonOperator_Estop_PB",
        type: "BOOL",
        direction: "in",
    },
    {
        examples: [
            "UNWINDER NON OPERATOR SIDE E-STOP (PBL)",
        ],
        description: [
            /UNWINDER NON[-| ]OPERATOR SIDE E-STOP/i,
        ],
        name: "{LL}_Unwind_NonOperator_Estop_PBL",
        type: "BOOL",
        direction: "out",
    },

    {
        examples: [
            "UNWIND SECTION SIDE NIP GUARD DOOR 1 SAFETY SWITCH",
            "UNWIND SECTION SIDE NIP GUARD DOOR 2 SAFETY SWITCH",
        ],
        description: [
            /UNWIND.*GUARD DOOR ([0-9])/i,
        ],
        name: "{LL}_Unwind_Door{1}_GuardOK_Ch{EVEN+1}",
        type: "BOOL",
        direction: "in",
        safetyType: "Safety",
    },
    

    {
        examples: [
            "LAMINATION ROLL OPEN PB",
        ],
        description: [
            /LAMINATION ROLL OPEN PB/i,
        ],
        name: "{LL}_Lamination_Roll_OpenPB",
        type: "BOOL",
        direction: "in",
    },
    {
        examples: [
            "LAMINATION ROLL CLOSE PBL",
        ],
        description: [
            /LAMINATION ROLL CLOSE PB/i,
        ],
        name: "{LL}_Lamination_Roll_ClosePB",
        type: "BOOL",
        direction: "in",
    },
    {
        examples: [
            "LAMINATION ROLL CLOSE PBL",
        ],
        description: [
            /LAMINATION ROLL CLOSE PB/i,
        ],
        name: "{LL}_Lamination_Roll_ClosePBL",
        type: "BOOL",
        direction: "out",
    },


    
    {
        examples: [
            "LAMINATION APPLICATION ROLL CLOSE RELAY",
            "LAMINATION NIP ROLL CLOSE RELAY"
        ],
        description: [
            /LAMINATION APPLICATION ROLL CLOSE RELAY/i,
            /LAMINATION NIP ROLL CLOSE RELAY/i,
        ],
        name: "{LL}_Lamination_Roll_CloseSol",
        type: "BOOL",
        direction: "out",
    },

    {
        examples: [
            "PULL ROLL 1 (LAMINATION NIP) OPERATOR CONTROL STATION E-STOP PBL",
        ],
        description: [
            /PULL ROLL ([0-9]).*LAMINATION.* OPERATOR CONTROL STATION E-STOP/i,
        ],
        name: "{LL}_Lamination{1}_Estop_PBL",
        type: "BOOL",
        direction: "in",
    },
    {
        examples: [
            "PULL ROLL 1 (LAMINATION NIP) INFEED/OUTFEED PULL CORD COS",
        ],
        description: [
            /PULL ROLL ([0-9]).*LAMINATION.* PULL CORD/i,
        ],
        name: "{LL}_Lamination{1}_Estop_PullCord",
        type: "BOOL",
        direction: "in",
    },



    {
        examples: [
            "LAMINATION COOLING ROLL OPEN PB",
        ],
        description: [
            /LAMINATION COOLING ROLL OPEN PB/i,
        ],
        name: "{LL}_Lamination_Cool_OpenPB",
        type: "BOOL",
        direction: "in",
    },
    {
        examples: [
            "LAMINATION COOLING ROLL CLOSE PBL",
        ],
        description: [
            /LAMINATION COOLING ROLL CLOSE PBL/i,
        ],
        name: "{LL}_Lamination_Cool_ClosePB",
        type: "BOOL",
        direction: "in",
    },
    {
        examples: [
            "LAMINATION COOLING ROLL CLOSE PBL INDICATOR (WHITE)",
        ],
        description: [
            /LAMINATION COOL.* ROLL CLOSE PB/i,
        ],
        name: "{LL}_Lamination_Cool_ClosePBL",
        type: "BOOL",
        direction: "out",
    },
    {
        examples: [
            "LAMINATION COOLING ROLL CLOSE RELAY",
        ],
        description: [
            /LAMINATION COOLING ROLL CLOSE RELAY/i,
        ],
        name: "{LL}_Lamination_Cool_CloseSol",
        type: "BOOL",
        direction: "out",
    },


    {
        examples: [
            "LAMINATION ROLL OPERATOR CONTROL STATION E-STOP (PBL)",
        ],
        description: [
            /LAMINATION ROLL OPERATOR.* E-STOP/i,
        ],
        name: "{LL}_Lamination_Operator_Estop_PBL",
        type: "BOOL",
        direction: "out",
    },
    {
        examples: [
            "LAMINATION ROLL OPERATOR CONTROL STATION E-STOP PBL",
        ],
        description: [
            /LAMINATION ROLL OPERATOR.* E-STOP/i,
        ],
        name: "{LL}_Lamination_Operator_Estop_PB",
        type: "BOOL",
        direction: "in",
    },
    {
        examples: [
            "ROLL STAND DOWNSTREAM LAMINATION GUARD SAFETY SWITCHES CH1"
        ],
        description: [
            /DOWNSTREAM LAMINATION.* ROLL GUARD DOOR/i,
            /DOWNSTREAM LAMINATION GUARD SAFETY/i,
        ],
        name: "{LL}_Lamination_Roll_DownstreamGuard_Ch{EVEN+1}",
        type: "BOOL",
        direction: "in",
        safetyType: "Safety",
    },
    {
        examples: [
            "ROLL STAND LAMINATION ROLL GUARD DOOR SAFETY SWITCHES",
        ],
        description: [
            /LAMINATION.* ROLL GUARD DOOR/i,
            /LAMINATION GUARD SAFETY/i,
        ],
        name: "{LL}_Lamination_Roll_GuardOK_Ch{EVEN+1}",
        type: "BOOL",
        direction: "in",
        safetyType: "Safety",
    },
    {
        examples: [
            "ROLL STAND DOWN STREAM LAMINATION ROLL GUARDING SAFETY SWITCH",
        ],
        description: [
            /DOWN.*STREAM LAMINATION ROLL GUARDING/i,
        ],
        name: "{LL}_Lamination_Roll_Outfeed_Ch{EVEN+1}",
        type: "BOOL",
        direction: "in",
        safetyType: "Safety",
    },

    {
        examples: [
            "LAMINATION COOLING ROLL SAFETY RELAY",
        ],
        description: [
            /LAMINATION COOLING ROLL SAFETY RELAY/i,
        ],
        name: "{LL}_Lamination_Cool_SafetyCoil",
        type: "BOOL",
        direction: "out",
        safetyType: "Safety",
    },
    {
        examples: [
            "LAMINATION COOLING ROLL SAFETY RELAY FEEDBACK",
        ],
        description: [
            /LAMINATION COOLING ROLL SAFETY RELAY/i,
        ],
        name: "{LL}_Lamination_Cool_SafetyFeedback",
        type: "BOOL",
        direction: "in",
        safetyType: "Safety",
    },
    {
        examples: [
            "LAMINATION ROLL SAFETY RELAY",
            "LAMINATION APPLICATION ROLL SAFETY RELAY"
        ],
        description: [
            /LAMINATION.* ROLL SAFETY RELAY/i,
        ],
        name: "{LL}_Lamination_Roll_SafetyCoil",
        type: "BOOL",
        direction: "out",
        safetyType: "Safety",
    },
    {
        examples: [
            "LAMINATION ROLL SAFETY RELAY FEEDBACK",
            "LAMINATION APPLICATION ROLL SAFETY RELAY FEEDBACK ESR-"
        ],
        description: [
            /LAMINATION.* ROLL SAFETY RELAY/i,
        ],
        name: "{LL}_Lamination_Roll_SafetyFeedback",
        type: "BOOL",
        direction: "in",
        safetyType: "Safety",
    },

    {
        examples: [
            "LAMINATION ROLL TCU REMOTE STOP RELAY",
        ],
        description: [
            /LAMINATION ROLL TCU .*STOP/i,
        ],
        name: "{LL}_Lamination_Roll_TCU_Stop",
        type: "BOOL",
        direction: "out",
    },
    {
        examples: [
            "LAMINATION ROLL TCU REMOTE START RELAY",
        ],
        description: [
            /LAMINATION ROLL TCU .*START/i,
        ],
        name: "{LL}_Lamination_Roll_TCU_Start",
        type: "BOOL",
        direction: "out",
    },



    {
        examples: [
            "SPLICE ROLL OPEN PB",
        ],
        description: [
            /([A-Z]+) SPLICE ROLL OPEN PB/i,
        ],
        name: "{LL}_Splice_{1}Roll_OpenPB",
        type: "BOOL",
        direction: "in",
    },
    {
        examples: [
            "SPLICE ROLL CLOSE PBL",
        ],
        description: [
            /([A-Z]+) SPLICE ROLL CLOSE PB/i,
        ],
        name: "{LL}_Splice_{1}Roll_ClosePB",
        type: "BOOL",
        direction: "in",
    },

    {
        examples: [
            "SPLICE ROLL OPEN PB",
        ],
        description: [
            /SPLICE ROLL OPEN PB/i,
        ],
        name: "{LL}_Splice_Roll_OpenPB",
        type: "BOOL",
        direction: "in",
    },
    {
        examples: [
            "SPLICE ROLL CLOSE PBL",
        ],
        description: [
            /SPLICE ROLL CLOSE PB/i,
        ],
        name: "{LL}_Splice_Roll_ClosePB",
        type: "BOOL",
        direction: "in",
    },

    {
        examples: [
            "SPLICE ROLL CLOSE RELAY",
        ],
        description: [
            /SPLICE ROLL CLOSE RELAY/i,
        ],
        name: "{LL}_Splice_Roll_CloseSol",
        type: "BOOL",
        direction: "out",
    },

    {
        examples: [
            "SPLICE ROLL OPERATOR CONTROL STATION E-STOP (PBL)",
        ],
        description: [
            /SPLICE ROLL OPERATOR.* E-STOP/i,
        ],
        name: "{LL}_Splice_Operator_Estop_PBL",
        type: "BOOL",
        direction: "out",
    },
    {
        examples: [
            "UPPER SPLICE ROLL OPERATOR CONTROL STATION E-STOP PBL",
            "LOWER SPLICE ROLL OPERATOR CONTROL STATION E-STOP PBL",
            "SPLICE ROLL OPERATOR CONTROL STATION E-STOP PBL",
        ],
        description: [
            /(UPPER|LOWER|).?SPLICE ROLL OPERATOR.* E-STOP/i,
        ],
        name: "{LL}_Splice_{1:Cap}Operator_Estop_PB",
        type: "BOOL",
        direction: "in",
    },
    {
        examples: [
            "UPPER SPLICE ROLL PULL CORD COS",
            "LOWER SPLICE ROLL PULL CORD COS",
        ],
        description: [
            /(UPPER|LOWER) SPLICE ROLL PULL CORD COS/i,
        ],
        name: "{LL}_Splice{1:Cap}_Estop_PullCord",
        type: "BOOL",
        direction: "in",
    },

    {
        examples: [
            "SPLICE ROLL SAFETY RELAY",
            "UPPER UNWIND SPLICE ROLL SAFETY RELAY",
            "LOWER UNWIND SPLICE ROLL SAFETY RELAY"
        ],
        description: [
            /((UPPER|LOWER)?)[ ]?.*SPLICE ROLL SAFETY RELAY/i,
        ],
        name: "{LL}_Splice_{1:Cap}Roll_SafetyCoil",
        type: "BOOL",
        direction: "out",
        safetyType: "Safety",
    },
    {
        examples: [
            "SPLICE ROLL SAFETY RELAY FEEDBACK",
            "UPPER UNWIND SPLICE ROLL SAFETY RELAY FEEDBACK",
            "LOWER UNWIND SPLICE ROLL SAFETY RELAY FEEDBACK"
        ],
        description: [
            /((UPPER|LOWER)?)[ ]?.*SPLICE ROLL SAFETY RELAY/i,
        ],
        name: "{LL}_Splice_{1:Cap}Roll_SafetyFeedback",
        type: "BOOL",
        direction: "in",
        safetyType: "Safety",
    },

    {
        examples: [
            "PI COATER TOP DOCTOR ROLL CLOSE SOLENOID VALVE",
            "PI COATER BOTTOM DOCTOR ROLL CLOSE SOLENOID VALVE"
        ],
        description: [
            /COATER (TOP|BOTTOM) DOCTOR ROLL CLOSE SOL/i,
        ],
        name: "{LL}_Doctor{1:Cap}_RollCloseSol",
        type: "BOOL",
        direction: "out",
        safetyType: "Standard",
    },



];

export  {knownIoUnwind as default}
