import type {KnownIO} from "../types/io";

let knownIoShear: Array<KnownIO> = [

	{
        examples: [
            "SHEAR MAIN CONTROL ENCLOSURE E-STOP PBL",
        ],
		description: /SHEAR MAIN (CONTROL)? ENCLOSURE E-STOP/i,
		name: "Shear_Enclosure_Estop_PB",
		type: "BOOL",
		direction: "in",
		rollStand: true,
        safetyType: "Standard",
	},
	{
        examples: [
            "SHEAR INFEED PULL CORD COS",
        ],
		description: /SHEAR INFEED PULL CORD/i,
		name: "Shear_Infeed_Estop_PullCord",
		type: "BOOL",
		direction: "in",
		rollStand: true,
        safetyType: "Standard",
	},
	{
        examples: [
            "SHEAR OUTFEED PULL CORD COS",
        ],
		description: /SHEAR OUTFEED PULL CORD/i,
		name: "Shear_Outfeed_Estop_PullCord",
		type: "BOOL",
		direction: "in",
		rollStand: true,
        safetyType: "Standard",
	},


    {
        examples: [
            "SHEAR OUTFEED HINGED GUARD SAFETY SWITCH",
        ],
		description: /SHEAR OUTFEED HINGED GUARD/i,
		name: "Shear_OutfeedHinge_Ch{EVEN+1}",
		type: "BOOL",
		direction: "in",
		rollStand: true,
        safetyType: "Safety",
	},


    {
        examples: [
            "SHEAR CUT ENABLE RELAY",
        ],
		description: /SHEAR CUT ENABLE/i,
		name: "Shear_Cut_Enable_Coil",
		type: "BOOL",
		direction: "out",
		rollStand: true,
        safetyType: "Standard",
	},

	
    {
        examples: [
            "SHEAR RUNNING RELAY LOCATED IN SHEAR CONTROL ENCLOSURE ",
        ],
		description: /SHEAR RUNNING/i,
		name: "Shear_Running",
		type: "BOOL",
		direction: "in",
        safetyType: "Standard",
	},
	{
        examples: [
            "SHEAR REMOTE STOP RELAY",
        ],
		description: /SHEAR REMOTE STOP/i,
		name: "Shear_Stop",
		type: "BOOL",
		direction: "out",
        safetyType: "Standard",
	},
	
	{
        examples: [
            "SHEAR REMOTE START RELAY",
        ],
		description: /SHEAR REMOTE START/i,
		name: "Shear_Start",
		type: "BOOL",
		direction: "out",
        safetyType: "Standard",
	},
    
    {
        examples: [
            "SHEAR E-STOP SAFETY RELAY FEEDBACK",
        ],
		description: /SHEAR E-STOP SAFETY RELAY/i,
		name: "Shear_Estop_Relay_Feedback",
		type: "BOOL",
		direction: "in",
		rollStand: true,
        safetyType: "Safety",
	},
    {
        examples: [
            "SHEAR E-STOP SAFETY RELAY",
        ],
		description: /SHEAR E-STOP SAFETY RELAY/i,
		name: "Shear_Estop_Relay_Coil",
		type: "BOOL",
		direction: "out",
		rollStand: true,
        safetyType: "Safety",
	},


];

export {knownIoShear as default}