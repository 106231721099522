import { Component } from 'react';

import { Lightbulb, LightbulbOff } from 'react-bootstrap-icons';

import './lighttoggle.css'

export default class LightToggle extends Component {
	constructor(props) {
		super(props);
		this.state = {
			light: true,
		}
	}

	// get user preference
	componentDidMount() {
		if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
				// console.log("PREFERS DARK MODE")
				this.setState({light: false});
		}

		this.updateBody();
	}

	// apply class to document body to change between light and dark themes
	updateBody() {
		if (this.state.light) {
			document.body.classList.add('light');
			document.body.classList.remove('dark');
		}
		else {
			document.body.classList.remove('light');
			document.body.classList.add('dark');
		}
	}

	// toggle on click
	onClick(e) {
		this.setState({
			light: ! this.state.light
		})
		this.updateBody();
	}

	// react render function
	render() {

		//dynamically change icon based on current state
		let icon = (<Lightbulb />);
		if (! this.state.light ) {
			icon = (<LightbulbOff />)
		}

		// update the document body whenever the state changes
		this.updateBody();

		// return HMTL
		return (
			<span className={"LightToggle LightToggle-"+ (this.state.light ? "Light" : "Dark")}
				onClick={(e) => this.onClick(e)}
				role="button"
				title="Toggle light/dark mode" >
			 	{icon}
			</span>
		)
	}
}
