import { Component } from 'react';

import type { IO } from '../types/io';

interface ModuleIoRowProps {
  io: IO;
}

export default class ModuleIoRow extends Component<ModuleIoRowProps> {
  render() {
    const io = this.props.io;

    // console.log(io.name, io, this);

    const description = io.description.split('\n').map((item, key) => {
      return <span key={key}>{item}<br/></span>
    })

    let name = io.name || "UNKNOWN"

    let labelTooltip = "";
    for (let e in io.labelEntities) {
      const entity = io.labelEntities[e];

      labelTooltip += "x:" + entity.position.x.toFixed(1);
      labelTooltip += ",y:" + entity.position.y.toFixed(1);
      labelTooltip += " " + entity.text;
      labelTooltip += " section:" + entity.dxfSection;
      if (entity.attr) {
        labelTooltip += " attr:" + entity.attr;
      }
      labelTooltip += "\n\n";
    }



    let position: JSX.Element | null = null;
    if (io?.pointEntity?.position && io.labelBounds) {
      const bounds = io.labelBounds;
      const x = io.pointEntity.position.x;
      const y = io.pointEntity.position.y;

      let section = (io.pointEntity.dxfSection || "");
      if (io.pointEntity.attr) {
        section += " attr:" + io.pointEntity.attr;
      }
      const pointText = ">" + bounds.xmin.toFixed(6) + ", " + bounds.ymin.toFixed(6) + "\n" +
                        "@" + x.toFixed(6) + ", " + y.toFixed(6) + "\n" +
                        "<" + bounds.xmax.toFixed(6) + ", " + bounds.ymax.toFixed(6) + "\n" +
                        section;

      position = (
        <div className="pointPosition" title={pointText} >
          x:<span className="point-x">{x.toFixed(1)}</span>,
          y:<span className="point-y">{y.toFixed(1)}</span>
        </div>
      )
    }
    
    return (
      <tr className={name.includes("Spare") ? "pointSpare" : ""}>
        <td className={"left " + (name.includes("UNKNOWN") ? "error" : "")}>{name}</td>
        <td className="left">{io.aliasTo}</td>
        <td>{io.type}</td>
        <td>{io.safetyType}</td>
        <td title={labelTooltip}>{description}</td>
        <td>
          {io?.pointEntity?.text}
          {position}
        </td>
      </tr>
    )
  }
}
