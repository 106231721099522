import simpleFilters from '../filters/descriptionremoves';
import type {IO, KnownIO, TagDirection} from '../types/io';
import { KnownModule, KnownModulePoint } from '../types/module';
import { DxfPageParseModule } from './parseModule';
import knownIO from '../filters/knownio';
import { BoundingBox, DxfPageParse, DxfParseEntityText } from './parseBase';

export default class DxfPageParsePoint {
    
    parse_module: DxfPageParseModule;
    found_module: KnownModule;
    point_entity: DxfParseEntityText;
    label_entities: DxfParseEntityText[];
    looksLabelled: boolean = false;
    label_strings: string[] = [];
    point_number: number = 100;
    isRightSpecial: boolean;
    parsedIO: IO[] = [];
    bounds: BoundingBox;
    possible_pair: DxfPageParsePoint | undefined;

    description: string = "";
    
    constructor(module: DxfPageParseModule, 
            found_module: KnownModule, 
            point_entity: DxfParseEntityText, 
            label_entities: DxfParseEntityText[], 
            isRightSpecial: boolean,
            bounds: BoundingBox,
            possible_pair: DxfPageParsePoint | undefined = undefined) {
        this.parse_module = module;
        this.point_entity = point_entity;
        this.label_entities = label_entities;
        this.found_module = found_module;
        this.isRightSpecial = isRightSpecial;
        this.bounds = bounds;
        this.possible_pair = possible_pair;


        this.filterLabels();
        this.createDescription();
        this.createAlias();
    }

    private filterLabels() {
        // LABELS MUST BE ON A MAIN LAYER
        // Hide descriptions from non-visible layers, which seems to be layer 0
        this.label_entities = this.label_entities.filter((item) => {
            if (item.parent && item.parent.layer === "0") {
                // console.log("Ignoring layer 0 for label", item.text, item);
                return false;
            }
            if ( ! (this?.found_module?.pointReg instanceof RegExp) ) { return false }
            // Remove unnecessary text from descriptions and labels
            // Remove any text that matches exactly from a list of things to ignore
            // From /filters/descriptionremoves
            if (item.text in simpleFilters) {return false}
            return ! this.found_module.pointReg.test(item.text)
        });
        
        // IS POINT LABELLED?
        // Search all of the texts found and see if there are any words in them
        this.looksLabelled = false;
        for (let l in this.label_entities) {
            const label = this.label_entities[l];

            // This is way too specific. Need something more robust
            // if (label.text === "DUAL CHANNEL" && this.parse_module.module?.safetyType === "Safety") {
            //     continue;
            // }
            
            // console.log("label check", label.text);
            if (label.text.match(/[A-Z]{4,}/i)) {
                this.looksLabelled = true;
                // console.log("LOOKS LABLLED", e, label.text);
                break;
            }
        }
        
        // sort the labels horizontally
        // Left most will be the line numbers and actual descriptions on inputs
        // labels = this.sortInX(labels);
        
        // outputs are sorted the opposite since they go in the other direction
        if (this.found_module.direction === "out" || (this.found_module.direction === "in|out" && this.isRightSpecial)) {
            this.label_entities = DxfPageParse.sortSimilarInY(this.label_entities, 0.65, true);
        }
        else {
            this.label_entities = DxfPageParse.sortSimilarInY(this.label_entities, 0.65, false);
        }
    }

    private createDescription() {
        // Filter each of the texts and sort them into labels and line numbers
        let labelTxts: string[] = [];
        let labelEntities: DxfParseEntityText[] = [];
        let lineNums: string[] = [];
        let wireNums: string[] = [];
        let cableID = "";
        let cableWires: string[] = [];
        let terminals: string[] = [];

        for (let l in this.label_entities) {
            const entity = this.label_entities[l]
            let txt = entity.text;

            
            
            // Ignore text if it's the IO point (Ex: "IN 0")
            if (txt.match(/^[A-Z][0-9]{3}-[0-9]{2}$/)) {
                // console.log(txt);
                cableID = txt;
                continue;
            }
            else if ( this?.found_module?.pointReg instanceof RegExp && 
                this.found_module.pointReg.test(txt) ) {
                    continue
                }
                // Blocks tend to add innaccurate information since they are
                // unused templates
                else if (entity.dxfSection?.includes("block")) {
                    continue;
                }
                // try to filter out stuff that's invisible or does not actually have a position
                else if (entity.parent && entity.parent.position && entity.parent.position.x === -100 &&
                    entity.parent.parent && entity.parent.parent.layer === "0") {
                    continue;
                }
                else if (txt.match(/.*-$/)) {
                    continue
                }
                else if (txt.match(/^[a-z]{1}$/i)) {
                    continue
                }
                else if (txt.match(/.*\(-\)$/)) {
                    continue
                }
                else if (txt.match(/^OUTPUT [0-9]B$/)) {
                    continue
                }
                else if (txt.match(/^[A-Z][A-B]?[0-9]?\+24$/i)) {
                    continue
                }
                else if (txt.match(/^[A-Z][A-B]?[0-9]?24(VDC|COM)?$/i)) {
                    continue
                }
                else if (txt.match(/^[A-Z][A-B]?[0-9]?-COM$/i)) {
                    continue
                }
                else if (txt.match(/^CH.[0-9]+ [I|V]?( COM| SHLD)?/i)) {
                    continue
                }
                else if (txt.match(/CH.[0-9] \(\+\)/i)) {
                    continue
                }
                else if (txt.match(/[A-Z]\. 40[0-9]{3,7}/i)) {continue} // Fuse, Fuse holder PTi part num
                else if (txt.match(/^(SAFETY )?(OUT|OUTPUT|IN|INPUT)[ ]?[0-9]+/i)) {
                    continue
                }
                else if (txt.match(/^TB[0-9]+\.?[0-9]*$/i)) {
                    terminals.push( txt );
                    continue;
                }
                // 15A 22A
                else if (txt.match(/^[0-9]+[A-Z]$/i) 
                      || txt.match(/^[0-9]+ [A-Z]{2,3}[0-9]{1,3}$/i)

                      // WHT+2 RED-1 BLK20
                      || txt.match(/^[A-Z]{2,3}[ |-|+]?[0-9]{1,2}$/i)) {
                    cableWires.push( txt );
                    continue;
                }
                // Seperate line numbers
                else if (txt.match(/^[A-Z]?[0-9]{4,5}$/)) {
                    lineNums.push( txt );
                    continue;
                }
                else if ((entity.attr === "LINE" || entity.attr === "DESC02") && txt.includes("SPARE")) {
                    continue;
                }
                else if (txt.match(/^(L-[A-Z][0-9]{4,5}$)/i)) {
                    // Pull out wire numbers if they're at the top L-A16235LINE
                    // console.log(entity.attr);
                    wireNums.push( txt )
                }
                // Add the description to the list of descriptions/labels
                else if ( txt.match(/[a-z]/i) || txt.match(/[a-z| |\-|0-9]{3,}/i)) {
                    const alreadyFound = labelEntities.filter(i => i.text === txt).length > 0;
                    if ( ! alreadyFound ) {
                        // Merge the first line of descriptions if they are in the same X position
                        // console.log("LABEL", txt, entity);
                        // DxfPageParse.isNear(labelEntities[0], entity, 1, 4.0)
                        // txt has more than two letters in a 
                        // console.log("LABEL", txt)
                        if (labelTxts.length === 1 && txt.match(/[a-z| |\-|0-9]{3,}/i) && entity.attr !== "ID" && entity.attr !== "DESC03") {
                            labelTxts[0] += " " + txt;
                        }
                        else {
                            if (txt === "CR_NC") { txt = "Normally Closed Relay"}
                            if (txt === "CR_NO") { txt = "Normally Open Relay"}
                            labelTxts.push( txt );
                        }
                        labelEntities.push( entity );
                    }
                }
            }
            
            if (terminals.length) {
                // remove all duplicates
                terminals = [...new Set(terminals)];
                labelTxts.push("TermBlk: " + terminals.join(", "));
            }

            if (wireNums.length) {
                // remove all duplicates
                wireNums = [...new Set(wireNums)];
                // combine with ", " seperated
                const wires = "Wires: " + wireNums.join(", ");
                labelTxts.push(wires);
            }

            if (cableID.length) {
                // console.log("Cable ID", cableID);
                labelTxts.push("CBL: "+cableID);
            }
            if (cableWires.length) {
                // remove all duplicates
                cableWires = [...new Set(cableWires)];
                labelTxts.push("CBL Wires: " + cableWires.join(", "));
            }
            
            // SINGLE LINE NUMBER ONLY
            // Take the list of line numbers and narrow it down to just one line number
            // Take the median sorted vertically
            // Add it to the bottom of the descriptions / labels
            if (lineNums.length) {
                lineNums.sort();
                const middleLineNum = Math.floor(lineNums.length / 2);
                const lineNum = lineNums[ middleLineNum ];
                
                const pagename = this.parse_module.page.filename.replace(/-[a-z]?[0-9]+[a-z]*.(dxf|dwg)/i, "");
                labelTxts.push(`Schem:${pagename}.${lineNum}`);
            }
            
            // Record in the description when this tag was generated
            // YYYY-MM-DD
            const datestr = new Date().toISOString().split("T")[0];
            labelTxts.push(`Gen:${datestr}`);

            this.label_strings = labelTxts;;
            
            // Merge array of descriptions into one list
            this.description = labelTxts.join("\n");
            // labelTxt = labelTxt.replace("M\nCON", "M CON");
            
            // Determine the point number
            let pointMatch = this.point_entity.text.match(/[^0-9]*([0-9]*)[^0-9]*/i);
            // console.log(point, entity);
            if (!pointMatch) {
                console.log("Could not identify point number");
            }
            else {
                this.point_number = parseInt(pointMatch[1]);
            }

            // establish new offset for point number
            if ( this.parse_module.parsedIO.length === 0 ) {
                const pointsStartAt = this.found_module.pointsStartAt;
                if ( typeof pointsStartAt === "number" ) {
                    this.parse_module.pointOffset = pointsStartAt - this.point_number;
                    
                    console.log("Point offset", this.parse_module.pointOffset, this.point_number, this.point_entity.text);
                }
            }
            this.point_number += this.parse_module.pointOffset;

            // THIS MIGHT HAVE BEEN NECESSARY
            // CAN'T SEE PREVIOUS POINT NUMBER FROM INSIDE THIS CLASS
            // if ( typeof lastPointNum === "undefined" ) { lastPointNum = point }
            // // console.log( lastPointNum, point, lastPointNum % 2)
            // if ( (lastPointNum % 2) === 0  ) {
            //     // console.log("Even end, should be even start", point);
                
            //     if (((this?.module?.points||0) / 2) % 2 === 0) {
            //         // console.log("OK to change");
            //         point -= 1;
            //     }
            // }
    }
    
    /**
    * Check if IO matches known IO
    * 
    */
    private checkIoMatchesKnown(known: KnownIO, io: IO): boolean {
        for (let known_attr in known) {
            const lookup = this.known_attr_lookups[known_attr];
            if (lookup) {
                if (! lookup(known, io, this.found_module)) {
                    return false;
                }
            }
        }
        return true;
    }
    
    private createAlias() {
        // Format the alias with replacement pattern
        // This module definition is from knownmodules.js
        for (let a in this.found_module.aliasTo) {
            let aliasTo = this.found_module.aliasTo[a];
            if ( this.isRightSpecial ) { 
                if ( ! Array.isArray(this.found_module.aliasToRight)) {
                    console.log("Right is special, but no special alias pattern was defined");
                }
                else {
                    if ( typeof this.found_module.aliasToRight[a] === "string" ) {
                        aliasTo = this.found_module.aliasToRight[a];
                    }
                    else if ( typeof this.found_module.aliasToRight[a] === "object" &&
                    typeof this.found_module.aliasToRight[a].aliasTo == "string" ) {
                        aliasTo = this.found_module.aliasToRight[a];
                    }
                    else {
                        console.log("Right is special, but invalid special alias pattern was defined");
                    }
                }
                
            }
            
            let alias_string: string = "";
            let overrides: KnownModulePoint | undefined = undefined;
            if (typeof aliasTo === "object") {
                overrides = aliasTo;
                alias_string = aliasTo.aliasTo;
            }
            else {
                alias_string = aliasTo;
            }
            


            // L: Rack Location Letter
            // N: Rack Number In Location
            // S: Slot Number
            // P: Point Number
            alias_string = alias_string.replace("{L}", this.parse_module.rackLetter + "");
            alias_string = alias_string.replace("{N}", this.parse_module.rackNumber + "");
            alias_string = alias_string.replace("{S}", this.parse_module.slotNumber + "");
            alias_string = alias_string.replace("{S:2}", (this.parse_module.slotNumber+"").padStart(2,"0"));
            alias_string = alias_string.replace("{E}", this.parse_module.extruderPrefix);
            alias_string = alias_string.replace("{P}", this.point_number + "");
            
            let direction = this.found_module.direction;
            if (direction === "in|out" || this.isRightSpecial ) {
                if ( this.isRightSpecial ) {
                    direction = "out";
                }
                else { direction = "in" }
            }
            // check that direction is defined
            if (typeof direction === "undefined") {
                console.log("Direction is undefined");
                return;
            }

            this.parsedIO.push( this.createParsedIO(alias_string, overrides, direction) );
        }
    }


    private createParsedIO(aliasTo: string, overrides: Partial<KnownIO> | undefined, direction: TagDirection): IO {

        let io: any = {
            name: "",
            aliasTo: aliasTo,
            pointNum: this.point_number,
            multi: this.parsedIO.length,
            direction: direction,
            description: this.description,
            labels: this.label_strings,
            labelEntities: this.label_entities,
            labelBounds: this.bounds,
            pointEntity: this.point_entity,
            safetyType: this.found_module.safetyType,
            rackLetter: this.parse_module.rackLetter,
            rackLabelEntity: this.parse_module.rackLabelEntity,
        }
        
        if (Array.isArray(this.found_module.type)) {
            io.type = this.found_module.type[this.parsedIO.length];
        }
        else {
            console.log("Module does not have type array defined")
        }
        
        if (this.parse_module.rackLocation.includes("EXT") || this.parse_module.ds){
            io.extruder = true;
        }
        
        if (overrides?.name && this.parsedIO.length > 0) {
            io.name = overrides.name.replace("{0:name}", this.parsedIO[0]?.name);
        }
        else {
            io.name = this.getIOname(io);
        }
        if (overrides?.direction) {
            io.direction = overrides.direction;
        }
        
        return io;
    }
    
    // todo define attr as keyof KnownIO type
    private known_attr_lookups: {[attr: string]: (known: KnownIO , io: IO,module: KnownModule) => boolean} = {
        "type": (known, io, module) => io.type === known.type,
        "thermocouple": (known, io, module) => known.thermocouple ? module.thermocouple === true: ! module.thermocouple,
        "analog": (known, io, module) => known.analog ? module.analog === true: ! module.analog,
        "direction": (known, io, module) => io.direction === known.direction,
        "safetyType": (known, io, module) => io.safetyType === known.safetyType,
        "multi": (known, io, module) => io.multi === known.multi,
        "aliasTo": (known, io, module) => io.aliasTo.includes( known.aliasTo || ""),
        "isEven": (known, io, module) => (io.pointNum % 2) === 0,
        "isOdd": (known, io, module) => (io.pointNum % 2) === 1,
        "downstream": (known, io, module) => known.downstream ? this.parse_module.ds !== "" : this.parse_module.ds === "",
        "extruder": (known, io, module) => io.extruder === known.extruder,
        "vacuum": (known, io, module) => known.vacuum ? this.parse_module.rackLetter.includes("V") : false,
        "rollStand": (known, io, module) => known.rollStand ? this.parse_module.rackLetter === "R" || this.parse_module.rackLetter === "P" || this.parse_module.rackLocation === "RS/TS": false,
        "pullroll": (known, io, module) => known.pullroll ? this.parse_module.rackLetter === "P" : this.parse_module.rackLetter !== "P",
        "titan": (known, io, module) => known.titan ? this.parse_module.rackLetter === "K" : this.parse_module.rackLetter !== "K",
        "winder": (known, io, module) => known.winder ? this.parse_module.rackLetter === "W" : this.parse_module.rackLetter !== "W",
        "auxRoll": (known, io, module) => known.auxRoll === true ? 
            (this.parse_module.rackLetter === "U" || this.parse_module.rackLocation === "AR") 
            : (this.parse_module.rackLetter !== "U" && this.parse_module.rackLocation !== "AR"),
        "tempStation": (known, io, module) => known.tempStation === true ? 
            (this.parse_module.rackLetter === "T" || this.parse_module.rackLocation === "TS")
            : (this.parse_module.rackLetter !== "T" && this.parse_module.rackLocation !== "TS"),
    }
    
    
    
    /**
    * Get IO Name
    * @param io IO
    * @returns IO Name String
    * 
    * @description Given an IO point, figure out what the AB Tag name should be
    */
    getIOname(io: IO): string {
        // use the knownio filters
        if (typeof this.parse_module !== "object") {
            console.log("Must know module type before getting IO names");
            return "ERROR_MODULE_TYPE_UNKNOWN";
        }

        
        
        for (let k in knownIO) {
            const known = knownIO[k];

            // console.log("Checking known", known, io)
            if (! this.checkIoMatchesKnown(known, io)) {
                continue;
            }
            const flattened = this.description.replaceAll(/\n/g, " ");
            // if (io.description.includes("LEFT HAND")) {
            //     console.log("Flat", flattened);
            //     console.log("IO: ", io);
            // }
            
            let m: ReturnType<String["match"]> = null;
            if (typeof known.description === "string" || known.description instanceof RegExp ) {
                m = flattened.match( known.description );
            }
            else if ( Array.isArray(known.description) ) {
                for (let d in known.description) {
                    m = flattened.match( known.description[d] );
                    if (m) {break}
                }
            }
            
            // knownio does not match this point's description
            if (!m) {
                continue;
            }
            
            return this.parse_module.formatNameTemplate(known.name, m, io);
        }
        
        console.log("No matches found for ", io)
        return "ERROR NO MATCHES"
    }
    
}