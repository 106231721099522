
import * as helpers from '../ParseHelpers'

export default function EntityParser() {}

EntityParser.ForEntityName = 'WIRE';



EntityParser.prototype.parseEntity = function(scanner, curr) {
    var entity = { 
      type: curr.value,
      children: [],
      dxfStartPos: scanner._pointer,
      dxfEndPos: 0,
    };
    // var self = scanner.dxfParser
    // var debug = false;

    curr = scanner.next();
    while(curr !== 'EOF') {
        if(curr.code === 0) break;


        switch(curr.code) {
          case 2:
            entity.fieldType = curr.value;
            break;
          case 10:
            entity.position = helpers.parsePoint(scanner);
            break;
          case 11:
            entity.direction = {x: curr.value};
            break;
          case 21:
            entity.direction.y = curr.value;
            break;
          case 31:
            entity.direction.z = curr.value;
            break;
          case 90:
            entity.vertices = curr.value;
            break;
          case 70:
            entity.polyline = curr.value; // 1 = closed
            break;
          case 43:
            entity.constantWidth = curr.value;
            break;
          case 42:
            entity.bulge = curr.value;
            break;
          default: // check common entity attributes
            let result = helpers.checkCommonEntityProperties(entity, curr);
            // if (! result) {console.log("Could not find WIRE code", curr, entity)}
            break;
        }
        curr = scanner.next();
    }
    entity.dxfEndPos = scanner._pointer;
    return entity;
};
