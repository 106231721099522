
import * as helpers from '../ParseHelpers'

export default function EntityParser() {}

EntityParser.ForEntityName = 'ATTRIB';

EntityParser.prototype.parseEntity = function(scanner, curr) {
    var entity;
    entity = { type: curr.value , children: []};
    var self = scanner.dxfParser;

    // var debug = false;

    curr = scanner.next();
    while(curr !== 'EOF') {
        if(curr.code === 0) break;
        // console.log("insert scan ", scanner._pointer, curr.code, curr.value)

        switch(curr.code) {
            case 2:
                entity.name = curr.value;
		// if (entity.name.includes("IB8 - REV 1")) { debug = true; }
                break;
            case 8:
                entity.layer = curr.value;
                break;
            case 10:
                entity.position = helpers.parsePoint(scanner);
                break;
            case 41:
                entity.xScale = curr.value;
                break;
            case 42:
                entity.yScale = curr.value;
                break;
            case 43:
                entity.zScale = curr.value;
                break;
            case 50:
                entity.rotation = curr.value;
                break;
            case 70:
                entity.columnCount = curr.value;
                break;
            case 71:
                entity.rowCount = curr.value;
                break;
            case 44:
                entity.columnSpacing = curr.value;
                break;
            case 45:
                entity.rowSpacing = curr.value;
                break;
            case 210:
                entity.extrusionDirection = helpers.parsePoint(scanner);
                break;
            case 100:

			// if ((curr.value === "AcDbEntity" || curr.value === "AcDbBlockReference" || "AcDbPolyline")) {
			// console.log("Found entity", curr);

			let child = {};

			curr = scanner.peek();
			if (curr.code === 66) {curr = scanner.next()}

			// entity.children.push(child);

			// console.log("Found entity next", curr);
			while (curr !== "EOF" && curr.code !== 5 && curr.code !== 0) {
				curr = scanner.next();
				// console.log("insert scan children", curr.code, curr.value, scanner._pointer)
				switch (curr.code) {
					case 8:
						var value = curr.value;
						const typeOriginal = curr.value;
						const startPos = scanner._pointer
						if (curr.value === "TXT") {value = "MTEXT"}
						if (curr.value === "BTEXT") {value = "TEXT"}
						if (curr.value === "BTEXTL") {value = "TEXT"}


            			// if (curr.value !== value) {console.log("CHANGED TO ", value, "FROM", curr.value, scanner._pointer)}


            			// if (scanner.peek().code === 66) {curr = scanner.next().next()}

						var handler = self._entityHandlers[value];
						if (typeof handler !== "undefined") {
							child = handler.parseEntity(scanner, {code: curr.code, value: value});
							if (typeOriginal !== value) {child.typeOriginal = typeOriginal}
							// if (typeOriginal === "BTEXTL") {console.log("BTEXTL", child, scanner._pointer);}
							// console.log("end handler", typeOriginal);

							child.dxfStartPos = startPos;
							child.dxfEndPos = scanner._pointer;
							// console.log("Parsed child automatically", child);
						}
						else {
							child = {
								type: curr.value,
								dxfStartPos: startPos,
								dxfEndPos: scanner._pointer
							};
							// console.log("Parsed child manually");
							// scanner.next();
						}
						if (! entity.children.includes(child)) {
							entity.children.push(child);
						}
						break;


					case 10:
						child.position = helpers.parsePoint(scanner);
						break;
					case 11:
						child.endPoint = helpers.parsePoint(scanner);
						break;
					case 1:
						child.text = (child.text || "") + curr.value;
						child.typeOriginal = child.type;
						child.type = "TEXT";
						break;
					case 3:
						child.typeOriginal = child.type;
						child.type = "TEXT";
						child.text = (child.text || "") + curr.value;
						break;
					case 2:
						child.typeOriginal = child.type;
						child.type = "TEXT";
						
						// child.text = (child.text || "") + curr.value;
						if (child.text) {
							child.attr = curr.value;
						}
						else {
							child.text = curr.value;
						}
						
						break;
					default:
						// console.log("Unknown code for child", child, curr);
						let result = helpers.checkCommonEntityProperties(child, curr);
            			if (! result) {console.log("Could not find INSERT CHILD CODE", curr, child, scanner._pointer)}
						// scanner.next();
						break;
				}
				// curr = scanner.peek();
				curr = scanner.peek();
			}

			if (Object.keys(child).length) { entity.children.push(child) }
	        // }
			else {
				console.log("Ignored Insert entity", curr);
			}
		break;
            default: // check common entity attributes
                helpers.checkCommonEntityProperties(entity, curr);
                break;

        }

        // console.log("insert ", entity, scanner._pointer)


        // if (curr.code !== 0) {
        	curr = scanner.next();
        // }
    }
    return entity;
};
