import { Component } from 'react';

import {
  CardHeading,
  TagFill,
  QuestionCircleFill,
  Diagram3Fill,
  //ChevronBarDown,
  //ArrowBarDown,
  //BellFill,
  ExclamationOctagonFill,
  ExclamationTriangleFill,

  SquareFill,
  SquareHalf,
  Stack
} from 'react-bootstrap-icons';

// import ModuleError from './moduleerror'

import './messagelist.css'
import DxfPage from '../dxfpage';

interface NewLinesProps {
  txt: string;
}

class NewLines extends Component<NewLinesProps> {
  render() {
    const txt = this.props.txt;

    const txts = txt.split('\n');

    let rows: JSX.Element[] = [];
    for (let t in txts) {
      rows.push( <div className="row" key={t}>{txts[t]}</div> );
    }

    return (
      <div className="rows">
        {rows}
      </div>
    );
  }
}

interface MessageListRowProps {
  page: DxfPage;
}

class MessageListRow extends Component<MessageListRowProps> {
  render() {
    const page = this.props.page;

    const module = page.module;
    if (!module) {
      console.error("No module for page", page);
      return null;
    }

    // console.log("PAGE", page);

    const filename = page.filename.split('.')[0];

    return (
      <tr aria-label={filename} title={"Jump to " + filename} className={"MessageListFile-" +filename}>
        <td className="left">
          <a href={"#" + page.id} >{page.filename}</a>
        </td>

        <td className="MessageListRow-RackLetter" title="Rack Letter">{module.rackLetter}</td>
        <td className="MessageListRow-RackNumber" title="Rack Number">{module.rackNumber}</td>
        <td className="MessageListRow-SlotNumber" title="Slot Number">{module.slotNumber}</td>

        <td className={"MessageListRow-Module left " + (module.module?.description ? "" : "error") }>
          <NewLines txt={module.module?.description || "UNKNOWN"} />
        </td>
        <td className="MessageListRow-IO right">
          <a href={"#" + page.id} >{module.io?.length || 0}</a>
        </td>
        <td className={"MessageListRow-Unknowns right " + (module.unknowns ? "error" : 0)}>
          <a href={"#" + page.id} >{module.unknowns || 0}</a>
        </td>
        <td className={"left " + (page.error ? "error" : "") }>
          <a href={"#" + page.id} ><NewLines txt={page.error} /></a>
        </td>
        <td className="left warn">
          <a href={"#" + page.id} ><NewLines txt={page.warn} /></a>
        </td>
      </tr>
    )
  }
}

interface MessageListProps {
  pages: DxfPage[];
}

export default class MessageList extends Component<MessageListProps> {
  render() {
    let pages: JSX.Element[] = [];

    for (let p in this.props.pages) {
      const page = this.props.pages[p];

      pages.push(<MessageListRow page={page} key={page.id} />);
    }

    if (!pages.length) {return null}

    return (
      <div className="MessageList">
        <table cellPadding={0} cellSpacing={0} className="MessageList-Table">
          <thead>
            <tr>
            <th><CardHeading /> File</th>

            <th title="Location / Rack Letter"><SquareFill /></th>
            <th title="Rack Number"><SquareHalf /></th>
            <th title="Slot Number"><Stack /></th>

            <th><Diagram3Fill /> Module</th>
            <th title="Total tags created per page">
              <TagFill />
            </th>
            <th title="Unknown points who did not have a recognized description">
              <QuestionCircleFill />
            </th>
            <th><ExclamationOctagonFill /> Errors</th>
            <th><ExclamationTriangleFill /> Warnings</th>
            </tr>
          </thead>
          <tbody>
            {pages}
          </tbody>
        </table>
      </div>
    )

  }
}
