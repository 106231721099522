import { Component } from 'react';

import TagRow from './tagrow'

// icons
import { TagFill, ChatRightTextFill, Box, LockFill } from 'react-bootstrap-icons';
import { SecondaryTag } from '../filters/secondaries';

interface SecondaryTagsProps {
  secondaries: SecondaryTag[];
}


export default class SecondaryTags extends Component<SecondaryTagsProps> {

  render() {
    const tags = this.props.secondaries;

    // console.log("SecondaryTags", tags);

    if (! tags.length) {
      return null;
    }

    // add rows to table
    let rows: JSX.Element[] = [];
    for (let i in tags) {
    	// console.log("io list", p.io, i);
      rows.push( <TagRow io={tags[i]} key={tags[i].name}/> )
    }


    // return HTML
    return (
        <div className="SecondaryTags">
          <h2>Secondary Tags</h2>
          <h3>Mainly AOIs and UDTs</h3>
          <table cellPadding={0} cellSpacing={0} className="ModulePage-Table">
            <thead>
              <tr>
              <th><TagFill /> Name</th>
              <th><Box /> Data Type</th>
              <th><LockFill /> Is Safety</th>
              <th><ChatRightTextFill /> Description</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
    )
  }
}
