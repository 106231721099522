import type { KnownModule } from "../types/module";

let knownModuleDrives: Array<KnownModule> = [
    {
        trigger: /ACS880-01-02A1-5/,
        pti_partnum: "4028758",
        description: "ABB ACS 880 1HP",
        points: 0,
        isAdapter: true,
        isDrive: true,
    },
    {
        trigger: /ACS355-03U-04A1-4/,
        pti_partnum: "4025970",
        description: "ABB ACS 355 2HP",
        points: 0,
        isAdapter: true,
        isDrive: true,
    },
    {
        trigger: /ACS880-01-(04A8|05A2)-5/,
        pti_partnum: "4026447",
        description: "ABB ACS 880 3HP",
        points: 0,
        isAdapter: true,
        isDrive: true,
    },
    {
        trigger: /ACS880-01-(07A6|076A)-5/, // 18085-R172A
        pti_partnum: "4026447",
        description: "ABB ACS 880 5HP",
        points: 0,
        isAdapter: true,
        isDrive: true,
    },
    {
        trigger: /ACS880-01-11A0-5/, // 18229-R170A
        pti_partnum: "4028091",
        description: "ABB ACS 880 7.5HP",
        points: 0,
        isAdapter: true,
        isDrive: true,
    },
    {
        trigger: /ACS880-01-052A-5/,
        pti_partnum: "4028194",
        description: "ABB ACS 880 40HP",
        points: 0,
        isAdapter: true,
        isDrive: true,
    },
    {
        trigger: /ACS880-01-065A-5/,
        pti_partnum: "4028094",
        description: "ABB ACS 880 50HP",
        points: 0,
        isAdapter: true,
        isDrive: true,
    },
    {
        // EXTRUDER A 8000 - GEAR PUMP DRIVE
        // 75 HP ABB AC DRIVE
        // ACS880-01-096A-6
        trigger: /ACS880-01-096A-5/,
        pti_partnum: "4034999",
        description: "ABB ACS 880 75HP",
        points: 0,
        isAdapter: true,
        isDrive: true,
    },
    {
        trigger: /ACS880-(PC|07)-302A-5/,
        pti_partnum: "4035773",
        description: "ABB ACS 880 250HP",
        points: 0,
        isAdapter: true,
        isDrive: true,
    },
    {
        trigger: /ACS880-(PC|07)-0503A-5/,
        pti_partnum: "4035931",
        description: "ABB ACS 880 400HP",
        points: 0,
        isAdapter: true,
        isDrive: true,
    },
    {
        trigger: /ACS880-(PC|07)-0583A-5/,
        pti_partnum: "4029813",
        description: "ABB ACS 880 450HP",
        points: 0,
        isAdapter: true,
        isDrive: true,
    },
    {
        trigger: /ACS880-(PC|07)-0635A-5/,
        pti_partnum: "4028178",
        description: "ABB ACS 880 500HP",
        points: 0,
        isAdapter: true,
        isDrive: true,
    },
    {
        // EXTRUDER A 8000 - EXTRUDER DRIVE
        // 600 HP ABB AC DRIVE
        // ACS880-07-0715A-5
        trigger: /ACS880-(PC|07)-[0]?715A-5/,
        pti_partnum: "4028178",
        description: "ABB ACS 880 600HP",
        points: 0,
        isAdapter: true,
        isDrive: true,
    },
    // {
    //     // 600 HP ABB AC DRIVE
    //     trigger: /([0-9]+) HP ABB AC DRIVE/,
    //     pti_partnum: "4028178",
    //     description: "ABB ACS 880 {1}",
    //     points: 0,
    //     isAdapter: true,
    //     isDrive: true,
    // },
    {
        trigger: /YASKAWA AC DRIVE GA800/,
        description: "YASKAWA GA800",
        points: 0,
        isAdapter: true,
        isDrive: true,
    },
    {
        trigger: /POWERFLEX 525 .*([0-9]+[.]?[0-9]*) HP/,
        description: "AB POWERFLEX 525",
        points: 0,
        isAdapter: true,
        isDrive: true,
    },
    
    {
        trigger: /ALLEN[ |-]BRADLEY POWERFLEX 525/,
        description: "AB POWERFLEX 525",
        points: 0,
        isAdapter: true,
        isDrive: true,
    },

    {
        trigger: /RMC151E-S2-A1/,
        description: "RMC151E",
        points: 0,
        isAdapter: true,
        isDrive: true,
    },
];

export default knownModuleDrives;